import { OBJModel } from "react-3d-viewer"

export default function ViewerModal(props) {
    const { isOpen, handleModal, footer, style, file } = props;

    return(
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"840px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{file.FL_ORGNAME}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" style={style}>
                        <OBJModel
                            height={800}
                            position={{ x: 0, y: 0, z: 0 }}
                            scale={{ x: 0.3, y: 0.3, z: 0.3 }}
                            src={file.FL_URL}
                            width={800}
                        />
                    </div>
                    <div className="modal-footer">
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    )
}
import useTranslate from "components/language/useTranslate";
import { Link, useNavigate } from "react-router-dom";
import { cm_dateMoment, cm_truncateText } from "utils/common";

export default function BoardSection({BC_LIST, BM_MAP}){
    const t = useTranslate();
    const navigate = useNavigate();

    const handleReadPage = (BC_SEQ) => {
        navigate("/board/boardread", {state: {BC_SEQ: BC_SEQ, boardMaster: BM_MAP}});
    }

    return(
        <div className="section">
            <div className="head">
                <h3 className="h3"><Link to={"/board"}>{t("home_notice")}</Link></h3>
            </div>
            <div className="body">
                <ul className="notice-list">
                    {BC_LIST &&
                        BC_LIST.map((data) => {
                            let key = data.BC_SEQ;
                            let title = cm_truncateText(data.BC_TITLE, 24);
                            let date = cm_dateMoment(data.BC_REGDTTM, "YY/MM/DD");
                            
                            return(
                                <li key={key}>
                                    <button onClick={() => handleReadPage(key)}>
                                        <p>
                                            {/* <i className="noti">공지사항</i> ***게시판 내용 추가시 활성화, marginleft:80px;*** */}
                                            {title}
                                        </p>
                                        <span>{date}</span>
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
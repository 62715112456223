import { userOptionWriteApi } from "api/userApi";
import useTranslate from "components/language/useTranslate";
import { PROSTHETIC_OPTIONS } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useState } from "react";
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function UserProsOptionModal({isOpen, handleModal, selectObj, reLoadOption}){
    const init = {
        UO_SEQ: 0,
        UO_NAME: "",
        UO_TYPE: "P",
        UO_OPTION: [
            {
                key: "contact",
                value: "0"
            },
            {
                key: "embrasure",
                value: "0"
            },
            {
                key: "hook",
                value: "0"
            },
            {
                key: "ponticType",
                value: "0"
            }
        ]
    }
    const store = useStore();
    const US_MAP =  store.US_MAP;
    const [prosOpt, setProsOpt] = useState(selectObj || init);
    const {UO_NAME, UO_OPTION} = prosOpt;
    const prosthetic = PROSTHETIC_OPTIONS();
    const t = useTranslate();

    const [asyncUserOptionWrite, userOptionWritePending] = useAsync(userOptionWriteApi);

    const handleChangeName = (e) =>{
        const {value} = e.target;
        setProsOpt(produce((prevValue) => {
            prevValue.UO_NAME = value;
        }))
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setProsOpt(produce((prevValue) => {
            const newMoOption = prevValue.UO_OPTION.find(x => x.key === name);
            if(newMoOption){
                newMoOption.value = value;
            }
        }))
    }

    const handleSave = async () => {
        if(!saveValidate()) return;

        let response = await asyncUserOptionWrite({
            UO_SEQ : prosOpt.UO_SEQ > 0 ? prosOpt.UO_SEQ : 0,
            US_SEQ : US_MAP.US_SEQ,
            UO_TYPE : "P",
            UO_NAME : prosOpt.UO_NAME,
            UO_OPTION : JSON.stringify(prosOpt.UO_OPTION)
        });
        
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "error", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            reLoadOption();
            handleModal();
        }
    }
    
    function saveValidate(){
        const regExp = /[!@#$%^&]/g; // 특수문자 불가

        let result = true;

        if(regExp.test(prosOpt.UO_NAME)){
            cm_swAlert(t("alert_op_content_1"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(prosOpt.UO_NAME)){
            cm_swAlert(t("alert_op_content_2"), "warning", t("common_check"));
            result = false;
        }

        return result;
    }

    function getOptions(key) {
        return (
            <>
                {prosthetic[key].map((prosOpt) => {
                    return(
                        <option key={`options_${prosOpt.value}`} value={prosOpt.value}>{prosOpt.title}</option>
                    )
                })}
            </>
        )
    }

    return(
        <>
            <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h1 className="modal-title">{t("myPage_addOption")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                        </div>
                        <div className="modal-body">
                            <h4 className="form-tit">{t("myPage_pstheticOptName")}</h4>
                            <div className="inp-box">
                                <input type="text" className="inp block" name="UO_NAME" value={UO_NAME} onChange={handleChangeName} placeholder={t("common_ph")}/>
                            </div>
                            {UO_OPTION.map((option) => {
                                let optionTitleText = t(`common_${option.key}`); 
                                return(
                                    <div key={`box_${option.key}`}>
                                        <h4 key={`${option.key}_h`} className="form-tit">{optionTitleText}</h4>
                                        <div key={`${option.key}_div`} className="inp-box ">
                                            <select className="select block" 
                                                key={`${option.key}_0`}
                                                name={option.key} 
                                                value={option.value || "0"}
                                                onChange={handleChange}
                                            >
                                                {getOptions(option.key)}
                                            </select>
                                        </div>
                                    </div>
                                )    
                            })}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-fill-blue" disabled={userOptionWritePending} onClick={handleSave}>{selectObj? t("common_edit") : t("common_add")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import QRCodes from "components/common/QRCodes";
import useTranslate from "components/language/useTranslate";
import OrderImplantList from "components/order/OrderImplantList";
import OrderSections from "components/order/OrderSections";
import OrderTeethBox from "components/order/OrderTeethBox";
import { _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import { forwardRef } from "react";
import { cm_dateMoment, cm_isEmpty, getProsOpTitle } from "utils/common";

const OrderRequest = forwardRef((props, ref) => {
    const t = useTranslate();
    const {OD_MAP, OL_LIST, OC_LIST} = props.orderMap;
    const commentList = [...OC_LIST].sort((a, b) => a.DTTM < b.DTTM ? -1 : 1);

    const store = useStore();
    const {US_MAP} = store;

    const currentURL = window.location.origin;

    return (
        <div ref={ref} className="home-main-box" id="orderRequest">
            <div className="order-request">
                <div className="title">
                    <strong>{t("order_teethConstructionRequest")}</strong>
                </div>

                {US_MAP.CO_TYPE === _CO_TYPE_LAB &&
                    <div className="qrcode" style={{display:"flex", justifyContent:"flex-end"}}>
                        <QRCodes OD_MAP={OD_MAP} option={{url:`${currentURL}/order/autoShipping`, o:OD_MAP.OD_SEQ}}>
                            <p>{t("order_step_shipping")}</p>
                        </QRCodes>
                    </div>
                }

                <div className="col">
                    <div className="table2">
                        <table>
                            <colgroup>
                                <col style={{width:"20%"}}/>
                                <col style={{width:"30%"}}/>
                                <col style={{width:"20%"}}/>
                                <col style={{width:"30%"}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td>{t("payment_orderNum")}</td><td>{cm_dateMoment(OD_MAP.OD_REGDTTM, "YYMMDDHHmmss")}</td>
                                    <td>{t("common_patientName")}</td>
                                    <td><strong>{OD_MAP.OD_NAME}</strong></td>
                                </tr>
                                <tr>
                                    <td>{t("common_dt")}</td><td>{OD_MAP.CO_NAME}</td>
                                    <td>{t("common_dtl")}</td><td>{OD_MAP.LAB_NAME}</td>
                                </tr>
                                <tr>
                                <td>{t("delivery_orderDay")}</td><td>{cm_dateMoment(OD_MAP.OD_REGDTTM, "YYYY-MM-DD")}</td>
                                    <td>{t("order_info_deadline")}</td><td>{OD_MAP.OD_DUEDATE}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col" style={{margin:"20px 0"}}>
                    <OrderTeethBox OL_LIST={OL_LIST} OD_BRIDGE={!cm_isEmpty(OD_MAP.OD_BRIDGE) ? JSON.parse(OD_MAP.OD_BRIDGE) : []} viewer={true}/>
                </div>

                <div className="col">
                    <div className="box block type2">
                        <div className="tit flex justify-content-between">
                            <h4 className="h4 static">{t("order_prodPsthetic")}</h4>
                            <div className="types">
                                <p><i className="xi-close"></i><span>Pontic</span></p>
                            </div>
                        </div>
                        <div className="tooth-list3">
                            <OrderSections OL_LIST={OL_LIST}/>
                        </div>
                    </div>
                </div>
                
                <div className="col-box">
                    <div className="box type2">
                        <div className="tit">
                            <h4 className="h4">{t("order_productOption")}</h4>
                        </div>

                        <div className="info">
                            <div className="section">
                                <dl className="box type4">
                                    <dt>{t("common_contact")}</dt>
                                    <dd>{getProsOpTitle("contact", OD_MAP.OD_CONTACT)}</dd>
                                </dl>
                                <dl className="box type4">
                                    <dt>{t("common_embrasure")}</dt>
                                    <dd>{getProsOpTitle("embrasure", OD_MAP.OD_EMBRASURE)}</dd>
                                </dl>
                                <dl className="box type4">
                                    <dt>{t("common_ponticType")}</dt>
                                    <dd>{getProsOpTitle("ponticType", OD_MAP.OD_PONTIC)}</dd>
                                </dl>
                                <dl className="box type4">
                                    <dt>{t("common_hook")}</dt>
                                    <dd>{getProsOpTitle("hook", OD_MAP.OD_HOOK)}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <OrderImplantList OL_LIST={OL_LIST}/>
                </div>

                <div className="col">
                    <div className="box block" style={{minHeight:"170px"}}>
                        <div className="tit">
                            <h4 className="h4">{t("home_orderList")}</h4>
                        </div>
                        <div className="text">
                            <ul className="order-comment-list">
                                {
                                    <>
                                        { OD_MAP.OD_MEMO && 
                                            <>
                                                <li key={"memo_" + OD_MAP.OD_SEQ} className="item">
                                                    <div className="type">
                                                        <div className={`typeC`}>{OD_MAP.CO_NAME}</div>
                                                    </div>
                                                    <div className="text">{OD_MAP.OD_MEMO}</div>
                                                    <div className="time">{cm_dateMoment(OD_MAP.OD_REGDTTM, "YYYY-MM-DD HH:mm")}</div>
                                                </li>
                                            </>
                                        }
                                        {
                                            commentList.map((comment) => {
                                                return (
                                                    <li key={"comment_" + comment.OC_SEQ} className="item">
                                                        <div className="type">
                                                            <div className={`type${comment.CO_TYPE}`}>{comment.CO_NAME}</div>
                                                        </div>
                                                        <div className="text">{comment.OC_TEXT}</div>
                                                        <div className="time">{cm_dateMoment(comment.DTTM, "YYYY-MM-DD HH:mm")}</div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default OrderRequest;
import { useRef } from "react";
import { checkFileSize, cm_isEmpty, cm_swAlert } from "utils/common";
import '../css/drop-file-input.css';
import useTranslate from "./language/useTranslate";

const DropFileInput = props => {
    const maxSize = 400 * 1024 * 1024; // 400 mb
    const wrapperRef = useRef(null);
    const {onChangeFile, deleteFile, fileList, type, accept = null, children} = props;
    const t = useTranslate();

    const onDragEnter = () => wrapperRef.current.classList.add("dragover");
    const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
    const onDrop = () => wrapperRef.current.classList.remove("dragover");

    const onFileDrop = (e) => {
        let newFiles = [...fileList];
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];

            if(type) {
                file.ref = type;
            }

            if(!checkFileSize(file.size, maxSize)){
                cm_swAlert(`${maxSize / 1024 / 1024} MB. ${t("common_content_1")}`, "warning", t("common_check"));
                return;
            }

            newFiles.push(file);
        }

        onChangeFile(newFiles);
    }

    const fileRemove = (file) => {
        if(!cm_isEmpty(file.FL_SEQ)){
            deleteFile(file.FL_SEQ);
        }

        const newFiles = [...fileList];
        newFiles.splice(fileList.indexOf(file), 1);
        onChangeFile(newFiles);
    }

    return (
            <div
                ref={wrapperRef}
                className="dragdrop mb10"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div>
                    <div>
                        <input type="file" id={type} multiple value="" onChange={onFileDrop} accept={accept}/>
                        <label className="box" htmlFor={type}>
                            <p>Drag &amp; Drop</p>
                            <span>{t("common_searchFile")}</span>
                            {children}
                        </label>
                    </div>
                    <div className="add-list auto">
                    {fileList.length > 0 ? (
                            <ul>
                                {fileList.map((item, index) => {
                                    const fileName = cm_isEmpty(item.FL_SEQ) ? item.name : item.FL_ORGNAME;
                                    const fileSize = cm_isEmpty(item.FL_SEQ) ? Math.round(item.size / 1024): Math.round(item.FL_SIZE); // KB

                                    return(
                                        <li key={"fileList_" + index}>
                                            <div href="#" className="file">{fileName + " (" + fileSize + "KB)"}</div>
                                            <button className="btn btn-ico" onClick={() => fileRemove(item)}><i className="xi-close"></i></button>
                                        </li>
                                    )
                                })}
                            </ul>
                    ) : null}
                    </div>
                </div>
            </div>
    )
}

export default DropFileInput;
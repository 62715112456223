import { axio } from 'utils/common';
import { API } from 'config';

// CONFIG
export async function configListApi(data){
    return await axio(API.CONFIG_LIST, "POST", data);
}

export async function configReadApi(data){
    return await axio(API.CONFIG_READ, "POST", data);
}

export async function configWriteApi(data){
    return await axio(API.CONFIG_WRITE, "POST", data);
}

export async function configWriteListApi(data){
    return await axio(API.CONFIG_WRITE_LIST, "POST", data);
}

export async function configUpdateApi(data){
    return await axio(API.CONFIG_UPDATE, "POST", data);
}

export async function configDeleteApi(data){
    return await axio(API.CONFIG_DELETE, "POST", data);
}




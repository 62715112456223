import useTranslate from "components/language/useTranslate";
import { NAVIS } from "config";
import { useOrder } from "contexts/OrderContext";
import { useStore } from "contexts/Store";
import { useLocation, useNavigate } from "react-router-dom";
import { cm_isEmpty, cm_swConfirm } from "utils/common";

const SideBar = () => {
    const store = useStore();
    const {US_TYPE} = !cm_isEmpty(store.US_MAP) ? store.US_MAP : "";
    const {CO_TYPE} = !cm_isEmpty(store.US_MAP) ? store.US_MAP : "";
    const navi = NAVIS();
    const userNavi = navi.filter(x => x.userType.indexOf(US_TYPE) !== -1 && x.coType.indexOf(CO_TYPE) !== -1);
    const location = useLocation();
    const navigate = useNavigate();
    const order = useOrder();
    const CONTEXT = process.env.REACT_APP_CONTEXT;
    const t = useTranslate();

    const currentURL = location.pathname;

    async function movePage(to){
        let originURL = window.location.origin;

        if(!cm_isEmpty(CONTEXT)){
            originURL = originURL + "/" + CONTEXT;
        }

        let moveURL = originURL + "/" + to;

        if(currentURL !== "/order/orderWrite"){
            window.location.href = moveURL;
        }else{
            if(cm_isEmpty(order.OD_NAME)){
                navigate(to);
            }else {
                if(await cm_swConfirm(t("common_content_17"), "", "", t("common_yes"), t("common_no"))){
                    window.location.href = moveURL;
                }
            }
        }
    }

    return (
        <aside className="aside-user">
            <h1 className="logo"><span className="hidden">NeXways</span></h1>
            <nav>
                <ul>
                    {userNavi.map((data) => {
                        let pathName = location.pathname.split('/');
                        pathName = pathName.slice(1).join('/');

                        return(
                            <li key={data.to} className={pathName === data.to ? "active" : null}>
                                <button className={data.navClass} onClick={() => movePage(data.to)}>{data.title}</button>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        </aside>
    );
};

export default SideBar;
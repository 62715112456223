import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER } from "config";
import { PLA_STATUS } from "config";
import { useStore } from "contexts/Store";
import { useState } from "react";

export default function SelectPlaStatus({OD_MAP, orderChangeStatus}) {
    const store = useStore();
    const {US_MAP} = store;

    const plaStatus = PLA_STATUS();

    const [isSelectPlaStatus, setIsSelectPlaStatus] = useState(false);
    const handleSelectPlaStatus = () => {setIsSelectPlaStatus((prevValue) => !prevValue);}

    return (
        <>
            <i className={(`print-img ${plaStatus.find(x => x.key === OD_MAP.OD_PLASTATUS).class}`)} onClick={US_MAP.CO_TYPE === _CO_TYPE_CENTER ? handleSelectPlaStatus : null}/>
            {isSelectPlaStatus &&
                <SelectStatus OD_MAP={OD_MAP} orderChangeStatus={orderChangeStatus} handleSelectPlaStatus={handleSelectPlaStatus}/>
            }
        </>
    )
}

function SelectStatus({OD_MAP, orderChangeStatus, handleSelectPlaStatus}){
    const t = useTranslate();

    const [plaStatus, setPlaStatus] = useState(OD_MAP.PLA_STATUS);

    const handlePlaStatus = (e) => {
        const OD_PLASTATUS = e.target.value;

        setPlaStatus(OD_PLASTATUS);
        handleSelectPlaStatus();
        orderChangeStatus(OD_PLASTATUS, "Y");
    }

    return (
        <select className="select" value={plaStatus} onChange={handlePlaStatus}>
            <option value={0}>{t("common_choice")}</option>
            <option value={"S"}>{t("print_status_complete")}</option>
        </select>
    )
}
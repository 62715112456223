function Section({ title, button, children }){
    // title, button = head
    // childre은 body 
    return(
        <div className="section">
            {/* head */}
            {title &&
                <>
                    <div className="head">
                        <h3 className="h3">{title}</h3>
                    </div>
                    <div className="btn-box">
                        {button}
                    </div>
                </>
            }
            {/* body */}
            {children}
        </div>
    )
}


export default Section
import { companyPaymentWriteApi } from "api/companyApi";
import useTranslate from 'components/language/useTranslate';
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useState } from "react";
import { cm_formatCardExpried, cm_formatCardNo, cm_isEmail, cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function MyPaymentAdd({CO_SEQ, handleReLoad, VIEW_TITLE}){
    const t = useTranslate();
    const initPayment = {
        CO_SEQ : CO_SEQ,
        CA_METHOD : "B",
        CA_BANKCODE : "",
        CA_EMAIL : "",
        CA_TAXEMAIL : "",
        CA_FAX : "",
        CA_NO : "",
        card_pw : "",
        card_identity_no : "",
        card_expire_year : "",
        card_expire_month : "",
        card_expire : "",
    }
    
    const [memberPayment, setMemberPayment] = useState(initPayment);
    const [asyncCompanyPaymentWrite, companyPaymentPending] = useAsync(companyPaymentWriteApi);

    // change
    const handleChangePayment = (e) => {
        let {name, value} = e.target;
        setMemberPayment(produce((prevValue) => {
            switch (name) {
                case "CA_NO" : case "CA_FAX" : case "card_pw" : case "card_identity_no" : case "card_expire" :
                    value = value.replace(/[^0-9]/g, ''); break;
                default :
                    break;
            }
            prevValue[name] = value;
        }));
    }

    // savePayment
    const handleSave = async () => {
        if(!saveValidate()) return;

        const response = await asyncCompanyPaymentWrite({
            CO_SEQ : memberPayment.CO_SEQ,
            CA_METHOD : memberPayment.CA_METHOD,
            CA_BANKCODE : memberPayment.CA_BANKCODE,
            CA_EMAIL : memberPayment.CA_EMAIL,
            CA_TAXEMAIL : memberPayment.CA_TAXEMAIL,
            CA_FAX : memberPayment.CA_FAX,
            CA_NO : memberPayment.CA_NO,
            card_pw : memberPayment.card_pw,
            card_identity_no : memberPayment.card_identity_no,
            card_expire_month : memberPayment.card_expire.slice(0, 2),
            card_expire_year : memberPayment.card_expire.slice(2, 4),
        });
        if(!resApiCheck(response)){
            cm_swAlert(t("common_regiFailed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("common_regiSuccess"), "success", t("common_check"));
            handleReLoad();
        }
    }

    function saveValidate(){
        const {CA_METHOD, CA_EMAIL, CA_TAXEMAIL, CA_NO, card_pw, card_identity_no, card_expire}  = memberPayment;

        let result = true;

        if(cm_isEmpty(CA_METHOD)){
            cm_swAlert(t("alert_pay_content_10"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(CA_EMAIL)){
            cm_swAlert(t("alert_pay_content_1"), "warning", t("common_check"));
            result = false;
        }else if(!cm_isEmail(CA_EMAIL, false, t("common_alert_wrongFormEmail"))){
            cm_swAlert(t("common_alert_wrongFormEmail"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(CA_TAXEMAIL)){
            cm_swAlert(t("alert_pay_content_12"), "warning", t("common_check"));
            result = false;
        }else if(!cm_isEmail(CA_TAXEMAIL, false, t("common_alert_wrongFormEmail"))){
            cm_swAlert(t("common_alert_wrongFormEmail"), "warning", t("common_check"));
            result = false;
        }else if(CA_METHOD === "C"){
            if(cm_isEmpty(CA_NO) || CA_NO.length < 16){
                cm_swAlert(t("alert_pay_content_3"),  "warning", t("common_check"));
                result = false;
            }else if(cm_isEmpty(card_pw) || card_pw < 2){
                cm_swAlert(t("alert_pay_content_4"),  "warning", t("common_check"));
                result = false;
            }else if(cm_isEmpty(card_identity_no)){
                cm_swAlert(t("alert_pay_content_5"),  "warning", t("common_check"));
                result = false;
            }else if(cm_isEmpty(card_expire) || card_expire.length < 4){
                cm_swAlert(t("alert_pay_content_6"),  "warning", t("common_check"));
                result = false;
            }
        }

        return result;
    }

    return(
        <div className="form-inner">
            {VIEW_TITLE && <h3 className="h3" >{t("myPage_payWay")}</h3>}
            <h4 className="form-tit">{t("myPage_payMethod")}</h4>
            <div className="flex gap12 align-items-center mb20">
                <label>
                    <input type="radio" 
                        className="radio" 
                        name="CA_METHOD" 
                        value="B" 
                        onChange={handleChangePayment}
                        checked={memberPayment.CA_METHOD === "B"} 
                    />
                    <div><em></em><p>{t("myPage_directDepo")}</p></div>
                </label>
                {/* <label>
                    <input 
                        type="radio" 
                        className="radio" 
                        name="CA_METHOD" 
                        value="C"
                        onChange={handleChangePayment}
                        checked={memberPayment.CA_METHOD === "C"} 
                    />
                    <div><em></em><p>{t("myPage_creditCheck")}</p></div>
                </label> */}
            </div>
            {memberPayment.CA_METHOD === "C" &&
                <MyPaymentAddCard memberPayment={memberPayment} handleChangePayment={handleChangePayment} />
            }

            <h4 className="form-tit">{t("myPage_billMail")}</h4>
            <div className="inp-box inp-btn-box">
                <input type="text" 
                    className="inp block" 
                    name="CA_EMAIL" 
                    value={memberPayment.CA_EMAIL}
                    onChange={handleChangePayment}
                    placeholder={t("myPage_billMail")}
                />
            </div>
            {!cm_isEmpty(memberPayment.CA_EMAIL) && !cm_isEmail(memberPayment.CA_EMAIL, false, t("common_alert_wrongFormEmail")) && 
                <div className="form-msg txt-red">{t("common_content_7")}</div>
            }
            <h4 className="form-tit">{t("myPage_taxMail")}</h4>
            <div className="inp-box inp-btn-box">
                <input type="text" 
                    className="inp block" 
                    name="CA_TAXEMAIL" 
                    value={memberPayment.CA_TAXEMAIL}
                    onChange={handleChangePayment}
                    placeholder={t("myPage_taxMail")}
                />
            </div>
            {!cm_isEmpty(memberPayment.CA_TAXEMAIL) && !cm_isEmail(memberPayment.CA_TAXEMAIL, false, t("common_alert_wrongFormEmail")) && 
                <div className="form-msg txt-red">{t("common_content_7")}</div>
            }
            <h4 className="form-tit">{t("myPage_faxNumber")}</h4>
            <div className="inp-box inp-btn-box">
                <input type="text" 
                    className="inp block" 
                    name="CA_FAX" 
                    value={memberPayment.CA_FAX}
                    onChange={handleChangePayment}
                />
            </div>

            <div className="btn-common-box">
                <button className="btn btn-fill-gray" onClick={handleReLoad}>{t("common_cancel")}</button>
                <button className="btn btn-fill-blue" disabled={companyPaymentPending} onClick={handleSave}>{t("common_regi")}</button>
            </div>
        </div>
    )
}

function MyPaymentAddCard({memberPayment, handleChangePayment}){
    const t = useTranslate();
    return(
        <>
            <h4 className="form-tit">{t("myPage_cardNum")}</h4>
            <div className="inp-box">
                <input type="text" 
                    className="inp block" 
                    name="CA_NO" 
                    value={cm_formatCardNo(memberPayment.CA_NO)} 
                    onChange={handleChangePayment}
                    placeholder={t("myPage_cardNum")}
                    maxLength="19"
                />
            </div>
            <h4 className="form-tit">{t("myPage_cardPw")}</h4>
            <div className="inp-box">
                <input type="password" 
                    className="inp block" 
                    name="card_pw" 
                    value={memberPayment.card_pw} 
                    onChange={handleChangePayment}
                    placeholder={t("myPage_cardPwPh")}
                    maxLength="2"
                />
            </div>
            <h4 className="form-tit">{t("myPage_cardValiPeriod")}</h4>
            <div className="inp-box">
                <input type="text" 
                    className="inp block" 
                    name="card_expire" 
                    value={cm_formatCardExpried(memberPayment.card_expire)} 
                    onChange={handleChangePayment}
                    placeholder={t("myPage_cardValiPeriodPh")} 
                    maxLength="5" 
                />
            </div>
            <h4 className="form-tit">{t("myPage_birthdayOrBisNum")}</h4>
            <div className="inp-box">
                <input type="text" 
                    className="inp block" 
                    name="card_identity_no" 
                    value={memberPayment.card_identity_no} 
                    onChange={handleChangePayment}
                    placeholder={t("myPage_birthdayOrBisNumPh")}
                    maxLength="10"
                />
            </div>
        </>
    )
}


import { companyDeviceListApi, companyPlanListApi } from "api/companyApi";
import { ex_tableToExcel } from "components/common/Excel";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_LAB } from "config";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_dateAddMonth, cm_dateMoment, cm_isEmpty, resApiCheck } from "utils/common";
import AdjustHistory from "./AdjustHistory";
import LabInvoiceForm from "./LabInvoiceForm";
import PayDeviceInfo from "./PayDeviceInfo";
import PayOrderList from "./PayOrderList";
import PayPlanInfo from "./PayPlanInfo";

export default function DetailPayment({ payInfo, account, setAccount, MONTH, CO_TYPE, handleReLoad,}) {
    const [tap, setTap] = useState("orderLine");
    const [companyPlanList, setCompanyPlanList] = useState(null);
    const [companyDeviceList, setCompanyDeviceList] = useState(null);
    
    //API
    const [asyncCompanyPlanList] = useAsync(companyPlanListApi);
    const [asyncCompanyDeviceList] = useAsync(companyDeviceListApi);
    
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        //Company Plan List
        const resCompanyPlanList = await asyncCompanyPlanList({
            CO_SEQ : account.CO_SEQ,
            FT_FRMONTH : cm_dateMoment(MONTH, "YYYY-MM"),
            FT_TOMONTH : cm_dateMoment(MONTH, "YYYY-MM")
        })
        if(!resApiCheck(resCompanyPlanList)) return;
        if(resCompanyPlanList.data.LIST_COUNT > 0){
            setCompanyPlanList(resCompanyPlanList.data.LIST);
        }

        //Company Device List
        const resCompanyDeviceList = await asyncCompanyDeviceList({
            CO_SEQ : account.CO_SEQ,
            FT_FRMONTH : cm_dateMoment(MONTH, "YYYY-MM"),
            FT_TOMONTH : cm_dateMoment(MONTH, "YYYY-MM")
        })
        if(!resApiCheck(resCompanyDeviceList)) return;
        if(resCompanyDeviceList.data.LIST.length > 0){
            setCompanyDeviceList(resCompanyDeviceList.data.LIST);
        }
    }

    const handleChangeTap = (e) => setTap(e.target.value);


    function tapView(){
        switch (tap){
            case "orderLine" :
                return <PayOrderList tap={tap} account={account} MONTH={MONTH} CO_TYPE={CO_TYPE}/>;
            case "plan" :
                return <PayPlanInfo tap={tap} companyPlanList={companyPlanList}/>;
            case "device" :
                return <PayDeviceInfo tap={tap} companyDeviceList={companyDeviceList} DV_CON_LIST={payInfo.DV_CON_LIST}/>;
            case "invoice" :
                return <LabInvoiceForm CO_TYPE={CO_TYPE} account={account} setAccount={setAccount} MONTH={cm_dateMoment(cm_dateAddMonth(account.AB_MONTH  + "-01", -1))} handleReLoad={handleReLoad}/>
            case "adjust" :
                return <AdjustHistory tap={tap} account={account} />
            default :
                return <></>;
        }
    }

    //Excel
    const handleExcel = (tableId) =>{
        let fileName = "";
        let sheetName = "";

        if(tableId === "orderLine"){
            fileName = t("payment_pstheticDetail");
            sheetName = t("payment_pstheticDetail");
        }else if(tableId === "plan"){
            fileName = t("common_planInfo");
            sheetName = t("common_planInfo");
        }else if(tableId === "device"){
            fileName = t("payment_deviceInfo");
            sheetName = t("payment_deviceInfo");
        }

        ex_tableToExcel(tableId, fileName, sheetName);
    }

    return(
        <div className="inquiry-box pd20" id="InquiryDetail">
            <div className="flex justify-content-between align-items-center mb20">
                <div className="tabs4">
                    <button className={tap === "orderLine" ? "active" : null} value={"orderLine"} onClick={handleChangeTap}>{t("payment_pstheticDetail")}</button>
                    <button className={tap === "plan" ? "active" : null} value={"plan"} onClick={handleChangeTap}>{t("common_planInfo")}</button>
                    <button className={tap === "device" ? "active" : null} value={"device"} onClick={handleChangeTap}>{t("payment_deviceInfo")}</button>
                    {!cm_isEmpty(account.AB_SEQ) &&
                        <button className={tap === "adjust" ? "active" : null} value={"adjust"} onClick={handleChangeTap}>{t("adjust_list")}</button>
                    }
                    {CO_TYPE === _CO_TYPE_LAB &&
                        <button className={tap === "invoice" ? "active" : null} value={"invoice"} onClick={handleChangeTap}>{"Invoice"}</button>
                    }
                </div>
                <div className="flex gap10 align-items-center">
                    {tap !== "invoice" &&
                        <button className="btn btn-fill-gray2 btn-s" onClick={() => handleExcel(tap)}><i className="ico-excel"></i> EXCEL </button>
                    }
                </div>
            </div>
            {tapView()}
        </div>
    )
}
import { signInApi } from "api/commonApi";
import { useTranslate } from "components/language/useTranslate";
import { useSetStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import useInput from "hooks/useInput";
import logo from "img/common/logo_login.png";
import { produce } from "immer";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function SignIn() {
    const t = useTranslate();
    const navigate = useNavigate();
    const setStore = useSetStore();
    const idRef = useRef();
    
    const [rememberID, setRememberID] = useState(false);
    const handleRememberID = () => setRememberID(prevValue => !prevValue);

    let initMember = {
        US_EMAIL : "",
        US_PWD : ""
    }

    useEffect(() => {
        if(localStorage.getItem("rememberID")){
            handleRememberID();
            initMember.US_EMAIL = localStorage.getItem("rememberID");
        }

        idRef.current.focus();
    },[]);

    const [memberState, onChange] = useInput(initMember);
    const {US_EMAIL, US_PWD} = memberState;

    const [asyncSignIn, signInPending] = useAsync(signInApi);

    const [signFailText, setSignFailText] = useState(null);
    const [capsLockOn, setCapsLockOn] = useState(false);

    const onKeyPress = (e) => {
        if(e.key === "Enter") signIn(e);
    }

    const handleCapsLock = (e) => {
        setCapsLockOn(e.getModifierState("CapsLock"));
    }

    const signIn = async (e) => {
        e.preventDefault();
        if(!signInValidate()) return;
        
        let response = await asyncSignIn(memberState);
        if(!resApiCheck(response)) return;
        const {RESULT} = response.data;

        if(RESULT === "OK"){
            const {US_MAP} = response.data;

            setStore(produce((prevValue) => {
                prevValue.US_MAP = US_MAP;
            }))

            if(rememberID){
                localStorage.setItem("rememberID", US_EMAIL);
            }else{
                localStorage.removeItem("rememberID");
            }
            
            // Check temporary password
            if(US_MAP.US_PWDTEMP === "Y"){
                navigate("/mypage", {state :"UserPwd"});
            }else{
                navigate("/home");
            }

        }else{
            setSignFailText(signInErrorText(RESULT));
        }
    }

    function signInValidate(){
        const {US_EMAIL, US_PWD} = memberState;

        let result = true;

        if(cm_isEmpty(US_EMAIL)){
            cm_swAlert(t("alert_input_id"),  "warning", t("common_check"));
            document.getElementById("US_EMAIL").focus();
            result = false;
        }else if(cm_isEmpty(US_PWD)){
            cm_swAlert(t("alert_input_pwd"),  "warning", t("common_check"));
            document.getElementById("US_PWD").focus();
            result = false;
        }

        return result;
    }

    function signInErrorText(RESULT){
        let txt = "";
        
        switch(RESULT){
            case "NO_ID" :
            case "NO_PWD" :
                txt = t("msg_signin_error");
                break;
            case "MAX_PWD" :
                txt = t("alert_maxPwd");
                break;
            default :
                txt = t("alert_input_idpwd");
                break;
        }

        return txt;
    }

    return (
        
            <div className="login-wrap">
                <div className="login-banner">
                    <h1 className="logo"><img src={logo} alt="NeXways"/></h1>
                    <h2>{t("signIn_signInTitle")}</h2>
                    <p>{t("signIn_content_1")}<br/>
                        {t("signIn_context_2")}</p>
                </div>
                <div className="login-box">
                    <div>
                        <h1>{t("signIn_welcome2")}</h1>
                        <h2 className="form-tit">{t("common_email")}</h2>
                        <div className="inp-box">
                            <input ref={idRef} type="text" className="inp block" id="US_EMAIL" name="US_EMAIL" value={US_EMAIL} onChange={onChange}/>
                        </div>
                        <h2 className="form-tit">{t("common_pw")}</h2>
                        <div className="inp-box mb15">
                            <input type="password" className="inp block" id="US_PWD" name="US_PWD" value={US_PWD} onChange={onChange} onKeyDown={onKeyPress} onKeyUp={handleCapsLock} />
                        </div>
                        <div className="mb10">
                            <label>
                                <input type="checkbox" className="checkbox" checked={rememberID} onChange={handleRememberID}/>
                                <div>
                                    <em></em>
                                    <p>{t("signIn_remId")}</p>
                                    {capsLockOn && <p className="txt-red">Caps Lock Is ON.</p>}
                                </div>
                            </label>
                        </div>

                        <div className="link-wrap">
                            <div className="find"><Link to={"/findpwd"}>{t("common_findPwd")}</Link></div>
                            <div className="signup"><Link to={"/signup"}>{t("signUp")}</Link></div>
                        </div>

                        {signFailText && <div id="error_msg" className="form-msg txt-red">{signFailText}</div>}

                        <div className="btn-common-box">
                            <button className="btn btn-fill-blue block" onClick={signIn} disabled={signInPending}>{t("signIn")}</button>
                        </div>
                        
                    </div>
                </div>
            </div>
    );
};
import MyList from 'components/mypage/MyList';
import { MyInfoProvider } from 'contexts/MyInfoContext';

export default function MyPage(){
    return(
        <>
            <MyInfoProvider>
                <MyList /> 
            </MyInfoProvider>
        </>
    )
}
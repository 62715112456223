import { RadioContext } from "contexts/RadioContext";
import { useContext } from "react";

function Radio({ children, value, name, defaultChecked, disabled }) {
    const group = useContext(RadioContext);

    return (
        <label className="radio-item">
            <input
                type="radio"
                className="radio"
                value={value}
                name={name}
                disabled={disabled || group.disabled}
                checked={group.value !== undefined ? value === group.value : undefined}
                onChange={(e) => group.onChange && group.onChange(e.target.value)}
            />
            <span>{children}</span>
        </label>
    );
}

export default Radio
import { configListApi } from "api/configApi";
import { userDeleteApi, userUpdateApi } from "api/userApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER } from "config";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect } from "react";
import { useState } from "react";
import { cm_isEmpty, cm_isNumeric, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function UserDetail({CO_TYPE, selectUser, setSelectUser, reLoad}) {
    const [edit, setEdit] = useState(false);
    const [userTypeList, setUserTypeList] = useState(null);
    const {US_MAP} = selectUser;

    //API
    const [asyncUserUpdate] = useAsync(userUpdateApi);
    const [asyncUserDelete, userDeletePending] = useAsync(userDeleteApi);
    const [asyncConfigList] = useAsync(configListApi);

    const t = useTranslate();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        // Config US_TYPE list
        const response = await asyncConfigList({CF_TYPE : "US_TYPE"});
        if(!resApiCheck(response)) return;
        if(response.data.LIST.length > 0){
            setUserTypeList(response.data.LIST);
        }
    }

    //edit
    const handleChangeFlag = () => {
        if(edit){
            handleUserUpdate();
        }
        setEdit((prevValue) => !prevValue);
    }

    const handleChangeSelectUser = (e) => {
        let {name, value} = e.target;

        if (name === "US_PHONE"){
            if (e.target.value.length > 11) return;
            if (!cm_isNumeric(value)) return;
        }

        setSelectUser(produce((prevValue) => {
            prevValue.US_MAP[name] = value;
        }));
    }

    //User Update
    const handleUserUpdate = async () => {
        if(!userUpdateValidate()) return;

        const response = await asyncUserUpdate({
            US_SEQ : US_MAP.US_SEQ,
            US_TYPE : US_MAP.US_TYPE,
            US_NAME : US_MAP.US_NAME,
            US_PHONE : US_MAP.US_PHONE,
            US_NOTICE : US_MAP.US_NOTICE
        })
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            reLoad();
        }
    }

    function userUpdateValidate() {
        let result = true;
        let text = "";

        if(cm_isEmpty(US_MAP.US_NAME)){
            text = t("alert_inputName");
            result = false;
        }else if(cm_isEmpty(US_MAP.US_PHONE)){
            text = t("alert_inputPhone");
            result = false;
        }

        if(!result) cm_swAlert(text, "info");

        return result;
    }

    //User Delete
    const handleUserDelete = async () => {
        if(!userDeleteValidate()) return;

        const confirm = await cm_swConfirm(t("confirm_user_delete"), "", "info", t("common_yes"), t("common_no"));
        if(confirm){
            const response = await asyncUserDelete({
                US_SEQ : US_MAP.US_SEQ
            })
            if(!resApiCheck(response)){
                cm_swAlert(t("alert_delete_failed"), "warning", t("common_check"));
            }else{
                cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
                reLoad();
                setSelectUser(null);
            }
        }
    }

    function userDeleteValidate() {
        let result = true;
        let text = "";
        if(US_MAP.US_STATUS !== "A"){
            text = t("alert_deleteUser");
            result = false;
        }

        if(!result) cm_swAlert(text, "info");

        return result;
    }

    return(
        <>
            {userTypeList &&
                <>
                    <div className="board-write no-border">
                        <table>
                            <colgroup>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "35%"}}/>
                                <col style={{width: "15%"}}/>
                                <col style={{width: "35%"}}/>
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>{t("common_name")}</th>
                                    <td><input type="text" className="inp block" name="US_NAME" value={US_MAP.US_NAME} onChange={handleChangeSelectUser} disabled={!edit} maxLength={33} /></td>
                                    <th>{t("common_callNumber")}</th>
                                    <td><input type="text" className="inp block" name="US_PHONE" value={US_MAP.US_PHONE} onChange={handleChangeSelectUser} disabled={!edit} maxLength={11}/></td>
                                </tr>
                                <tr>
                                    <th>{t("common_email")}</th>
                                    <td><input type="text" className="inp block" name="US_EMAIL" value={US_MAP.US_EMAIL} disabled/></td>
                                    <th>{t("common_mbType")}</th>
                                    <td>
                                        <select className="select block" key="US_TYPE" name="US_TYPE" value={US_MAP.US_TYPE} onChange={handleChangeSelectUser} disabled={!edit} >
                                            {CO_TYPE !== _CO_TYPE_CENTER ?
                                                userTypeList.filter(x => x.CF_VAL1 < 10).map((userType) => {
                                                    return(
                                                        <option key={userType.CF_CODE} value={userType.CF_CODE}>{userType.CF_TITLE}</option>
                                                    )
                                                })
                                                :
                                                userTypeList.filter(x => x.CF_VAL1 >= 10).map((userType) => {
                                                    return(
                                                        <option key={userType.CF_CODE} value={userType.CF_CODE}>{userType.CF_TITLE}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{t("common_receiveNotice")}</th>
                                    <td>
                                        <div className="flex gap10" style={{width:"60%", justifyContent:"space-evenly"}}>
                                            <label>
                                                <input type="radio" className="radio" name="US_NOTICE" value={"Y"} checked={"Y" === US_MAP.US_NOTICE} onChange={handleChangeSelectUser} disabled={!edit}/>
                                                <div><em></em><p>{t("common_yes")}</p></div>
                                            </label>
                                            <label>
                                                <input type="radio" className="radio" name="US_NOTICE" value={"N"} checked={"N" === US_MAP.US_NOTICE} onChange={handleChangeSelectUser} disabled={!edit}/>
                                                <div><em></em><p>{t("common_no")}</p></div>
                                            </label>
                                        </div>
                                    </td>
                                    <th></th>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="flex gap10 justify-content-center">
                        <button className="btn btn-fill-blue2 btn-round" onClick={handleUserDelete} disabled={userDeletePending}>{t("myPage_del")}</button>
                        <button className="btn btn-fill-blue2 btn-round" onClick={handleChangeFlag}>{edit? t("common_save") : t("common_edit")}</button>
                        <button className="btn btn-fill-blue2 btn-round" onClick={() => setSelectUser(null)}>{t("common_list")}</button>
                    </div>
                </>
            }
        </>
    )
}
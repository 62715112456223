import { companyListApi } from "api/companyApi";
import { configListApi } from "api/configApi";
import useTranslate from "components/language/useTranslate";
import AccountList from "components/pay/AccountList";
import BankStatement from "components/pay/BankStatement";
import LabPayment from "components/pay/LabPayment";
import RealTime from "components/pay/RealTime";
import { _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { resApiCheck } from "utils/common";

export default function Payment(){
    const t = useTranslate();

    const {US_MAP} = useStore();
    const [payInfo, setPayInfo] = useState(null);

    const location = useLocation();
    const [viewType, setViewType] = useState((US_MAP.CO_TYPE === _CO_TYPE_CLINIC || US_MAP.CO_TYPE === _CO_TYPE_DESIGN) ? (location.state || "realTime") : "labpayment");
    const handleChangeView = (viewType) => {
        setViewType(viewType);
    }

    const [asyncConfigList] = useAsync(configListApi);
    const [asyncCompanyList] = useAsync(companyListApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        let payInfoData = {};

        // device config List
        const resDeviceConfigList = await asyncConfigList({CF_TYPE_START : "DV_"});
        if(!resApiCheck(resDeviceConfigList)) return;
        if(resDeviceConfigList.data.LIST.length > 0){
            payInfoData.DV_CON_LIST = resDeviceConfigList.data.LIST;
        }

        //companyList
        const resCompanyList = await asyncCompanyList({});
        if(!resApiCheck(resCompanyList)) return;
        if(resCompanyList.data.LIST.length > 0){
            payInfoData.COMPANY_LIST = resCompanyList.data.LIST;
        }

        setPayInfo(payInfoData);
    }

    return(
        <> 
            <div className="inquiry-head">
                <div className="tabs5">
                    {(US_MAP.CO_TYPE === _CO_TYPE_CLINIC || US_MAP.CO_TYPE === _CO_TYPE_DESIGN) &&
                        <>
                            <button className={viewType === "realTime" ? "active" : null} onClick={() => handleChangeView("realTime")}>
                                {t("payment_realtime")}
                            </button>
                            <button className={viewType === "invoiceList" ? "active" : null} onClick={() => handleChangeView("invoiceList")}>
                                {t("payment_useSpecification")}
                            </button>
                            <button className={viewType === "bankStatement" ? "active" : null} onClick={() => handleChangeView("bankStatement")}>
                                {t("payment_depositWithdrawHistory")}
                            </button>
                        </>
                    }
                    {US_MAP.CO_TYPE === _CO_TYPE_LAB &&
                        <button className={viewType === "labpayment" ? "active" : null} >
                            {t("payment_mg")}
                        </button>
                    }
                </div>
            </div>

            {viewType === "realTime" && <RealTime payInfo={payInfo}/>}
            {viewType === "invoiceList" && <AccountList payInfo={payInfo}/>}
            {viewType === "bankStatement" && <BankStatement />}
            {viewType === "labpayment" && <LabPayment />}
        </>
    )
}
import { axio } from 'utils/common';
import { API } from 'config';

// COMPANY
export async function companyListApi(data){
    return await axio(API.COMPANY_LIST, "POST", data);
}

export async function companyReadApi(data){
    return await axio(API.COMPANY_READ, "POST", data);
}

export async function companyWriteApi(data){
    return await axio(API.COMPANY_WRITE, "POST", data);
}

export async function companyDeleteApi(data){
    return await axio(API.COMPANY_DELETE, "POST", data);
}

export async function companyPlanListApi(data){
    return await axio(API.COMPANY_PALN_LIST, "POST", data);
}

export async function companyPlanReadApi(data){
    return await axio(API.COMPANY_PALN_READ, "POST", data);
}

export async function companyPlanWriteApi(data){
    return await axio(API.COMPANY_PALN_WRITE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function companyPlanDeleteApi(data){
    return await axio(API.COMPANY_PALN_DELETE, "POST", data);
}

export async function companyDeviceListApi(data){
    return await axio(API.COMPANY_DEVICE_LIST, "POST", data);
}

export async function companyDeviceWriteApi(data){
    return await axio(API.COMPANY_DEVICE_WRITE, "POST", data);
}

export async function companyDeviceSnapshotListApi(data){
    return await axio(API.COMPANY_DEVICE_SNAPSHOT_LIST, "POST", data);
}

export async function companyProductListApi(data){
    return await axio(API.COMPANY_PRODUCT_LIST, "POST", data);
}

export async function companyProductWriteApi(data){
    return await axio(API.COMPANY_PRODUCT_WRITE, "POST", data);
}

export async function companyPaymentReadApi(data){
    return await axio(API.COMPANY_PAYMENT_READ, "POST", data);
}

export async function companyPaymentWriteApi(data){
    return await axio(API.COMPANY_PAYMENT_WRITE, "POST", data);
}

export async function companyPaymentUpdateApi(data){
    return await axio(API.COMPANY_PAYMENT_UPDATE, "POST", data);
}

export async function companyPaymentDeleteApi(data){
    return await axio(API.COMPANY_PAYMENT_DELETE, "POST", data);
}




import { configListApi } from "api/configApi";
import { deviceListApi } from "api/deviceApi";
import { ex_tableToExcel } from "components/common/Excel";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import DeviceModal from "components/modal/DeviceModal";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_numComma, resApiCheck } from "utils/common";
export default function DeviceMng(){
    const t = useTranslate();
    // api
    const [asyncConfigList] = useAsync(configListApi);
    const [asyncDeviceList] = useAsync(deviceListApi);
    
    // data
    const [deviceConfigList, setDeviceConfigList] = useState(null);
    const [deviceList, setDeviceList] = useState(null);

    const [selectDevice, setSelectDevice] = useState(null);
    const [deviceCount, setDeviceCount] = useState(0);

    const limit = 10;
    const [searchFilter, setSearchFilter] = useState({
        DV_TYPE : "",
        DV_MAN : "",
        DV_MODEL : "",
        PAGE_ROWS : limit,
        PAGE_INDEX : 1,
    });
    
    const handlePage = (page) => {
        setSearchFilter(produce(prevValue => {
            prevValue.PAGE_INDEX = page;
        }));
    }

    // tableId
    const deviceTableId = "deviceTable";

    // modal
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        handleLoad();
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchFilter.PAGE_INDEX])
    
    // config Load
    const handleLoad = async () => {
        const resDeviceConfig = await asyncConfigList({
            CF_TYPE_START : "DV_",
        });

        if(!resApiCheck(resDeviceConfig)) return;

        if(resDeviceConfig.data.LIST.length > 0){
            setDeviceConfigList(resDeviceConfig.data.LIST);
        }
    }

    const handleChangeFilter = (e) => {
        // 상위 항목 변경 하위 항목 리셋
        const {name, value} = e.target;

        let newSearch = {...searchFilter};

        if(name === "DV_TYPE"){
            newSearch = {...searchFilter, [name] : value};
            newSearch.DV_MAN = "";
            newSearch.DV_MODEL = "";
        }else if(name === "DV_MAN"){
            newSearch = {...searchFilter, [name] : value};
            newSearch.DV_MODEL = "";
        }else{
            newSearch = {...searchFilter, [name] : value};
        }

        setSearchFilter(newSearch);
    };

    // Device Add || Modify
    const handleModify = (e) => {
        const {name, value} = e.target;

        if(name === "modify"){
            const find = deviceList.find(x => x.DV_SEQ === Number(value));
            setSelectDevice(find);
        }else{
            setSelectDevice(null);
        }

        handleModal();
    }

    // Read Device List
    const handleSearch = async () => {
        const resDeviceList = await asyncDeviceList(searchFilter);
        if(!resApiCheck(resDeviceList)) return;

        const LIST_COUNT = resDeviceList.data.LIST_COUNT;

        setDeviceCount(LIST_COUNT);
        if(LIST_COUNT > 0){
            setDeviceList(resDeviceList.data.LIST);
        }else{
            setDeviceList(null);
        }
    }

    const handleModal = () => setIsOpen((nextIsOpen) => !nextIsOpen);

    return(
        <>
            <div id="container">
                <div className="admin-content">
                    <div className="title-head">
                        <h3>{t("device_deviceMg")}</h3>
                    </div>
                    <div className="admin-search">
                        <div className="title">
                            <h4>{t("common_surchOpt")}</h4>
                        </div>
                        <div className="search-box">
                            <div className="item">
                                <dl>
                                    <dt>{t("device_equipType")}</dt>
                                    <dd>
                                        <DeviceSelectOption deviceConfigList={deviceConfigList} type={"DV_TYPE"} searchFilter={searchFilter} onChange={handleChangeFilter} />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t("common_mf")}</dt>
                                    <dd>
                                        <DeviceSelectOption deviceConfigList={deviceConfigList} type={"DV_MAN"} searchFilter={searchFilter} onChange={handleChangeFilter} />
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t("common_prodModel")}</dt>
                                    <dd>
                                        <DeviceSelectOption deviceConfigList={deviceConfigList} type={"DV_MODEL"} searchFilter={searchFilter} onChange={handleChangeFilter} />
                                    </dd>
                                </dl>
                                <div className="btns">
                                    <button className="btn btn-fill-blue2 btn-round" onClick={handleSearch}>{t("common_search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="board-head" style={{float : "right"}}>
                        <div className="btns">
                            <button className="btn btn-fill-gray2 btn-s" onClick={() => ex_tableToExcel(deviceTableId, "장치목록")}><i className="ico-excel"></i> EXCEL </button>
                            <button className="btn btn-fill-blue2 btn-s" name="add" value="" onClick={handleModify}><i className="xi-plus"></i> {t("common_addDevice")}</button>
                        </div>
                    </div>

                    <DeviceList talbeId={deviceTableId} deviceList={deviceList} configList={deviceConfigList} handleModify={handleModify}/>
                    <Pagination total={deviceCount} limit={limit} page={searchFilter.PAGE_INDEX} setPage={handlePage}/>
                    <div className="flex justify-content-between align-items-center mb20">
                        <div></div>
                    </div>
                </div>
                <Modal show={isOpen} onHide={handleModal} centered>
                    <DeviceModal isOpen={isOpen} handleModal={handleModal} selectDevice={selectDevice} handleSearch={handleSearch} configList={deviceConfigList} />
                </Modal>
            </div>
        </>
    )
}

function DeviceList({ talbeId, deviceList, configList, handleModify}){
    const t = useTranslate();

    function getCfTitle(CF_CODE){
        let cfTitle = "";
        let findConfig = configList.find(x => x.CF_CODE === CF_CODE);
        if(!cm_isEmpty(findConfig)){
            cfTitle = findConfig.CF_TITLE;
        }
        return cfTitle;
    }

    return (
        <>
            <div className="table1 admin-type">
                <table id={talbeId}>
                    <thead>
                        <tr>
                            <th>{t("device_equipType")}</th>
                            <th>{t("common_mf")}</th>
                            <th>{t("common_mfModel")}</th>
                            <th>{t("common_prodName")}</th>
                            <th>{t("common_cost")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {deviceList ?
                            deviceList.map((device) => {
                                return(
                                    <tr key={"device_" + device.DV_SEQ}>
                                        <td>{getCfTitle(device.DV_TYPE)}</td>
                                        <td>{getCfTitle(device.DV_MAN)}</td>
                                        <td>{getCfTitle(device.DV_MODEL)}</td>
                                        <td>{device.DV_NAME}</td>
                                        <td>{cm_numComma(device.DV_COST)}</td>
                                        <td>
                                            <button className="btn btn-fill-gray btn-s" name="modify" value={device.DV_SEQ} onClick={(e) => handleModify(e)}>{t("common_edit")}</button>
                                        </td>
                                    </tr>
                                )
                            })
                        : 
                            <tr><td colSpan={6}>{t("common_noResult")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export function DeviceSelectOption({deviceConfigList, type, searchFilter, onChange}){
    const t= useTranslate();

    if(cm_isEmpty(deviceConfigList)) return;

    // Device Modal, DeviceMng use function
    function deviceConfigFilters(list, type, searchFilter){
        let curList = list.filter(x => x.CF_TYPE === type);
    
        switch(type){
            case "DV_TYPE":
                break;
            case "DV_MAN" :
                curList = curList.filter(x => x.CF_VAL1 === searchFilter.DV_TYPE);
                break;
            case "DV_MODEL" :
                curList = curList.filter(x => x.CF_VAL2 === searchFilter.DV_MAN);
                break;
            default :
                break;
        }
    
        return curList;
    }

    const configList = deviceConfigFilters(deviceConfigList, type, searchFilter);

    return (
        <>
            <select className="select block" name={type} value={searchFilter[type]} onChange={onChange}>
                <option value="">{t("common_choice")}</option>
                {configList && configList.map((config) => {
                        return(
                            <option key={type + config.CF_CODE} value={config.CF_CODE}>{config.CF_TITLE}</option>
                        )
                    }
                )}
            </select>
        </>
    )
}
import CalendarInput from "components/common/CalendarInput";
import useTranslate from "components/language/useTranslate";
import { ACCOUNT_STATUS, TRANSACTION_TYPE } from "config";
import { produce } from "immer";

export default function AccountSearch({searchInfo, setSearchInfo, searchList, filterStatus, handleFilterStatus, tap, handleTap}){
    const accountStatus = ACCOUNT_STATUS();
    const transactionType = TRANSACTION_TYPE();
    const t = useTranslate();

    const handleChangeSearchInfo = (e) => {
        const {name, value} = e.target;
        
        setSearchInfo(produce(prevValue => {
            prevValue[name] = value;
        }))
    }

    const onChangeFrDate = (date) => {
        const newInfo = {...searchInfo};
        newInfo[calendarKey("FR")] = date;

        setSearchInfo(newInfo);
    };

    const onChangeToDate = (date) => {
        const newInfo = {...searchInfo};
        newInfo[calendarKey("TO")] = date;

        setSearchInfo(newInfo);
    };

    const onKeyPress = (e) => {
        if(e.key === "Enter") searchList();
    }

    const calendarKey = (type) => {
        let key = "";

        if(tap === "accountBook"){
            key = `FT_${type}MONTH`;
        }else {
            key = `FT_${type}DATE`;
        }

        return key;
    }

    return(
        <div className="admin-search">
            <div className="title">
                <div className="tabs4">
                    <button className={tap === "accountBook" ? "active" : null} value="accountBook" onClick={handleTap}>{t("common_payment")}</button>
                    <button className={tap === "bankStatement" ? "active" : null} value="bankStatement" onClick={handleTap}>{t("payment_depositWithdrawHistory")}</button>
                    <button className={tap === "adjust" ? "active" : null} value="adjust" onClick={handleTap}>{t("adjust_list")}</button>
                </div>
            </div>
            <div className="search-box" >
                <div className="item" style={{paddingRight:"20px"}}>
                    <dl style={{flex: "3"}}>
                        <div className="flex gap20 align-items-center" style={{position:"absolute"}}>
                            <div>{t("payment_searchPeriod")}</div>
                            <div style={{position:"relative", zIndex:"2"}}>
                                <CalendarInput
                                    onChange={onChangeFrDate}
                                    name={calendarKey("FR")}
                                    value={searchInfo[calendarKey("FR")]} 
                                    option={{viewType: tap === "accountBook" ? "year" : "month"}} 
                                    inputAddStyle={{width: "250px"}}
                                    isOn={false}
                                />
                            </div>
                            <span>~</span>
                            <div style={{position:"relative", zIndex: "2"}}>
                                <CalendarInput
                                    onChange={onChangeToDate}
                                    name={calendarKey("TO")}
                                    value={searchInfo[calendarKey("TO")]} 
                                    option={{viewType: tap === "accountBook" ? "year" : "month"}} 
                                    inputAddStyle={{width: "250px"}}
                                    isOn={false}
                                />
                            </div>
                        </div>
                    </dl>

                    <dl style={{flex: "1"}}>
                        <dt>{t("common_companyType")}</dt>
                        <dd>
                            <select 
                                className="select block" 
                                name="CO_TYPE" 
                                value={searchInfo.CO_TYPE} 
                                onChange={handleChangeSearchInfo}
                            >
                                <option value="">{t("common_selectAll")}</option>
                                <option value="C">{t("common_dt")}</option>
                                <option value="L">{t("common_dtl")}</option>
                            </select>
                        </dd>
                    </dl>

                    <dl style={{flex: "1"}}>
                        <dt>{t("common_companyName")}</dt>
                        <dd>
                            <input 
                                type="text" 
                                className="inp"
                                name="CO_NAME" 
                                value={searchInfo.CO_NAME}
                                onChange={handleChangeSearchInfo} 
                                placeholder={t("common_ph")}
                                onKeyDown={onKeyPress}
                            />
                        </dd>
                    </dl>
                    <dl style={{flex: "1", justifyContent:"center"}}>
                        <div className="btns">
                            <button className="btn btn-fill-blue2 btn-round" onClick={searchList}>{t("payment_search")}</button>
                        </div>
                    </dl>
                </div>
                {tap === "accountBook" &&
                    <div className="item" style={{justifyContent : "space-between", paddingRight:"20px"}}>
                        <dl>
                            <dt>{t("common_status")}</dt>
                            <dd>
                                <div className="flex gap10 align-items-center">
                                    {accountStatus.map((item) => {
                                        return(
                                            <label key={item.status}>
                                                <input 
                                                    type="checkbox" 
                                                    className="checkbox" 
                                                    id={item.status}
                                                    name="AB_STATUS" 
                                                    value={item.status}
                                                    onChange={handleFilterStatus}
                                                    checked={filterStatus.includes(item.status)} 
                                                />
                                                <div><em></em><p>{item.text}</p></div>
                                            </label>
                                        )
                                    })}
                                </div>
                            </dd>
                        </dl>
                    </div>
                }
                {tap === "bankStatement" &&
                    <div className="item" style={{justifyContent : "space-between", paddingRight:"20px"}}>
                        <dl>
                            <dt>{t("common_status")}</dt>
                            <dd>
                                <div className="flex gap10 align-items-center">
                                    {transactionType.filter(x => "IO".indexOf(x.type) !== -1).map((item) => {
                                        return(
                                            <label key={item.type}>
                                                <input 
                                                    type="checkbox" 
                                                    className="checkbox" 
                                                    id={item.type}
                                                    name="AT_TYPE" 
                                                    value={item.type} 
                                                    onChange={handleFilterStatus} 
                                                    checked={filterStatus.includes(item.type)}
                                                />
                                                <div><em></em><p>{item.text}</p></div>
                                            </label>
                                        )
                                    })}
                                </div>
                            </dd>
                        </dl>
                    </div>
                }
            </div>
        </div>
    )
}

import { axio } from 'utils/common';
import { API } from 'config';

// ORDER
export async function orderListApi(data){
    return await axio(API.ORDER_LIST, "POST", data);
}

export async function orderReadApi(data){
    return await axio(API.ORDER_READ, "POST", data);
}

export async function orderCreateApi(data){
    return await axio(API.ORDER_CREATE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function orderUpdateApi(data){
    return await axio(API.ORDER_UPDATE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function orderUpdateInfoApi(data){
    return await axio(API.ORDER_UPDATE_INFO, "POST", data);
}

export async function orderRepairApi(data){
    return await axio(API.ORDER_REPAIR, "POST", data);
}

export async function orderRepairCancelApi(data){
    return await axio(API.ORDER_REPAIR_CANCEL, "POST", data);
}

export async function orderSplitApi(data){
    return await axio(API.ORDER_SPLIT, "POST", data);
}

export async function orderChangeStatusApi(data){
    return await axio(API.ORDER_CHANGE_STATUS, "POST", data);
}

export async function orderDeleteApi(data){
    return await axio(API.ORDER_DELETE, "POST", data);
}

export async function orderUploadFileApi(data){
    return await axio(API.ORDER_UPLOAD_FILE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function orderTempFileApi(data){
    return await axio(API.ORDER_TEMPFILE, "POST", data);
}

export async function orderCommentListApi(data){
    return await axio(API.ORDER_COMMENT_LIST, "POST", data);
}

export async function orderCommentWriteApi(data){
    return await axio(API.ORDER_COMMENT_WRITE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function orderCommentDeleteApi(data){
    return await axio(API.ORDER_COMMENT_DELETE, "POST", data);
}

export async function orderPrintListApi(data){
    return await axio(API.ORDER_PRINT_LIST, "POST", data);
}

export async function orderPrintReadApi(data){
    return await axio(API.ORDER_PRINT_READ, "POST", data);
}

export async function orderPrintUpdateApi(data){
    return await axio(API.ORDER_PRINT_UPDATE, "POST", data);
}

export async function orderPrintDeleteApi(data){
    return await axio(API.ORDER_PRINT_DELETE, "POST", data);
}

export async function orderPrintDownloadApi(data){
    return await axio(API.ORDER_PRINT_DOWNLOAD, "POST", data);
}

export async function orderFileUpload(data){
    return await axio(API.ORDER_FILE_UPLOAD, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function orderAutoShippingApi(data){
    return await axio(API.ORDER_AUTO_SHIPPING, "POST", data);
}

export async function orderAdjustWriteApi(data){
    return await axio(API.ORDER_ADJUST_WIRTE, "POST", data);
}

export async function orderAdjustListApi(data){
    return await axio(API.ORDER_ADJUST_LIST, "POST", data);
}

export async function orderAutoDesign(data){
    return await axio(API.ORDER_AUTO_DESIGN, "POST", data);
}
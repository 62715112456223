import { emailAuthApi, signUpApi } from "api/commonApi";
import Timer from "components/Timer";
import { _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import useAsync from "hooks/useAsync";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { cm_isEmail, cm_isEmpty, cm_isMobile, cm_isNumeric, cm_isPassword, cm_swAlert, resApiCheck } from "utils/common";
import Checkbox from "../components/Checkbox";
import { useTranslate } from "../components/language/useTranslate";
import { produce } from "immer";
import { _US_TYPE_CO } from "config";

export default function SignUp(){
    // Values
    const [values, setValues] = useState({
        CO_TYPE: null,
        CO_NAME: "",
        CO_OWNER: "",
        CO_TEL: "",
        US_TYPE: _US_TYPE_CO,
        US_EMAIL: "",
        US_PWD: "",
        US_PWDC: "",
        US_NAME: "",
        US_PHONE: "",
        US_NOTICE: "N",
    });

    return(
        <>
            {values.CO_TYPE === null ?
                <SignupType setValues={setValues}/>
            :
                <SignupInfo values={values} setValues={setValues}/>
            }
        </>
    )
}

function SignupType({setValues}){
    const t = useTranslate();

    const handleCompanyType = (coType) => {
        setValues(produce((prevValue) => {
            prevValue.CO_TYPE = coType;
        }))
    }

    return(
        <div className="join-type-wrap">
            <h2 className="h2">{t("signUp_service")}</h2>
            <div className="join-type">
                <div className="item" onClick={() => handleCompanyType(_CO_TYPE_CLINIC)}>
                    <h3>{t("signUp_dentalService")}</h3>
                    <div className="info">
                        <h4>{t("signUp_dental_content_1")}</h4>
                        <div className="t1">{t("signUp_dental_content_2")}</div>
                        <div className="t2">{t("signUp_dental_content_3")}</div>
                        <div className="t2">{t("signUp_dental_content_4")}</div>

                        <h4>{t("signUp_dental_content_5")}</h4>
                        <div className="t1">{t("signUp_dental_content_6")}</div>
                    </div>
                </div>
                <div className="item" onClick={() => handleCompanyType(_CO_TYPE_DESIGN)}>
                    <h3>{t("signUp_designService")}</h3>
                    <div className="info">
                        <h4>{t("signUp_design_content_1")}</h4>
                        
                        <div className="t1">{t("signUp_design_content_2")}</div>
                        <div className="t1">{t("signUp_design_content_3")}</div>
                    </div>
                </div>
                <div className="item" onClick={() => handleCompanyType(_CO_TYPE_LAB)}>
                    <h3>{t("signUp_labService")}</h3>
                    <div className="info">
                        <h4>{t("signUp_lab_content_1")}</h4>
                        
                        <div className="t1">{t("signUp_lab_content_2")}</div>
                        <div className="t1">{t("signUp_lab_content_3")}</div>
                        <div className="t1">{t("signUp_lab_content_4")}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SignupInfo({values, setValues}){
    const t = useTranslate();
    const navigate = useNavigate();

    //API
    const [asyncEmailAuth] = useAsync(emailAuthApi);
    const [asyncSignUp] = useAsync(signUpApi);

    const [state, setState] = useState({
        emailError: true,
        authSuccess: false,
        pwdError: "OK",
        pwdcError: false,
        pwdValid: 0,
        phoneError: true,
        authCode: "",
        authToken: "",
        service: false,
        notice: false,
        marketing: false,
    });

    const onChangeValues = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        handleErrorValues(name, value);

        setValues(produce((prevValue) => {
            prevValue[name] = value;
        }));
    };

    const onChangeState = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setState(produce((prevValue) => {
            prevValue[name] = value;
        }));
    }

    const handleErrorValues = (name, value) => {
        switch(name){
            case "US_EMAIL":
                setState(produce((prevValue) => {
                    prevValue.emailError = cm_isEmail(value, false);
                    prevValue.authToken = "";
                    prevValue.authSuccess = false;
                }));
                break;
            case "US_PWD":
                let result = cm_isPassword(value);

                setState(produce((prevValue) => {
                    prevValue.pwdError = result.str;
                    prevValue.pwdValid = result.num;
                }));
                break;
            case "US_PWDC":
                setState(produce((prevValue) => {
                    prevValue.pwdcError = (values.US_PWD !== value);
                }));
                break;
            default:
                break;
        }
    }

    const onChangePhone = (e) => {
        const {name, value} = e.target;

        if (e.target.value.length > 11) return;
        if (!cm_isNumeric(value)) return;

        setValues(produce((prevValue) => {
            prevValue[name] = value;
        }));
        
        if (name === "US_PHONE"){
            setState(produce((prevValue) => {
                prevValue.phoneError = cm_isMobile(value);
            }));
        }
    }

    const sendEmailAuth = async() => {
        const response = await asyncEmailAuth({
            US_EMAIL : values.US_EMAIL
        });
        if(!resApiCheck(response)) return;

        if(response.data.RESULT === "OK"){
            setState(produce((prevValue) => {
                prevValue.authToken = response.data.AUTH_CODE;
            }));
        }else if(response.data.RESULT === "NO_EMAIL"){
            cm_swAlert(t("alert_email_aready"), "warning", t("common_check"));
        }
    }

    const checkEmailAuth = () => {
        if(state.authToken === state.authCode){
            setState(produce((prevValue) => {
                prevValue.authSuccess = true;
            }));
            cm_swAlert(t("alert_email_success"), "success", t("common_check"));
        }else{
            cm_swAlert(t("alert_email_fail"), "warning", t("common_check"));
        }
    }

    const onServiceChange = (value) => setState((prev) => ({ ...prev, service: value }));
    const onNoticeChange = (value) => setState((prev) => ({ ...prev, notice: value }));
    const onMarketingChange = (value) => setState((prev) => ({ ...prev, marketing: value }));

    const signUp = async () => {
        if(!signUpValidate()) return;
        const response = await asyncSignUp({...values, US_NOTICE: state.notice ? "Y" : "N"});

        if(!resApiCheck(response)) return;

        if(response.data.RESULT === "OK"){
            cm_swAlert(t("common_regiSuccess"), "success", t("common_check"));
            navigate("/");
        }else{
            cm_swAlert(t("common_regiFailed"), "warning", t("common_check"));
        }
    }

    function signUpValidate() {
        const validationRules = [
            { key: 'US_EMAIL', message: t('common_emailPlaceHolder') },
            { key: 'US_PWD', message: t('alert_input_pwd') },
            { key: 'CO_NAME', message: t('common_ph_coName') },
            { key: 'CO_OWNER', message: t('common_content_16') },
            { key: 'CO_TEL', message: t('common_content_5') },
            { key: 'US_PHONE', message: t('common_content_3') }
        ];
    
        for(const rule of validationRules) {
            if(cm_isEmpty(values[rule.key])) {
                cm_swAlert(rule.message, 'warning', t('common_check'));
                document.getElementById(rule.key).focus();

                return false;
            }
        }

        if(!state.authSuccess){
            cm_swAlert(t("alert_validate_email"), 'warning');
            return false;
        }else if(values.US_PWD !== values.US_PWDC || state.pwdValid !== 4){
            cm_swAlert(t("alert_validate_pwd"), 'warning');
            return false;
        }else if(!state.phoneError){
            cm_swAlert(t("alert_validate_phone"), 'warning');
            return false;
        }

        return true;
    }
    
    return (
        <div className="join-wrap">
            <div className="form-inner">
                <div className="title-head2">
                    <h2>{(values.CO_TYPE === _CO_TYPE_CLINIC) ? t("signUp_dentalService") : (values.CO_TYPE === _CO_TYPE_DESIGN) ? t("signUp_designService") : t("signUp_labService")}</h2>
                </div>

                {/* 이메일 */}
                <h3 className="form-tit ">{t("common_email")}<span className='required'>＊</span></h3>
                
                <div className="inp-box inp-btn-box">
                    <input type="email" className="inp block" id="US_EMAIL" name="US_EMAIL" value={values.US_EMAIL} onChange={onChangeValues} placeholder={t("common_emailPlaceHolder")}/>
                    <button type="button" className="btn btn-line-black" style={{ width: "130px", padding: "0 15px"}} disabled={!state.emailError} onClick={sendEmailAuth} >{t("common_emailAuthNum")}</button>
                </div>
                {(!cm_isEmpty(values.US_EMAIL) && !state.emailError) && <div id="email_msg" className="form-msg txt-red">{t("common_content_7")}</div>}
                
                {/* 이메일 인증 */}
                {!cm_isEmpty(state.authToken) &&
                    <div className="inp-box inp-btn-box">
                        {!state.authSuccess && 
                            <span className="time">
                                <Timer mm={"05"} ss={"00"}></Timer>
                            </span>
                        }

                        <input type="text" id="authCode" name="authCode" className="inp block" value={state.authCode} onChange={onChangeState} placeholder={t("common_numPlaceHolder")} maxLength="6" disabled={state.authSuccess}/>
                        <button type="button" className="btn btn-line-black" onClick={checkEmailAuth} disabled={state.authSuccess}>{t("common_emailAuth")}</button>
                    </div>
                }

                {/* 비밀번호 */}
                <h3 className="form-tit">{t("common_pw")}<span className='required'>＊</span></h3>
                <div className="inp-box">
                    <input type="password" className="inp block" id="US_PWD" name="US_PWD" value={values.US_PWD} onChange={onChangeValues} placeholder={t("alert_input_pwd")} autoComplete="off"/>
                </div>
                {state.pwdError !== "OK" && <div className="form-msg txt-red">{state.pwdError}</div>}

                <div className="form-validation">
                    <span>{t("common_pwSuitability")}</span>
                    <div className={`bar step${state.pwdValid}`}></div>
                    <strong>{state.pwdError !== "OK" ? t("common_notSafty") : t("common_safty")}</strong>
                </div>

                <h3 className="form-tit">{t("common_pwConfirm")}<span className='required'>＊</span></h3>
                <div className="inp-box">
                    <input type="password" className="inp block" id="US_PWDC" name="US_PWDC" value={values.US_PWDC} onChange={onChangeValues} placeholder={t("alert_input_pwdAgain")} autoComplete="off"/>
                </div>
                {state.pwdcError && <div className="form-msg txt-red">{t("alert_pw_content_3")}</div>}

                {/* 상호명, 주소, 전화번호, 대표 */}
                <h3 className="form-tit">{t("common_companyName")}<span className='required'>＊</span></h3>
                <div className="inp-box">
                    <input type="text" className="inp block" id="CO_NAME" name="CO_NAME" value={values.CO_NAME} onChange={onChangeValues} placeholder={t("common_ph_coName")}/>
                </div>

                <h3 className="form-tit">{t("common_RepresentativeName")}<span className='required'>＊</span></h3>
                <div className="inp-box">
                    <input type="text" className="inp block" id="CO_OWNER" name="CO_OWNER" value={values.CO_OWNER} onChange={onChangeValues} placeholder={t("common_content_16")}/>
                </div>

                <h3 className="form-tit">{t("common_rePresentativePhone")}<span className='required'>＊</span></h3>
                <div className="inp-box">
                    <input type="text" className="inp block" id="CO_TEL" name="CO_TEL" value={values.CO_TEL} onChange={onChangePhone} placeholder={t("common_content_5")}/>
                </div>

                <h3 className="form-tit">{t("common_phone")}<span className='required'>＊</span></h3>
                <div className="inp-box inp-btn-box">
                    <input type="text" className="inp block" id="US_PHONE" name="US_PHONE" value={values.US_PHONE} onChange={onChangePhone} placeholder={t("common_content_3")}/>
                </div>
                {!state.phoneError && <div className="form-msg txt-red">{t("common_invalidPhNum")}</div>}

                <hr/>

                <div className="opt-in-wrap">
                    {/* 이용약관 */}
                    <Checkbox checked={state.service} onChange={onServiceChange} >
                        <p data-bs-toggle="modal" data-bs-target="#ModalPrivacy">{t("signUp_agreeTerms")} <span className="font-color4">({t("signUp_required")})</span></p>
                    </Checkbox>
                    
                    {/* 알림 메시지 수신동의 */}
                    <Checkbox checked={state.notice} onChange={onNoticeChange} >
                        <p data-bs-toggle="modal">{t("signUp_optIn_notice")}<span className="font-color4">({t("signUp_choice")})</span></p>
                    </Checkbox>

                    <Checkbox checked={state.marketing} onChange={onMarketingChange} >
                        <p data-bs-toggle="modal" data-bs-target="#ModalEmail">{t("signUp_adInfo")}<span className="font-color4">({t("signUp_choice")})</span></p>
                    </Checkbox>
                </div>
                <div className="form-msg mb30">{t("signUp_termsCheck")}</div>

                {/* Button */}
                <div className="mb20">
                    <button className="btn btn-fill-blue block" onClick={signUp} disabled={!state.service}>{t("common_submit")}</button>
                </div>
                <div className="text-center">
                    <Link to="/" className="btn-link">{t("signUp_out")}</Link>
                </div>
            </div>
        </div>
    )
}

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { boardCommentWriteApi, boardReadApi } from "api/boardApi";
import { cm_dateMoment, cm_isEmpty, cm_swAlert, downloadFile, resApiCheck } from "utils/common";
import useAsync from "hooks/useAsync";
import { useStore } from "contexts/Store";
import { _US_TYPE_QA } from "config";
import useTranslate from "components/language/useTranslate";

export default function BoardRead(){
    //TODO 코멘트 삭제, HIT, style
    const store = useStore();
    const US_MAP = store.US_MAP;

    const [boardMaster, setBoardMaster] = useState(null);
    const [board, setBoard] = useState(null);
    const [boardComments, setBoardComments] = useState([]);
    const [fileList, setFileList] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const t = useTranslate();

    const [comment, setComment] = useState("");
    const handleComment = (e) => {
        setComment(e.target.value);
    }

    const [asyncBoardRead] = useAsync(boardReadApi);
    const [asyncBoardCommentWrite] = useAsync(boardCommentWriteApi);

    useEffect(() => {
        if(location.state.boardMaster !== undefined){
            setBoardMaster(location.state.boardMaster);
        }

        handleLoad();
    },[]);

    const handleLoad = async () => {
        const response = await asyncBoardRead({
            BC_SEQ: location.state.BC_SEQ
        });

        if(!resApiCheck(response)) return;

        setBoard(response.data.BC_MAP);
        setBoardComments(response.data.BT_LIST);
        setFileList(response.data.FL_LIST);
    }

    const commentWrtie = async () => {
        const response = await asyncBoardCommentWrite({
            BC_SEQ: board.BC_SEQ,
            BT_COMMENT: comment
        })
        if(!resApiCheck(response)){
            cm_swAlert(t("common_regiFailed"), "info", t("common_check"));
        }else{
            handleLoad();
            setComment("");
        }

    }

    return(
        <>
            {board && 
                <div className="board-read">
                    <h3 className="h3">
                        {`[${boardMaster.BM_TITLE}] ${board.BC_TITLE}`}
                    </h3>
                    <table>
                        <colgroup>
                            <col style={{width:"150px"}} />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr style={{height:"500px"}}>
                                <th>{t("board_content")}</th>
                                <td dangerouslySetInnerHTML={{ __html: board.BC_CONTENTS }}></td>
                            </tr>

                            {/* Board File */}
                            {!cm_isEmpty(fileList) &&
                                <tr>
                                    <th>File</th>
                                    <td>
                                        <div className="file-box">
                                            <ul className="file-list">
                                            {fileList.map((file) => {
                                                return(
                                                        <li key={file.FL_SEQ}>
                                                            <span onClick={() => downloadFile(file.FL_URL, file.FL_ORGNAME)}>{file.FL_ORGNAME}</span>
                                                        </li>
                                                )
                                            })}
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            }

                            {/* Board Comment */}
                            {boardMaster.BM_COMMENT === "Y" &&
                                <tr className="comment">
                                    <th>{t("board_comment")}</th>
                                    <td>
                                        <ul>
                                            {boardComments && boardComments.map((comment) =>{
                                                return (
                                                    <li key={`comment_${comment.BT_SEQ}`}><span>{comment.BT_COMMENT}</span><small>{cm_dateMoment(comment.BT_REGDTTM, "YYYY-MM-DD HH:mm")}</small></li>
                                                )
                                            })}
                                        </ul>

                                        <div className="text">
                                            <textarea className="textarea" style={{maxHeight: "80px", maxWidth: "800px"}} placeholder={""} value={comment} onChange={handleComment} />
                                            <button type="button" className="btn btn-fill-blue2 btn-s" onClick={commentWrtie}>{t("common_ph")}</button>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>

                    <div className="button-box">
                        <button className="btn btn-line-blue2 btn-round btn-s" value="list" onClick={()=> navigate("/board")}>{t("common_list")}</button>
                        {(US_MAP.US_TYPE === _US_TYPE_QA || US_MAP.US_SEQ === board.US_SEQ) && 
                            <button className="btn btn-line-blue2 btn-round btn-s" onClick={()=> navigate("/board/boardwrite", {state: {BC_SEQ: board.BC_SEQ, boardMaster: boardMaster}})}>{t("common_edit")}</button>
                        }
                    </div>
                </div>
            }

        </>
    )
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import './css/style.css';
import {LangProvider} from "contexts/LangContext";
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import axios from 'axios';
import { StoreProvider } from 'contexts/Store';
import { cm_isEmpty } from 'utils/common';

axios.defaults.baseURL = process.env.REACT_APP_HOME_URL;
const CONTEXT = process.env.REACT_APP_CONTEXT;
let baseName = "";
if(!cm_isEmpty(CONTEXT)){
  baseName = CONTEXT;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <LangProvider>
    <StoreProvider>
      <BrowserRouter basename={`/${baseName}`}>
        <App />
      </BrowserRouter>
    </StoreProvider>
  </LangProvider>
);

reportWebVitals();

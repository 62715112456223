import { orderUpdateInfoApi } from "api/orderApi";
import { userListApi } from "api/userApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_swAlert, resApiCheck } from "utils/common";

export default function SelectDesigner({OD_MAP, handleOrderMap}) {
    const t = useTranslate();
    const store = useStore();
    const {US_MAP} = store;
    const {DESINGER_NAME} = OD_MAP;

    const [isSelectDesigner, setIsSelectDesigner] = useState(false);
    const handleSelectDesigner = () => {setIsSelectDesigner((prevValue) => !prevValue);}

    return (
        <div className="flex align-items-center gap15">
            {US_MAP.CO_TYPE === _CO_TYPE_CENTER &&
                <>
                    <em>{t("order_pic")}</em>
                    <div className="lab-name" onClick={US_MAP.CO_TYPE === _CO_TYPE_CENTER ? (handleSelectDesigner) : null}>{DESINGER_NAME ? DESINGER_NAME : t("common_ndesignated")}</div>

                    {isSelectDesigner &&
                        <div>
                            <div className="flex align-items-center gap15">
                                <SelectOptions OD_MAP={OD_MAP} handleOrderMap={handleOrderMap} handleSelectDesigner={handleSelectDesigner} />
                            </div>
                        </div>
                    }
                </>
            }
        </div>

    )
}

function SelectOptions({OD_MAP, handleOrderMap, handleSelectDesigner}){
    const t = useTranslate();

    const [userList, setUserList] = useState(null);
    const [asyncUserList] = useAsync(userListApi);
    const [asyncOrderUpdateInfo] = useAsync(orderUpdateInfoApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        const resUserList = await asyncUserList({CO_SEQ : 1});
        if(!resApiCheck(resUserList)) return;

        if(resUserList.data.LIST_COUNT > 0){
            setUserList(resUserList.data.LIST);
        }
    }

    const [designer, setDesigner] = useState(0);

    const handleDesigner = (e) => {
        const OD_DESIGNER = e.target.value;

        setDesigner(OD_DESIGNER);
        orderUpdateLab(OD_DESIGNER);
    }

    async function orderUpdateLab(OD_DESIGNER){
        const OD_SEQ = OD_MAP.OD_SEQ;

        const response = await asyncOrderUpdateInfo({
            OD_SEQ : OD_SEQ,
            OD_DESIGNER : OD_DESIGNER,
        })

        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleOrderMap(OD_SEQ);
            handleSelectDesigner();
        }
    }

    return (
        <select className="select" value={designer} onChange={handleDesigner}>
            <option value={0}>{t("common_choice")}</option>
            {
                userList && userList.map((user) => {
                    return (
                        <option key={"option_" + user.US_SEQ} value={user.US_SEQ}>{user.US_NAME}</option>
                    )
                })
            }
        </select>
    )
}
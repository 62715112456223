import Radio from "components/common/Radio";
import RadioGroup from "components/common/RadioGroup";
import useTranslate from "components/language/useTranslate";
import CalendarDropdown from "components/modal/CalendarDropdown";
import { _CO_TYPE_CLINIC, _CO_TYPE_DESIGN } from "config";
import { useStore } from "contexts/Store";
import { produce } from "immer";
import { useState } from "react";
import { cm_dateAddDay, cm_dateAddMonth, cm_dateDiff, cm_dateMoment, cm_dateToday, cm_isEmpty, cm_swAlert } from "utils/common";
import OrderIssueFilter from "./OrderIssueFilter";
import OrderStatusFilter from "./OrderStatusFilter";

export default function OrderSearch({orderListFilter, setOrderListFilter, orderCnt}){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const {FT_STATUS, FT_ISSUE, FT_PRINT, FT_REMAKE, FT_REPAIR, FT_RESCAN, FT_PLASTATUS, FT_FRDATE, FT_TODATE, PERIOD} = orderListFilter;
    const [period, setPeriod] = useState(PERIOD);
    const [fromDate, setFromDate] = useState(FT_FRDATE);
    const [toDate, setToDate] = useState(FT_TODATE);
    const [searchName, setSearchName] = useState({
        OD_NAME: "",
        CO_NAME: ""
    });

    const INIT_FT_STATUS = cm_isEmpty(FT_STATUS) ? [] : FT_STATUS.split(",");
    const [statusFilter, setStatusFilter] = useState(INIT_FT_STATUS);
    const [issueFilter, setIssueFilter] = useState({
        FT_ISSUE : FT_ISSUE,
        FT_PRINT : FT_PRINT,
        FT_REMAKE : FT_REMAKE,
        FT_REPAIR : FT_REPAIR,
        FT_RESCAN : FT_RESCAN
    });
    const INIT_FT_PLASTATUS = cm_isEmpty(FT_PLASTATUS) ? [] : FT_PLASTATUS.split(",");
    const [plaStatusFilter, setPlaStatusFilter] = useState(INIT_FT_PLASTATUS);
    const t = useTranslate();
    
    // Date
    const handlePeriod = (value) => {
        let date = "";
        
        switch (value) {
            case "quarter":
                date = cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -3);
                break;
            case "half":
                date = cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -6);
                break;
            case "year":
                date = cm_dateAddMonth(cm_dateAddDay(new Date(), 1), -12);
                break;
            default:
                date = "0000-01-01";
                break;
        }
        
        setPeriod(value);
        setFromDate(date);
        setToDate(cm_dateToday());
    }

    const handleToDate = (date) => {
        setToDate(date);
        setPeriod("");
    }

    const handleFromDate = (date) => {
        setFromDate(date);
        setPeriod("");
    }

    // Name change
    const handleName = (e) => {
        let {name, value} = e.target;

        setSearchName(produce((prevValue) => {
            prevValue[name] = value;
        }))
    }

    // Status change
    const handleFilter = (e) => {
        let {value} = e.target;
        if(cm_isEmpty(value)){
            value = e.target.parentElement.getAttribute('value')
        }

        let newArr = [].concat(statusFilter); 

        if(newArr.includes(value)){
            newArr = newArr.filter(x => x !==  value); 
        }else{
            newArr.push(value);
        }

        setStatusFilter(produce(prevValue => prevValue = newArr));
        setOrderListFilter(produce(prevValue => {
            prevValue.FT_STATUS = newArr.join(",")
            prevValue.PAGE_INDEX = 1
        }));
    }

    // Issue change
    const handleIssue = (e) => {
        let {value} = e.target;
        if(cm_isEmpty(value)){
            value = e.target.parentElement.getAttribute('value')
        }

        setIssueFilter(produce(prevValue => {
            if(prevValue[value] !== 1){
                prevValue[value] = 1;
            }else{
                prevValue[value] = null;
            }

            let arrKey = Object.keys(prevValue);
            arrKey = arrKey.filter(x => x !== "FT_ISSUE");
            for(let key of arrKey){
                if(!cm_isEmpty(prevValue[key])){
                    prevValue["FT_ISSUE"] = "Y";
                    break;
                }else{
                    prevValue["FT_ISSUE"] = null;
                }
            }
        }))

        setOrderListFilter(produce(prevValue => {
            if(prevValue[value] !== 1){
                prevValue[value] = 1;
            }else{
                prevValue[value] = null;
            }

            let arrKey = Object.keys(prevValue);
            arrKey = arrKey.filter(x => x !== "FT_ISSUE");
            for(let key of arrKey){
                if(!cm_isEmpty(prevValue[key])){
                    prevValue["FT_ISSUE"] = "Y";
                    break;
                }else{
                    prevValue["FT_ISSUE"] = null;
                }
            }
            prevValue.PAGE_INDEX = 1
        }));
    }

    //apply filter
    const onKeyPress = (e) => {
        if(e.key === "Enter") searchOrder();
    }

    function searchOrder(){
        let curFilter = {
            ...issueFilter,
            ...searchName,
            FT_STATUS: statusFilter.join(","),
            FT_FRDATE: fromDate,
            FT_TODATE: toDate,
            PAGE_ROWS : 10,
            PAGE_INDEX : 1,
            PERIOD : period,
            OD_COUNT : "Y"
        }

        if(!cm_isEmpty(searchName.OD_NAME)){
            curFilter.FT_FRDATE = "0000-01-01";
            curFilter.FT_TODATE = cm_dateToday();
            curFilter.FT_STATUS = "";
            curFilter.PERIOD = "entire";
            handlePeriod("entire")
        } else{
            // validate date
            if(cm_dateDiff(fromDate, toDate) < 0){
                cm_swAlert(t("alert_validate_diffdate"), "warning");
                return;
            }
        }


        setOrderListFilter(curFilter);
    }

    return(
        <div className="order-search">
            <div className="search-box">
                <div className="item left">
                    <div className="period">
                        <dl>
                            <dt>{t("common_period")}</dt>
                            <dd>
                                <div className="period-box">
                                    <CalendarDropdown 
                                        isOn={false}
                                        name="FT_FRDATE"
                                        value={cm_dateMoment(fromDate, "YYYY-MM-DD") || ''}
                                        onChange={handleFromDate}
                                        option={{minDate:null}}
                                        title={t("board_startPh")}
                                    />
                                    <span>~</span>
                                    <CalendarDropdown
                                        isOn={false}
                                        name="FT_TODATE"
                                        value={cm_dateMoment(toDate, "YYYY-MM-DD") || ''}
                                        onChange={handleToDate}
                                        option={{minDate:null}}
                                        title={t("board_endPh")}
                                    />
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="period">
                        <RadioGroup value={period} onChange={handlePeriod}>
                            <div className="flex gap10 align-items-center" style={{marginRight:"10px"}}>
                                <Radio value="quarter">{t("order_threeMonth")}</Radio>
                                <Radio value="half">{t("common_halfMonth")}</Radio>
                                <Radio value="year" defaultChecked>{t("common_oneYear")}</Radio>
                                <Radio value="entire">{t("common_selectAll")}</Radio>
                            </div>
                        </RadioGroup>
                    </div>
                    <div>
                        
                    </div>
                </div>
                <div className="item right">
                    {(CO_TYPE !== _CO_TYPE_CLINIC && CO_TYPE !== _CO_TYPE_DESIGN) &&
                        <dl>
                            <dt>{t("common_clinicName")}</dt>
                            <dd>
                                <div className="search">
                                    <input type="text" className="inp" name="CO_NAME" value={searchName.CO_NAME} onChange={handleName} onKeyDown={onKeyPress}/>
                                </div>
                            </dd>
                        </dl>
                    }
                    <dl>
                        <dt>{t("common_patientName")}</dt>
                        <dd>
                            <div className="search">
                                <input type="text" className="inp" name="OD_NAME" value={searchName.OD_NAME} onChange={handleName} onKeyDown={onKeyPress}/>
                            </div>
                        </dd>
                    </dl>
                    <div className="btns">
                        <button className="btn btn-fill-blue2 btn-round" onClick={() => searchOrder()}>{t("common_search")}</button>
                    </div>
                </div>
            </div>
            <div className="search-box">
                <div className="item left">
                    <OrderStatusFilter statusFilter={statusFilter} handleFilter={handleFilter} orderCnt={orderCnt}/>
                </div>
                <div className="item right" style={{minWidth:"350px"}}>
                    <OrderIssueFilter issueFilter={issueFilter} handleIssue={handleIssue} />
                </div>
            </div>
        </div>
    )
}
import { companyPaymentReadApi } from "api/companyApi";
import { configReadApi } from "api/configApi";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_isEmpty, resApiCheck } from "utils/common";
import MyPaymentAdd from "./MyPaymentAdd";
import MyPaymentItem from "./MyPaymentItem";

export default function CompanyPayment({CO_SEQ, VIEW_TITLE=true}){
    const [myPay, setMyPay] = useState(null);
    const [quveInfo, setQuveInfo] = useState(null);
    const [reLoad, setReLoad] = useState(false);

    const [asyncCompanyPaymentRead] = useAsync(companyPaymentReadApi);
    const [asyncConfigRead] = useAsync(configReadApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);
    
    const handleLoad = async () => {
        const resPaymentInfo = await asyncCompanyPaymentRead({CO_SEQ : CO_SEQ});
        if(!resApiCheck(resPaymentInfo)) return;
        if(!cm_isEmpty(resPaymentInfo.data.CA_MAP)){
            setMyPay(resPaymentInfo.data.CA_MAP);
        }

        //QUVE INFO
        const rewQuveInfo = await asyncConfigRead({CF_TYPE : "QV_INFO"});
        if(!resApiCheck(rewQuveInfo)) return;
        if(!cm_isEmpty(rewQuveInfo.data.CF_MAP)){
            setQuveInfo(rewQuveInfo.data.CF_MAP)
        }
    }

    const handleReLoad = () => setReLoad(prevValue => !prevValue);

    return(
        <>
            {quveInfo && 
                <>
                    {myPay ? 
                        <MyPaymentItem myPay={myPay} setMyPay={setMyPay} quveInfo={quveInfo} VIEW_TITLE={VIEW_TITLE}/>
                        :
                        <MyPaymentAdd CO_SEQ={CO_SEQ} handleReLoad={handleReLoad} VIEW_TITLE={VIEW_TITLE}/>
                    }
                </>
            }
        </>
    )
}
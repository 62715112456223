import { configListApi } from "api/configApi";
import { productWriteApi } from "api/productApi";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_isEmpty, cm_isPositiveNum, cm_numComma, cm_removeNumComma, cm_swAlert, resApiCheck } from "utils/common";

export default function AdminProductModal({isOpen, handleModal, selectObj, setReLoad}) {
    const t = useTranslate();
    const init = {
        PR_SEQ : 0,
        PR_TYPE : "P",
        PR_CODE : "",
        PR_NAME : "",
        PR_DESC : "",
        PR_DPSEQ : 1,
        PR_PRICE : 0,
        PR_PNPRICE : 0,
        PR_DSPRICE : 0,
        PR_DSPNPRICE : 0,
        PR_SALES : "N",
        PR_SHADE : "N",
        PR_TEMP : "N",
        PR_PMMA : "N",
        PR_PONTIC : "N",
        PR_GROUP : 0
    };

    const [productOpt, setProductOpt] = useState(selectObj || init);
    const [productTypeList, setProductTypeList] = useState(null);

    const [asyncProductWrite, prWritePending] = useAsync(productWriteApi);
    const [asyncConfigList] = useAsync(configListApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        const response = await asyncConfigList({CF_TYPE : "PR_TYPE"});
        if(!resApiCheck(response)) return;

        if(response.data.LIST.length > 0) {
            setProductTypeList(response.data.LIST);
        }
    }

    const handleChangeNumber = (e) => {
        const {name, value} = e.target;
        const PR_PRICE = Number(cm_removeNumComma(value));
        setProductOpt(produce(prevValue => {
            if(!cm_isPositiveNum(PR_PRICE, false)) return;
            prevValue[name] = PR_PRICE;
        }));
    }   

    const handleChange = (e) => {
        const {name, value} = e.target;
        setProductOpt(produce(prevValue => {
            prevValue[name] = value;

            if(name === "PR_PONTIC" && value === "N"){
                prevValue["PR_PNPRICE"] = 0;
            }
        }));
    }   

    const handleSave = async () => {
        if(!saveValidate()) return;
        
        const response = await asyncProductWrite(productOpt);
        
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            setReLoad();
            handleModal();
        }
    }

    function saveValidate(){
        const {PR_TYPE, PR_CODE, PR_NAME, PR_DESC, PR_DPSEQ, PR_PRICE, PR_SALES}  = productOpt;

        let result = true;

        // 필수값
        if(cm_isEmpty(PR_TYPE)){
            cm_swAlert(t("alert_prtheticType"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(PR_CODE)){
            cm_swAlert(t("alert_pstheticCode"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(PR_NAME)){
            cm_swAlert(t("alert_pstheticName"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(PR_DESC)){
            cm_swAlert(t("alert_pstheticDESC"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(PR_DPSEQ)){
            cm_swAlert(t("alert_pstheticOrderby"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(PR_PRICE)){
            cm_swAlert(t("alert_pstheticPrice"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(PR_SALES)){
            cm_swAlert(t("alert_pstheticSaleState"),  "warning", t("common_check"));
            result = false;
        }

        return result;
    }

    const tdStyle = {
        width : "90%",
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"840px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{t("prod_addPsalesPstheticList")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="board-write no-border">
                            <table>
                                <colgroup>
                                    <col style={{width: "130px"}}/>
                                    <col/>
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>{t("prod_pstheticType")}</th>
                                        <td>
                                        <select className="select block" key="PR_TYPE" style={tdStyle} name="PR_TYPE" value={productOpt.PR_TYPE} onChange={handleChange}>
                                            {productTypeList &&
                                                productTypeList.map((productType) =>{
                                                    return(
                                                        <option key={productType.CF_CODE} value={productType.CF_CODE}>{productType.CF_TITLE}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        </td>
                                        <th>{"PR_SHADE"}</th>
                                        <td>
                                            <select className="select block" key="PR_SHADE" style={tdStyle} name="PR_SHADE" value={productOpt.PR_SHADE} onChange={handleChange}>
                                                <option value="N">{t("order_hookNotUse")}</option>
                                                <option value="Y">{t("order_hookUse")}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("prod_pstheticViewName")}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_NAME" value={productOpt.PR_NAME} onChange={handleChange} placeholder={t("common_ph")}/></td>
                                        <th>{"PR_TEMP"}</th>
                                        <td>
                                            <select className="select block" key="PR_TEMP" style={tdStyle} name="PR_TEMP" value={productOpt.PR_TEMP} onChange={handleChange}>
                                                <option value="N">{t("order_hookNotUse")}</option>
                                                <option value="Y">{t("order_hookUse")}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("prod_pstheticCode")}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_CODE" value={productOpt.PR_CODE} onChange={handleChange} placeholder={t("common_ph")}/></td>
                                        <th>{"PR_PMMA"}</th>
                                        <td>
                                        <select className="select block" key="PR_PMMA" style={tdStyle} name="PR_PMMA" value={productOpt.PR_PMMA} onChange={handleChange}>
                                            <option value="N">{t("order_hookNotUse")}</option>
                                            <option value="Y">{t("order_hookUse")}</option>
                                        </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("prod_pstheticAllName")}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_DESC" value={productOpt.PR_DESC} onChange={handleChange} placeholder={t("common_ph")}/></td>
                                        <th>{"PR_PONTIC"}</th>
                                        <td>
                                            <select className="select block" key="PR_PONTIC" style={tdStyle} name="PR_PONTIC" value={productOpt.PR_PONTIC} onChange={handleChange}>
                                                <option value="N">{t("order_hookNotUse")}</option>
                                                <option value="Y">{t("order_hookUse")}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t("common_sellingPrice")}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_PRICE" value={cm_numComma(productOpt.PR_PRICE)} onChange={handleChangeNumber} placeholder={t("common_ph")}/></td>
                                        <th>{"PR_GROUP"}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_GROUP" value={productOpt.PR_GROUP} onChange={handleChangeNumber} placeholder={t("common_ph")}/></td>
                                    </tr>
                                    <tr>
                                        <th>{`${t("common_sellingPrice")}(Pontic)`}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_PNPRICE" value={cm_numComma(productOpt.PR_PNPRICE)} onChange={handleChangeNumber} placeholder={t("common_ph")} disabled={productOpt.PR_PONTIC !== "Y"}/></td>
                                        <th>{t("prod_orderby")}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_DPSEQ" value={productOpt.PR_DPSEQ} onChange={handleChangeNumber} placeholder={t("common_ph")}/></td>
                                    </tr>
                                    <tr>
                                        <th>{`${t("common_sellingPrice")}(Design)`}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_DSPRICE" value={cm_numComma(productOpt.PR_DSPRICE)} onChange={handleChangeNumber} placeholder={t("common_ph")}/></td>
                                        <th>{t("prod_salesState")}</th>
                                        <td>
                                            <select className="select block" key="PR_SALES" style={tdStyle} name="PR_SALES" value={productOpt.PR_SALES} onChange={handleChange}>
                                                <option value="N">{t("common_notUse")}</option>
                                                <option value="Y">{t("common_use")}</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{`${t("common_sellingPrice")}(Design_Pontic)`}</th>
                                        <td><input type="text" className="inp block" style={tdStyle} name="PR_DSPNPRICE" value={cm_numComma(productOpt.PR_DSPNPRICE)} onChange={handleChangeNumber} placeholder={t("common_ph")}/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" disabled={prWritePending} onClick={handleSave}>{selectObj? t("common_edit") : t("common_add")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
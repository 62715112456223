import { holidayListApi } from "api/holidayApi";
import { orderListApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import OrderSections from "components/order/OrderSections";
import OrderTags from "components/order/OrderTags";
import { _CO_TYPE_CLINIC } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cm_dateAddDay, cm_dateMoment, cm_dateOfWeek, cm_dateToday, resApiCheck } from "utils/common";

export default function WeekDelivery({searchInfo}) {
    const [asyncOrderList] = useAsync(orderListApi);
    const [asyncHolidayList] = useAsync(holidayListApi);

    const [orderList, setOrderList] = useState(null);
    const [orderLineList, setOrderLineList] = useState(null);
    const [holidayList, setHolidayList] = useState([]);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchInfo])

    const handleLoad = async () => {
        const response = await asyncOrderList(searchInfo);
        if(!resApiCheck(response)) return;
        const {LIST_COUNT} = response.data;
        if(LIST_COUNT > 0 ){
            setOrderList(response.data.LIST.filter(x => x.OD_PROSTATUS !== "N"));
            setOrderLineList(response.data.OL_LIST.filter(x => x.PR_TYPE !== "P"));
        }else{
            setOrderList(null);
            setOrderLineList(null);
        }

        const resHolidayList = await asyncHolidayList({});
        if(!resApiCheck(resHolidayList)) return;
        if(resHolidayList.data.LIST_COUNT > 0){
            setHolidayList(resHolidayList.data.LIST);
        }
    }

    function getDateList(date) {
        let nextDateList = [];
        for(let i=0; i < 6; i++){
            nextDateList.push(cm_dateMoment(cm_dateAddDay(date, i)));
        }
        return nextDateList;
    }

    const setDeliveryClass = (date) => {
        let className = "";

        if([0].includes(new Date(date).getDay()) || holidayList.map(x => x.HD_DATE).includes(cm_dateMoment(date, "YYYY-MM-DD"))) className += 'holiday ';
        if(cm_dateToday() === date) className += "today";

        return className;
    }

    return(
        <div className="inquiry-list">
            <ul>
                {getDateList(searchInfo.FT_FRDUEDATE).map((date) => {
                    return(
                        <li key={"delivery" + date} className={setDeliveryClass(date)}>
                            <div className="head">
                                <h3>{cm_dateOfWeek(date)}</h3>
                                <div className="day">{date}</div>
                            </div>
                            <div className="body" style={{minHeight: "600px"}}>
                                {orderList && 
                                    orderList.filter(x => x.OD_DUEDATE === date).map((order) => {
                                        let filterOrderLineList = orderLineList.filter(x => x.OD_SEQ === order.OD_SEQ);
                                        
                                        return(
                                            <DeliveryItem key={order.OD_SEQ} order={order} orderLineList={filterOrderLineList}/>
                                        )
                                    })
                                }
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

function DeliveryItem({order, orderLineList}) {
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const t = useTranslate();
    
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => setIsOpen(!isOpen);
    
    const navigate = useNavigate();
    const handleMovePage = () => {
        navigate("/order", {state :{OD_SEQ : order.OD_SEQ}});
    }

    return(
        <div className="order-item" onClick={handleOpen}>
            <div className="before" >
                <div className="title f14">
                    <span>{cm_dateMoment(order.OD_REGDTTM, "YYMMDDHHmmss")}</span>
                    {CO_TYPE !== _CO_TYPE_CLINIC && <strong>{order.CO_NAME}</strong>}
                </div>
                <div className="user">
                    <div className="name">
                        {order.OD_NAME}<br/>
                        {order.LAB_NAME && <small>{order.LAB_NAME}</small>}
                    </div>
                    <div className="type"><strong>{t("delivery_orderDay")}</strong><br/>{cm_dateMoment(order.OD_REGDTTM)}</div>
                </div>
                <OrderTags OD_MAP={order} />
            </div>
            {isOpen &&
                <div className="detail" style={{display:"block", transition: "all 0.5s ease"}}>
                    <div className="tooth-list3">
                        <OrderSections OL_LIST={orderLineList} />

                        <button className="btn-detail" onClick={handleMovePage}>{t("delivery_orderViewDetail")} <i className="xi-angle-right-thin"></i></button>
                    </div>
                </div>
            }
        </div>
    )
}
import axios from "axios";
import useTranslate from "components/language/useTranslate";
import { ORDER_STEP, PROSTHETIC_OPTIONS } from 'config';
import moment from "moment/moment";
import Swal from "sweetalert2";

export const _FMT_DT = "YYYY-MM-DD";
export const _FMT_DTT = "YYYY-MM-DD HH:mm";
export const _FMT_DTS = "YY-MM-DD HH:mm";
// const _FMT_DTS = "YYYY-MM-DD HH:mm:ss";
const LANGUAGE = process.env.REACT_APP_LANGUAGE;
const _DATE_SYMBOL = "-";

const _REGEX_NUM = /^-?[-0-9.]*$/;
const _REGEX_POSITIVE_NUM = /^[0-9]+$/;
const _REGEX_EMAIL = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
const _REGEX_PWD = /^[A-Za-z\d!@#$%^&*()_+,.]{8,16}$/;
const _REGEX_MOBILE = /^(01[016789]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
const _REGEX_PHONE = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
const _REGEX_DATE = /^\d{4}([./-])\d{2}\1\d{2}$/;

const WEEK = () => {
	const t = useTranslate();
	const week = [
		t("common_sun"), 
		t("common_mon"), 
		t("common_tue"), 
		t("common_wed"), 
		t("common_thu"), 
		t("common_fri"), 
		t("common_sat"), 
	];
	return week;
}

// CHECK FUNCTIONS
export function cm_isEmpty(str) {
	let chkStr = String(str);

	if (str == null) return true;
	if (str === "NaN") return true;
	if (chkStr.valueOf() === "undefined") return true;
	if (chkStr == null) return true;
	// if (isNaN(chkStr)) return true;
	if (chkStr.toString().trim().length === 0) return true;
	if (chkStr === "[]") return true;

	return false;
}

export function cm_isNum(num, show, alertText) {
	alertText = cm_isEmpty(alertText) ? "숫자를 입력하십시오!" : alertText;

	if (_REGEX_NUM.test(num) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isNumeric(val) {
	return val === "" || _REGEX_POSITIVE_NUM.test(val);
}

export function cm_isPositiveNum(num, show, alertText) {
	alertText = cm_isEmpty(alertText) ? "양수를 입력하십시오!" : alertText;

	if (_REGEX_POSITIVE_NUM.test(num) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isEmail(email, show, alertText) {
	alertText = cm_isEmpty(alertText) ? "잘못된 이메일 형식 입니다!" : alertText;

	if (_REGEX_EMAIL.test(email) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isPassword(pwd, arrAlertText) {
	let result = {
		str: "OK",
		num: 4
	};
	const pwdMsgKo = [
		"8 ~ 16 자리의 비밀번호를 입력하세요.",
		"공백은 포함될 수 없습니다.",
		"하나 이상의 영문 대문자가 포함 되어야 합니다.",
		"하나 이상의 영문 소문자가 포함 되어야 합니다.",
		"하나 이상의 숫자가 포함 되어야 합니다.",
		"하나 이상의 특수문자(!@#$%^&)가 포함 되어야 합니다.",
		"허용 되지 않은 문자가 포함되었습니다."
	];
	const pwdMsgUs = [
		"Enter an 8 to 16 digit password.",
		"Cannot contain spaces.",
		"Must contain at least one uppercase letter.",
		"Must contain at least one lowercase letter.",
		"Must contain at least one number.",
		"Must contain at least one special character (!@#$%^&).",
		"Contains illegal characters."
	];

	arrAlertText = cm_isEmpty(arrAlertText) ? (LANGUAGE !== "ko" ? pwdMsgUs : pwdMsgKo) : arrAlertText;
	
	pwd = pwd.trim();
	
	if(pwd === "") {
		result.str = "";
		result.num = 0;
	}
	else if (pwd.length < 8 || pwd.length > 16){
		result.str = arrAlertText[0]
		result.num = 1;
	}else if (pwd.search(/\s/) > -1){
		result.str = arrAlertText[1]
		result.num = 1;
	}else if (pwd.search(/[A-Z]/g) < 0){
		result.str = arrAlertText[2]
		result.num = 2;
	}else if (pwd.search(/[a-z]/g) < 0){
		result.str = arrAlertText[3]
		result.num = 2;
	}else if (pwd.search(/[0-9]/g) < 0){
		result.str = arrAlertText[4]
		result.num = 2;
	}else if (pwd.search(/[!@#$%^&]/g) < 0){
		result.str = arrAlertText[5]
		result.num = 3;
	}else if (_REGEX_PWD.test(pwd) < 0){
		result.str = arrAlertText[6]
		result.num = 3; 
	}

	return result;
}

export function cm_isMobile(mobile, show, alertText) {
	mobile = mobile.replace(/-/gi, "");

	alertText = cm_isEmpty(alertText) ? "잘못된 휴대전화 형식 입니다!" : alertText;

	if (_REGEX_MOBILE.test(mobile) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}

export function cm_isPhone(phone, show, alertText) {
	phone = phone.replace(/-/gi, "");

	alertText = cm_isEmpty(alertText) ? "잘못된 전화번호를 형식 입니다!" : alertText;

	if (_REGEX_PHONE.test(phone) === false) {
		if (show) cm_swAlert(alertText, "warning");
		return false;
	}

	return true;
}
// CHECK FUNCTIONS

// NUMBER FUNCTIONS
export function cm_num(str) { 
	let num = 0;

	if (!cm_isEmpty(str) && cm_isNum(str)){
		return Number(str); 
	}

	return num;
}

export function cm_numComma(num) {
	let str = String(num);
	return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cm_numZeroPad(num, length) {
	let str = String(num);

	if (str.length < length){
		str = str.padStart(length, "0");
	} 

	return str;
}

export function cm_removeNumComma(num){
	let str = num.replace(/,/g, "");
	return str;
}

export function cm_formatMobile(phone) {
	if(cm_isEmpty(phone)) return "";
	
	phone = phone.trim().replace(/-/g, "");
	return phone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3");
}

export function cm_formatRegNo(num, type=null){
	if(cm_isEmpty(num)) return "";
	let regNo = "";

	if(type === "hide"){
		regNo = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
	}else{
		regNo = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
	}

	return regNo;
}

export function cm_foramtCardMasked(num){
	if(num.length > 16) return;
	let cardNo = num.slice(0, 8) + "****" + num.slice(12, 15) + "*";

	return cardNo;
}

export function cm_formatCardNo(num){
    let cardNo = "";
    if(num.length <= 4){
        cardNo = num;
    }else if(num.length <= 8){
        cardNo = num.slice(0, 4) + " " + num.slice(4, 8);
    }else if(num.length <= 12){
        cardNo = num.slice(0, 4) + " " + num.slice(4, 8) + " " + num.slice(8, 12);
    }else{
        cardNo = num.slice(0, 4) + " " + num.slice(4, 8) + " " + num.slice(8, 12) + " " + num.slice(12, 16);
    }
    return cardNo;
}

export function cm_formatCardExpried(cardExpired){
    let str = "";
    if(cardExpired.length > 2){
        str = cardExpired.slice(0, 2) + "/" + cardExpired.slice(2, 4);
    }else{
        str = cardExpired;
    }
    return str;
}
// NUMBER FUNCTIONS

// TEXT FUNCTIONS
export function cm_truncateText(text, maxLength) {
	if (text.length <= maxLength) {
        return text;
    } else {
        return text.substring(0, maxLength) + '...';
    }
}
// TEXT FUNCTIONS

// SWEET ALERT FUNCTIONS
export async function cm_swAlert(title, ic, confirmText) {
	ic = cm_isEmpty(ic) ? "error" : ic;

	confirmText = cm_isEmpty(confirmText) ? "확인" : confirmText;

	await Swal.fire({
		title: title,
		icon: ic,
		timer: 3000,
		confirmButtonText: confirmText,
	}).then();

	return;
}

export async function cm_swInput(title, text, icon, placeholder, confirmText, cancelText) {
	let inputText = "";

	icon = cm_isEmpty(icon) ? "info" : icon;
	confirmText = cm_isEmpty(confirmText) ? "확인" : confirmText;
	cancelText = cm_isEmpty(cancelText) ? "취소" : cancelText;

	await Swal.fire({
		title: title,
		text: text,
		icon: icon,
		input: "text",
		inputPlaceholder: placeholder,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
	}).then(result => {
		inputText = result.value;
	});

	return inputText;
}

export async function cm_swConfirm(title, text, icons, confirmText, cancelText) {
	let isConfirmed = false;

	icons = cm_isEmpty(icons) ? "success" : icons;
	confirmText = cm_isEmpty(confirmText) ? "예" : confirmText;
	cancelText = cm_isEmpty(cancelText) ? "아니요" : cancelText;

	isConfirmed = await Swal.fire({
		title: title,
		text: text,
		icon: icons,
		confirmButtonText: confirmText,
		cancelButtonText: cancelText,
		showCancelButton: true,
	}).then(result => {
		return result.isConfirmed;
	});

	return isConfirmed;
}

// DATE FUNCTIONS
export function cm_dateNow(format) {
	format = cm_isEmpty(format) ? _FMT_DTT : format;
	return moment(new Date()).format(format);
}

export function cm_dateToday(format, offset = 0) {
	let date = new Date();
	format = cm_isEmpty(format) ? _FMT_DT : format;
	if(offset !== 0) date.setDate(date.getDate() + offset);
	
	return moment(date).format(format);
}

export function cm_dateMoment(date, format){
	format = cm_isEmpty(format) ? _FMT_DT : format;
	return moment(date).format(format);
}

export function cm_dateDiff(fromDate, toDate){
	let diffDays = Math.floor((new Date(toDate).getTime() - new Date(fromDate).getTime()) / (1000 * 60 * 60 * 24));
	return diffDays;
}

export function cm_dateDDay(dueDate){
	let diffDays = cm_dateDiff(cm_dateToday(), dueDate);
	let dDay = (diffDays > 0 ? ("D-" + diffDays) : diffDays < 0 ? ("D+" + Math.abs(diffDays)) : "D-Day");

	return dDay;
}

export function cm_dateAddDay(date, days) {
	let result = new Date(date);
	result.setDate(result.getDate() + days);

	return result;
}

export function cm_dateAddMonth(date, months) {
	let result = new Date(date);
	result.setMonth(result.getMonth() + months);

	return result;
}

export function cm_datePeriod(date, month){
	let endDate = cm_dateMoment(cm_dateAddMonth(date, month), "YYYY-MM-DD");

	return endDate;
}

export function cm_dateMonthDate(date, type){
	let arrDate = date.split("-");
	
	if(type === "ML"){
		date = arrDate[0] + "-" + arrDate[1] + "-" + new Date(arrDate[0], arrDate[1], 0).getDate();
		return date;
	}else if(type === "MF"){
		date = arrDate[0] + "-" + arrDate[1] + "-01";
		return date;
	}

	return "";
}

export function cm_dateOfWeek(date){
	const week = WEEK();
	let dateOfWeek = week[new Date(date).getDay()];
	return dateOfWeek;
}

export function cm_dateYearList(sYear, eYear){
	let yearList = [];

	for(let i=Number(sYear); i<=Number(eYear); i++){
		yearList.push(i.toString());
	}

	return yearList;
}

export function cm_dateFormat(){
	const symbol = _DATE_SYMBOL;
	const indexes = [];

	for (let i = 0; i < _FMT_DT.length; i++) {
		if (_FMT_DT.substring(i, i + symbol.length) === symbol) {
			indexes.push(i);
			i += symbol.length - 1;
		}
	}

	return { symbol, indexes };
};

export function cm_dateCompare(inputDate){
	return inputDate.match(_REGEX_DATE);
}

// DATE FUNCTIONS

// JSON FUNCTIONS
export function cm_isJsonString(str) {
	try {
		var json = JSON.parse(str);
		return (typeof json === 'object');
	} catch (e) {
		return false;
	}
}

export function cm_JsonParseList(list, key){
	for(let map of list){
		if(!cm_isEmpty(map[key]) && cm_isJsonString(map[key])){
			map[key] = JSON.parse(map[key]);
		}
	}
	
	return list;
}
// JSON FUNCTIONS

// AXIOS FUNCTION
export async function axio(url, method, data, header=null){
	let response = await axios({
		url: url,
		method: method,
		headers: header === null ? {
			"Content-Type": "application/json",
		} : header,
		data: data,
		timeout: header === null ? Number(process.env.REACT_APP_AXIO_TIMEOUT) : 0,
	})
	.then()
	.catch((err) => {
		//에러 처리
		if(err.response) {
			// 요청이 이루어졌고 서버가 응답했을 경우
			const { status, config } = err.response;
			if(status === 404){
				console.log(`${config.url} not found`);
				cm_swAlert(`${config.url} not found`, "warning");
			}

			if(status === 500){
				console.log("Server error");
				cm_swAlert("Server error", "warning");
			}

		}else if(err.request){
			//요청이 이루어졌으나 서버에서 응답이 없었을 경우
			console.error(err.message);
		}else{
			console.error(err.message);
		}
	})

	return response;
}
// AXIOS FUNCTION

// API RESULT FUNCTION
export function resApiCheck(response){
	let result = true;

	if(cm_isEmpty(response)){
		result = false;
		cm_swAlert("response is empty", "info");
	}

	return result;
}

export function resResultAlert(result, success){
	if(result === "OK"){
		cm_swAlert(success, "success");
	}else{
		cm_swAlert(result, "warning");
	}
}

export function downloadFile(url, name){
	fetch(url, { method: 'GET' })
		.then((res) => {
			return res.blob();
		})
		.then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = name;
			document.body.appendChild(a);
			a.click();
			setTimeout((_) => {
				window.URL.revokeObjectURL(url);
			}, 60000);
			a.remove();
		})
		.catch((err) => {
			console.error('err: ', err);
		});
}

// File Type Validation
export function cm_acceptFile(accepts, files, alertText){
	let acceptFiles = [];

	alertText = cm_isEmpty(alertText) ? "파일만 등록 가능합니다" : alertText;

	for(let accept of accepts) {
		const oldFiles = files.filter(x => typeof x.name === "undefined");
		const newFiles = files.filter(x => typeof x.name !== "undefined");
		
		acceptFiles = [...acceptFiles, ...oldFiles.filter(x => x.FL_ORGNAME.indexOf(accept) !== -1)];
		acceptFiles = [...acceptFiles, ...newFiles.filter(x => x.name.indexOf(accept) !== -1)];
	}

	if(acceptFiles.length < files.length) cm_swAlert(accepts + " " + alertText, "warning");

	return acceptFiles;
}

export function checkFileSize(fileSize, maxSize){
	let result = true;

	if(fileSize > maxSize){
		result = false;
	}

	return result;
}

// Order function
export function setButtonText(STATUS){
	const orderStep = ORDER_STEP();

	return orderStep.find(x => x.key === STATUS).text;
}

export function checkFullCase(product){
	let fullFlag = false;

	if(product === 'P-Tray' || product === 'S-Guide'){
		fullFlag = true;
	}

	return fullFlag;
}

export function getProsOpTitle(keyName, value){
	const prosthetic = PROSTHETIC_OPTIONS();

	let title = "";
	let findOption = prosthetic[keyName].find(x => x.value === value);

	if(!cm_isEmpty(findOption)){
		title= findOption.title;
	}

	return title;
}

// List function
export function cm_findData(list, key, value, findKey){
	let result = "";

	if(!cm_isEmpty(list)){
		let findData = list.find(x => x[key] === value);
		if(!cm_isEmpty(findData)){
			result = findData[findKey];
		}
	}

	return result;
}

// Sort by tooth position
export function sortbyToothPosition(OL_LIST) {
	const sortList = OL_LIST.sort((a, b) => {
		const sortby = { '1': 0, '2': 1, '4': 2, '3': 3 };
		const firstNumA = a.OL_TOOTH.charAt(0);
		const firstNumB = b.OL_TOOTH.charAt(0);

		if(sortby[firstNumA] !== sortby[firstNumB]) {
			return sortby[firstNumA] - sortby[firstNumB];
		}

		if((firstNumA === '1' || firstNumA === '4') && (firstNumB === '1' || firstNumB === '4')) {
			return b.OL_TOOTH - a.OL_TOOTH;
		}else {
			return a.OL_TOOTH - b.OL_TOOTH;
		}
	});

	return sortList;
}
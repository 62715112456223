import { companyPlanListApi, companyPlanReadApi, companyPlanWriteApi } from "api/companyApi";
import { planListApi, planReadApi } from "api/planApi";
import DropFileInput from "components/DropFileInput";
import CalendarInput from "components/common/CalendarInput";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_acceptFile, cm_dateAddDay, cm_dateMoment, cm_datePeriod, cm_dateToday, cm_isEmpty, cm_numComma, cm_swAlert, downloadFile, resApiCheck } from "utils/common";

export default function CompanyPlanMng({selectCompany}){
    const [isOpen, setIsOpen] = useState(false);
    const [companyPlanList, setCompanyPlanList] = useState(null);
    const [selectCompanyPlan, setSelectCompanyPlan] = useState(null);
    const [reLoad, setReLoad] = useState(false);
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const t = useTranslate();
    const toDay = cm_dateToday();

    //API
    const [asyncCompanyPlanList] = useAsync(companyPlanListApi);
    const [asyncCompanyPlanRead] = useAsync(companyPlanReadApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);

    const handleLoad = async () => {
        const response = await asyncCompanyPlanList({CO_SEQ : selectCompany.CO_MAP.CO_SEQ});
        if(!resApiCheck) return;

        if(response.data.LIST_COUNT > 0){
            setCompanyPlanList(response.data.LIST);
        }else{
            setCompanyPlanList([]);
        }
    }

    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const handleReLoad = () => setReLoad(prevValue => !prevValue);

    const handleCompanyPlanRead = async (CP_SEQ) => {
        const response = await asyncCompanyPlanRead({CP_SEQ : CP_SEQ});
        if(!resApiCheck(response)) return;
        setSelectCompanyPlan(response.data);
        handleModal();
    }

    //Company plan Add || Modify
    const handleModify = (e) => {
        const {name, value} = e.target;

        if(name === "modify"){
            handleCompanyPlanRead(Number(value));
        }else{
            setSelectCompanyPlan(null);
            handleModal();
        }
    }

    async function downLoadFiles(CP_SEQ){
        const response = await asyncCompanyPlanRead({CP_SEQ : CP_SEQ});
        if(!resApiCheck(response)) return;

        if(!cm_isEmpty(response.data.FL_LIST)){
            response.data.FL_LIST.map((file) => {
                return downloadFile(file.FL_URL, file.FL_ORGNAME);
            })
        }else{
            cm_swAlert(t("alert_noContract"), "info", t("common_check"));
        }
    }

    return (
        <>  
            <div className="board-head" style={{float : "right"}}>
                <div className="btns">
                    <button type="button" className="btn btn-fill-blue2 btn-round btn-s" name="add" onClick={handleModify}>{t("memberMg_planAdd")}</button>
                </div>
            </div>
            <div className="table1">
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "10%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common_planType")}</th>
                            <th>{t("common_planName")}</th>
                            <th>{t("common_period")}</th>
                            <th>{t("common_monthFee")}</th>
                            <th>{t("common_basePrice")}</th>
                            <th>{t("memberMg_contract")}</th>
                            <th>{t("common_note")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(companyPlanList) ?
                            companyPlanList.map((companyPlan) => {
                                let period = companyPlan.CP_SDATE + " ~ " + companyPlan.CP_EDATE;
                                let terminate = companyPlan.CP_EDATE < toDay;

                                return(
                                    <tr style={ terminate ? {backgroundColor : "#FFF5F5"} : null} key={"CP_" + companyPlan.CP_SEQ}>
                                        <td>{companyPlan.PL_TYPE}</td>
                                        <td>{companyPlan.PL_NAME}</td>
                                        <td>{period}</td>
                                        <td>{cm_numComma(companyPlan.PL_PRICE)}{SYMBOL}</td>
                                        <td>{cm_numComma(companyPlan.PL_BASEPRICE)}{SYMBOL}</td>
                                        <td>
                                            <div className="plan-item"><button className="btn-ico" onClick={() => downLoadFiles(companyPlan.CP_SEQ)}><i className="xi-download"></i></button></div>
                                        </td>
                                        <td>{companyPlan.PL_DESC}</td>
                                        <td>
                                            {!terminate &&
                                                <button 
                                                    type="button" 
                                                    className="btn btn-round btn-fill-gray btn-s" 
                                                    name="modify"
                                                    value={companyPlan.CP_SEQ}
                                                    onClick={handleModify}
                                                >{t("common_edit")}
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan='8' align='center'>{t("plan_content_1")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            <Modal show={isOpen} onHide={handleModal} centered>
                <AddCompanyPlanModal 
                    isOpen={isOpen} 
                    handleModal={handleModal} 
                    selectCompany={selectCompany}
                    selectCompanyPlan={selectCompanyPlan} 
                    companyPlanList={companyPlanList} 
                    handleReLoad={handleReLoad}/>
            </Modal>
        </>
    )
}

function AddCompanyPlanModal({isOpen, handleModal, selectCompany, selectCompanyPlan, companyPlanList, handleReLoad}){
    const init = {
        CP_SEQ : 0,
        CO_SEQ : selectCompany.CO_MAP.CO_SEQ,
        PL_SEQ : "",
        CP_SDATE : "",
        CP_EDATE : ""
    }

    const [contract, setContract] = useState(() => {
        if(!cm_isEmpty(selectCompanyPlan) && !cm_isEmpty(selectCompanyPlan.CP_MAP)){
            return selectCompanyPlan.CP_MAP;
        }else{
            return init;
        }
    });
    const [fileList, setFileList] = useState(() => {
        if(!cm_isEmpty(selectCompanyPlan) && !cm_isEmpty(selectCompanyPlan.CP_MAP)){
            return selectCompanyPlan.FL_LIST;
        }else{
            return [];
        }
    });
    const [delFiles, setDelFiles] = useState([]);

    const [planList, setPlanList] = useState(null);
    const [planInfo, setPlanInfo] = useState({});

    // Api
    const [asyncPlanList] = useAsync(planListApi);
    const [asyncPlanRead] = useAsync(planReadApi);
    const [asyncCompanyPlanWrite] = useAsync(companyPlanWriteApi);

    const t = useTranslate();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        const response = await asyncPlanList({});
        if(!resApiCheck(response)) return;
        if(response.data.LIST_COUNT > 0){
            setPlanList(response.data.LIST);
        }
    }

    const handlePlanRead = async (e) => {
        const response = await asyncPlanRead({PL_SEQ: e.target.value});
        if(!resApiCheck(response)) return;

        setPlanInfo(response.data);

        setContract(produce(prevValue => {
            prevValue.PL_SEQ = response.data.PL_MAP.PL_SEQ;
            prevValue.CP_SDATE = "";
            prevValue.CP_EDATE = "";
        }));
    }
    
    // date
    const onChangeSdate = (date) => {
        if(!periodValidate(date)) return;
        
        const endDate = cm_dateMoment(cm_dateAddDay(cm_datePeriod(date, planInfo.PL_MAP.PL_MONTH), -1));
        
        setContract(produce(prevValue => {
            prevValue.CP_SDATE = date;
            prevValue.CP_EDATE = endDate;
        }));
    };

    function periodValidate(date){
        if(cm_isEmpty(planInfo.PL_MAP)) {
            cm_swAlert(t("alert_choicePlan"), "warning", t("common_check"));
            return false;
        }

        if(planInfo.PL_MAP.PL_TYPE !== "B") return true;

        if(companyPlanList.filter(x => x.PL_TYPE === "B" && x.CP_EDATE >= date).length > 0){
            cm_swAlert(t("alert_existContractCheck"), "warning", t("common_check"));
            return false;
        }

        return true;
    }

    const onChangeEdate = (date) => {
        setContract(produce(prevValue => {
            prevValue.CP_EDATE = date;
        }));
    };
    

    const onFileChange = (files) => {
        const accepts = [".png", ".jpg", ".pdf"];
        let list = cm_acceptFile(accepts, files, t("alert_common_file"));
        setFileList(list);
    }

    function deleteFile(FL_SEQ) {
        let delFile = {FL_SEQ: FL_SEQ};
        setDelFiles([...delFiles, delFile]);
    }
    
    // Company Plan Write
    const companyPlanWrite = async () => {
        if(!companyPlanValidate()) return;

        let formData = new FormData();
        formData.append("CP_SEQ", contract.CP_SEQ);
        formData.append("CO_SEQ", contract.CO_SEQ);
        formData.append("PL_SEQ", contract.PL_SEQ);
        formData.append("CP_SDATE", contract.CP_SDATE);
        formData.append("CP_EDATE", contract.CP_EDATE);
        formData.append("DELFILE_LIST", JSON.stringify(delFiles));
        
        if(!cm_isEmpty(fileList)){
            for(let file of fileList){
                formData.append("file", file);
            }
        }

        const response = await asyncCompanyPlanWrite(formData);
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "error", t("common_check"));
            handleModal();
            handleReLoad();
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleModal();
            handleReLoad();
        }
    }

    function companyPlanValidate() {
        let result = true;
        let text = "";

        if(cm_isEmpty(contract.PL_SEQ)) {
            text = t("alert_choicePlan");
            result = false;
        }else if(cm_isEmpty(contract.CP_SDATE)){
            text = t("alert_choice_startDate");
            result = false;
        }else if(cm_isEmpty(contract.CP_EDATE)){
            text = t("alert_choice_endDate");
            result = false;
        }

        if(!result) cm_swAlert(text, "info", t("common_check"));

        return result;
    }

    return (
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{selectCompanyPlan ? t("memberMg_planModify") : t("memberMg_planAdd")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">{t("common_plan")}</h4>
                        <div className="inp-box">
                            {!cm_isEmpty(selectCompanyPlan) ?
                                <div className="inp-box">
                                    <input type="text" className="inp block" name="PL_NAME"  value={contract.PL_NAME} readOnly/>
                                </div>
                                :
                                <select className="select" style={{width : "100%"}} name="PL_SEQ" value={contract.PL_SEQ} onChange={handlePlanRead}>
                                    <option value="">{t("common_choice")}</option>
                                    {planList && planList.map((item) => {
                                        return(
                                            <option key={"planOption_" + item.PL_SEQ} value={item.PL_SEQ}>{item.PL_NAME}</option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                        {/* select planInfo */}
                        {planInfo.PL_MAP && 
                            <>
                                <h4 className="form-tit">{t("common_planInfo")}</h4>
                                <div className="table1">
                                    <table>
                                        <colgroup>
                                            <col style={{width: "25%"}}/>
                                            <col style={{width: "25%"}}/>
                                            <col style={{width: "25%"}}/>
                                            <col style={{width: "25%"}}/>
                                            <col/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>{t("plan_planType")}</th>
                                                <th>{t("plan_period")}</th>
                                                <th>{t("common_monthFee")}</th>
                                                <th>{t("common_basePrice")}</th>
                                                <th>{t("common_note")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{planInfo.PL_MAP.PL_TYPE}</td>
                                                <td>{planInfo.PL_MAP.PL_MONTH} {t("common_months")}</td>
                                                <td>{cm_numComma(planInfo.PL_MAP.PL_PRICE)}</td>
                                                <td>{cm_numComma(planInfo.PL_MAP.PL_BASEPRICE)}</td>
                                                <td>{planInfo.PL_MAP.PL_DESC}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        }
                        {planInfo.PD_LIST && 
                            <div className="add-list auto mb20">
                                <ul>
                                    {
                                        planInfo.PD_LIST.map((item) => {
                                            return (
                                                <li key={item.PD_SEQ}>{item.DV_NAME}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        }

                        <h4 className="form-tit">{t("plan_startDate")}</h4>
                        <div className="inp-box" style={{zIndex:"3"}}>
                        {!cm_isEmpty(selectCompanyPlan) ?
                            <div className="inp-box">
                                <input type="text" className="inp block" name="CP_SDATE"  value={contract.CP_SDATE} readOnly/>
                            </div>
                            :
                            <CalendarInput 
                                onChange={onChangeSdate}
                                name="CP_SDATE"
                                value={contract.CP_SDATE}
                                option={{minDate:null}} 
                            />
                        }
                        </div>
                        <h4 className="form-tit">{t("plan_endDate")}</h4>
                        <div className="inp-box" style={{zIndex:"2"}}>
                            <CalendarInput 
                                onChange={onChangeEdate}
                                name="CP_EDATE"
                                value={contract.CP_EDATE}
                                option={{minDate:null}} 
                            />
                        </div>
                        <h4 className="form-tit">{t("memberMg_contract")}</h4>
                        <DropFileInput 
                            onChangeFile={(files) => onFileChange(files)} 
                            text={t("common_biz_content_1")} 
                            fileList={fileList} 
                            setFileList={setFileList} 
                            deleteFile={(FL_SEQ) => deleteFile(FL_SEQ)}
                            accept={".png, .jpg, .pdf"}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" onClick={companyPlanWrite}>{selectCompanyPlan? t("common_edit") : t("common_add")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { companyDeviceListApi, companyPlanListApi, companyReadApi, companyProductListApi } from 'api/companyApi';
import { configListApi } from 'api/configApi';
import { userReadApi } from 'api/userApi';
import useTranslate from 'components/language/useTranslate';
import { MY_PAGE_LIST, _CO_TYPE_CENTER, _CO_TYPE_LAB } from 'config';
import { useMyInfo, useSetMyInfo } from "contexts/MyInfoContext";
import { useStore } from 'contexts/Store';
import useAsync from 'hooks/useAsync';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { resApiCheck } from 'utils/common';
import CompanyPayment from './CompanyPayment';
import CompanyPlan from './CompanyPlan';
import CompanyProduct from './CompanyProduct';
import UserInfo from './UserInfo';
import UserOption from './UserOption';
import UserPwd from './UserPwd';

export default function MyList(){
    const myInfo = useMyInfo();
    const setMyInfo = useSetMyInfo();
    const store = useStore();
    const STORE_US_MAP = store.US_MAP;
    const location = useLocation();
    const myPageList = MY_PAGE_LIST();
    const pageTapList = myPageList.filter(x => x.type.includes(STORE_US_MAP.US_TYPE) && x.coType.includes(STORE_US_MAP.CO_TYPE));
    const [view, setView] = useState(location.state || "myInfo");
    const t = useTranslate();

    //API
    const [asyncCompanyRead] = useAsync(companyReadApi);
    const [asyncCompanyPlanList] = useAsync(companyPlanListApi);
    const [asyncCompanyDeviceList] = useAsync(companyDeviceListApi);
    const [asyncCompanyProductList] = useAsync(companyProductListApi);
    const [asyncConfigList] = useAsync(configListApi);
    const [asyncUserRead] = useAsync(userReadApi);
    
    const handleViewChange = e => setView(e.target.value);

    useEffect(() => {
        handleLoad(STORE_US_MAP);
        setView(location.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location.state]);
    
    const handleLoad = async (STORE_US_MAP) => {
        let data = {};

        //COMPANY INFO
        const resCompanyInfo = await asyncCompanyRead({CO_SEQ : STORE_US_MAP.CO_SEQ});
        if(!resApiCheck(resCompanyInfo)) return;
        data.CO_MAP = resCompanyInfo.data.CO_MAP;

        //USER INFO
        const resUserInfo = await asyncUserRead({US_SEQ : STORE_US_MAP.US_SEQ});
        if(!resApiCheck(resUserInfo)) return;
        data.US_MAP = resUserInfo.data.US_MAP;

        if(STORE_US_MAP.CO_TYPE === _CO_TYPE_CENTER){
            const US_MAP = {};
            const CO_MAP = {};
            const sessionKeys = Object.keys(STORE_US_MAP);
            for(let key of sessionKeys){
                if(key.indexOf("CO_") !== -1){
                    CO_MAP[key] = STORE_US_MAP[key];
                }else if(key.indexOf("MB_") !== -1){
                    US_MAP[key] = STORE_US_MAP[key];
                }
            }

            setMyInfo({US_MAP: US_MAP, CO_MAP : CO_MAP});
        }else{
            //COMPANY PLAN
            const resCompanyPlanList = await asyncCompanyPlanList({CO_SEQ : STORE_US_MAP.CO_SEQ});
            if(!resApiCheck(resCompanyPlanList)) return;
            if(resCompanyPlanList.data.LIST_COUNT > 0){
                data.CP_LIST = resCompanyPlanList.data.LIST;
            }

            //COMPANY DEVICE
            const resCompanyDeviceList = await asyncCompanyDeviceList({CO_SEQ : STORE_US_MAP.CO_SEQ});
            if(!resApiCheck(resCompanyDeviceList)) return;
            if(resCompanyPlanList.data.LIST_COUNT > 0){
                data.CD_LIST = resCompanyDeviceList.data.LIST;
            }

            //DEVICE COFNIG
            const resDeviceConfigList = await asyncConfigList({CF_TYPE_START : "DV_"});
            if(!resApiCheck(resDeviceConfigList)) return;
            if(resDeviceConfigList.data.LIST.length > 0){
                data.DV_CON_LIST = resDeviceConfigList.data.LIST;
            }

            const resCompanyProductList = await asyncCompanyProductList({CO_SEQ : STORE_US_MAP.CO_SEQ});
            if(!resApiCheck(resCompanyProductList)) return;
            if(resCompanyProductList.data.LIST.length > 0){
                data.CR_LIST = resCompanyProductList.data.LIST;
            }

            if(STORE_US_MAP.CO_TYPE === _CO_TYPE_LAB){
                // config product type
                const resProductTypeList = await asyncConfigList({CF_TYPE_START : "PR_TYPE"});
                if(!resApiCheck(resProductTypeList)) return;
                if(resProductTypeList.data.LIST.length > 0){
                    data.PRODUCT_TYPE_LIST = resProductTypeList.data.LIST;
                }
            }
        }

        setMyInfo(data);
    };
    
    return(
        <div className="mypage">
            <h2 className="h2">{t("myPage_myPage")}</h2>
            <div className="tabs">
                {pageTapList.map((data) => {
                    return(
                        <button key={data.key}
                            className={view === data.key ? "active" : null}
                            value={data.key}
                            onClick={handleViewChange} 
                        >
                            {data.title}
                        </button>
                    )
                })}
            </div>
            {myInfo && <ViewChange view={view} handleLoad={handleLoad} myInfo={myInfo}/> }
        </div>
    )
}

function ViewChange({view, handleLoad, myInfo}){
    switch(view){
        case "UserInfo":
            return <UserInfo handleLoad={handleLoad} />;
        case "UserOption":
            return <UserOption />;
        case "CompanyPlan":
            return <CompanyPlan />;
        case "CompanyProduct":
            return <CompanyProduct />;
        case "CompanyPayment":
            return <CompanyPayment CO_SEQ={myInfo.CO_MAP.CO_SEQ} VIEW_TITLE={true}/>;
        case "UserPwd":
            return <UserPwd />;
        default:
            return null;
    }
}
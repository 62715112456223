import { userListApi } from "api/userApi";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { useState } from "react";
import { cm_isEmpty, resApiCheck } from "utils/common";
import UserDetail from "./UserDetail";
import UserList from "./UserList";
import { useSetStore, useStore } from "contexts/Store";
import { produce } from "immer";
import { configListApi } from "api/configApi";

export default function CompanyUserListMng ({companyType, selectCompany}) {
    const [userList, setUserList] = useState([]);
    const [selectUser, setSelectUser] = useState(null);
    const store = useStore();
    const setStore = useSetStore();
    
    //API
    const [asyncUserList] = useAsync(userListApi);
    const [asyncConfigList] = useAsync(configListApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        const resUserList = await asyncUserList({CO_SEQ : selectCompany.CO_MAP.CO_SEQ, US_STATUS : "A"});
        if(!resApiCheck) return;
        if(resUserList.data.LIST_COUNT > 0){
            setUserList(resUserList.data.LIST);
        }

        // Config US_TYPE list
        if(cm_isEmpty(store.US_TYPE_LIST)){
            const response = await asyncConfigList({CF_TYPE : "US_TYPE"});
            if(!resApiCheck(response)) return;
            if(response.data.LIST.length > 0){
                setStore(produce((prevValue) => {
                    prevValue.US_TYPE_LIST = response.data.LIST;
                }));
            }
        }
    }

    function readUserInfo(US_SEQ){
        if(!cm_isEmpty(US_SEQ)){
            let findUser = userList.find(x => x.US_SEQ === US_SEQ);
            if(!cm_isEmpty(findUser)){
                setSelectUser({US_MAP : findUser});
            }
        }else{
            setSelectUser(null);
        }
    }

    return(
        <>
            {!selectUser ? 
                <UserList CO_TYPE={companyType} CO_SEQ={selectCompany.CO_MAP.CO_SEQ} userList={userList} onClickDetail={readUserInfo} reLoad={handleLoad}/>
                :
                <UserDetail CO_TYPE={companyType} selectUser={selectUser} setSelectUser={setSelectUser} reLoad={handleLoad}/>
            }
        </>
    )
}



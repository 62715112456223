import banner from "img/common/banner.png";

const { default: Section } = require("components/dashboard/Section");

function Banner(){
    return(
        <Section>
            <div className="banner">
                <img src={banner} alt="BannerImgUrl"/>
            </div>
        </Section>
    )
}

export default Banner
import { accountListApi, accountRemainAmountApi, accountTransactionWriteApi } from "api/accountApi";
import { companyListApi } from "api/companyApi";
import { configListApi } from "api/configApi";
import { ex_tableToExcel } from "components/common/Excel";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import AccountSearch from "components/manage/AccountSearch";
import InvoicePreviewModal from "components/modal/InvoicePreviewModal";
import DetailPayment from "components/pay/DetailPayment";
import { ACCOUNT_STATUS, _CO_TYPE_CLINIC, _CO_TYPE_LAB } from "config";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_dateAddMonth, cm_dateMoment, cm_dateToday, cm_findData, cm_isEmpty, cm_isNum, cm_numComma, cm_removeNumComma, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function AccountBook({tap, handleTap}) {
    const t = useTranslate();
    const limit = 10;

    const [account, setAccount] = useState(null);
    const [accountList, setAccountList] = useState([]);
    const [accountListCount, setAccountListCount] = useState(0);
    const [payInfo, setPayInfo] = useState({});
    const [reLoad, setReLoad] = useState(false);
    let today = cm_dateToday();
    let fromDate = cm_dateMoment(cm_dateAddMonth(today, -1), "YYYY-MM");
    let toDate = cm_dateMoment(today, "YYYY-MM");

    const [searchInfo, setSearchInfo] = useState({
        FT_FRMONTH: fromDate,
        FT_TOMONTH: toDate,
        CO_TYPE : "",
        CO_NAME : "",
        PAGE_ROWS : limit,
        PAGE_INDEX : 1
    });

    const [filterStatus, setFilterStatus] = useState([])

    const [asyncAccountList] = useAsync(accountListApi);
    const [asyncConfigList] = useAsync(configListApi);
    const [asyncCompanyList] = useAsync(companyListApi);
    
    const handleFilterStatus = (e) => {
        const value = e.target.value;

        let newFilter = [...filterStatus];
        if(!newFilter.includes(value)){
            newFilter = [...newFilter, e.target.value]
        }else{
            newFilter = [...newFilter.filter(x => x !== e.target.value)];
        }

        setFilterStatus(newFilter);
    }

    useEffect(() => {
        handleConfigLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        searchAccountList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInfo.PAGE_INDEX, filterStatus, reLoad]);

    const handleConfigLoad = async () => {
        // payInfo
        let payInfoData = {...payInfo};
        if(cm_isEmpty(payInfoData)){
            payInfoData = {};
        }

        //device config
        if(cm_isEmpty(payInfoData.DV_CON_LIST)){
            const resDeviceConfigList = await asyncConfigList({CF_TYPE_START : "DV_"});
            if(!resApiCheck(resDeviceConfigList)) return;
            if(resDeviceConfigList.data.LIST.length > 0){
                payInfoData.DV_CON_LIST = resDeviceConfigList.data.LIST;
            }
        }

        //quveInfo
        if(cm_isEmpty(payInfoData.QUVE_INFO)){
            const resQvInfo = await asyncConfigList({CF_TYPE : "QV_INFO"});
            if(!resApiCheck(resQvInfo)) return;
            if(resQvInfo.data.LIST.length > 0){
                payInfoData.QV_INFO = resQvInfo.data.LIST[0];
            }
        }

        //companyList
        if(cm_isEmpty(payInfoData.COMPANY_LIST)){
            const resCompanyList = await asyncCompanyList({});
            if(!resApiCheck(resCompanyList)) return;
            if(resCompanyList.data.LIST.length > 0){
                payInfoData.COMPANY_LIST = resCompanyList.data.LIST;
            }
        }

        setPayInfo(payInfoData);
    }

    const handleReLoad = () => {
        setAccount(null);
        setReLoad(prevValue => !prevValue);
    }

    const searchAccountList = async () => {
        const FT_STATUS = filterStatus.map(x => `"${x}"`).join(",");

        const resAccountList = await asyncAccountList({...searchInfo, FT_STATUS: FT_STATUS});
        if(!resApiCheck(resAccountList)) return;
        setAccountListCount(resAccountList.data.LIST_COUNT);
        if(resAccountList.data.LIST_COUNT > 0){
            setAccountList(resAccountList.data.LIST);
        }else{
            setAccountList([]);
        }
    }

    const handlePage = (page) => {
        setSearchInfo(produce(prevValue => {
            prevValue.PAGE_INDEX = page;
        }));
    }

    const onClickDetail = (selectAccount) => {
        setAccount(selectAccount);
    }

    //Excel
    function handleExcel(tableId){
        let fileName = "";
        let sheetName = "";

        if(tableId === "accountList"){
            fileName = t("common_payment") + "_LIST_" + searchInfo.FT_FRMONTH + "_" + searchInfo.FT_TOMONTH;
            sheetName = searchInfo.FT_FRMONTH + "~" + searchInfo.FT_TOMONTH;
        }

        ex_tableToExcel(tableId, fileName, sheetName);
    }

    return(
        <>
            {!account ?
                <>
                    <AccountSearch
                        searchInfo={searchInfo}
                        setSearchInfo={setSearchInfo}
                        searchList={searchAccountList}
                        filterStatus={filterStatus} 
                        handleFilterStatus={handleFilterStatus}
                        tap={tap}
                        handleTap={handleTap}
                    />
                    <>
                        <AccountBookList accountList={accountList} onClickDetail={onClickDetail} handleExcel={handleExcel} />
                        <Pagination total={accountListCount} limit={limit} page={searchInfo.PAGE_INDEX} setPage={handlePage}/>
                    </>
                </>
                :
                <AccountBookDetail payInfo={payInfo} account={account} setAccount={setAccount} handleReLoad={handleReLoad}/>
            }
        </>
    )
}

function AccountBookList({accountList, onClickDetail, handleExcel}){
    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const accountStatus = ACCOUNT_STATUS();

    return(
        <>
            <div className="flex justify-content-between align-items-center mb20">
                <div></div>
                <div className="flex gap10 align-items-center">
                    <button className="btn btn-fill-gray2 btn-s" onClick={() => handleExcel("accountList")}>
                        <i className="ico-excel"></i> EXCEL
                    </button>
                </div>
            </div>
            <div className="table2 cursor">
                <table id="accountList">
                    <thead>
                        <tr>
                            <th>{t("payment_billMonth")}</th>
                            <th>{t("common_companyName")}</th>
                            <th>{t("payment_totalCost")}</th>
                            <th>{t("payment_adjustCost")}</th>
                            <th>{t("payment_billCost")}</th>
                            <th>{t("payment_payCost")}</th>
                            <th>{t("payment_unpaid")}</th>
                            <th>{t("common_status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {!cm_isEmpty(accountList) ?
                        accountList.map((account) => {
                            let statusText = cm_findData(accountStatus, "status", account.AB_STATUS, "text");

                            return(
                                <tr key={`account_${account.AB_SEQ}`} onClick={() => onClickDetail(account)}>
                                    <td>{account.AB_MONTH}</td>
                                    <td>{account.CO_NAME}</td>
                                    <td>
                                        <div className="inq-price">
                                            <strong>{cm_numComma(account.AB_TAMOUNT)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inq-price">
                                            <strong>{cm_numComma(account.AB_AAMOUNT)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inq-price">
                                            <strong>{cm_numComma(account.AB_IAMOUNT)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inq-price">
                                            <strong>{cm_numComma(account.AB_DAMOUNT)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inq-price">
                                            <strong className="txt-red">{cm_numComma(account.AB_RAMOUNT)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>{statusText}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan='8' align='center'>{t("payment_noBill")}</td></tr>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

function AccountBookDetail({payInfo, account, setAccount, handleReLoad}){
    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    const accountStatus = ACCOUNT_STATUS();

    const {CO_SEQ, CO_TYPE, CO_NAME, AB_SEQ, AB_STATUS, AB_MONTH, AB_TAMOUNT, AB_AAMOUNT, AB_IAMOUNT, AB_DAMOUNT, AB_RAMOUNT} = account;

    const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);

    const [totalRemainAmount, setTotalRemainAmount] = useState(0);
    const [accountUpdateInfo, setAccountUpdateInfo] = useState({
        CO_SEQ : CO_SEQ,
        AB_SEQ : AB_SEQ,
        AT_ADJUST : 0,
        AT_MEMO : "",
        AT_AMOUNT : 0,
    })

    const [asyncAccountTransactionWrite, accountTransactionWritePending] = useAsync(accountTransactionWriteApi);
    const [asyncAccountRemainAmount] = useAsync(accountRemainAmountApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        const resRemainAmount = await asyncAccountRemainAmount({
            CO_SEQ : CO_SEQ
        })
        if(!resApiCheck(resRemainAmount)) return;
        if(!cm_isEmpty(resRemainAmount.data.AB_RAMOUNT)){
            setTotalRemainAmount(resRemainAmount.data.AB_RAMOUNT);
        }else{
            setTotalRemainAmount(0);
        }
    }
    const handleInvoiceModal = () => {setIsInvoiceOpen((prevValue) => !prevValue);}

    // handle accountInfo
    const handleChangeAccountUpdateInfo = (e) => {
        let {name, value} = e.target;

        if(name === "AT_AMOUNT" || name === "AT_ADJUST"){
            // 마이너스 금액 가능
            setAccountUpdateInfo(produce(prevValue => {
                let number = cm_removeNumComma(value);

                if(number.length > 1){
                    if(cm_isNum(number, true, t("alert_num_number"))){
                        number = Number(number);
                        prevValue[name] = number;
                    }else{
                        prevValue[name] = "";
                    }
                }else{
                    prevValue[name] = number;
                }
            }))
        }else{
            setAccountUpdateInfo(produce(prevValue => {
                prevValue[name] = value;
            }))
        }
    }

    //Adjust
    async function handleAdjustWrite(){
        if(!adjustValidate()) return;

        const confirm = await cm_swConfirm(t("confirm_pay_adjust"), `${cm_numComma(accountUpdateInfo.AT_ADJUST)} ${SYMBOL}`, "info", t("common_yes"), t("common_no"));

        if(confirm){
            const response = await asyncAccountTransactionWrite({
                CO_SEQ : accountUpdateInfo.CO_SEQ,
                AT_TYPE : "A",
                AB_SEQ : accountUpdateInfo.AB_SEQ,
                AT_AMOUNT : accountUpdateInfo.AT_ADJUST,
                AT_MEMO : accountUpdateInfo.AT_MEMO,
                AB_MONTH : AB_MONTH,
            })
            
            if(!resApiCheck(response)){
                cm_swAlert(t("alert_adjust_reflectFailed"), "warning", t("common_check"));
            }else{
                cm_swAlert(t("alert_adjust_reflected"), "success", t("common_check"));
                handleReLoad();
            }
        }
    }

    function adjustValidate(){
        if(cm_isEmpty(accountUpdateInfo.AT_ADJUST) || accountUpdateInfo.AT_ADJUST === 0) {
            cm_swAlert(t("alert_adjust_amount_input"), "warning", t("common_check"));
            return false;
        } else if(cm_isEmpty(accountUpdateInfo.AT_MEMO)){
            cm_swAlert(t("alert_adjust_memo_input"), "warning", t("common_check"));
            return false;
        }

        if(CO_TYPE === _CO_TYPE_CLINIC){
            // 치과 금액 조정 validation
            if(accountUpdateInfo.AT_AMOUNT > AB_IAMOUNT){
                cm_swAlert(t("alert_adjust_Larger"), "info", t("common_check"));
                return false;
            }
        }else if(CO_TYPE === _CO_TYPE_LAB){
            //기공소 금액 조정 validation
        }

        return true;
    }

    //TransactionWrite
    async function handleTransactionWrite(){
        if(!transactionValidate()) return;

        const confirm = await cm_swConfirm(t("confirm_pay_settlement"), `${cm_numComma(accountUpdateInfo.AT_AMOUNT)} ${SYMBOL}`, "info", t("common_yes"), t("common_no"));
        if(confirm){
            let atType = (CO_TYPE === _CO_TYPE_CLINIC ? "I" : "O");

            const response = await asyncAccountTransactionWrite({
                CO_SEQ : accountUpdateInfo.CO_SEQ,
                AT_TYPE : atType,
                AT_AMOUNT : accountUpdateInfo.AT_AMOUNT,
                AB_MONTH : AB_MONTH,
            })
            if(!resApiCheck(response)){
                cm_swAlert(t("alert_settlement_reflectFailed"), "warning", t("common_check"));
            }else{
                cm_swAlert(t("alert_settlement_reflected"), "success", t("common_check"));
                handleReLoad();
            }
        }
    }

    function transactionValidate(){
        if(cm_isEmpty(accountUpdateInfo.AT_AMOUNT) || accountUpdateInfo.AT_AMOUNT === 0) {
            cm_swAlert(t("alert_settlement_amount_input"), "warning", t("common_check"));
            return false;
        }

        if(CO_TYPE === _CO_TYPE_CLINIC){
            if(accountUpdateInfo.AT_AMOUNT > totalRemainAmount){
                cm_swAlert(t("alert_settlement_Larger"), "info", t("common_check"));
                return false;
            }

            if(accountUpdateInfo.AT_AMOUNT < 0){
                cm_swAlert(t("alert_settlement_inputPlus"), "info", t("common_check"));
                return false;
            }
        }else if(CO_TYPE === _CO_TYPE_LAB){
            if(accountUpdateInfo.AT_AMOUNT > AB_RAMOUNT){
                cm_swAlert(t("alert_settlement_Larger"), "info", t("common_check"));
                return false;
            }

            if(accountUpdateInfo.AT_AMOUNT < 0){
                cm_swAlert(t("alert_settlement_inputPlus"), "info", t("common_check"));
                return false;
            }
        }else{
            cm_swAlert(t("alert_settlement_CompanyLookupFailed"), "info", t("common_check"));
            return false;
        }

        return true;
    }
    
    return(
        <>
            <div className="calcus-wrap">
                <div className="inquiry-box visible">
                    <div className="table-summary">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t("payment_billMonth")}</th>
                                    <th>{t("common_companyName")}</th>
                                    <th>{t("payment_totalCost")}</th>
                                    <th>{t("payment_adjustCost")}</th>
                                    <th>{t("payment_billCost")}</th>
                                    <th>{t("payment_payCost")}</th>
                                    <th>{t("payment_unpaid")}</th>
                                    <th>{t("common_status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span>{AB_MONTH}</span></td>
                                    <td><span>{CO_NAME}</span></td>
                                    <td><span>{cm_numComma(AB_TAMOUNT)}{SYMBOL}</span></td>
                                    <td><span>{cm_numComma(AB_AAMOUNT)}{SYMBOL}</span></td>
                                    <td><span>{cm_numComma(AB_IAMOUNT)}{SYMBOL}</span></td>
                                    <td><span>{cm_numComma(AB_DAMOUNT)}{SYMBOL}</span></td>
                                    <td><span className="txt-red">{cm_numComma(AB_RAMOUNT)}{SYMBOL}</span></td>
                                    <td><span>{accountStatus.find(x => x.status === AB_STATUS).text}</span></td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{display: "flex", justifyContent:"space-between"}}>
                            <div style={{flex: "1", padding:"10px"}}>
                                {CO_TYPE === _CO_TYPE_CLINIC && 
                                    <div className="calcus" style={{justifyContent:"flex-start"}}>
                                        <span>{t("payment_pastOutstanding")}</span>
                                        <span className="txt-red">{cm_numComma(totalRemainAmount)}{SYMBOL}</span>
                                    </div>
                                }
                            </div>
                            <div style={{flex: "1"}}>
                                <div className="calcus">
                                    {AB_STATUS !== "C" &&
                                        <>
                                            <span style={{marginRight:"0px"}}>
                                                {t("payment_adjustCost")}
                                            </span>
                                            <div className="tip-wrap" style={{width:"10px"}}>
                                                <i className="xi-error"></i>
                                                <div className="tip-box" style={{width: "auto"}}>
                                                    <strong>- {t("payment_content_2")}</strong>
                                                    <strong>- {t("payment_content_3")}</strong>
                                                </div>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="inp" 
                                                name="AT_ADJUST" 
                                                value={cm_numComma(accountUpdateInfo.AT_ADJUST)} 
                                                onChange={handleChangeAccountUpdateInfo} 
                                                placeholder={t("common_ph")} 
                                                maxLength={11}
                                            />
                                            <span>{t("common_memo")}</span>
                                            <input 
                                                type="text" 
                                                className="inp" 
                                                name="AT_MEMO" 
                                                value={accountUpdateInfo.AT_MEMO}
                                                onChange={handleChangeAccountUpdateInfo} 
                                                placeholder={t("common_ph")}
                                                maxLength={500}
                                            />
                                            {/* 조정하기 */}
                                            <button
                                                className="btn btn-fill-blue" 
                                                disabled={accountTransactionWritePending}
                                                onClick={handleAdjustWrite}>{t("payment_adjust_do")}
                                            </button>
                                        </>
                                    }

                                    {/* 청구서 */}
                                    {CO_TYPE !== _CO_TYPE_LAB &&
                                        <button
                                            className="btn btn-fill-blue" 
                                            onClick={handleInvoiceModal}>{t("payment_bill")}
                                        </button>
                                    }
                                </div>

                                {/* 정산중 */}
                                {AB_STATUS === "I" &&
                                    <div className="calcus">
                                        <span>{t("payment_payCost")}</span>
                                        <input 
                                            type="text" 
                                            className="inp" 
                                            name="AT_AMOUNT"
                                            value={cm_numComma(accountUpdateInfo.AT_AMOUNT)}
                                            onChange={handleChangeAccountUpdateInfo}
                                            placeholder={t("common_ph")}
                                            maxLength={11}
                                        />
                                        {/* 정산하기 */}
                                        <button 
                                            className="btn btn-fill-blue" 
                                            disabled={accountTransactionWritePending}
                                            onClick={handleTransactionWrite}>{t("payment_billPlay")}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {account &&
                    <DetailPayment payInfo={payInfo} account={account} setAccount={setAccount} MONTH={cm_dateMoment(cm_dateAddMonth(AB_MONTH  + "-01", -1))} CO_TYPE={CO_TYPE} handleReLoad={handleReLoad} />
                } 
            </div>
            <div className="flex gap10 justify-content-center" style={{marginTop: "20px"}}>
                <button className="btn btn-fill-blue2 btn-round" onClick={() => setAccount(null)}>{t("common_list")}</button>
            </div>

            <Modal show={isInvoiceOpen} onHide={handleInvoiceModal} centered>
                <InvoicePreviewModal isOpen={isInvoiceOpen} handleModal={handleInvoiceModal} handleReLoad={handleReLoad} account={account} CO_TYPE={CO_TYPE} CO_SEQ={CO_SEQ}/>
            </Modal>
        </>
        
    )

}
import { useEffect } from "react";
import OrderMemo from "./OrderMemo";
import ProstheticOption from "./ProstheticOption";
import useTranslate from "components/language/useTranslate";
import { useState } from "react";
import { userOptionListApi } from "api/userApi";
import useAsync from "hooks/useAsync";
import { useStore } from "contexts/Store";
import { cm_JsonParseList, cm_isEmpty, resApiCheck } from "utils/common";

export default function OrderOption(props) {
    const t = useTranslate();
    const store = useStore();

    const [userProsList, setUserProsList] = useState([]);
    const [reLoad, setReLoad] = useState(false);

    const [asyncUsOptionList] = useAsync(userOptionListApi);

    const init = {
        UO_SEQ: 0,
        UO_NAME: t("common_directSelection"),
        UO_OPTION: [
            {
                key: "contact",
                value: "1"
            },
            {
                key: "embrasure",
                value: "1"
            },
            {
                key: "hook",
                value: "1"
            },
            {
                key: "ponticType",
                value: "1"
            }
        ]
    }

    useEffect(() => {
        handleLoad();
    },[reLoad])

    const handleLoad = async () => {
        const response = await asyncUsOptionList({
            US_SEQ : store.US_MAP.US_SEQ,
            UO_TYPE : "P"
        });
        if(!resApiCheck(response)) return;

        if(response.data.LIST.length > 0){
            let initList = response.data.LIST;
            initList.unshift(init);
            initList = cm_JsonParseList(initList, "UO_OPTION");

            setUserProsList(initList);

        }else{
            setUserProsList([init]);
        }
    }

    const handleSetReLoad = () => {setReLoad((prevValue) => !prevValue)};

    return (
        <div className="order-writeFrame">
            <div className="side">
                <div className="sideDiv left">
                    <button className="arrow arrow-left" onClick={() => props.changeTab("product")}></button>
                </div>
            </div>

            <div className="middle">
                <div className="inner1100">
                    <div className="flex gap20">
                        <h3 className="h3 mb0">{t("order_selectOpt")} </h3>
                        {!cm_isEmpty(userProsList) &&
                            <ProstheticOption userProsList={userProsList} reLoadOption={handleSetReLoad}/>
                        }
                    </div>
                    <hr/>
                    <div style={{display:"flex", gap:"20px"}}>
                        <div style={{flex:"1"}}>
                            <OrderMemo />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="side">
                <div className="sideDiv right">
                    <button className="arrow arrow-right" onClick={() => props.changeTab("check")}></button>
                </div>
            </div>
        </div>
    )
}
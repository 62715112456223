import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { cm_isEmpty } from "utils/common";
import { useOrder, useSetOrder } from "contexts/OrderContext";
import { produce } from "immer";
import UserProsOptionModal from "components/modal/UserProsOptionModal";
import { PROSTHETIC_OPTIONS } from "config";
import useTranslate from "components/language/useTranslate";

export default function ProstheticOption({userProsList, reLoadOption}){
    const order = useOrder();
    const setOrder = useSetOrder();
    const [isOpen, setIsOpen] = useState(false);
    
    const [selectMoSeq, setSelectMoSeq] = useState(0);
    const prosthetic = PROSTHETIC_OPTIONS();
    const prostheticKeys = Object.keys(prosthetic);
    const t = useTranslate();

    useEffect(() => {
        handleLoad();

        let startPoint = 0;
        let endPoint = 0;
        const slide = document.querySelector(".board");

        // PC 드래그 이벤트
        const handleClickStart = (e) => {
            e.preventDefault();
            startPoint = e.pageX;
        }
        
        const handleClickEnd = (e) => {
            e.preventDefault();
            endPoint = e.pageX;
            let options = document.querySelector("#options");
            if(startPoint < endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft - 600, top: 0, behavior:"smooth"});
            }else if (startPoint > endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft + 600, top: 0, behavior:"smooth"});
            }
        }

        slide.addEventListener("mousedown", handleClickStart, false);
        slide.addEventListener("mouseup", handleClickEnd, false);
        
        // 모바일 스와이프 이벤트
        const handleTouchStart = (e) => {
            e.preventDefault();
            startPoint = e.touches[0].pageX;
        }

        const handleTouchEnd = (e) => {
            e.preventDefault();
            endPoint = e.changedTouches[0].pageX;
            let options = document.querySelector("#options");
            if(startPoint < endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft - 600, top: 0, behavior:"smooth"});
            }else if (startPoint > endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft + 600, top: 0, behavior:"smooth"});
            }else{
                handleClickRegiOption(e);
            }
        }
        
        slide.addEventListener("touchstart", handleTouchStart, false);
        slide.addEventListener("touchend", handleTouchEnd, false);

        //PC click 이벤트
        const left = document.querySelector(".button-left");
        const right = document.querySelector(".button-right");

        const handleClickLeft = () => {
            let options = document.querySelector("#options");
            let curScrollLeft = options.scrollLeft;
            options.scrollTo({left: curScrollLeft - 600, top: 0, behavior:"smooth"});
        }
    
        const handleClickRight = () => {
            let options = document.querySelector("#options");
            let curScrollLeft = options.scrollLeft;
            options.scrollTo({left: curScrollLeft + 600, top: 0, behavior:"smooth"});
        }

        left.addEventListener("click", handleClickLeft);
        right.addEventListener("click", handleClickRight);
        

        return() => {
            slide.removeEventListener("mouseDown", handleClickStart);
            slide.removeEventListener("mouseup", handleClickEnd);
            slide.removeEventListener("touchstart", handleTouchStart);
            slide.removeEventListener("touchend", handleTouchEnd);
            left.removeEventListener("click", handleClickLeft);
            right.removeEventListener("click", handleClickRight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const handleLoad = async () => {
        // localStorage Option check
        if(localStorage.getItem(`OP_PROS_SEQ`)){
            let localProsSeq = JSON.parse(localStorage.getItem(`OP_PROS_SEQ`));

            let findOption = userProsList.find(x => x.UO_SEQ === localProsSeq.UO_SEQ);
            if(!cm_isEmpty(findOption)){
                handleOptionChange(findOption);
            }
        }
    }
    
    // 사전 등록 옵션 선택
    const handleClickRegiOption = (e) => {
        let UO_SEQ = Number(e.target.value);

        let findOption = userProsList.find(x => x.UO_SEQ === UO_SEQ);

        handleOptionChange(findOption);
    }

    const handleOptionChange = (findOption) => {
        let optionList = findOption.UO_OPTION;
        let OD_CONTACT = optionList.find(x => x.key === "contact").value;
        let OD_EMBRASURE = optionList.find(x => x.key === "embrasure").value;
        let OD_HOOK = optionList.find(x => x.key === "hook").value;
        let OD_PONTIC = optionList.find(x => x.key === "ponticType").value;

        // 사전옵션 localStorage save
        localStorage.setItem(`OP_PROS_SEQ`, JSON.stringify({UO_SEQ : findOption.UO_SEQ}));
        
        setOrder(produce((prevValue) => {
            prevValue.OD_CONTACT = OD_CONTACT;
            prevValue.OD_EMBRASURE = OD_EMBRASURE;
            prevValue.OD_HOOK = OD_HOOK;
            prevValue.OD_PONTIC = OD_PONTIC;
        }));
        
        setSelectMoSeq(findOption.UO_SEQ);
    }

    // 직접 옵션 선택
    const handleClickOption = (e) => {
        const {name, value} = e.target;

        let orderOptionKey = "";

        if(name === "contact"){
            orderOptionKey = "OD_CONTACT";
        }else if(name === "embrasure"){
            orderOptionKey = "OD_EMBRASURE";
        }else if(name === "hook"){
            orderOptionKey = "OD_HOOK";
        }else if(name === "ponticType"){
            orderOptionKey = "OD_PONTIC";
        }
        
        setOrder(produce((prevValue) => {
            prevValue[orderOptionKey] = value;
        }))

        setSelectMoSeq(0);
    }

    function getOption(key) {
        let orderOptionValue = 0;

        if(key === "contact"){
            orderOptionValue = order.OD_CONTACT;
        }else if(key === "embrasure"){
            orderOptionValue = order.OD_EMBRASURE;
        }else if(key === "hook"){
            orderOptionValue = order.OD_HOOK;
        }else if(key === "ponticType"){
            orderOptionValue = order.OD_PONTIC;
        }

        return (
            <>
                {prosthetic[key].map((config) => {
                    return(
                        <li key={`config_${config.title}_${config.value}`}>
                            <button
                                className={config.value === orderOptionValue ? "active" : null}
                                name={key}
                                value={config.value}
                                onClick={handleClickOption}
                            >
                                {config.title}
                            </button>
                        </li>
                    )
                })}
            </>
        )
    }

    return(
        <>
            <div className="options2-wrap">
                <div className="optionBox mb20" style={{gap: "20px"}}>

                    <div className="button">
                        <button className="button-left"></button>
                    </div>

                    <div className="board">
                        <ul id="options" style={{flexWrap:"nowrap", overflow:"hidden", width: "700px"}}>
                            {userProsList && userProsList.map((option) => {
                                return(
                                    <li style={{flex: "0 0 auto"}} key={`option_key_${option.UO_SEQ}`}>
                                        <button
                                            className={selectMoSeq === Number(option.UO_SEQ) ? "active" : null}
                                            key={option.UO_SEQ}
                                            value={option.UO_SEQ}
                                            onClick={handleClickRegiOption}
                                        >
                                            {option.UO_NAME}
                                        </button>
                                    </li>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <div className="button">
                        <button className="button-right"></button>
                    </div>
                </div>

                <div className="selectBox">
                    {prostheticKeys.map((key) => {
                        return(
                            <div className="row" key={`selectBox_${key}`}>
                                <ul className="gap10">
                                    <li className="title" key={`title_${key}`}>
                                        <h4 className="h4" style={{}}>{key}</h4>
                                        {/* <h4 className="h4" style={{}}>
                                            {key === "contact" && t("common_contact")}
                                            {key === "embrasure" && t("common_embrasure")}
                                            {key === "hook" && t("common_hook")}
                                            {key === "ponticType" && t("common_ponticType")}
                                        </h4> */}
                                    </li>
                                    {getOption(key)}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
            <button className="btn btn-fill-gray" onClick={handleModal}>{t("common_add")}</button>

            <Modal show={isOpen} onHide={handleModal} centered>
                <UserProsOptionModal isOpen={isOpen} handleModal={handleModal} selectObj={null} reLoadOption={reLoadOption}/>
            </Modal>
        </>
    )
}
import { companyPlanListApi } from "api/companyApi";
import { deviceListApi } from "api/deviceApi";
import { planWriteApi } from "api/planApi";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_isPositiveNum, cm_numComma, cm_removeNumComma, cm_swAlert, resApiCheck } from "utils/common";

export default function PlanWrite({handleIsWrite, plan, setPlan, planDevice, setPlanDevice}) {
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const [usePlanCompany, setUsePlanCompany] = useState(false);
    const t = useTranslate();

    //API
    const [asyncCompanyPlanList] = useAsync(companyPlanListApi);
    const [asyncPlanWrite] = useAsync(planWriteApi);

    useEffect(() => {
        if(plan.PL_SEQ > 0){
            handleLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        const response = await asyncCompanyPlanList({PL_SEQ : plan.PL_SEQ});
        if(!resApiCheck(response)) return;
        
        if(response.data.LIST_COUNT > 0){
            setUsePlanCompany(true);
        }
    }

    const handleChange = (e) => {
        let {name, value} = e.target;
        
        setPlan(produce(prevValue => {
            if(name === "PL_TYPE" && value === "R"){
                prevValue.PL_BASEPRICE = 0;
                prevValue[name] = value;
            }else if(name === "PL_MONTH" || name === "PL_PRICE" || name === "PL_BASEPRICE"){
                value = Number(cm_removeNumComma(value));
                if(!cm_isPositiveNum(value, false)) return;
                prevValue[name] = value;
            }else{
                prevValue[name] = value;
            }
            
        }));
    };

    const handleDeleteDevice = (device) => {
        const {DV_SEQ} = device;

        if(planDevice.filter(x => x.DV_SEQ === DV_SEQ).length > 0){
            setPlanDevice(planDevice.filter(x => x.DV_SEQ !== DV_SEQ));
        }else{
            setPlanDevice([...planDevice, device]);
        }
    }

    const handleSave = async () => {
        if(!planValidate()) return;

        const response = await asyncPlanWrite({...plan, PD_LIST: JSON.stringify(planDevice)});
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleIsWrite();
        }
    }

    function planValidate(){
        let result = true;
        let text = "";

        if(usePlanCompany){
            result = false;
            text = t("plan_alert_planUse");
        }else if(cm_isEmpty(plan.PL_NAME)){
            result = false;
            text = t("plan_alert_planName");
        }else if(cm_isEmpty(plan.PL_MONTH)){
            result = false;
            text = t("plan_alert_period");
        }else if(cm_isEmpty(plan.PL_PRICE)){
            result = false;
            text = t("plan_alert_monthFee");
        }else if(plan.PL_TYPE === "R" && plan.PL_BASEPRICE > 0){
            result = false;
            text = t("plan_alert_type");
        }

        for(let device of planDevice){
            if(cm_isEmpty(device.PD_QTY)){
                result = false;
                text = t("plan_content_2");
                break;
            }
        }

        if(!result){
            cm_swAlert(text, "info", t("common_check"));
        }

        return result;
    }

    // Modal
    const [isOpen, setIsOpen] = useState(false);
    const handleDeviceModal = () => setIsOpen((prevValue) => !prevValue);

    return(
        <>
            <div className="board-write type2">
                <h3 className="h3">{t("plan_planRegi")}</h3>
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "40%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "40%"}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("plan_planType")}</th>
                            <td>
                                <select className="select block" name="PL_TYPE" value={plan.PL_TYPE} onChange={handleChange}>
                                    <option value="B">{t("plan_basicFee")}</option>
                                    <option value="R">{t("plan_rental")}</option>
                                </select>
                            </td>
                            <th>{t("plan_planName")}</th>
                            <td><input type="text" className="inp block" name="PL_NAME" value={plan.PL_NAME} placeholder={t("common_ph")} onChange={handleChange} maxLength={33}/></td>
                        </tr>
                        <tr>
                            <th>{t("common_contractPeriod")}</th>
                            <td>
                                <div className="inp-box mb0">
                                    <input type="text" className="inp block in text-left" name="PL_MONTH" value={plan.PL_MONTH} placeholder={t("common_ph")} onChange={handleChange} maxLength={2}/>
                                    <div className="inside">{t("common_months")}</div>
                                </div>
                            </td>
                            <th>{t("common_monthFee")}</th>
                            <td>
                                <div className="inp-box mb0">
                                    <input type="text" className="inp block in text-left" name="PL_PRICE" value={cm_numComma(plan.PL_PRICE)} placeholder={t("common_ph")} onChange={handleChange} maxLength={10}/>
                                    <div className="inside">{SYMBOL}</div>
                                </div>
                            </td>
                        </tr>
                        {plan.PL_TYPE !== "R" && 
                            <tr>
                                <th>{t("common_basePrice")}</th>
                                <td>
                                    <div className="inp-box mb0">
                                        <input type="text" className="inp block in text-left" name="PL_BASEPRICE" value={cm_numComma(plan.PL_BASEPRICE)} placeholder={t("common_ph")} onChange={handleChange} maxLength={10}/>
                                        <div className="inside">{SYMBOL}</div>
                                    </div>
                                </td>
                                <th></th>
                                <td></td>
                            </tr>
                        }
                        <tr>
                            <th>{t("common_equip")}</th>
                            <td colSpan="3">
                                <div className="gear-edit">
                                    {planDevice && planDevice.map((device) => {
                                        return (
                                            <div key={device.DV_SEQ} className="item">
                                                <strong>{device.DV_NAME} ({device.PD_QTY})</strong>
                                                <button className="btn-del" onClick={() => handleDeleteDevice(device)}><span className="hidden">{t("myPage_del")}</span></button>
                                            </div>
                                        )
                                    })}
                                    <button className="btn" onClick={handleDeviceModal}>+ {t("common_add")}</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("common_note")}</th>
                            <td colSpan="3"><input type="text" className="inp block" name="PL_DESC" value={plan.PL_DESC} placeholder={t("common_ph")} onChange={handleChange} maxLength={500}/></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="flex gap10 justify-content-center">
                <button className="btn btn-line-blue2 btn-round" onClick={handleIsWrite}>{t("common_cancel")}</button>
                <button className="btn btn-fill-blue2 btn-round" onClick={handleSave}>{plan.PL_SEQ > 0 ? t("common_edit") : t("common_regi")}</button>
            </div>

            <Modal show={isOpen} onHide={handleDeviceModal} centered>
                <DeviceChoice isOpen={isOpen} handleDeviceModal={handleDeviceModal} planDevice={planDevice} setPlanDevice={setPlanDevice}/>
            </Modal>
        </>
    )
}

function DeviceChoice({isOpen, handleDeviceModal, planDevice, setPlanDevice}){
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const [deviceType, setDeviceType] = useState("P");
    const [tempPlanDevice, setTempPlanDevice] = useState(planDevice);
    const t = useTranslate();

    // Device Api
    const [deviceList, setDeviceList] = useState(null);
    const [asyncDeviceList] = useAsync(deviceListApi);
    
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        const response = await asyncDeviceList({});
        if(!resApiCheck(response)) return;

        if(response.data.LIST_COUNT > 0){
            setDeviceList(response.data.LIST);
        }
    }

    const handleDeviceType = (e) => setDeviceType(e.target.value);

    const handleTempDevice = (device) => {
        const {DV_SEQ} = device;

        if(tempPlanDevice.filter(x => x.DV_SEQ === DV_SEQ).length > 0){
            setTempPlanDevice(tempPlanDevice.filter(x => x.DV_SEQ !== DV_SEQ));
        }else{
            device.PD_QTY = 1;
            setTempPlanDevice([...tempPlanDevice, device]);
        }
    }

    const handleChangeCount = (e) => {
        let {name, value} = e.target;
        value = value.replace(/[^0-9]/g, '');
        let seq = e.currentTarget.id;

        setTempPlanDevice(produce((prevValue) => {
            let findIdx = prevValue.findIndex(x => x.DV_SEQ === Number(seq));
            prevValue[findIdx][name] = value; 
        }))
    }

    const handleDeviceSave = () => {
        if(!saveValidate()) return;

        setPlanDevice([...tempPlanDevice])
        handleDeviceModal();
    }

    function saveValidate(){
        let result = true;
        let text = "";

        for(let device of tempPlanDevice){
            if(cm_isEmpty(device.PD_QTY)){
                result = false;
                text = t("plan_content_2");
                break;
            }
        }
        
        if(!result){
            cm_swAlert(text, "info", t("common_check"));
        }

        return result;
    }

    return (
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered modal-list" style={{maxWidth:"1000px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("common_equip")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleDeviceModal}></button>
                    </div>

                    <div className="modal-body">
                        <div>
                            <h3 className="h3">{t("plan_deviceList")}</h3>
                        </div>
                        <div className="tabs4 mb20">
                            <button className={deviceType === "P" ? "active" : null} value={"P"} onClick={handleDeviceType}>{t("plan_3dPrint")}</button>
                            <button className={deviceType === "S" ? "active" : null} value={"S"} onClick={handleDeviceType}>{t("plan_scaner")}</button>
                        </div>

                        <div className="table1 text-left hover-type">
                            <table>
                                <colgroup>
                                    <col style={{width:"25%"}}/>
                                    <col style={{width:"25%"}}/>
                                    <col style={{width:"25%"}}/>
                                    <col style={{width:"25%"}}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>{t("common_mf")}</th>
                                        <th>{t("common_prodModel")}</th>
                                        <th>{t("common_prodName")}</th>
                                        <th>{t("common_price")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deviceList && deviceList.filter(x => x.DV_TYPE === deviceType).map((device) => {
                                        const active = planDevice.filter(x => x.DV_SEQ === device.DV_SEQ).length > 0;

                                        return (
                                            <tr key={"device_" + device.DV_SEQ} onClick={() => handleTempDevice(device)} className={active ? "active" : null}>
                                                <td>{device.DV_MAN}</td>
                                                <td>{device.DV_MODEL}</td>
                                                <td>{device.DV_NAME}</td>
                                                <td>{cm_numComma(device.DV_COST)}{SYMBOL}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h3 className="h3">{t("plan_regiDevice")}</h3>
                        </div>
                        <div className="table1 text-left hover-type">
                            <table>
                                <colgroup>
                                    <col style={{width:"20%"}}/>
                                    <col style={{width:"20%"}}/>
                                    <col style={{width:"20%"}}/>
                                    <col style={{width:"20%"}}/>
                                    <col style={{width:"20%"}}/>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>{t("common_mf")}</th>
                                        <th>{t("common_mfModel")}</th>
                                        <th>{t("common_prodName")}</th>
                                        <th>{t("common_unitPrice")}</th>
                                        <th>{t("common_deviceCount")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!cm_isEmpty(tempPlanDevice) ?
                                        tempPlanDevice.map((device) => {
                                            return(
                                                <tr key={device.DV_SEQ}>
                                                    <td>{device.DV_MAN}</td>
                                                    <td>{device.DV_MODEL}</td>
                                                    <td>{device.DV_NAME}</td>
                                                    <td>{cm_numComma(device.DV_COST)}{SYMBOL}</td>
                                                    <td>
                                                        <input 
                                                            type="text" 
                                                            className="inp block"
                                                            id={device.DV_SEQ}
                                                            name="PD_QTY" 
                                                            value={device.PD_QTY} 
                                                            onChange={handleChangeCount}
                                                            maxLength={1}
                                                            />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr><td colSpan={5} align="center">{t("alert_noDquipment")}</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-gray" data-bs-dismiss="modal" onClick={handleDeviceModal}>{t("common_cancel")}</button>
                        <button type="button" className="btn btn-fill-blue" onClick={handleDeviceSave}>{t("common_add")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useRef } from "react";
import '../css/drop-file-input.css';
import { checkFileSize, cm_isEmpty, cm_swAlert } from "utils/common";
import useTranslate from "./language/useTranslate";

const DropFileInputWide = props => {
    const maxSize = 400 * 1024 * 1024; // 400mb
    const wrapperRef = useRef(null);
    const {onChangeFile, deleteFile, fileList, type, children} = props;
    const t = useTranslate();

    const onDragEnter = () => wrapperRef.current.classList.add("dragover");
    const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
    const onDrop = () => wrapperRef.current.classList.remove("dragover");

    const onFileDrop = (e) => {
        let newFiles = [...fileList];
        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            
            if(props.currentLab) {
                file.ref = type;
                file.labName = props.currentLab.US_NAME;
            }

            if(!checkFileSize(file.size, maxSize)){
                cm_swAlert(`${maxSize / 1024 / 1024} MB. ${t("common_content_1")}`, "warning", t("common_check"));
                return;
            }

            newFiles.push(file);
        }
        onChangeFile(newFiles);
    }

    const fileRemove = (file) => {
        if(!cm_isEmpty(file.FL_SEQ)){
            deleteFile(file.FL_SEQ);
        }

        const newFiles = [...fileList];
        newFiles.splice(fileList.indexOf(file), 1);
        onChangeFile(newFiles);
    }

    const accept = !cm_isEmpty(props.accept) ? props.accept : null;

    return (
        <>
            <div className="col">
                <div 
                    ref={wrapperRef}
                    className="dragdrop bd-color"
                    style={{borderColor:"#2F61D5"}}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                >
                    <div>
                        <div>
                            <input 
                                type="file" 
                                id={type} 
                                multiple 
                                value="" 
                                onChange={onFileDrop}
                                accept={accept}
                            />
                            <label className="box" htmlFor={type}>
                                {children}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="dragdrop">
                    <div className="add-list">
                        {fileList.length > 0 ? (
                            <ul>
                                {fileList.map((item, index) => {
                                    const fileName = cm_isEmpty(item.FL_REFSEQ) ? item.name : item.FL_ORGNAME;
                                    const fileSize = cm_isEmpty(item.FL_REFSEQ) ? Math.round(item.size / 1024): Math.round(item.FL_SIZE); // KB

                                    return(
                                        <li key={"fileList_" + index}>
                                            <div href="#" className="file">{fileName + " (" + fileSize + "KB)"}</div>
                                            <button className="btn btn-ico" onClick={() => fileRemove(item)}><i className="xi-close"></i></button>
                                        </li>
                                    )
                                })}
                            </ul>
                        ) 
                        : 
                        <>
                            <div style={{position:"relative", top:"50%"}}>
                                <p>{t("file_fileList")}</p>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DropFileInputWide;
import { companyListApi } from "api/companyApi";
import DropFileInputWide from "components/DropFileInputWide";
import useTranslate from "components/language/useTranslate";
import CalendarDropdown from "components/modal/CalendarDropdown";
import { _CO_TYPE_CENTER } from "config";
import { useOrder, useSetOrder } from "contexts/OrderContext";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import useDueDate from "hooks/useDueDate";
import ico_files from "img/common/ico_files.svg";
import { produce } from "immer";
import { useEffect, useRef, useState } from "react";
import { cm_acceptFile, cm_dateMoment, cm_isEmpty, resApiCheck } from "utils/common";

export default function OrderInfo(props){
    const t = useTranslate();
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;

    const order = useOrder();
    const setOrder = useSetOrder();
    const odNameRef = useRef();

    const accepts = CO_TYPE === _CO_TYPE_CENTER ?
                [".zip", ".obj", ".ply", ".stl", ".dxd", ".png", ".jpg", ".nex"] :
                [".zip", ".obj", ".ply", ".stl", ".dxd", ".png", ".jpg"];

    const [holidayList, minDate, defaultDueDate] = useDueDate();
    useEffect(() => {
        const handleDueDate = () => {
            setOrder(produce(prevValue => {
                prevValue.OD_DUEDATE = cm_dateMoment(defaultDueDate);
                prevValue.OD_MINDATE = minDate;
            }))
        }

        handleDueDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultDueDate])

    useEffect(() => {
        odNameRef.current.focus();
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if(CO_TYPE !== _CO_TYPE_CENTER) {
            const filterList = order.OD_FILE_SCAN.filter(file => !file.FL_ORGNAME?.includes("nex"));
            setOrderValue("OD_FILE_SCAN", filterList);
        }
    },[order])
    
    const [asyncCompanyList] = useAsync(companyListApi);
    const [clinicList, setClinicList] = useState([]);

    const handleLoad = async () => {
        const resCompanyList = await asyncCompanyList({CO_TYPE : "C", CO_STATUS : "A"});
        if(!resApiCheck(resCompanyList)) return;
        if(resCompanyList.data.LIST.length > 0){
            setClinicList(resCompanyList.data.LIST);
        }
    }

    const onChangeName = (e) => {setOrderValue("OD_NAME", e.target.value)};
    const onChangeDueDate = (date) => {setOrder({...order, OD_DUEDATE: date})};
    const onChangeFile = (files) => {
        let list = cm_acceptFile(accepts, files, t("alert_common_file"));

        setOrderValue("OD_FILE_SCAN", list);
    }

    function setOrderValue(key, value){
        setOrder(produce(prevValue => {
            prevValue[key] = value;
        }))
    }

    function deleteFile(FL_SEQ) {
        let delFile = {FL_SEQ: FL_SEQ};
        props.setDelFiles([...props.delFiles, delFile]);
    }

    const setClinic = (e) => {
        setOrder(produce(prevValue => {
            prevValue.CO_SEQ = e.target.value;
        }))
    }

    return (
        <div className="order-writeFrame">
            <div className="side"></div>
            <div className="middle">
                <div className="inner1100">
                    {CO_TYPE === _CO_TYPE_CENTER &&
                        <div className="row">
                            <div className="col">
                                <h3 className="form-tit">{t("common_dt")}</h3>
                                <select className="select block w300" key="clinic" value={order.CO_SEQ} name="clinic" onChange={setClinic} disabled={order.OD_SEQ > 0 ? true : false}>
                                    <option value="">{t("common_choice")}</option>
                                    {clinicList && clinicList.map((clinic) => {
                                        return (
                                            <option key={`clinic_${clinic.CO_SEQ}`} value={clinic.CO_SEQ}>
                                                {clinic.CO_NAME}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col">
                            <h3 className="form-tit">{t("order_info_deadline")}</h3>
                            <div className="inp-box" style={{zIndex:"1"}}>
                                <CalendarDropdown
                                    isOn={false}
                                    value={order.OD_DUEDATE}
                                    minDate={order.OD_MINDATE}
                                    onChange={onChangeDueDate}
                                    holidayList={holidayList}
                                    title={t("order_info_deadline")}
                                    inputAddStyle={{width: "350px"}}
                                />
                            </div>
                            <div className="form-msg">{`* ${t("order_write_text_deadline")} (${t("order_write_text_pla")})`}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h3 className="form-tit">{t("common_name")}</h3>
                            <div className="inp-box"><input ref={odNameRef} type="text" className="inp w300" value={order.OD_NAME} onChange={onChangeName} placeholder={t("order_content_10")} maxLength={85}/></div>
                        </div>
                    </div>

                    <div className="row" style={{marginBottom: "0px"}}>
                        <div className="col">
                            <h3 className="form-tit">{t("order_info_file_scanshade")}</h3>
                        </div>
                        <div className="col" style={{textAlign:"end", paddingRight: "10px"}}>
                            {!cm_isEmpty(order.OD_FILE_SCAN) && 
                                <> File Count : {order.OD_FILE_SCAN.length}</>
                            }
                        </div>
                    </div>
                    <div className="dragdrop-wrap">
                        <div className="row">
                            <DropFileInputWide
                                type={"scan"}
                                onChangeFile={(files) => onChangeFile(files)}
                                fileList={order.OD_FILE_SCAN}
                                deleteFile={(FL_SEQ) => deleteFile(FL_SEQ)}
                                accept={accepts.join(", ")}
                            >
                                <p>Drag &amp; Drop</p>
                                <em>{t("common_text_file_drag")}</em>
                                <div className="tit">{t("common_text_file_type")}</div>
                                <div className="ic"><img src={ico_files} alt=""/></div>
                            </DropFileInputWide>
                        </div>
                    </div>

                </div>
            </div>
            <div className="side">
                <div className="sideDiv right">
                    <button className="arrow arrow-right" onClick={() => props.changeTab("product")}></button>
                </div>
            </div>
        </div>
    )
}
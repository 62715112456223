import { companyWriteApi } from "api/companyApi";
import SearchAddress from "components/common/SearchAddress";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useState } from "react";
import { cm_formatRegNo, cm_formatMobile, cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function AddCompanyModal({isOpen, handleModal, companyType, handleReLoad}){
    const t = useTranslate();

    //API
    const [asyncCompanyWrite, companyWritePending] = useAsync(companyWriteApi);

    // State
    const [companyInfo, setCompanyInfo] = useState({
        CO_MAP : {
            CO_SEQ : 0,
            CO_TYPE : companyType,
            CO_NAME : "",
            CO_REGNO : "",
            CO_OWNER : "",
            CO_TEL : "",
            CO_FAX : "",
            CO_ZIPCODE : "",
            CO_ADDRESS : "",
        }
    })

    // handle
    const handleChange = (e) => {
        let {name, value} = e.target;

        if(name === "CO_TEL" || name === "CO_REGNO" || name === "CO_FAX"){
            value = value.replace(/[^0-9]/g, '');
        }

        setCompanyInfo(produce(prevValue => {
            prevValue.CO_MAP[name] = value;
        }));
    }

    const handleZipcode = (value) => {
        setCompanyInfo(produce(prevValue => {
            prevValue.CO_MAP.CO_ZIPCODE = value;
        }));
    }

    const handleAddress = (value) => {
        setCompanyInfo(produce(prevValue => {
            prevValue.CO_MAP.CO_ADDRESS = value;
        }));
    }

    const handleSave = async () => {
        if(!saveValidate()) return;
        const response = await asyncCompanyWrite(companyInfo.CO_MAP);
        if(!resApiCheck(response)) return;

        if(response.data.CO_SEQ > 0){
            cm_swAlert(t("common_regiSuccess"), "success", t("common_check"));
            handleModal();
            handleReLoad();
        }else{
            cm_swAlert(t("common_regiFailed"), "warning", t("common_check"));
        }
    }

    function saveValidate(){
        let result = true;
        let text = "";
        
        if(cm_isEmpty(companyInfo.CO_MAP.CO_NAME)){
            text = t("common_alert_bizName");
            result = false;
        }else if(cm_isEmpty(companyInfo.CO_MAP.CO_REGNO)){
            text = t("common_content_4");
            result = false;
        }else if(cm_isEmpty(companyInfo.CO_MAP.CO_OWNER)){
            text = t("common_content_16");
            result = false;
        }else if(cm_isEmpty(companyInfo.CO_MAP.CO_TEL)){
            text = t("common_content_5");
            result = false;
        }else if(cm_isEmpty(companyInfo.CO_MAP.CO_ADDRESS)){
            text = t("common_content_6");
            result = false;
        }

        if(!result){
            cm_swAlert(text, "info", t("common_check"));
        }

        return result;
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{t("common_addregi")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">{t("memberMg_companyType")}</h4>
                        <div className="inp-box">
                            <select className="select block" key="CO_TYPE" name="CO_TYPE" value={companyInfo.CO_MAP.CO_TYPE} onChange={handleChange}>
                                <option value={_CO_TYPE_CLINIC}>{t("common_dt")}</option>
                                <option value={_CO_TYPE_LAB}>{t("common_dtl")}</option>
                                <option value={_CO_TYPE_DESIGN}>{t("common_dtd")}</option>
                            </select>
                        </div>
                        <h4 className="form-tit">{t("common_companyName")}<span className='required'>＊</span></h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="CO_NAME" value={companyInfo.CO_MAP.CO_NAME} onChange={handleChange} maxLength={60}/>
                        </div>
                        <h4 className="form-tit">{t("common_licenseNum")}<span className='required'>＊</span></h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="CO_REGNO" value={cm_formatRegNo(companyInfo.CO_MAP.CO_REGNO)} onChange={handleChange} maxLength={10}/>
                        </div>
                        <h4 className="form-tit">{t("common_RepresentativeName")}<span className='required'>＊</span></h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="CO_OWNER" value={companyInfo.CO_MAP.CO_OWNER} onChange={handleChange} maxLength={30}/>
                        </div>
                        <h3 className="form-tit">{t("common_address")}<span className='required'>＊</span></h3>
                        <div className="inp-box inp-btn-box">
                            <input type="text" className="inp block" name="CO_ZIPCODE" defaultValue={companyInfo.CO_MAP.CO_ZIPCODE} placeholder={t("common_postalCode")} disabled />
                            <SearchAddress handleZipcode={handleZipcode} handleAddress={handleAddress} />
                        </div>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="CO_ADDRESS" defaultValue={companyInfo.CO_MAP.CO_ADDRESS} placeholder={t("common_address")}/>
                        </div>

                        <h4 className="form-tit">{t("common_rePresentativePhone")}<span className='required'>＊</span></h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="CO_TEL" value={cm_formatMobile(companyInfo.CO_MAP.CO_TEL)} onChange={handleChange} maxLength={13}/>
                        </div>
                        <h4 className="form-tit">{t("myPage_faxNumber")}</h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="CO_FAX" value={companyInfo.CO_MAP.CO_FAX} onChange={handleChange} maxLength={13}/>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-fill-blue" disabled={companyWritePending} onClick={handleSave}>{t("common_regi")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import useTranslate from "components/language/useTranslate";
import { cm_isEmpty, cm_numComma } from "utils/common";

export default function PayPlanInfo({ tap, companyPlanList }){
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const t = useTranslate();

    return(
        <div className="table1" style={{ maxHeight: "395px", overflow:"auto"}}>
            <table id={tap}>
                <colgroup>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "25%"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>{t("common_planName")}</th>
                        <th>{t("common_period")}</th>
                        <th>{t("common_monthFee")}</th>
                        <th>{t("common_basePrice")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!cm_isEmpty(companyPlanList) ?
                        companyPlanList.map((companyPlan) => {
                            let period = companyPlan.CP_SDATE + " ~ " + companyPlan.CP_EDATE;
                            
                            return(
                                <tr key={companyPlan.PL_SEQ}>
                                    <td>{companyPlan.PL_NAME}</td>
                                    <td>{period}</td>
                                    <td>{cm_numComma(companyPlan.PL_PRICE)}{SYMBOL}</td>
                                    <td>{cm_numComma(companyPlan.PL_BASEPRICE)}{SYMBOL}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan='4' align='center'>{t("plan_content_1")}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
import { useState } from 'react';
import ModalFrame from 'components/modal/ModalFrame';
import useTranslate from 'components/language/useTranslate';
import { Modal } from 'react-bootstrap';
import DaumPost from './DaumPost';
import GooglePost from './GooglePost';

const SearchAddress = ({ handleZipcode, handleAddress, isEdit=true, btnStyle }) => {
    const t = useTranslate();
    const COUNTRY = process.env.REACT_APP_COUNTRY;

    const [modalIsOpen, setModalIsOpen] = useState(false);

    return (
        <>
            {isEdit && <button className="btn btn-line-black" onClick={() => setModalIsOpen(true)} style={btnStyle}>{t("common_findAddress")}</button>}

            <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)} centered>
                <ModalFrame isOpen={modalIsOpen} title={t("common_findAddress")} handleModal={() => setModalIsOpen(false)} contentStyle={{width:"90%", margin:"0 auto", border:"1px solid", boxShadow:" 0px 0px 5px #aaa"}} >
                    {COUNTRY === "ko" ?
                        <DaumPost handleZipcode={handleZipcode} handleAddress={handleAddress} setModalIsOpen={setModalIsOpen}/>
                        :
                        <GooglePost handleZipcode={handleZipcode} handleAddress={handleAddress} setModalIsOpen={setModalIsOpen}/>
                    }
                </ModalFrame>
            </Modal>
        </>
    );
};

export default SearchAddress;
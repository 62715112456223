import { onSilentRefresh, tokenUserApi } from 'api/commonApi';
import { orderReadApi } from 'api/orderApi';
import useTranslate from 'components/language/useTranslate';
import { useSetOrderMap } from 'contexts/OrderContext';
import { useSetStore } from 'contexts/Store';
import useAsync from 'hooks/useAsync';
import { produce } from 'immer';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cm_isEmpty, cm_swAlert, resApiCheck } from 'utils/common';

export default function NextUrl(){
    const params = useParams();
    const navigate = useNavigate();
    const t = useTranslate();
    const setStore = useSetStore();

    const setOrderMap = useSetOrderMap();

    const [asyncOnSilentRefresh] = useAsync(onSilentRefresh);
    const [asyncTokenUser] = useAsync(tokenUserApi);

    useEffect(()=> {
        const pathname = window.location.pathname;

        async function urlNavigate() {
            if (pathname.includes('/common/tokenSignIn')) {

                const expireTime = Date.now() + 24 * 60 * 60 * 1000; //24hours
                const refreshTokenWithExpiration = expireTime + "/" + params._REFRESH_TOKEN;

                localStorage.setItem("refresh_token", refreshTokenWithExpiration);
                sessionStorage.setItem("TEMP_KEY", params.TEMP_KEY);
                sessionStorage.setItem("NEXT_URL", params.NEXT_URL);

                await handleSignIn();
                navigate("/" + decodeURIComponent(params.NEXT_URL));

            } else if(pathname.includes('/order')) {
                await handleSignIn();
                handleOrderMap(params.OD_SEQ);
            }
        }

        urlNavigate();
    },[])

    // 접속 사용자 기본 정보 받기
    const handleSignIn = async () => {
        //axio header token setting
        const response = await asyncOnSilentRefresh({});
        if(!resApiCheck(response)) return;

        //store US_MAP setting
        const resTokenUser = await asyncTokenUser({});
        if(!resApiCheck(resTokenUser)) return;

        if(!cm_isEmpty(resTokenUser.data)){
            let US_MAP = resTokenUser.data;
            setStore(produce((prevValue) => {
                prevValue.US_MAP = US_MAP;
            }))
        }
    }

    // 주문 정보 받기
    const handleOrderMap = async (OD_SEQ) => {
        const response = await orderReadApi({OD_SEQ : OD_SEQ});

        if (response) {
            setOrderMap(response.data);
            navigate("/order");
        } else {
            cm_swAlert(t("common_content_10"), "info", t("common_check"));
            navigate("/home");
        }
    }
}
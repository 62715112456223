import { companyDeviceSnapshotListApi } from "api/companyApi";
import { orderPrintDeleteApi, orderPrintListApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import { PRINT_STATUS, _CO_TYPE_CENTER, _INTERVAL_TIME } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import useInterval from "hooks/useInterval";
import $ from "jquery";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_dateMoment, cm_dateToday, cm_isEmpty, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function PrintQueue(){
    const store = useStore();
    const [printList, setPrintList] = useState([]);
    const printStatus = PRINT_STATUS();
    const t = useTranslate();
    
    const [asyncPrintList] = useAsync(orderPrintListApi);
    const [asyncPrintDelete] = useAsync(orderPrintDeleteApi);

    useInterval(() => {
        handleLoad();
    }, _INTERVAL_TIME)

    const handlePrintFlip = (e) => {
        $(e.target).closest('.quick-print').toggleClass('active');
    }

    useEffect(() => {
        handleLoad();
    },[]);

    const handleLoad = async () => {
        const response = await asyncPrintList({
            FT_FRDATE: cm_dateToday(),
            OP_NOSTATUS : "C",
            OP_QUEUE : "Y"
        })
        if(!resApiCheck(response)) return;
        if(response.data.LIST.length > 0){
            let printStatusList = printStatus.map(x => x.status);
            let list = response.data.LIST.filter(x => printStatusList.includes(x.OP_STATUS));
            setPrintList(list);
        }
    }

    const handlePrintDelete = async (orderPrint) => {
        const confrim = await cm_swConfirm(t("confirm_common_delete"), "", "warning", t("common_yes"), t("common_no"));
        if(confrim){
            const response = await asyncPrintDelete({
                OP_SEQ : orderPrint.OP_SEQ,
                FL_SEQ : orderPrint.FL_SEQ
            });
            if(!resApiCheck(response)){
                cm_swAlert(t("alert_delete_failed"), "warning", t("common_check"));
            }else{
                cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
                handleLoad();
            }
        }
    }

    const [printSeq, setPrintSeq] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const handleSnapshot = (CD_SEQ=0) => {
        setIsOpen((prevValue) => !prevValue);
        setPrintSeq(CD_SEQ);
    }

    return(
        <div className="quick-print">
            <div className="head">
                <button className="btn-flip" onClick={handlePrintFlip}>
                    <div className="before">
                        <div style={{writingMode:"vertical-lr", letterSpacing:"3px", margin:"0 auto"}}>
                            <strong>{t("common_print_queue")}</strong>
                        </div>
                        <span>{t("common_open")}</span>
                    </div>
                    <div className="after">{t("common_print_queue")} <span></span></div>
                </button>
            </div>
            <div className="body">
                <ul className="print-list3">
                    {printList.length > 0 ? 
                        printList.map((orderPrint) => {
                            const printState = printStatus.find(x => x.status === orderPrint.OP_STATUS);
                            return (
                                <li key={"print" + orderPrint.OP_SEQ}>
                                    <div className={printState.class}> 
                                        <div className="current">
                                            <p><img src={printState.img} alt=""/><span>{printState.text}</span></p>
                                        </div>
                                        <div className="auth">
                                            <div className="name">{orderPrint.OD_NAME}</div>
                                            <div className="etc">
                                                {store.US_MAP.CO_TYPE === _CO_TYPE_CENTER &&
                                                    <span>{orderPrint.CO_NAME}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="times">
                                            {<div className="time">{t("common_time_start")}<br/>{!cm_isEmpty(orderPrint.OP_STARTDTTM) ? cm_dateMoment(orderPrint.OP_STARTDTTM, "HH:mm:ss") : "00:00:00"}</div>}
                                        </div>
                                        <div className="times">
                                            {<div className="time">{t("common_time_end")}<br/>{!cm_isEmpty(orderPrint.OP_COMDTTM) ? cm_dateMoment(orderPrint.OP_COMDTTM, "HH:mm:ss") : "00:00:00"}</div>}
                                            {/* <button className="btn-del" onClick={() => handlePrintDelete(orderPrint)}><span className="hidden">{t("myPage_del")}</span></button> */}
                                        </div>
                                        <div className="icon">
                                            <button className="btn-ico" onClick={() => handleSnapshot(orderPrint.CD_SEQ)}><i className="xi-camera"></i></button>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                        :
                        <li>
                            <div className="item alert">{t("common_print_queue_empty")}</div>
                        </li>
                    }
                </ul>
            </div>

            <Modal show={isOpen} onHide={handleSnapshot} centered>
                <SnapshotModal isOpen={isOpen} handleModal={handleSnapshot} printSeq={printSeq}/>
            </Modal>
        </div>
    )
}

function SnapshotModal({isOpen, handleModal, printSeq}){
    const [snapshotList, setSnapshotList] = useState([]);
    const [asyncSnapshotList] = useAsync(companyDeviceSnapshotListApi);

    const snapshotTitles = [{key:"1", title:"Waiting"}, {key:"2", title:"Printing(1)"}, {key:"3", title:"Printing(2)"}, {key:"4", title:"Printing(3)"}];

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        // Company Device List
        const response = await asyncSnapshotList({CD_SEQ: printSeq});
        if(!resApiCheck(response)) return;
        if(response.data.LIST.length > 0){
            setSnapshotList(response.data.LIST.sort((a, b) => a.FL_REFKEY < b.FL_REFKEY ? -1 : 1));
        }
    }

    return(
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "880px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{"Printer Snapshot"}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="snapshot container">
                            {snapshotList && snapshotList.map((item) => {
                                const title = snapshotTitles.find(x => x.key === item.FL_REFKEY.split("_")[2]).title;

                                return (
                                    <div key={`snapshot_${item.FL_SEQ}`} className="item">
                                        <p className="title">{title} {`(${cm_dateMoment(item.DTTM, "YYYY-MM-DD HH:mm:ss")})`}</p>
                                        <img src={item.FL_URL} alt={"Please wait until I snapshot."} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { accountListApi } from "api/accountApi";
import useTranslate from "components/language/useTranslate";
import { ACCOUNT_STATUS } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_dateAddMonth, cm_dateMoment, cm_dateToday, cm_dateYearList, cm_findData, cm_isEmpty, cm_numComma, resApiCheck } from "utils/common";
import LabInvoiceForm from "./LabInvoiceForm";

export default function LabPayment() {
    const store = useStore();
    const US_MAP =  store.US_MAP;
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const accountStatus = ACCOUNT_STATUS();
    const yearList = cm_dateYearList("2023", cm_dateToday("YYYY"));
    
    const [accountList, setAccountList] = useState(null);
    const [account, setAccount] = useState(null);
    const [searchYear, setSearchYear] = useState(cm_dateToday(cm_dateToday("YYYY")));
    const [asyncAccountList] = useAsync(accountListApi);
    const t = useTranslate();

    const [reLoad, setReLoad] = useState(false);
    const handleReLoad = () => setReLoad((prevValue) => !prevValue);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchYear, reLoad]);

    const handleLoad = async () => {
        let fromMonth = searchYear + "-01";
        let toMonth = searchYear + "-12";

        let response = await asyncAccountList({
            CO_SEQ : US_MAP.CO_SEQ,
            FT_FRMONTH : fromMonth,
            FT_TOMONTH : toMonth
        });
        if(!resApiCheck(response)) return;
        
        if(response.data.LIST_COUNT > 0){
            // Lab에서는 AccountBokk List "Memo" 상태부터 전부 표시
            setAccountList(response.data.LIST);
        }else{
            setAccountList(null);
        }
    }

    const handleChangeSearchYear = (e) => {
        setAccount(null);
        setSearchYear(e.target.value);
    }

    const onClickDetail = (account) => {
        setAccount(account);
    }

    return(
        <>
            <div className="inquiry-box pd20">
                <div className="mb20">
                    <select className="select" value={searchYear} onChange={handleChangeSearchYear}>
                        {yearList && 
                            yearList.map((year) => {
                                return <option key={`account_${year}`} value={year}>{year}{t("common_year")}</option>
                            })
                        }
                    </select>
                </div>
                <div className="table2 cursor">
                    <table>
                        <thead>
                            <tr>
                                <th>{t("payment_billMonth")}</th>
                                <th>{t("payment_totalCost")}</th>
                                <th>{t("payment_adjustCost")}</th>
                                <th>{t("payment_billCost")}</th>
                                <th>{t("common_status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!cm_isEmpty(accountList) ? 
                                accountList.map((account) => {
                                    let statusText = cm_findData(accountStatus, "status", account.AB_STATUS, "text");

                                    return(
                                        <tr key={account.AB_MONTH} onClick={() => onClickDetail(account)}>
                                            <td>{account.AB_MONTH}</td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_TAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_AAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_IAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>{statusText}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan='7' align='center'>{t("payment_noBill")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {account &&
                <LabInvoiceForm CO_TYPE={US_MAP.CO_TYPE} account={account} setAccount={setAccount} MONTH={cm_dateMoment(cm_dateAddMonth(account.AB_MONTH  + "-01", -1))} handleReLoad={handleReLoad}/>
            }
        </>
    )
}
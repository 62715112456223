export default function EmptySection() {
    return (
        <div className="section">
            <div className="head">
                <h3 className="h3">
                    작업중입니다.
                </h3>
            </div>
        </div>
    )
}
import { noticeConfirmAll, noticeListApi, noticeReadApi } from "api/noticeApi";
import { MY_PAGE_LIST, _INTERVAL_TIME } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import useInterval from "hooks/useInterval";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cm_dateAddDay, cm_dateMoment, cm_dateToday, resApiCheck } from "utils/common";
import { useTranslate } from "../language/useTranslate";

export default function Header() {
    const store = useStore();
    const {US_MAP} = store;

    return (
        <header className="header-user">
            <div className="top">
                <div className="user-profile"><img src="img/common/ico_top_user.png" alt="" /></div>
                <div className="user-name">{US_MAP.CO_NAME}</div>
                <div className="user-name">{US_MAP.US_NAME}</div>
                <MyMenu />
            </div>
        </header>
    )
}

function Notice({ US_SEQ }) {
    const [isOpen, setIsOpen] = useState(false);
    const [noticeList, setNoticeList] = useState([]);
    const [count, setCount] = useState(null);
    const [asyncNoticeList] = useAsync(noticeListApi);
    const [asyncNoticeRead] = useAsync(noticeReadApi);
    const [asyncNoticeConfirmAll] = useAsync(noticeConfirmAll);
    const divRef = useRef();
    const navigate = useNavigate();
    const t = useTranslate();

    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }, []); 
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            const {target} = event;
            if(!divRef.current.contains(target)){
                setIsOpen(false);
            } 
        }
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        handleLoad();
    }, _INTERVAL_TIME)

    const handleLoad = async () => {
        let toDate = cm_dateToday();
        let fromDate = cm_dateMoment(cm_dateAddDay(toDate, -15)); 
        const response = await asyncNoticeList({
            US_SEQ : US_SEQ,
            NT_CONFIRM : "N",
            FT_FRDATE : fromDate,
            FT_TODATE : toDate
        })
        if(!resApiCheck(response)) return;
        
        if(response.data.LIST.length > 0){
            setNoticeList(response.data.LIST);
            setCount(response.data.LIST.length);
        }
    }

    const handleOpen = () => setIsOpen(false);

    const handleNotice = (notice, type) => {
        handleNoticeConfirm(notice.NT_SEQ);
        
        if(type === "move"){
            navigate("/order", {state :{OD_SEQ : notice.NT_REFSEQ}});
            handleOpen();
        }
    }

    const handleNoticeConfirm = async (NT_SEQ) => {
        let response = await asyncNoticeRead({
            US_SEQ : US_SEQ,
            NT_SEQ : NT_SEQ,
        });
        if(!resApiCheck(response)) return;

        let newList = noticeList.filter( x => x.NT_SEQ !== NT_SEQ);
        setNoticeList(newList);
        setCount(newList.length);
    }

    const handleNoticeConfirmAll = async () => {
        let response = await asyncNoticeConfirmAll({});
        if(!resApiCheck(response)) return;        
        setNoticeList([]);
        setCount(0);
        handleOpen();
    }

    return(
        <div ref={divRef} className="alert">
            <button className="btn-alert" onClick={handleButtonClick}>
                <span className="hidden">{t("board_notfication")}</span>
            </button>
            {count && <span>{count}</span>}
            
            {isOpen &&
                <div className="alert-box">
                    <div className="alert-head">
                        <strong>{t("board_notfication")} {count}</strong>
                        <button style={{float:"right"}} onClick={handleNoticeConfirmAll}>{t("header_deleteAll")}</button>
                    </div>
                    <ul>
                        {noticeList.length > 0 ?
                            noticeList.map((notice) => {
                                let ntSeq = notice.NT_SEQ;
                                let text = notice.NT_TEXT;
                                let date = cm_dateMoment(notice.NT_REGDTTM, "YY/MM/DD");
                                return(
                                    <li key={ntSeq}>
                                        <div>
                                            <button name="MOVE" onClick={() => handleNotice(notice, "move")}>
                                                <p>{text}</p><span>{date}</span>
                                            </button>

                                            <button className="type3" name="READ" onClick={(e) => handleNotice(notice, "read")}>X</button>
                                        </div>
                                    </li>
                                )
                            })
                            :
                            <li><p>{t("header_content_1")}</p></li>
                        }
                    </ul>
                </div>
            }
        </div>
    )
}

function MyMenu(){
    const navigate = useNavigate();
    const t = useTranslate();
    const store = useStore();
    const {US_MAP} = store;
    const [isOpen, setIsOpen] = useState(false);
    const myPageList = MY_PAGE_LIST();
    const myPageTapList = myPageList.filter(x => x.type.includes(US_MAP.US_TYPE) && x.coType.includes(US_MAP.CO_TYPE));

    const handleButtonClick = useCallback((e) => {
        e.stopPropagation();
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }, []); 
    
    useEffect(() => {
        const handleClickOutside = () => setIsOpen(false);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);

    const moveMyPage = (e) => {
        navigate("/mypage", {state :e.target.value});
    };

    const signOut= () => {
        localStorage.removeItem("refresh_token");
        sessionStorage.clear();
        navigate("/");
    }

    return(
        <div className={isOpen ? "menu active" : "menu"}>
            <button className="btn-menu" onClick={handleButtonClick}><span className="hidden">{t("header_menu")}</span></button>
            <div className="gnb">
                <div className="ver">
                    {process.env.REACT_APP_SYSTEM_VERSION}
                </div>
                <nav>
                    <ul>
                        {myPageTapList.map((tap) => {
                            return(
                                <li key={tap.key}><button value={tap.key} onClick={moveMyPage}>{tap.title}</button></li>
                            )
                        })}
                    </ul>
                </nav>
                <div className="etc">
                    <button onClick={signOut}><i className="xi-log-out"></i>{t("myPage_logout")}</button>
                </div>
            </div>
        </div>
    )

}
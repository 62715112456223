import { useState, useEffect } from "react";
import { produce } from "immer";
import useAsync from "hooks/useAsync";
import { companyDeviceWriteApi } from "api/companyApi";
import useTranslate from "components/language/useTranslate";
import CalendarInput from "components/common/CalendarInput";
import { COMPANY_DEVICE_STATUS } from "config";
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function AddDeviceModal({isOpen, handleModal, selectDevice, planDeviceList, companyDeviceList, selectCompany, handleLoad}){
    const init = {
        DV_SEQ : "",
        CD_SEQ : 0,
        CD_SN : "",
        CD_MAC : "",
        CD_SDATE : "",
        CD_EDATE : "",
        CD_STATUS : "M",
        PL_SEQ : "",
    }

    const [companyDevice, setCompanyDevice] = useState(selectDevice || init);
    const [planWithDevice, setPlanWithDevice] = useState(null);
    const [deviceList, setDeviceList] = useState([]);
    const companyDeviceStatus = COMPANY_DEVICE_STATUS();
    const t = useTranslate();

    const [asyncCompanyDeviceWrite] = useAsync(companyDeviceWriteApi);

    useEffect(() => {
        handleInit();
    },[]);

    const handleInit = () => {
        let filterPlanDeviceList = planDeviceList.filter(x => !cm_isEmpty(x.PD_LIST));
        setPlanWithDevice(filterPlanDeviceList);
    }

    //change
    const handleChange = (e) => {
        let {name, value} = e.target;

        if(name === "PL_SEQ"){
            if(!cm_isEmpty(value)){
                let findPdList = planWithDevice.find(x => x.PL_SEQ === Number(value));
                if(!cm_isEmpty(findPdList)){
                    setDeviceList(findPdList.PD_LIST);
                }
    
                setCompanyDevice(produce(prevValue => {
                    prevValue[name] = value;
                    prevValue["DV_SEQ"] = "";
                    prevValue["CD_SN"] = "";
                    prevValue["CD_SDATE"] = findPdList.CP_SDATE;
                    prevValue["CD_EDATE"] = findPdList.CP_EDATE;
                }))
            }else{
                setCompanyDevice(init);
            }

        }else{
            setCompanyDevice(produce(prevValue => {
                prevValue[name] = value;
            }))
        }
    }

    const onChangeSdate = (date) => {
        setCompanyDevice(produce((prevValue) => {
            prevValue.CD_SDATE = date;
        }))
    };

    const onChangeEdate = (date) => {
        setCompanyDevice(produce((prevValue) => {
            prevValue.CD_EDATE = date;
        }))
    }

    //save
    const handleAdd = async () => {
        if(!saveValidate()) return;

        let newCompanyDeviceList = [].concat(companyDeviceList);
        if(!cm_isEmpty(selectDevice)){
            let findIndex = companyDeviceList.findIndex(x => x.CD_SEQ === companyDevice.CD_SEQ);
            newCompanyDeviceList[findIndex] = companyDevice;
        }else{
            newCompanyDeviceList = [...companyDeviceList, companyDevice];
        }
        
        const response = await asyncCompanyDeviceWrite({
            CO_SEQ : selectCompany.CO_MAP.CO_SEQ,
            CD_LIST: JSON.stringify(newCompanyDeviceList)
        });
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "info", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleLoad();
        }

        handleModal();
    }

    function saveValidate(){
        let result = true;
        let text = "";

        if(cm_isEmpty(companyDevice.DV_SEQ)){
            result = false;
            text = t("alert_choice_device");
        }else if(cm_isEmpty(companyDevice.CD_SN)){
            result = false;
            text = t("alert_input_sn");
        }else if(cm_isEmpty(companyDevice.CD_SDATE)){
            result = false;
            text = t("alert_input_releaseStart");
        }else if(cm_isEmpty(companyDevice.CD_EDATE)){
            result = false;
            text = t("alert_input_releaseEnd");
        }else if(cm_isEmpty(companyDevice.CD_STATUS)){
            result = false;
            text = t("alert_choice_status");
        }

        if(!result){
            cm_swAlert(text, "info", t("common_check"));
        }

        return result;
    }

    return (
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("memberMg_deviceRelease")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        {cm_isEmpty(selectDevice) &&
                            <>
                                <h4 className="form-tit">{t("memberMg_forwardablePlan")}</h4>
                                <div className="inp-box">
                                    <select className="select" style={{width : "100%"}} name="PL_SEQ" value={companyDevice.PL_SEQ} onChange={handleChange}>
                                        <option value="">{t("common_choice")}</option>
                                        {planWithDevice && planWithDevice.map((plan) => {
                                            return(
                                                <option key={"planOption_" + plan.PL_SEQ} value={plan.PL_SEQ}>{plan.PL_NAME}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </>
                        }
                        <h4 className="form-tit">{t("memberMg_planDevice")}</h4>
                        <div className="inp-box">
                            {!cm_isEmpty(selectDevice) ?
                                <div className="inp-box">
                                    <input type="text" className="inp block" name="DV_NAME"  value={companyDevice.DV_NAME} readOnly/>
                                </div>
                                :
                                <select className="select" style={{width : "100%"}} name="DV_SEQ" value={companyDevice.DV_SEQ} onChange={handleChange}>
                                    <option value="">{t("common_choice")}</option>
                                    {deviceList && deviceList.map((device) => {
                                        return(
                                            <option key={"device_" + device.DV_SEQ} value={device.DV_SEQ}>{device.DV_NAME}</option>
                                        )
                                    })}
                                </select>
                            }
                        </div>
                        <h4 className="form-tit">S/N</h4>
                            <div className="inp-box">
                                <input type="text" className="inp block" name="CD_SN"  value={companyDevice.CD_SN} onChange={handleChange} maxLength={50}/>
                            </div>
                        <h4 className="form-tit">{t("plan_startDate")}</h4>
                        <div className="inp-box" style={{zIndex:"3"}}>
                            <CalendarInput 
                                onChange={onChangeSdate}
                                name="CD_SDATE"
                                value={companyDevice.CD_SDATE || ''}
                                option={{minDate:0}} 
                            />
                        </div>
                        <h4 className="form-tit">{t("plan_endDate")}</h4>
                        <div className="inp-box" style={{zIndex:"2"}}>
                            <CalendarInput 
                                onChange={onChangeEdate}
                                name="CD_EDATE"
                                value={companyDevice.CD_EDATE || ''}
                                option={{minDate:0}} 
                            />
                        </div>

                        <h4 className="form-tit">{t("common_status")}</h4>
                        <div className="inp-box">
                            <select className="select" style={{width : "100%"}} name="CD_STATUS" value={companyDevice.CD_STATUS} onChange={handleChange}>
                                <option value="">{t("common_choice")}</option>
                                {companyDeviceStatus.map((device) => {
                                    return(
                                        <option key={device.status} value={device.status}>{device.text}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" onClick={handleAdd}>{t("common_save")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import Frame from 'Frame';
import OrderAutoShipping from "components/order/OrderAutoShipping";
import { ROLES } from "config";
import { OrderProvider } from "contexts/OrderContext";
import { AccountMng, Board, BoardWrite, CompanyMng, Delivery, DeviceMng, FindPwd, MyPage, NextUrl, NotFound, Order, OrderWrite, Payment, PlanMng, ProductMng, SignIn, UserHome } from "pages";
import BoardRead from "pages/BoardRead";
import HolidayMng from "pages/HolidayMng";
import SignUp from "pages/SignUp";
import Staff from "pages/Staff";
import { Outlet, Route, Routes } from 'react-router-dom';
import { Private, Protect, Public } from 'routes';

export default function App() {
    return (
        <Routes>
            <Route element={<Public />}>
                <Route path="/" element={<SignIn />} />
                <Route path="/findpwd" element={<FindPwd />} />
                <Route path="/signup" element={<SignUp />} />
            </Route>
            <Route element={<OrderProvider><Private /></OrderProvider>}>
                <Route element={<Frame />} >
                    <Route path="/home" element={<Protect roles={ROLES.home} component={<UserHome />} />} />
                    <Route path="/order" element={<Protect roles={ROLES.order} component={<Outlet />} />} >
                        <Route index element={<Order />} />
                        <Route path="orderwrite" element={<Protect roles={ROLES.orderwrite} component={<OrderWrite />} />} />
                    </Route>
                    <Route path="/delivery" element={<Protect roles={ROLES.delivery} component={<Delivery />} />} />
                    <Route path="/payment" element={<Protect roles={ROLES.payment} component={<Payment />} />} />
                    <Route path="/staff" element={<Protect roles={ROLES.staff} component={<Staff />} />} />

                    {/* 공통 */}
                    <Route path="/mypage" element={<Protect roles={ROLES.mypage} component={<MyPage />} />} />
                    <Route path="/board">
                        <Route index element={<Board />} />
                        <Route path="boardread" element={<BoardRead />} />
                        <Route path="boardwrite" element={<BoardWrite />} />
                    </Route>
                    
                    {/* 어드민 */}
                    <Route path="/accountmanage" element={<Protect roles={ROLES.admin_accountmanage} component={<AccountMng />} />} />
                    <Route path="/companymanage" element={<Protect roles={ROLES.admin_membermanage} component={<CompanyMng />} />} />
                    <Route path="/planmanage" element={<Protect roles={ROLES.admin_planmanage} component={<PlanMng />} />} />
                    <Route path="/productmanage" element={<Protect roles={ROLES.admin_productmanage} component={<ProductMng />} />} />
                    <Route path="/devicemanage" element={<Protect roles={ROLES.admin_devicemanage} component={<DeviceMng />} />} />
                    <Route path="/holidaymanage" element={<Protect roles={ROLES.admin_holidaymanage} component={<HolidayMng />} />} />
                </Route>
            </Route>

            {/* 스캔 */}
            <Route path="/common/tokenSignIn/:NEXT_URL/:TEMP_KEY/:_REFRESH_TOKEN" element={<OrderProvider><NextUrl /></OrderProvider>}/>
             {/* 주문서 바로가기 */}
            <Route path="/order/:OD_SEQ" element={<OrderProvider><NextUrl /></OrderProvider>}/>

            <Route path="/order/autoShipping" element={<OrderAutoShipping />}/>

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
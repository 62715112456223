function Checkbox({ children, className, disabled, checked, onChange }) {
    return (
        <label>
            <input
                type="checkbox"
                className={className ? className : "checkbox"}
                disabled={disabled}
                checked={checked}
                onChange={({ target: { checked } }) => onChange(checked)}
            />
            <div>
                <em></em>
                {children}
            </div>
        </label>
    );
}

export default Checkbox;
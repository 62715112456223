import useTranslate from "components/language/useTranslate";
import AccountAdjust from "components/manage/AccountAdjust";
import AccountBook from "components/manage/AccountBook";
import AccountStatement from "components/manage/AccountStatement";
import { useState } from "react";

export default function AccountMng() {
    const t = useTranslate();
    
    const [tap, setTap] = useState("accountBook");
    
    const handleTap = (e) => setTap(e.target.value);

    return(
        <div id="admin-container">
            <div className="title-head">
                <h3>{t("payment_mg")}</h3>
            </div>

                
                {tap === "accountBook" && <AccountBook tap={tap} handleTap={handleTap} />}
                {tap === "bankStatement" && <AccountStatement tap={tap} handleTap={handleTap}/>}
                {tap === "adjust" && <AccountAdjust tap={tap} handleTap={handleTap}/>}
                
        </div>
    )
}

import { useEffect, useState } from "react";
import useAsync from "hooks/useAsync";
import { cm_isEmpty, resApiCheck } from "utils/common";
import useTranslate from "components/language/useTranslate";
import { orderAutoShippingApi } from "api/orderApi";

export default function OrderAutoShipping() {
    const queryParameters = new URLSearchParams(window.location.search);
    const OD_SEQ = queryParameters.get("o");

    const [asyncOrderAutoShipping] = useAsync(orderAutoShippingApi);
    const t = useTranslate();

    useEffect(() => {
        if(!cm_isEmpty(OD_SEQ)){
            handleAutoShipping();
        }
    },[]);

    const handleAutoShipping = async () => {
        const response = await asyncOrderAutoShipping({o : OD_SEQ})
        if(!resApiCheck(response)) return;

        setMessage(t("alert_auto_shipping_success"));
    }

    const [message, setMessage] = useState(t("alert_auto_shipping_failed"));

    return (
        <div className="mobile-message">
            <h3 className="h3">
                {message}
            </h3>
        </div>
    )
}
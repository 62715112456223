import { companyListApi } from "api/companyApi";
import { orderUpdateInfoApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER } from "config";
import { _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { useState } from "react";
import { cm_swAlert, resApiCheck } from "utils/common";

export default function SelectDentalLab({OD_MAP, handleOrderMap}) {
    const t = useTranslate();
    const store = useStore();
    const {US_MAP} = store;
    const {LAB_NAME, OD_STATUS} = OD_MAP;

    const [isSelectLab, setIsSelectLab] = useState(false);
    const handleSelectLab = () => {setIsSelectLab((prevValue) => !prevValue);}

    return (
        <div className="flex align-items-center gap15">
            <em>{t("common_dtl")}</em>
            <div className="lab-name" onClick={(US_MAP.CO_TYPE === _CO_TYPE_CENTER && OD_STATUS === "D") ? handleSelectLab : null}>{LAB_NAME ? LAB_NAME : t("common_ndesignated")}</div>
            {(isSelectLab && US_MAP.CO_TYPE === _CO_TYPE_CENTER) &&
                <div>
                    <div className="flex align-items-center gap15">
                        <SelectLab OD_MAP={OD_MAP} handleOrderMap={handleOrderMap} handleSelectLab={handleSelectLab} />
                    </div>
                </div>
            }
        </div>
    )
}

function SelectLab({OD_MAP, handleOrderMap, handleSelectLab}){
    const t = useTranslate();

    const [labList, setLabList] = useState(null);
    const [asyncLabList] = useAsync(companyListApi);
    const [asyncOrderUpdateInfo] = useAsync(orderUpdateInfoApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        const resLabList = await asyncLabList({ CO_TYPE : _CO_TYPE_LAB, CO_STATUS : "A" })
        if(!resApiCheck(resLabList)) return;

        if(resLabList.data.LIST_COUNT > 0){
            setLabList(resLabList.data.LIST);
        }
    }

    const [lab, setLab] = useState(0);

    const handleLab = (e) => {
        const LAB_SEQ = e.target.value;

        setLab(LAB_SEQ);
        orderUpdateLab(LAB_SEQ);
    }

    async function orderUpdateLab(OD_LAB){
        const OD_SEQ = OD_MAP.OD_SEQ;

        const response = await asyncOrderUpdateInfo({
            OD_SEQ : OD_SEQ,
            OD_LAB : OD_LAB,
        })

        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleOrderMap(OD_SEQ);
            handleSelectLab();
        }
    }

    return (
        <select className="select" value={lab} onChange={handleLab}>
            <option value={0}>{t("common_choice")}</option>
            <option value={0}>{t("common_ndesignated")}</option>
            {
                labList && labList.map((x) => {
                    return (
                        <option key={"option_" + x.CO_SEQ} value={x.CO_SEQ}>{x.CO_NAME}</option>
                    )
                })
            }
        </select>
    )
}
import { read, utils, writeFile } from "xlsx"

//Export Excel
export function ex_eventHandler(){
    let object = {};
    
    object.getExcelFileName = (fileName) => { return fileName + ".xlsx"; }
    object.getExcelData = (tableId) => { return document.getElementById(tableId); }

    return object;
}

export const ex_jsonToExcel = (jsonData, fileName, sheetName=null) => {
    let eventHandler = ex_eventHandler();
    let wsName = sheetName || "Sheet Name";

    const wb = utils.book_new();
    const ws = utils.json_to_sheet(jsonData);
    utils.book_append_sheet(wb, ws, wsName);

    writeFile(wb, eventHandler.getExcelFileName(fileName), {compression: true});
}

export const ex_tableToExcel = (tableId, fileName, sheetName=null) => {
    let eventHandler = ex_eventHandler();
    let wsName = sheetName || "Sheet Name";

    const wb = utils.book_new();
    const ws = utils.table_to_sheet(eventHandler.getExcelData(tableId));
    utils.book_append_sheet(wb, ws, wsName);

    writeFile(wb, eventHandler.getExcelFileName(fileName), {compression: true});
}

//TODO ex_jsonToExcel

//TODO Import Excel
import useTranslate from "components/language/useTranslate";
import AddUserModal from "components/modal/AddUserModal";
import { useStore } from "contexts/Store";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_findData, cm_formatMobile, cm_isEmpty } from "utils/common";

export default function UserList({CO_TYPE, CO_SEQ, userList, onClickDetail, reLoad}){
    const store = useStore();
    const {US_TYPE_LIST} = store;
    const [isOpen, setIsOpen] = useState(false);
    const t = useTranslate();

    const handleModalUserWrite = () => setIsOpen((prevValue) => !prevValue);

    return(
        <>
            <div className="board-head" style={{float : "right"}}>
                <div className="btns">
                    <button className="btn btn-fill-blue2 btn-round btn-s" onClick={handleModalUserWrite}>{t("memberMg_addStaff")}</button>
                </div>
            </div>
            <div className="table1 hover-type">
                <table>
                    <thead>
                        <tr>
                            <th>{t("common_name")}</th>
                            <th>{t("memberMg_accountPermission")}</th>
                            <th>{t("common_phone")}</th>
                            <th>{t("common_email")}</th>
                            <th>{t("common_receiveNotice")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(userList) ?
                            userList.map((member) =>{
                                const {US_SEQ, US_EMAIL, US_NAME, US_PHONE, US_TYPE, US_NOTICE} = member;
                                let US_TYPE_NAME = cm_findData(US_TYPE_LIST, "CF_CODE", US_TYPE, "CF_TITLE");
                                let notice = US_NOTICE === "Y" ? t("common_yes") : t("common_no");
                                
                                return(
                                    <tr key={US_SEQ} onClick={() => onClickDetail(US_SEQ)}>
                                        <td>{US_NAME}</td>
                                        <td>{US_TYPE_NAME}</td>
                                        <td>{cm_formatMobile(US_PHONE)}</td>
                                        <td>{US_EMAIL}</td>
                                        <td>{notice}</td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr><td colSpan={5}>{t("order_searchOrder_content_1")}</td></tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
            <Modal show={isOpen} onHide={handleModalUserWrite} centered>
                <AddUserModal isOpen={isOpen} handleModal={handleModalUserWrite} CO_TYPE={CO_TYPE} CO_SEQ={CO_SEQ} reLoad={reLoad}/>
            </Modal>
        </>
    )
}
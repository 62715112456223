import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const makePdf = async (area, fileName, isDownload = false, isOpen = false) => {
    
    const converToImg = async () => {
        const canvas = await html2canvas(document.getElementById(area));
        const image = canvas.toDataURL("image/png", 1.0);

        return [image, canvas]
    }

    const converToPdf = (image, canvas) => {
        const doc = new jsPDF('p', 'mm', 'a4');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
    
        const widthRatio = pageWidth / canvas.width;
        const customHeight = canvas.height * widthRatio;
        doc.addImage(image, 'png', 0, 0, pageWidth, customHeight);
    
        let heightLeft = customHeight;
        let heightAdd = -pageHeight;
    
        while (heightLeft >= pageHeight) {
            doc.addPage();
            doc.addImage(image, 'png', 0, heightAdd, pageWidth, customHeight);
            heightLeft -= pageHeight;
            heightAdd -= pageHeight;
        }
    
        if (isDownload)
            doc.save(fileName + '.pdf');

        if (isOpen) {
            window.open(doc.output('bloburl'));
        }

        const pdf = new File([doc.output("blob")], (`${fileName}.pdf`), {
            type: "application/pdf",
        });
    
        return pdf
    }

    const [image, canvas] = await converToImg()
    return converToPdf(image, canvas)
}

export default makePdf
import { configListApi } from "api/configApi";
import { userWriteApi } from "api/userApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER, _US_TYPE_CE, _US_TYPE_QD } from "config";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_isEmail, cm_isEmpty, cm_isNumeric, cm_swAlert, resApiCheck } from "utils/common";

export default function AddUserModal({isOpen, handleModal, CO_TYPE, CO_SEQ, reLoad}){
    const [userTypeList, setUserTypeList] = useState(null);
    const [userInfo, setUserInfo] = useState({
        CO_SEQ : CO_SEQ,
        US_TYPE : CO_TYPE !== _CO_TYPE_CENTER ? _US_TYPE_CE : _US_TYPE_QD,
        US_EMAIL: "",
        US_NAME : "",
        US_PHONE : "",
        US_NOTICE : "N",
    })

    //API
    const [asyncConfigList] = useAsync(configListApi);
    const [asyncUserWrite, userWritePending] = useAsync(userWriteApi);
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleLoad = async () => {
        // Config US_TYPE list
        const response = await asyncConfigList({CF_TYPE : "US_TYPE"});
        if(!resApiCheck(response)) return;
        if(response.data.LIST.length > 0){
            setUserTypeList(response.data.LIST);
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target;

        if (name === "US_PHONE"){
            if (!cm_isNumeric(value)) return;
        }

        setUserInfo(produce(prevValue => {
            prevValue[name] = value;
        }));
    }

    const handleSave = async () => {
        if(!saveValidate()) return;

        const response = await asyncUserWrite(userInfo);
        if(!resApiCheck(response)) return;
        if(response.data.RESULT === "OK"){
            cm_swAlert(t("common_content_8"), "success", t("common_check"));
            handleModal();
            reLoad();
        }else{
            cm_swAlert(t("common_regiFailed"), "warning", t("common_check"));
        }
    }

    function saveValidate(){
        const validationRules = [
            { key: 'US_EMAIL', message: t('common_emailPlaceHolder') },
            { key: 'US_NAME', message: t('alert_inputName') },
            { key: 'US_PHONE', message: t('common_content_3') },
        ];

        for(const rule of validationRules) {
            if(cm_isEmpty(userInfo[rule.key])) {
                cm_swAlert(rule.message, "info", t("common_check"));
                return false;
            }

            if(rule.key === "US_EMAIL" && !cm_isEmail(userInfo.US_EMAIL)){
                cm_swAlert(t("common_alert_wrongFormEmail"), "info", t("common_check"));
                return false;
            }
        }

        return true;
    }

    return(
        <>
            {userTypeList &&
                <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h1 className="modal-title">{t("memberMg_addStaff")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                            </div>
                            <div className="modal-body">
                                <h4 className="form-tit">{t("memberMg_accountPermission")}</h4>
                                <div className="inp-box">
                                    <select className="select block" key="US_TYPE" name="US_TYPE" value={userInfo.US_TYPE} onChange={handleChange}>
                                        {CO_TYPE !== _CO_TYPE_CENTER ?
                                            userTypeList.filter(x => x.CF_VAL1 < 10).map((userType) => {
                                                return(
                                                    <option key={userType.CF_CODE} value={userType.CF_CODE}>{userType.CF_TITLE}</option>
                                                )
                                            })
                                            :
                                            userTypeList.filter(x => x.CF_VAL1 >= 10).map((userType) => {
                                                return(
                                                    <option key={userType.CF_CODE} value={userType.CF_CODE}>{userType.CF_TITLE}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <h4 className="form-tit">{t("common_email")}<span className='required'>＊</span></h4>
                                <div className="inp-box">
                                    <input type="text" className="inp block" name="US_EMAIL" value={userInfo.US_EMAIL} onChange={handleChange} maxLength={30}/>
                                </div>
                                <h4 className="form-tit">{t("common_name")}<span className='required'>＊</span></h4>
                                <div className="inp-box">
                                    <input type="text" className="inp block" name="US_NAME" value={userInfo.US_NAME} onChange={handleChange} maxLength={40}/>
                                </div>
                                <h4 className="form-tit">{t("common_phone")}<span className='required'>＊</span></h4>
                                <div className="inp-box">
                                    <input type="text" className="inp block" name="US_PHONE" value={userInfo.US_PHONE} onChange={handleChange} maxLength={11}/>
                                </div>

                                <h4 className="form-tit">{t("common_receiveNotice")}</h4>
                                <div className="inp-box">
                                    <div className="flex gap10" style={{justifyContent:"space-evenly"}}>
                                        <label>
                                            <input type="radio" className="radio" name="US_NOTICE" value={"Y"} checked={"Y" === userInfo.US_NOTICE} onChange={handleChange}/>
                                            <div><em></em><p>{t("common_yes")}</p></div>
                                        </label>
                                        <label>
                                            <input type="radio" className="radio" name="US_NOTICE" value={"N"} checked={"N" === userInfo.US_NOTICE} onChange={handleChange}/>
                                            <div><em></em><p>{t("common_no")}</p></div>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-fill-blue" disabled={userWritePending} onClick={handleSave}>{t("common_regi")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
import { userUpdatePwdApi } from "api/userApi";
import useTranslate from 'components/language/useTranslate';
import useAsync from "hooks/useAsync";
import { useState } from "react";
import { cm_isEmpty, cm_isPassword, cm_swAlert, resApiCheck } from "utils/common";

export default function UserPwd(){
    const [newPwdErr, setNewPwdErr] = useState("");
    const [newPwdcErr, setNewPwdcErr] = useState(false);
    const [pwdValid, setPwdValid] = useState(0);
    const [pwd, setPwd] = useState({
        US_PWD: "",
        US_PWD_NEW: "",
        US_PWDC_NEW: ""
    });
    const [asyncUserUpdate, userUpdatePending] = useAsync(userUpdatePwdApi);
    const t = useTranslate();

    const arrPwdAlertText = [
        t("alert_pwd_enterpwd"),
        t("alert_pwd_cantgap"),
        t("alert_pwd_uppercase"),
        t("alert_pwd_small"),
        t("alert_pwd_number"),
        t("alert_pwd_specialcharacter"),
        t("alert_pwd_notallowed")
    ]

    const onChangePwd = (e) => {
        const {name, value} = e.target;
        let result = cm_isPassword(value, arrPwdAlertText);

        switch(name){
            case "US_PWD_NEW" :
                setNewPwdErr(result.str);
                setPwdValid(result.num);
                if(!cm_isEmpty(pwd.US_PWDC_NEW)){
                    setNewPwdcErr(pwd.US_PWDC_NEW !== value);
                }
                break;
            case "US_PWDC_NEW" :
                setNewPwdcErr(pwd.US_PWD_NEW !== value);
                break;
            default :
                break;
        }

        setPwd({...pwd,[name] : value});
    };

    const changePassword = async () => {
        if(!saveValidate()) return;

        const response = await asyncUserUpdate({
            US_PWD : pwd.US_PWD,
            US_PWD_NEW : pwd.US_PWD_NEW,
        });
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_pw_content_1"), "error", t("common_check"));
        }else{
            cm_swAlert(t("alert_pw_content_4"), "info", t("common_check"))
        }
    }

    function saveValidate(){
        let result = true;

        if(cm_isEmpty(pwd.US_PWD)){
            cm_swAlert(t("alert_input_currPwd"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(pwd.US_PWD_NEW)) {
            cm_swAlert(t("alert_pw_content_5"), "warning", t("common_check"));
            result = false;
        }else if(pwd.US_PWD_NEW === pwd.US_PWD) {
            cm_swAlert(t("alert_pw_content_6"), "warning", t("common_check"));
            result = false;
        }else if(newPwdErr !== "OK") {
            cm_swAlert(t("alert_pw_content_2"), "warning", t("common_check"));
            result = false;
        }else if(pwd.US_PWD_NEW !== pwd.US_PWDC_NEW){
            cm_swAlert(t("alert_pw_content_3"), "warning", t("common_check"));
            result = false;
        }

        return result;
    }


    return(
        <div className="form-inner">
            <h3 className="h3">{t("myPage_pwChg")}</h3>
            <h4 className="form-tit">{t("myPage_pwCur")}</h4>
            <div className="inp-box">
                <input type="password" className="inp block" name="US_PWD" onChange={onChangePwd} value={pwd.US_PWD}/>
            </div>
            <h4 className="form-tit">{t("myPage_pwNew")}</h4>
            <div className="inp-box">
                <input type="password" className="inp block" name="US_PWD_NEW" onChange={onChangePwd} value={pwd.US_PWD_NEW}/>
            </div>
            {newPwdErr !== "OK" && <div className="form-msg txt-red">{newPwdErr}</div>}
            <div className="form-validation">
                <span>{t("common_pwSuitability")}</span>
                <div className={`bar step${pwdValid}`}></div>
                <strong>{newPwdErr !== "OK" ? t("common_notSafty") : t("common_safty")}</strong>
            </div>
            <h4 className="form-tit">{t("common_pwConfirm")}</h4>
            <div className="inp-box">
                <input type="password" className="inp block" name="US_PWDC_NEW" onChange={onChangePwd} value={pwd.US_PWDC_NEW}/>
            </div>
            {newPwdcErr && <div className="form-msg txt-red">{t("alert_pw_content_3")}</div>}

            <button className="btn btn-fill-blue block" disabled={userUpdatePending} onClick={changePassword} >{t("myPage_change")}</button>
        </div>
    )
}
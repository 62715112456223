import { useOrder, useSetOrder } from "contexts/OrderContext";
import { cm_dateDDay, cm_isEmpty, resApiCheck, cm_swAlert, getProsOpTitle, cm_dateToday} from "utils/common";
import { produce } from "immer";
import { orderCreateApi, orderUpdateApi } from "api/orderApi";
import useAsync from "hooks/useAsync";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "contexts/Store";
import useTranslate from "components/language/useTranslate";
import OrderTeethBox from "./OrderTeethBox";
import OrderSections from "./OrderSections";
import OrderImplantList from "./OrderImplantList";
import { ORDER_INIT } from "config";
import OrderMemo from "./OrderMemo";

export default function OrderCheck(props){
    const order = useOrder();
    const setOrder = useSetOrder();
    const store = useStore();
    const navigate = useNavigate();
    const {OD_DUEDATE, OD_FILE_SCAN, OL_LIST, OD_BRIDGE} = order;
    const [asyncOrderCreate, orderCreatePending] = useAsync(orderCreateApi);
    const [asyncOrderUpdate] = useAsync(orderUpdateApi);

    const t = useTranslate();
    const orderInit = ORDER_INIT();

    useEffect(() => {
        handleSetData();
    },[]);

    const handleSetData = async () => {
        setOrder(produce((prevValue) => {
            if(onlyPlaCheck(OL_LIST)){
                prevValue.OD_DUEDATE = cm_dateToday();
            }
        }))
    }

    function onlyPlaCheck(OL_LIST){
        let result = true;
        for(let orderLine of OL_LIST){
            if(orderLine.PR_CODE !== "PLA-Cr"){
                result = false;
                break;
            }
        }
        return result;
    }

    const handleOrderWrite = async () => {
        const {US_SEQ} = store.US_MAP;

        let fileKeys = [];

        let formData = new FormData();
        formData.append("US_SEQ", US_SEQ);
        formData.append("CO_SEQ", order.CO_SEQ);
        formData.append("OD_SEQ", order.OD_SEQ);
        formData.append("OD_STATUS", order.OD_STATUS);
        formData.append("OD_DUEDATE", order.OD_DUEDATE);
        formData.append("OD_NAME", order.OD_NAME);
        formData.append("OL_LIST", JSON.stringify(order.OL_LIST));
        formData.append("OD_BRIDGE", JSON.stringify(order.OD_BRIDGE));
        formData.append("OD_CONTACT", order.OD_CONTACT);
        formData.append("OD_EMBRASURE", order.OD_EMBRASURE);
        formData.append("OD_HOOK", order.OD_HOOK);
        formData.append("OD_PONTIC", order.OD_PONTIC);
        formData.append("OD_MEMO", order.OD_MEMO);
        formData.append("OD_DC", order.OD_DC);
        formData.append("OD_DESIGNER", order.OD_DESIGNER);
        formData.append("DELFILE_LIST", JSON.stringify(props.delFiles));
        
        if(!cm_isEmpty(order.OD_FILE_SCAN)){
            for(let file of order.OD_FILE_SCAN){
                if(!cm_isEmpty(file.FL_SEQ)) continue; //이미 등록된 파일은 insert 제외 : TEMP_KEY, 주문수정시 기 등록된 파일

                fileKeys = [...fileKeys, { fileName: file.name, fileRef: "SCAN" }]
                formData.append("file", file);
            }
        }
        
        formData.append("fileKey", JSON.stringify(fileKeys));

        if(!cm_isEmpty(order.TEMP_KEY)){
            formData.append("TEMP_KEY", order.TEMP_KEY);
        }

        if(order.OD_SEQ > 0){
            formData.append("OD_UKEY", order.OD_UKEY);
            formData.append("UPDATE", "Y");

            const response = await asyncOrderUpdate(formData);
            if(!resApiCheck(response)) return;

            const result = response.data.RESULT;
            if(result === "OK"){
                sessionStorage.removeItem("OD_SAVE");
                writeSuccess();
            }else{
                cm_swAlert(result, "warning", t("common_check"));
                navigate("/order");
            }

        }else{
            const response = await asyncOrderCreate(formData);
            if(!resApiCheck(response)) return;

            const {OD_SEQ} = response.data;
        
            if(OD_SEQ){
                sessionStorage.removeItem("OD_SAVE");
                writeSuccess();
            }
        }
    }

    function writeSuccess(){
        cm_swAlert(t("alert_orderComplet"), "success", t("common_check"));
        setOrder({...orderInit});
        navigate("/order");
    }

    return (
        <div className="order-writeFrame">
            <div className="side">
                <div className="sideDiv left">
                    <button className="arrow arrow-left" onClick={() => props.changeTab("option")}></button>
                </div>
            </div>
            <div className="middle">
                <div className="order-wrap">
                    <div className="inner1100">
                        <div className="user">
                            <h3>
                                <strong>{order.OD_NAME}</strong>
                            </h3>
                            <div className="date">
                                <span>{t("order_info_deadline")} : {OD_DUEDATE}</span>
                                <strong>{cm_dateDDay(OD_DUEDATE)}</strong>
                            </div>
                        </div>

                        <OrderTeethBox OL_LIST={OL_LIST} OD_BRIDGE={OD_BRIDGE} viewer={true}/>

                        <div className="ordercheck-wrap">
                            <div className="order-data">

                                <div className="box block type2 h170">
                                    <div className="tit justify-content-between">
                                        <div className="tooth-list3">
                                            <OrderSections OL_LIST={OL_LIST} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-box">
                                    <div className="box type2 h170">
                                        <div className="tit">
                                            <h4 className="h4">{t("order_productOption")}</h4>
                                        </div>
                                        <div className="info">
                                            <div className="section">
                                                <dl className="box type4">
                                                    <dt>{t("common_contact")}</dt>
                                                    <dd>{getProsOpTitle("contact", order.OD_CONTACT)}</dd>
                                                </dl>
                                                <dl className="box type4">
                                                    <dt>{t("common_embrasure")}</dt>
                                                    <dd>{getProsOpTitle("embrasure", order.OD_EMBRASURE)}</dd>
                                                </dl>
                                                <dl className="box type4">
                                                    <dt>{t("common_ponticType")}</dt>
                                                    <dd>{getProsOpTitle("ponticType", order.OD_PONTIC)}</dd>
                                                </dl>
                                                <dl className="box type4">
                                                    <dt>{t("common_hook")}</dt>
                                                    <dd>{getProsOpTitle("hook", order.OD_HOOK)}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <OrderImplantList OL_LIST={OL_LIST}/>
                                </div>
                            </div>

                            <div className="order-etc">
                                <div className="file-box mb20">
                                    <h4 className="h4"><span>{t("order_info_file_scanshade")}</span>
                                        <button className="btn-ico" onClick={() => props.changeTab("info")}><i className="xi-pen"></i></button>
                                    </h4>
                                    <ul className="file-list">
                                        {
                                            OD_FILE_SCAN.map((item, index) => {
                                                const fileName = !cm_isEmpty(item.FL_ORGNAME) ? item.FL_ORGNAME : item.name;
                                                
                                                return (
                                                    <li key={"scan_file_" + index}><p>{fileName}</p></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                                <OrderMemo />
                            </div>
                        </div>

                        <div className="check-bottom">
                            <div className="check-button">
                                <button className="btn btn-fill-blue3" style={{width:"300px", height:"50px", fontSize:"20px"}} onClick={handleOrderWrite} disabled={orderCreatePending}>{t("order_orderComplet")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="side"></div>
        </div>
        
    )
}
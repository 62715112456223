import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER, _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";


export default function CompanySearch({handleCompanyType, handleReLoad}){
    const store = useStore();
    const {US_MAP} = store;
    const t = useTranslate();

    const onKeyPress = (e) => {
        if(e.key === "Enter") handleReLoad();
    }

    return (
        <div className="admin-search">
            <div className="title">
                <h4>{t("common_surchOpt")}</h4>
            </div>
            <div className="search-box">
                <div className="item">
                    <dl>
                        <dt>{t("common_mbType")}</dt>
                        <dd>
                            <select className="select block" onChange={handleCompanyType}>
                                <option value={_CO_TYPE_CLINIC}>{t("common_dt")}</option>
                                <option value={_CO_TYPE_LAB}>{t("common_dtl")}</option>
                                <option value={_CO_TYPE_DESIGN}>{t("common_dtd")}</option>
                                {US_MAP.US_LEVEL >= 18 &&
                                    <option value={_CO_TYPE_CENTER}>{t("memberMg_quve")}</option>
                                }
                            </select>
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t("common_companyName")}</dt>
                        <dd><input type="text" className="inp" placeholder={t("common_ph")} onKeyDown={onKeyPress}/></dd>
                    </dl>
                    <div className="btns">
                        <button className="btn btn-fill-blue2 btn-round" onClick={() => handleReLoad()}>{t("payment_search")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
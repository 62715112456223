import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { cm_isEmpty } from "utils/common";

export default function PublicRoute() {
    let isLogin = false;

    if(!cm_isEmpty(localStorage.getItem("refresh_token"))){
        isLogin = true;
    }
    
    let authRouter = isLogin ? "/home" : "/";

    return(
        !isLogin ? <Outlet/> : <Navigate to={authRouter}/> 
    )
}
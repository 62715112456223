import { configListApi } from "api/configApi";
import { productListApi } from "api/productApi";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import AdminProductModal from "components/modal/AdminProductModal";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_numComma, resApiCheck } from "utils/common";

export default function ProductMng(){
    const t = useTranslate();
    
    const [isOpen, setIsOpen] = useState(false);
    const [reLoad, setReLoad] = useState(false);
    const [selectObj, setselectObj] = useState(null);
    
    const [productCount, setProductCount] = useState(0);
    const [productList, setProductList] = useState(null);
    const [productTypeList, setProductTypeList] = useState(null);

    const [asyncProductList] = useAsync(productListApi);
    const [asyncConfigList] = useAsync(configListApi);
    
    const limit = 10;
    const [searchPage, setSearchPage] =  useState({
        PR_TYPE : "",
        PAGE_ROWS : limit,
        PAGE_INDEX : 1,
    })

    const handleModal = () => setIsOpen((prevValue) => !prevValue);
    const handleSetReLoad = () => setReLoad((prevValue) => !prevValue);
    const handlePage = (page) => {
        setSearchPage(produce(prevValue => {
            prevValue.PAGE_INDEX = page;
        }));
    }

    useEffect(() => {
        handleLoad();
    },[reLoad, searchPage.PAGE_INDEX]);

    const handleLoad = async () => {
        // product
        const resProductList = await asyncProductList(searchPage);
        if(!resApiCheck(resProductList)) return;
        const {LIST_COUNT} = resProductList.data;
        if(LIST_COUNT > 0){
            setProductList(resProductList.data.LIST);
            setProductCount(resProductList.data.LIST_COUNT);
        }

        // config product type
        const resProductTypeList = await asyncConfigList({CF_TYPE_START : "PR_TYPE"});
        if(!resApiCheck(resProductTypeList)) return;

        if(resProductTypeList.data.LIST.length > 0){
            setProductTypeList(resProductTypeList.data.LIST);
        }
    }

    const handleAddModify = (e) => {
        const {name, value} = e.target;
        let findOpt = null;

        if(name === "modify"){
            findOpt = productList.find(x => x.PR_SEQ === Number(value));
            setselectObj(findOpt);
        }else{
            setselectObj(null);
        }

        handleModal();
    }

    return(
        <>
            <div id="container">
                <div className="admin-content">
                    <div className="title-head">
                        <h3>{t("prod_prodMg")}</h3>
                    </div>
                    <div className="board-head">
                        <h3 className="h3">{t("prod_salesPstheticList")}</h3>
                        <div className="btns">
                            <button className="btn btn-fill-blue2 btn-s" name="add" value="" onClick={handleAddModify}><i className="xi-plus"></i>{t("prod_addPsalesPstheticList")}</button>
                        </div>
                    </div>
                    <div className="table1 admin-type">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t("prod_pstheticType")}</th>
                                    <th>{t("prod_pstheticViewName")}</th>
                                    <th>{t("prod_pstheticAllName")}</th>
                                    <th>{"PR_SHADE"}</th>
                                    <th>{"PR_PMMA"}</th>
                                    <th>{"PR_TEMP"}</th>
                                    <th>{"PR_PONTIC"}</th>
                                    <th>{"PR_GROUP"}</th>
                                    <th>{t("prod_orderby")}</th>
                                    <th>{t("common_sellingPrice")}</th>
                                    <th>{`${t("common_sellingPrice")}(Pontic)`}</th>
                                    <th>{`${t("common_sellingPrice")}(Design)`}</th>
                                    <th>{`${t("common_sellingPrice")}(Design_Pontic)`}</th>
                                    <th>{t("prod_salesState")}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {productList && productTypeList &&
                                    productList.map((product) => {
                                        let productTypeName = productTypeList.find(x => x.CF_CODE === product.PR_TYPE).CF_TITLE || "";
                                        
                                        return(
                                            <tr key={product.PR_SEQ}>
                                                <td>{productTypeName}</td>
                                                <td>{product.PR_NAME}</td>
                                                <td>{product.PR_DESC}</td>
                                                <td>{product.PR_SHADE}</td>
                                                <td>{product.PR_TEMP}</td>
                                                <td>{product.PR_PMMA}</td>
                                                <td>{product.PR_PONTIC}</td>
                                                <td>{product.PR_GROUP}</td>
                                                <td>{product.PR_DPSEQ}</td>
                                                <td>{cm_numComma(product.PR_PRICE)}</td>
                                                <td>{cm_numComma(product.PR_PNPRICE)}</td>
                                                <td>{cm_numComma(product.PR_DSPRICE)}</td>
                                                <td>{cm_numComma(product.PR_DSPNPRICE)}</td>
                                                <td>
                                                    <span>{product.PR_SALES === "Y" ? t("common_use") : <em>{t("common_notUse")}</em>}</span>
                                                </td>
                                                <td>
                                                    <button className="btn btn-fill-gray btn-s" name="modify" value={product.PR_SEQ} onClick={handleAddModify}>{t("common_edit")}</button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination total={productCount} limit={limit} page={searchPage.PAGE_INDEX} setPage={handlePage}/>
            <Modal show={isOpen} onHide={handleModal} centered>
                <AdminProductModal isOpen={isOpen} handleModal={handleModal} selectObj={selectObj} setReLoad={handleSetReLoad} />
            </Modal>
        </>
    )
}
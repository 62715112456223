import { axio } from 'utils/common';
import { API } from 'config';

// PLAN
export async function planListApi(data){
    return await axio(API.PLAN_LIST, "POST", data);
}

export async function planReadApi(data){
    return await axio(API.PLAN_READ, "POST", data);
}

export async function planWriteApi(data){
    return await axio(API.PLAN_WRITE, "POST", data);
}

export async function planDeleteApi(data){
    return await axio(API.PLAN_DELETE, "POST", data);
}




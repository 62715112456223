import { orderCommentDeleteApi } from 'api/orderApi';
import useTranslate from 'components/language/useTranslate';
import useAsync from 'hooks/useAsync';
import { cm_dateMoment, cm_swAlert, cm_swConfirm, downloadFile, resApiCheck } from 'utils/common';
import OrderCommentWirte from './OrderCommentWrite';
import { ORDER_COMMENT } from 'config';
import { useStore } from 'contexts/Store';

function OrderComment({OD_SEQ, OC_LIST, FL_LIST, handleComment, handleOrderMap}){
    const t = useTranslate();
    const store = useStore();
    const orderComment = ORDER_COMMENT();
    const {US_SEQ, US_LEVEL} = store.US_MAP;
    
    const commentList = [...OC_LIST].sort((a, b) => new Date(b.DTTM) - new Date(a.DTTM));

    const [asyncDeleteComment] = useAsync(orderCommentDeleteApi);
    const deleteComment = async (OC_SEQ, delFiles) => {
        const commentContent = {
            OC_SEQ: OC_SEQ,
            DELFILE_LIST: JSON.stringify(delFiles)
        }
        const response = await asyncDeleteComment(commentContent);
        if(!resApiCheck(response)) return;
        handleOrderMap(OD_SEQ);
        cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
    }
    
    const handleDeleteComment = async (OC_SEQ, delFiles) => {
        const confirm = await cm_swConfirm(t("confirm_order_deleteMemo"), "", "info", t("common_yes"), t("common_no"));
        if(confirm){
            deleteComment(OC_SEQ, delFiles);
        }
    }

    return (
        <div className="home-side">
            <div className="head">
                <h4 className="h4">{t("common_memo")}</h4>
                <button className="btn-close" onClick={handleComment}><span className="hidden">{t("common_close")}</span></button>
            </div>
            <div className="body">
                <div className="chat-wrap2">
                    <div className="chat-body">
                        {commentList.map((comment) => {
                            const files = FL_LIST.filter(x => x.FL_REFKEY.indexOf("COMMENT=" + comment.OC_SEQ) !== -1);

                            return (
                                <div key={"comment_" + comment.OC_SEQ} className={`item type-${comment.OC_TYPE}`}>
                                    <div className="chat-group">
                                        <div className={`type type${comment.CO_TYPE}`}>
                                            {comment.CO_NAME}
                                        </div>
                                        <div className="tags">
                                            {comment.OC_TYPE !== "M" &&
                                                <p className={`tag-${comment.OC_TYPE}`}>{`#${orderComment.find(x => x.type === comment.OC_TYPE).text}`}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className="file">
                                        {files?.map((file) => {
                                            return (
                                                <img
                                                    key={`ckey_${file.FL_SEQ}`}
                                                    width={200}
                                                    style={{backgroundRepeat:"no-repeat", objectFit:"cover", cursor:"pointer"}}
                                                    src={file.FL_URL}
                                                    onClick={() => downloadFile(file.FL_URL, file.FL_ORGNAME)}
                                                    alt=""
                                                />
                                            )
                                        })}
                                    </div>
                                    <div className="text" style={{wordBreak: "break-word", whiteSpace:"pre-line"}}>
                                        {comment.OC_TEXT}
                                    </div>

                                    <div className='flex justify-content-between align-items-center'>
                                        {(comment.US_SEQ === US_SEQ && comment.OC_TYPE === "M") || US_LEVEL >= 18 ?
                                            <button className="btn-del" onClick={() => handleDeleteComment(comment.OC_SEQ, files)}><i className="xi-trash"></i> {t("myPage_del")}</button>
                                            : 
                                            <div></div>
                                        }
                                        <div className="time">
                                            {cm_dateMoment(comment.DTTM, "YYYY-MM-DD HH:mm")}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <OrderCommentWirte OD_SEQ={OD_SEQ} handleOrderMap={handleOrderMap}/>
                </div>
            </div>
        </div>
    )
}

export default OrderComment
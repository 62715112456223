import { TOOTH_NOTATION } from 'config';
import { useState, useEffect } from 'react';

export default function OrderTeethBox({OL_LIST, OD_BRIDGE, onClickTooth, onClickBridge, boxSize, viewer, productList}){
    const [drawBridge, setDrawBridge] = useState(false);

    useEffect(() => {
        setDrawBridge(true);
    },[])

    const [SVG_MAXILLA, SVG_MANDIBLE, BRIDGE_MAXILLA, BRIDGE_MANDIBLE, REF_NUM] = TOOTH_NOTATION();

    return (
        <div className="tooth-choice-wrap" style={{transform:`${boxSize}`}}>
            <div className="tooth">

                {/* Maxilla */}
                <div className="tooth-strike">
                    <svg width="1085" height="69" viewBox="0 0 1085 69" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <g id="wrap-maxilla">
                            {SVG_MAXILLA.map((item) => {
                                const currentTooth = OL_LIST.filter(x => x.OL_TOOTH === item.toothNum || x.OL_TOOTH === "u");
                                const color = currentTooth.length > 1 ? "cX active" : currentTooth.length === 1 ? `c${currentTooth[0].PR_TYPE} active` : null;

                                return(
                                    <g key={item.toothNum} id={item.toothNum} className={viewer ? "viewer" : null}>
                                        <path id={item.pathId} d={item.d} fill="white" stroke="black" strokeMiterlimit="10" onClick={() => onClickTooth && onClickTooth(item.toothNum)} className={color}/>
                                        <text id={item.toothNum + "_2"} fill="black" xmlSpace="preserve" style={{whiteSpace:"pre"}} fontFamily="Pretendard" fontSize="16" letterSpacing="0em">
                                            <tspan x={item.x} y={item.y}>
                                                {OL_LIST.filter(x => x.OL_TOOTH === item.toothNum && x.OL_PONTIC === "Y").length > 0 ? "X" : item.toothNum}
                                            </tspan>
                                        </text>
                                    </g>
                                )
                            })}
                        </g>
                    </svg>

                    {/* Maxilla Bridge */}
                    {(!viewer && productList) &&
                        <div id="bridge_maxilla" className="bridge-box">
                        {
                            BRIDGE_MAXILLA.map((item) => {
                                const OL_LIST_BR = OL_LIST.filter(x => productList.filter(cr => cr.PR_GROUP > 0).some(cr => cr.PR_SEQ === x.PR_SEQ)); // Allow Bridge OrderLineList
                                const itemGroup = item.group.split("_");
                                const itemDisplay = OL_LIST_BR.find(x => x.OL_TOOTH === itemGroup[0]) && OL_LIST_BR.find(x => x.OL_TOOTH === itemGroup[1]) ? "block" : "none";
                                const itemStyle = {top: "-15px", left: item.left, display: itemDisplay};
                                const itemClass = (OD_BRIDGE.some(x => x.indexOf(item.group) !== -1) || itemDisplay === "none") ? "" : "btn-refresh";
                                
                                return (
                                    <button key={"bridge_" + item.group} group={item.group} className={itemClass} style={itemStyle} onClick={onClickBridge}></button>
                                )
                            })
                        }
                        </div>
                    }

                    {/* Bridge-rounder-box */}
                    {drawBridge && 
                        OD_BRIDGE.filter(bridge => {
                            const numbers = bridge.split("_").map(num => parseInt(num));
                            return numbers.every(num => num <= REF_NUM);
                        }).map((group) => {
                            let width = 0;
                            const teeth = group.split("_");
                            const left = document.getElementById(teeth[0]).getBBox().x;

                            for(let tooth of teeth){
                                const toothWidth = document.getElementById(tooth).getBoundingClientRect().width + 20;

                                width += toothWidth;
                            }

                            return(
                                <div key={`rounder_${group}`} className='rounder-box maxilar'
                                    style={{
                                        width: width + "px",
                                        left: (left - 4) + "px"
                                    }}
                                >
                                </div>
                            )
                        })
                    }
                </div>

                {/* Mandible */}
                <div className="tooth-strike">  
                    <svg width="1058" height="64" viewBox="0 0 1058 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <g id="wrap-mandible">
                            {SVG_MANDIBLE.map((item) => {
                                const currentTooth = OL_LIST.filter(x => x.OL_TOOTH === item.toothNum || x.OL_TOOTH === "l");
                                const color = currentTooth.length > 1 ? "cX active" : currentTooth.length === 1 ? `c${currentTooth[0].PR_TYPE} active` : null;

                                return(
                                    <g key={item.toothNum} id={item.toothNum} className={viewer ? "viewer" : null}>
                                        <path id={item.pathId} d={item.d} fill="white" stroke="black" strokeMiterlimit="10" onClick={() => onClickTooth && onClickTooth(item.toothNum)} className={color}/>
                                        <text id={item.toothNum + "_2"} fill="black" xmlSpace="preserve" style={{whiteSpace:"pre"}} fontFamily="Pretendard" fontSize="16" letterSpacing="0em">
                                            <tspan x={item.x} y={item.y}>
                                                {OL_LIST.filter(x => x.OL_TOOTH === item.toothNum && x.OL_PONTIC === "Y").length > 0 ? "X" : item.toothNum}
                                            </tspan>
                                        </text>
                                    </g>
                                )
                            })}
                        </g>
                    </svg>

                    {(!viewer && productList) &&
                        <div id="bridge_mandible" className="bridge-box">
                        {
                            BRIDGE_MANDIBLE.map((item) => {
                                const OL_LIST_BR = OL_LIST.filter(x => productList.filter(cr => cr.PR_GROUP > 0).some(cr => cr.PR_SEQ === x.PR_SEQ)); // Allow Bridge OrderLineList
                                const itemGroup = item.group.split("_");
                                const itemDisplay = OL_LIST_BR.find(x => x.OL_TOOTH === itemGroup[0]) && OL_LIST_BR.find(x => x.OL_TOOTH === itemGroup[1]) ? "block" : "none";
                                const itemStyle = {top: "144px", left: item.left, display: itemDisplay};
                                const itemClass = (OD_BRIDGE.some(x => x.indexOf(item.group) !== -1) || itemDisplay === "none") ? "" : "btn-refresh";

                                return (
                                    <button key={"bridge_" + item.group} group={item.group} className={itemClass} style={itemStyle} onClick={onClickBridge}></button>
                                )
                            })
                        }
                        </div>
                    }

                    {/* group-rounder-box */}
                    {drawBridge && 
                        OD_BRIDGE.filter(bridge => {
                            const numbers = bridge.split("_").map(num => parseInt(num));
                            return numbers.every(num => num > REF_NUM);
                        }).map((group) => {
                            let width = 0;
                            const teeth = group.split("_");
                            const left = document.getElementById(teeth[0]).getBBox().x;

                            for(let tooth of teeth){
                                const toothWidth = document.getElementById(tooth).getBoundingClientRect().width + 20;

                                width += toothWidth;
                            }

                            return(
                                <div key={`rounder_${group}`} className='rounder-box mandible'
                                    style={{
                                        top: "76px",
                                        width: width + "px",
                                        left: (left + 10) + "px"
                                    }}
                                >
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
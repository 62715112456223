import { orderListApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CLINIC, _CO_TYPE_LAB } from "config";
import useAsync from "hooks/useAsync";
import { Fragment, useEffect, useState } from "react";
import { cm_dateMoment, cm_dateMonthDate, cm_numComma, resApiCheck } from "utils/common";

export default function PayOrderList({tap, account, MONTH, CO_TYPE}){
    const [orderList, setOrderList] = useState([]);
    const [orderLineList, setOrderLineList] = useState([]);
    const [asyncOrderList] = useAsync(orderListApi);

    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    useEffect(() => {
        handleLoad();
    },[])
    
    const handleLoad = async () => {
        let data = {
            FT_FRPAYDATE : cm_dateMonthDate(MONTH, "MF"),
            FT_TOPAYDATE : cm_dateMonthDate(MONTH, "ML")
        }

        // Admin에서 orderList 조회시 key name 구분
        if(CO_TYPE === _CO_TYPE_CLINIC){
            data["CO_SEQ"] = account.CO_SEQ;
        }else if(CO_TYPE === _CO_TYPE_LAB){
            data["CO_LAB"] = account.CO_SEQ;
        }

        const resOrderList = await asyncOrderList(data);
        if(!resApiCheck(resOrderList)) return;
        if(resOrderList.data.LIST_COUNT > 0){
            setOrderList(resOrderList.data.LIST);
            if(CO_TYPE === _CO_TYPE_LAB){
                setOrderLineList(resOrderList.data.OL_LIST.filter(x => x.PR_CODE !== "PLA-Cr"));
            }else{
                setOrderLineList(resOrderList.data.OL_LIST);
            }
        }else{
            setOrderList([]);
            setOrderLineList([]);
        }
    }

    return(
        <div className="table1 pmDetail" style={{ maxHeight: "395px", overflow:"auto"}}>
            <table id={tap}>
                <thead>
                    <tr>
                        <th>{t("payment_recpDat")}</th>
                        <th>{t("payment_deliveryDate")}</th>
                        <th>{t("common_dtl")}</th>
                        <th>{t("common_patientName")}</th>
                        <th>{t("common_type")}</th>
                        <th>{t("payment_dtFormula")}</th>
                        <th>{t("payment_pstheticCost")}({SYMBOL})</th>
                        <th>{t("common_remakeCnt")}</th>
                    </tr>
                </thead>
                <tbody>
                    {orderList ? orderList.map((order) => {
                        const orderLine = orderLineList.filter(x => x.OD_SEQ === order.OD_SEQ);
                        let products = [...new Set(orderLine.map(x => x.PR_CODE))]

                        return(
                            <Fragment key={order.OD_SEQ}>
                                {products.map((product) => {
                                    let filterProductOrderLine = orderLine.filter(x => x.PR_CODE === product);
                                    let toothString = filterProductOrderLine.map(x => x.OL_TOOTH).join(', ');
                                    let sellPriceSum = filterProductOrderLine.map(x => x.OL_SELLPRICE).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
                                    let costPriceSum = filterProductOrderLine.map(x => x.OL_COSTPRICE).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
                                    let priceSum = CO_TYPE === _CO_TYPE_LAB ? costPriceSum : sellPriceSum;

                                    return(
                                        <tr key={`order_${order.OD_SEQ}_${product}`}>
                                            <td>{cm_dateMoment(order.OD_REGDTTM)}</td>
                                            <td>{order.OD_DUEDATE}</td>
                                            <td>{order.LAB_NAME}</td>
                                            <td>{order.OD_NAME}</td>
                                            <td>{product}</td>
                                            <td>{toothString}</td>
                                            <td>{cm_numComma(priceSum)}</td>
                                            <td>{order.OD_REMAKE}</td>
                                        </tr>
                                    )
                                })}
                            </Fragment>
                        )
                    })
                    : <tr><td colSpan='8' align='center'>{t("payment_content_1")}</td></tr>
                }
                </tbody>
            </table>
        </div>
    )
}
import { Link } from "react-router-dom";
import useAsync from "hooks/useAsync";
import useInput from "hooks/useInput";
import {findPwdApi } from "api/commonApi";
import { resApiCheck, cm_swAlert } from "utils/common";
import useTranslate from "components/language/useTranslate";

export default function FindPwd(){
    const t = useTranslate();

    const [findMember, onChange] = useInput({
        US_EMAIL: "",
        US_NAME: "",
    })
    const {US_EMAIL, US_NAME} = findMember;
    const [asyncFindPwd, findPwdPending] = useAsync(findPwdApi);

    const findPwdCall = async (e) => {
        e.preventDefault();

        let response = await asyncFindPwd(findMember);
        if(!resApiCheck(response)) return;

        if(response.data.RESULT === "OK"){
            cm_swAlert(t("common_content_8"), "info", t("common_check"));
        }else{
            cm_swAlert(t("alert_noFindId"), "error", t("common_check"));
        }
    }

    return(
        <div id="wrap-user">
            <div id="container">
                <div className="mypage align-items-center ">
                    <div className="form-inner">
                        <h4 className="form-tit">{t("common_email")}</h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="US_EMAIL" value={US_EMAIL} placeholder="" onChange={onChange}/>
                        </div>
                        <h4 className="form-tit">{t("common_name")}</h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="US_NAME" value={US_NAME}  placeholder="" onChange={onChange}/>
                        </div>
                        <div className="description2 mb20"><i className="xi-info"></i> {t("common_content_9")}</div>
                        <button className="btn btn-fill-blue block mb30" onClick={findPwdCall} disabled={findPwdPending}>{t("common_sendTemporaryPwd")}</button>

                        <div className="btn-common-box">
                            <button className="btn-link"><Link to={"/"}>{t("signIn")}</Link></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
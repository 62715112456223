import { deleteFileApi } from 'api/commonApi';
import { orderAutoDesign, orderRepairCancelApi, orderUploadFileApi } from 'api/orderApi';
import DropFileInput from 'components/DropFileInput';
import useTranslate from 'components/language/useTranslate';
import OrderModifyModal from 'components/modal/OrderModifyModal';
import OrderRequest from 'components/modal/OrderRequest';
import ViewerModal from 'components/modal/ViewerModal';
import OrderButtonStatus from "components/order/OrderButtonStatus";
import OrderComment from "components/order/OrderComment";
import { ORDER_STATUS, _CO_TYPE_CENTER, _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from 'config';
import { useStore } from 'contexts/Store';
import useAsync from 'hooks/useAsync';
import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { _FMT_DTS, cm_acceptFile, cm_dateDDay, cm_dateMoment, cm_isEmpty, cm_numComma, cm_swAlert, cm_swConfirm, downloadFile, getProsOpTitle, resApiCheck, resResultAlert } from 'utils/common';
import OrderImplantList from './OrderImplantList';
import OrderSections from './OrderSections';
import OrderTags from './OrderTags';
import OrderTeethBox from './OrderTeethBox';
import SelectDentalLab from './SelectDentalLab';
import SelectDesigner from './SelectDesigner';
import SelectPlaStatus from './SelectPlaStatus';

export default function OrderDetail({orderMap, handleOrderStatus, handleOrderMap, handleSplitModal, orderChangeStatus, setOrderMap, orderUpdateCall}){
    const store = useStore();
    const orderStatus = ORDER_STATUS();

    const {OD_MAP, OL_LIST, FL_LIST, BTN_MAP} = orderMap;
    // PLA(즉시임시치아)는 기공소 표시 숨김
    const {US_MAP} = store;
    const OD_FILE_SCAN = FL_LIST.filter(item => item.FL_REFKEY === 'SCAN');
    const OL_TOOTH_LIST = [...new Set(OL_LIST.map(item => item.OL_TOOTH))];
    const filterOrderList = US_MAP.CO_TYPE === _CO_TYPE_LAB ? OL_LIST.filter(x => x.PR_CODE !== "PLA-Cr") : OL_LIST;

    const t = useTranslate();

    const [commentOpen, setCommentOpen] = useState(true);
    const handleComment = () => setCommentOpen((prevValue) => !prevValue);

    const [uploadFileType, setUploadFileType] = useState(null);
    const [uploadFileOpen, setUploadFileOpen] = useState(false);
    const [modifyOpen, setModifyOpen] = useState(false);
    const [viewerFile, setViewerFile] = useState(null);
    const [viewerOpen, setViewerOpen] = useState(false);

    const handleUploadFile = (type) => {
        setUploadFileType(type);
        setUploadFileOpen((prevValue) => !prevValue);
    }
    const handleModifyModal = () => setModifyOpen((prevValue) => !prevValue);
    const handleViewerModal = (file) => {
        setViewerFile(file);
        setViewerOpen((prevValue) => !prevValue);
    }

    function downLoadFiles(fileType){
        let FL_DOWN_LIST = FL_LIST.filter(function(file){
            let result = true;
            const fileTypeOrigin = file.FL_ORGNAME.split(".")[1];
            
            if(file.FL_REFKEY !== fileType) result = false;
            if(fileType === "SCAN" && US_MAP.CO_TYPE === _CO_TYPE_LAB && (fileTypeOrigin !== "jpg" && fileTypeOrigin !== "png")){
                result = false;
            }
            return result;
        });
        FL_DOWN_LIST.map((file) => {
            return downloadFile(file.FL_URL, file.FL_ORGNAME);
        })
    }

    const [asyncOrderAutoDesign] = useAsync(orderAutoDesign);

    async function autoDesign (){
        if (await cm_swConfirm(t("confirm_auto_design"), "")) {
            await asyncOrderAutoDesign({OD_SEQ : OD_MAP.OD_SEQ});
            cm_swAlert(t("alert_orderTask"), "success", t("common_check"));
        }
    }

    const navigate = useNavigate();
    function modifyOrder(){
        navigate('/order/orderWrite', {state: {orderMap}});
    }

    const [asyncDeleteFile] = useAsync(deleteFileApi);

    const deleteFile = async (FL_SEQ) => {
        const response = await asyncDeleteFile({FL_SEQ: FL_SEQ});
        if(!resApiCheck(response)) return;

        cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
        handleOrderMap(OD_MAP.OD_SEQ);
    }

    const handleDeleteFile = async (FL_SEQ) => {
        const confirm = await cm_swConfirm(t("confirm_order_deleteFile"), "", "info", t("common_yes"), t("common_no"));
        if(confirm){
            deleteFile(FL_SEQ);
        }
    }

    const autoUpdateStatus = (OD_STATUS) => {
        // 제작대기상태 기공소 다운로드시 제작으로 변경
        if(US_MAP.CO_TYPE === _CO_TYPE_LAB && OD_MAP.OD_STATUS === "W"){
            handleOrderStatus(OD_STATUS);
        }
    }

    const componentRef = useRef();

    const handleRequestPrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: "@media print { @page { width:148mm; height:210mm; margin: 2mm;} }",
        documentTitle: `파일명`,
    })

    function totalPrice(OL_LIST){
        let totalPrice = 0;

        for(let line of OL_LIST){
            totalPrice += (line.OL_SELLPRICE + (!cm_isEmpty(line.OA_PRICE) ? line.OA_PRICE : 0));
        }

        return totalPrice;
    }

    const [asyncOrderRepairCancel] = useAsync(orderRepairCancelApi);
    const handleRepairCancel = async () => {
        const confirm = await cm_swConfirm(t("confirm_order_repair_cancel"), "", "info", t("common_yes"), t("common_no"));
        if(confirm) {
            repairCancel();
        }
    }

    const repairCancel = async () => {
        const {OD_SEQ, OD_UKEY} = orderMap.OD_MAP;
    
        const request = {
            OD_SEQ: OD_SEQ,
            OD_UKEY: OD_UKEY,
            OC_TEXT: t("order_step_repair_cancel"),
            OD_STATUS: "C"
        }

        const response = await asyncOrderRepairCancel(request);

        if(resApiCheck(response)){
            handleOrderMap(OD_SEQ);

            resResultAlert(response.data.RESULT, t("alert_cancel_success"));
        }
    }
    
    const openCadViewer = () => {
        let jawscan = "";
        let antagonist = "";
        let design1 = "";
        let design2 = "";
        let design3 = "";

        for(let file of FL_LIST){
            if(file.FL_ORGNAME.indexOf(".stl") === -1) continue;

            // TODO: File Check
            if(file.FL_ORGNAME.indexOf("upperjaw") !== -1) {
                jawscan = `jawscan="${encodeURIComponent(file.FL_URL)}"`
            } else if(file.FL_ORGNAME.indexOf("lowerjaw") !== -1) {
                antagonist = `antagonist="${encodeURIComponent(file.FL_URL)}"`
            } else if(file.FL_ORGNAME.indexOf("Crown, 15") !== -1) {
                design1 = `design1="${encodeURIComponent(file.FL_URL)}"`
            } else if(file.FL_ORGNAME.indexOf("Crown, 16") !== -1) {
                design2 = `design2="${encodeURIComponent(file.FL_URL)}"`
            } else if(file.FL_ORGNAME.indexOf("Crown, 17") !== -1) {
                design3 = `design3="${encodeURIComponent(file.FL_URL)}"`
            }
        }

        if(!jawscan || !antagonist || !design1 || !design2 || !design3) {
            cm_swAlert("파일을 확인해주세요.", "warning");
            return;
        }

        const url = `cadViewer/index.html?${jawscan}&${antagonist}&${design1}&${design2}&${design3}`;
        const newWindow = window.open(url, 'CadViewer', 'width=960,height=650');

        if (newWindow) {
            newWindow.focus();
        } else {
            cm_swAlert('팝업이 차단되었습니다.', "warning");
        }
    }

    return (
        <>
            <div className="home-main-wrap">
                <div className="home-main-box">
                    <div className="order-data">
                        <div className="user">
                            <h3>
                                {US_MAP.CO_TYPE === _CO_TYPE_CENTER &&
                                    <button className='btn-ico' onClick={handleModifyModal}><i className="xi-wrench"></i></button>
                                }
                                <span className={`state-${OD_MAP.OD_STATUS}`}>{orderStatus.find(x => x.key === OD_MAP.OD_STATUS).text}</span>
                                {US_MAP.CO_TYPE !== _CO_TYPE_LAB && OD_MAP.OD_PLASTATUS !== "N" && 
                                    <SelectPlaStatus OD_MAP={OD_MAP} orderChangeStatus={orderChangeStatus} />
                                }
                                {(US_MAP.CO_TYPE !== _CO_TYPE_CLINIC && US_MAP.CO_TYPE !== _CO_TYPE_DESIGN) && <span>{OD_MAP.CO_NAME}</span>}
                                <strong>{OD_MAP.OD_NAME}</strong>
                                <OrderTags OD_MAP={OD_MAP}/>
                            </h3>
                            <div className="date">
                                <span>{t("order_info_deadline")}: {cm_dateMoment(OD_MAP.OD_DUEDATE, "YYYY-MM-DD")}</span>
                                <span></span>
                                <strong>{cm_dateDDay(OD_MAP.OD_DUEDATE)}</strong>
                                <button className="btn btn-s btn-fill-gray btn-round" onClick={handleRequestPrint}>{t("order_teethConstructionRequest")}</button>
                                <button className="btn-memo" onClick={handleComment}><span className="hidden">{t("common_memo")}</span></button>
                            </div>
                        </div>

                        <div className="box pd10" style={{height:"auto"}}>
                            <div className="teethbox">
                                <OrderTeethBox OL_LIST={filterOrderList} OD_BRIDGE={!cm_isEmpty(OD_MAP.OD_BRIDGE) ? JSON.parse(OD_MAP.OD_BRIDGE) : []} viewer={true} />
                            </div>
                        </div>
                        <div className="col-wrap">
                            <div className="col">
                                <div className="box block type2 h170">
                                    <div className="tit flex justify-content-between">
                                        <h4 className="h4 static">
                                            {t("order_prodPsthetic")}
                                        </h4>
                                        <div className="types">
                                            <p><i className="xi-close"></i><span>Pontic</span></p>
                                        </div>
                                    </div>

                                    <div className="tooth-list3">
                                        <OrderSections OL_LIST={filterOrderList}/>
                                    </div>
                                </div>

                                <div className="col-box">
                                    <div className="box type2 h170">
                                        <div className="tit">
                                            <h4 className="h4">{t("order_productOption")}</h4>
                                        </div>

                                        <div className="info">
                                            <div className="section">
                                                <dl className="box type4">
                                                    <dt>{t("common_contact")}</dt>
                                                    <dd>{getProsOpTitle("contact", OD_MAP.OD_CONTACT)}</dd>
                                                </dl>
                                                <dl className="box type4">
                                                    <dt>{t("common_embrasure")}</dt>
                                                    <dd>{getProsOpTitle("embrasure", OD_MAP.OD_EMBRASURE)}</dd>
                                                </dl>
                                                <dl className="box type4">
                                                    <dt>{t("common_ponticType")}</dt>
                                                    <dd>{getProsOpTitle("ponticType", OD_MAP.OD_PONTIC)}</dd>
                                                </dl>
                                                <dl className="box type4">
                                                    <dt>{t("common_hook")}</dt>
                                                    <dd>{getProsOpTitle("hook", OD_MAP.OD_HOOK)}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>

                                    <OrderImplantList OL_LIST={OL_LIST}/>
                                </div>
                                <div className="box block h170">
                                    <div className="tit flex justify-content-between">
                                        <h4 className="h4">{t("order_otherRequest")}</h4>
                                    </div>
                                    <div className="text">{OD_MAP.OD_MEMO}</div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col file-box">
                                        <h4 className="h4"><span>{t("order_info_file_scanshade")}</span>
                                            {(OL_TOOTH_LIST.length === 1 && OD_FILE_SCAN.length !== 0 && ([_CO_TYPE_CENTER, _CO_TYPE_DESIGN].includes(US_MAP.CO_TYPE))) &&
                                                <button className="btn-ico" onClick={() => autoDesign()}>
                                                    <i className="xi-command"></i>
                                                    {OD_MAP.OD_PLASTATUS === "E" && 
                                                        <i 
                                                            className="print-img error" 
                                                            style={{
                                                                backgroundSize: "cover",
                                                                width: "15px",
                                                                height: "15px",
                                                                position: "relative",
                                                                right: "10px",
                                                                top: "3px"
                                                            }}/>
                                                    }
                                                </button>
                                            }
                                            <button className="btn-ico" onClick={() => downLoadFiles("SCAN")}><i className="xi-download"></i></button>
                                        </h4>
                                        <ul className="file-list">
                                            {
                                                FL_LIST?.filter(function(file){
                                                    let result = true;
                                                    const fileType = file.FL_ORGNAME.split(".")[1];

                                                    if(file.FL_REFKEY !== "SCAN") result = false;
                                                    if(US_MAP.CO_TYPE === _CO_TYPE_LAB && (fileType !== "jpg" && fileType !== "png")) result = false;
                                                    if(US_MAP.CO_TYPE !== _CO_TYPE_CENTER && fileType === "nex") result = false;

                                                    return result;
                                                }).map((file) => {
                                                    return (
                                                        <li key={"file_" + file.FL_SEQ}>
                                                            <small>{cm_dateMoment(file.DTTM, _FMT_DTS)}</small>
                                                            <span onClick={() => downloadFile(file.FL_URL, file.FL_ORGNAME)}>{file.FL_ORGNAME}</span>
                                                            {US_MAP.CO_TYPE === _CO_TYPE_CENTER && <button className="btn-ico" onClick={() => handleDeleteFile(file.FL_SEQ)}><i className="xi-close"></i></button>}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        {OD_MAP.OD_STATUS === "R" && 
                                            <div className="upload-rescan">
                                                <div>
                                                    <button className="btn-ico" id={"rescan"} onClick={() => handleUploadFile("SCAN")}><i className="xi-upload"></i></button>
                                                    <label htmlFor={"rescan"}>{t("order_rescan_file")}</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    
                                    {OD_MAP.OD_PROSTATUS !== "N" &&
                                        <div className="col file-box">
                                            <h4 className="h4">
                                                <span>
                                                    {t("order_designFile")}
                                                    {!cm_isEmpty(FL_LIST.filter(x => x.FL_REFKEY === "DESIGN")) &&
                                                        <> ({FL_LIST.filter(x => x.FL_REFKEY === "DESIGN").length})</>
                                                    }
                                                    {
                                                        <button onClick={() => openCadViewer()}><i className="xi-eye-o f20 ml5"></i></button>
                                                    }
                                                </span>
                                                {US_MAP.CO_TYPE === _CO_TYPE_CENTER && OD_MAP.OD_STATUS === "D" &&
                                                    <button className="btn-ico" onClick={() => handleUploadFile("DESIGN")}><i className="xi-upload"></i></button>
                                                }
                                                <button className="btn-ico" onClick={() => { downLoadFiles("DESIGN"); autoUpdateStatus("K"); }}><i className="xi-download"></i></button>
                                            </h4>
                                            <ul className="file-list">
                                                {
                                                    FL_LIST?.filter(x => x.FL_REFKEY === "DESIGN").map((file) => {
                                                        const fileType = file.FL_ORGNAME.split(".")[1];
                                                        
                                                        return (
                                                            <li key={"file_" + file.FL_SEQ}>
                                                                <small>{cm_dateMoment(file.DTTM, _FMT_DTS)}</small>
                                                                <span onClick={() => {downloadFile(file.FL_URL, file.FL_ORGNAME); autoUpdateStatus("K");}}>{file.FL_ORGNAME}</span>
                                                                {fileType === "obj" && <button type="button" className="viewer" onClick={() => handleViewerModal(file)}></button>}
                                                                {US_MAP.CO_TYPE === _CO_TYPE_CENTER && <button className="btn-ico" onClick={() => handleDeleteFile(file.FL_SEQ)}><i className="xi-close"></i></button>}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }

                                    {(OD_MAP.OD_PLASTATUS !== "N" && US_MAP.CO_TYPE !== _CO_TYPE_LAB) &&
                                        <div className="col file-box">
                                            <h4 className="h4"><span>{t("order_printFile")}</span>
                                                {US_MAP.CO_TYPE === _CO_TYPE_CENTER && OD_MAP.OD_STATUS === "D" &&
                                                    <button className="btn-ico" onClick={() => handleUploadFile("PRINT")}><i className="xi-upload"></i></button>
                                                }
                                                <button className="btn-ico" onClick={() => downLoadFiles("PRINT")}><i className="xi-download"></i></button>
                                            </h4>
                                            <ul className="file-list">
                                                {
                                                    FL_LIST?.filter(x => x.FL_REFKEY === "PRINT").map((file) => {
                                                        return (
                                                            <li key={"file_" + file.FL_SEQ}>
                                                                <small>{cm_dateMoment(file.DTTM, _FMT_DTS)}</small>
                                                                <span onClick={() => downloadFile(file.FL_URL, file.FL_ORGNAME)}>{file.FL_ORGNAME}</span>
                                                                {US_MAP.CO_TYPE === _CO_TYPE_CENTER && <button className="btn-ico" onClick={() => handleDeleteFile(file.FL_SEQ)}><i className="xi-close"></i></button>}
                                                            </li>
                                                            
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="total-price justify-content-between" style={{padding:"10px 10px 0 10px"}}>
                            <SelectDesigner OD_MAP={OD_MAP} handleOrderMap={handleOrderMap}/>

                            {US_MAP.US_LEVEL >= 18 &&
                                <div className="flex align-items-center gap15">
                                    <em>{t("order_cost")}</em>
                                    <div className="lab-name">{cm_numComma(totalPrice(OL_LIST))}</div>
                                </div>
                            }

                            {US_MAP.CO_TYPE !== _CO_TYPE_LAB && US_MAP.CO_TYPE !== _CO_TYPE_DESIGN && OD_MAP.OD_PROSTATUS !== "N" &&
                                <SelectDentalLab OD_MAP={OD_MAP} handleOrderMap={handleOrderMap} />
                            }
                        </div>
                    </div>

                    <OrderButtonStatus BTN_MAP={BTN_MAP} handleOrderStatus={handleOrderStatus} modifyOrder={modifyOrder} handleSplitModal={handleSplitModal} handleRemakeControlModal={handleModifyModal} handleOrderMap={handleOrderMap} orderChangeStatus={orderChangeStatus} handleRepairCancel={handleRepairCancel}/>
                </div>

                {commentOpen && <OrderComment OD_SEQ={OD_MAP.OD_SEQ} OC_LIST={orderMap.OC_LIST} FL_LIST={orderMap.FL_LIST} handleComment={handleComment} handleOrderMap={handleOrderMap}/>}
            </div>

            <div style={{ display:"none", width:"148mm", height:"210mm"}}>
                <OrderRequest ref={componentRef} orderMap={orderMap}/>
            </div>

            <Modal show={uploadFileOpen} onHide={handleUploadFile} centered>
                <UploadFiles isOpen={uploadFileOpen} handleUploadFile={handleUploadFile} uploadFileType={uploadFileType} OD_MAP={OD_MAP} handleOrderMap={handleOrderMap} handleOrderStatus={handleOrderStatus}/>
            </Modal>

            <Modal show={viewerOpen} onHide={handleViewerModal} centered>
                <ViewerModal isOpen={viewerOpen} handleModal={handleViewerModal} file={viewerFile} />
            </Modal>

            <Modal show={modifyOpen} onHide={handleModifyModal} centered>
                <OrderModifyModal isOpen={modifyOpen} handleModal={handleModifyModal} orderMap={orderMap} setOrderMap={setOrderMap} orderUpdateCall={orderUpdateCall} handleOrderStatus={handleOrderStatus} />
            </Modal>
        </>
    )
}

function UploadFiles({isOpen, handleUploadFile, uploadFileType, OD_MAP, handleOrderMap, handleOrderStatus}){
    const {OD_SEQ, CO_SEQ} = OD_MAP;
    const t = useTranslate();

    const [accepts, setAccepts] = useState([]);
    const [text, setText] = useState("");

    useEffect(()=> {
        handleFileType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleFileType = () => {
        if(uploadFileType === "PRINT"){
            setAccepts([".stl", ".cfb", ".gcode", ".zip"]);
            setText(t("order_printFile"));
        }else if(uploadFileType === "DESIGN"){
            setAccepts([".stl", ".zip"]);
            setText(t("order_designFile"))
        }else if(uploadFileType === "SCAN"){
            setAccepts([".zip", ".obj", ".ply", ".stl", ".dxd", ".png", ".jpg", ".nex"]);
            setText(t("order_scanFile"))
        }
    }

    // file
    const [fileList, setFileList] = useState([]);
    const onFileChange = (files) => {
        let list = cm_acceptFile(accepts, files, t("alert_common_file"));

        setFileList(list);
    }

    const [asyncUploadFile, uploadFilePending] = useAsync(orderUploadFileApi);
    
    const handleSave = async () => {
        if(cm_isEmpty(fileList)) {
            cm_swAlert(t("alert_order_scan_file"));
            return;
        }

        let fileKeys = [];

        let formData = new FormData();
        formData.append("CO_SEQ", CO_SEQ);
        formData.append("OD_SEQ", OD_SEQ);
        formData.append("OD_STATUS", "U");

        for(let file of fileList){
            fileKeys = [...fileKeys, { fileName: file.name, fileRef: uploadFileType }]
            formData.append("file", file);
        }
        formData.append("fileKey", JSON.stringify(fileKeys));

        const response = await asyncUploadFile(formData);
        if(!resApiCheck(response)) return;
        
        handleUploadFile(uploadFileType);

        if(OD_MAP.OD_STATUS === "R"){
            const confirm = await cm_swConfirm(t("confirm_order_registration"), t("confirm_common_registration"), "info", t("common_yes"), t("common_no"));

            if(confirm){
                handleOrderStatus("M")
            }
        }else{
            cm_swAlert(t("common_regiSuccess"), "success", t("common_check"));
        }
        
        handleOrderMap(OD_SEQ);
    }

    return (
        <div className="modal fade" id="ModalAsset" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{text} {t("common_regi")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => handleUploadFile(uploadFileType)}></button>
                    </div>
                    <div className="modal-body">
                        <DropFileInput onChangeFile={(files) => onFileChange(files)} fileList={fileList} setFileList={setFileList} accept={accepts}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue2" disabled={uploadFilePending} onClick={handleSave}>{t("common_regi")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
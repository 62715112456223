import { useEffect, useState } from "react";
import { produce } from "immer";
import useAsync from "hooks/useAsync";
import { useStore } from "contexts/Store";
import useTranslate from "components/language/useTranslate";
import { configListApi } from "api/configApi";
import { userOptionWriteApi } from "api/userApi";
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function UserImplantModelModal({ isOpen, handleModal, selectObj, reLoad }) {
    const store = useStore();
    const {US_SEQ} = store.US_MAP;

    const init = {
        UO_SEQ : 0,
        US_SEQ : US_SEQ,
        UO_NAME : "",
        UO_TYPE : "I",
        UO_OPTION : {
            IMP_MAN : "",
            IMP_TYPE : "",
            IMP_SCREW : "",
            IMP_SIZE : "",
        }
    };

    const [implantModel, setImplantModel] = useState(selectObj || init);
    const [implantManList, setImplantManList] = useState(null);
    const [implantTypeList, setImplantTypeList] = useState(null);
    const [implantScrewList, setImplantScrewList] = useState(null);
    const [implantSizeList, setImplantSizeList] = useState(null);

    const [asyncUserOptionWrite, userOptionWritePending] = useAsync(userOptionWriteApi);
    const [asyncConfigList] = useAsync(configListApi);
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
    },[])

    const handleLoad = async () => {
        const resMan = await asyncConfigList({CF_TYPE : "IMP_MAN"});
        if(!resApiCheck(resMan)) return;
        if(resMan.data.LIST.length > 0) {
            setImplantManList(resMan.data.LIST);
        }

        const resType = await asyncConfigList({CF_TYPE : "IMP_TYPE"});
        if(!resApiCheck(resType)) return;
        if(resType.data.LIST.length > 0) {
            setImplantTypeList(resType.data.LIST);
        }
        const resScrew = await asyncConfigList({CF_TYPE : "IMP_SCREW"});
        if(!resApiCheck(resScrew)) return;
        if(resScrew.data.LIST.length > 0) {
            setImplantScrewList(resScrew.data.LIST);
        }
        const resSize = await asyncConfigList({CF_TYPE : "IMP_Size"});
        if(!resApiCheck(resSize)) return;
        if(resSize.data.LIST.length > 0) {
            setImplantSizeList(resSize.data.LIST);
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target;

        setImplantModel(produce(prevValue => {
            let imName = "";
            let {man, type, screw, size} = "";

            if(name === "IMP_MAN"){
                prevValue.UO_OPTION.IMP_TYPE = "";
            }
            prevValue.UO_OPTION[name] = value;

            man = implantManList.find(x => x.CF_CODE === prevValue.UO_OPTION.IMP_MAN);
            type = implantTypeList.find(x => x.CF_CODE === prevValue.UO_OPTION.IMP_TYPE);
            screw = implantScrewList.find(x => x.CF_CODE === prevValue.UO_OPTION.IMP_SCREW);
            size = implantSizeList.find(x => x.CF_CODE === prevValue.UO_OPTION.IMP_SIZE);

            if(man) imName += man.CF_TITLE;
            if(type) imName += " " + type.CF_TITLE;
            if(screw) imName += " " + screw.CF_TITLE;
            if(size) imName += " " +size.CF_TITLE;
            
            prevValue.UO_NAME = imName;
        }));
    }   

    const handleSave = async () => {
        if(!saveValidate()) return;

        let saveData = {...implantModel};
        saveData.UO_OPTION = JSON.stringify(saveData.UO_OPTION);
        
        const response = await asyncUserOptionWrite(saveData);
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "error", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            reLoad();
            handleModal();
        }
    }

    function saveValidate(){
        const {IMP_MAN, IMP_TYPE, IMP_SCREW}  = implantModel.UO_OPTION;

        let result = true;

        if(cm_isEmpty(IMP_MAN)){
            cm_swAlert(t("alert_order_content_3"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(IMP_TYPE)){
            cm_swAlert(t("alert_order_content_4"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(IMP_SCREW)){
            cm_swAlert(t("alert_order_content_5"),  "warning", t("common_check"));
            result = false;
        }

        return result;
    }

    return(
        <div className="modal fade" id="ModalAdd" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title">{t("common_implModelRegi")}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">{t("common_implModelName")}</h4>
                        <div className="inp-box">
                        <input type="text" className="inp block" name="UO_NAME" value={implantModel.UO_NAME} disabled placeholder={t("alert_op_content_3")}/>
                        </div>
                        <h4 className="form-tit">{t("common_mf")}</h4>
                        <div className="inp-box">
                            <select className="select block" key="IMP_MAN" name="IMP_MAN" value={implantModel.UO_OPTION.IMP_MAN} onChange={handleChange}>
                                <option value="">{t("common_choice")}</option>
                                {implantManList &&
                                    implantManList.map((man) =>{
                                        return(
                                            <option key={`man_${man.CF_CODE}`} value={man.CF_CODE}>{man.CF_TITLE}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <h4 className="form-tit">{t("common_type")}</h4>
                        <div className="inp-box">
                            <select className="select block" key="IMP_TYPE" name="IMP_TYPE" value={implantModel.UO_OPTION.IMP_TYPE} onChange={handleChange}>
                                <option value="">{t("common_choice")}</option>
                                {implantTypeList &&
                                    implantTypeList.filter(x => x.CF_VAL1 === implantModel.UO_OPTION.IMP_MAN).map((type) =>{
                                        return(
                                            <option key={`type_${type.CF_CODE}`} value={type.CF_CODE}>{type.CF_TITLE}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <h4 className="form-tit">{t("common_screw")}</h4>
                        <div className="inp-box">
                            <select className="select block" key="IMP_SCREW" name="IMP_SCREW" value={implantModel.UO_OPTION.IMP_SCREW} onChange={handleChange}>
                                <option value="">{t("common_choice")}</option>
                                {implantScrewList &&
                                    implantScrewList.map((screw) =>{
                                        return(
                                            <option key={`screw_${screw.CF_CODE}`} value={screw.CF_CODE}>{screw.CF_TITLE}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <h4 className="form-tit">{t("common_size")}</h4>
                        <div className="inp-box">
                            <select className="select block" key="IMP_SIZE" name="IMP_SIZE" value={implantModel.UO_OPTION.IMP_SIZE} onChange={handleChange}>
                                <option value="">{t("common_choice")}</option>
                                {implantSizeList &&
                                    implantSizeList.map((size) =>{
                                        return(
                                            <option key={`size_${size.CF_CODE}`} value={size.CF_CODE}>{size.CF_TITLE}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-fill-blue" disabled={userOptionWritePending} onClick={handleSave}>{selectObj? t("common_edit") : t("common_add")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};
import useTranslate from "components/language/useTranslate";
import { ORDER_STATUS } from "config";
import { cm_findData } from "utils/common";

export default function DoListSection({ODW_LIST}){
    const orderStatus = ORDER_STATUS();
    const t = useTranslate();

    return(
        <div className="section">
            <div className="head">
                <h3 className="h3">{t("home_orderList")}</h3>
            </div>
            <div className="body">
                <div className="table1">
                    <table>
                        <thead>
                            <tr>
                                <th>{t("common_clinicName")}</th>
                                <th>{t("home_patientInfo")}</th>
                                <th>{t("home_designer")}</th>
                                <th>{t("common_status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ODW_LIST.map((order, index) => {
                                    let OD_STATUS_TEXT = cm_findData(orderStatus, "key", order.OD_STATUS, "text");
                                    
                                    return(
                                        <tr key={index}>
                                            <td>{order.CO_NAME}</td>
                                            <td>{order.OD_NAME}</td>
                                            <td>{order.DESINGER_NAME}</td>
                                            <td>{OD_STATUS_TEXT}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
import { API } from 'config';
import { axio } from 'utils/common';

export async function holidayListApi(data){
    return await axio(API.HOLIDAY_LIST, "POST", data);
}

export async function holidayWriteApi(data){
    return await axio(API.HOLIDAY_WRITE, "POST", data);
}

export async function holidayDeleteApi(data){
    return await axio(API.HOLIDAY_DELETE, "POST", data);
}
import { axio } from 'utils/common';
import { API } from 'config';

// PRODUCT
export async function productListApi(data){
    return await axio(API.PRODUCT_LIST, "POST", data);
}

export async function productReadApi(data){
    return await axio(API.PRODUCT_READ, "POST", data);
}

export async function productWriteApi(data){
    return await axio(API.PRODUCT_WRITE, "POST", data);
}

export async function productDeleteApi(data){
    return await axio(API.PRODUCT_DELETE, "POST", data);
}





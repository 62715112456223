import { companyListApi } from "api/companyApi";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import CompanyDetailMng from "components/manage/CompanyDetailMng";
import CompanyListMng from "components/manage/CompanyListMng";
import CompanySearch from "components/manage/CompanySearch";
import { _CO_TYPE_CLINIC } from "config";
import useAsync from "hooks/useAsync";
import $ from "jquery";
import { useEffect, useState } from "react";
import { cm_isEmpty, resApiCheck } from "utils/common";

export default function CompanyMng(){
    const [companyType, setCompanyType] = useState(_CO_TYPE_CLINIC);
    const [companyListCount, setCompanyListCount] = useState(0);
    const [companyList, setCompanyList] = useState([]);
    const [selectCompany, setSelectCompany] = useState(null);
    const [asyncCompanyList] = useAsync(companyListApi);

    const t = useTranslate();

    const handleCompanyType = (e) => {
        setCompanyType(e.target.value);
        setSelectCompany(null);
    }

    const limit = 10;
    const [page, setPage] = useState(1);
    const [reLoad, setReLoad] = useState(false);

    const handleReLoad = () => setReLoad(prevValue => !prevValue);

    useEffect(() => {
        handleLoadCompanyList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[companyType, page, reLoad])

    async function handleLoadCompanyList() {
        const searchInput = cm_isEmpty($(".inp").val()) ? "" : $(".inp").val();

        const companyList = await asyncCompanyList({
            CO_TYPE : companyType,
            CO_NAME : searchInput,
            PAGE_ROWS : limit,
            PAGE_INDEX : page,
            CO_STATUS : "A",
        });
        if(!resApiCheck(companyList)) return;

        setCompanyListCount(companyList.data.LIST_COUNT);
        if(companyList.data.LIST_COUNT > 0){
            setCompanyList(companyList.data.LIST);
        }else{
            setCompanyList([]);
        }
        setSelectCompany(null);
    }

    function selectCompanyInfo(CO_SEQ){
        if(!cm_isEmpty(CO_SEQ)){
            let findCompany = companyList.find(x => x.CO_SEQ === CO_SEQ);
            if(!cm_isEmpty(findCompany)){
                setSelectCompany({CO_MAP : findCompany});
            }
        }else{
            setSelectCompany(null);
        }
    }

    return(
        <div className="admin-content">
            <div className="title-head">
                <h3>{t("memberMg_userMg")}</h3>
            </div>
            <CompanySearch handleCompanyType={handleCompanyType} handleReLoad={handleReLoad}/>
            
            {!selectCompany ?
                <>
                    <CompanyListMng companyType={companyType} companyListCount={companyListCount} companyList={companyList} onClickDetail={selectCompanyInfo} handleReLoad={handleReLoad}/>
                    <Pagination total={companyListCount} limit={limit} page={page} setPage={setPage}/>
                </>
                :
                <CompanyDetailMng companyType={companyType} selectCompany={selectCompany} setSelectCompany={setSelectCompany} handleReLoad={handleReLoad}/>
            }
        </div>
    )
}
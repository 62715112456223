import { companyProductListApi, companyProductWriteApi } from "api/companyApi";
import { configListApi } from "api/configApi";
import useTranslate from "components/language/useTranslate";
import { _PRODUCT_MINIMU_COST } from "config";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_isPositiveNum, cm_numComma, cm_removeNumComma, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function CompanyProductMng({selectCompany}){
    const [productTypeList, setProductTypeList] = useState(null);
    const [companyProductList, setCompanyProductList] = useState(null);
    
    //API
    const [asyncConfigList] = useAsync(configListApi)
    const [asyncCompanyProductList] = useAsync(companyProductListApi);
    const [asyncCompanyProductWrite, companyProductWritePending] = useAsync(companyProductWriteApi);
    
    const [reLoad, setReLoad] = useState(false);

    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);

    const handleLoad = async () => {
        // ProductType List
        const resProductTypeList = await asyncConfigList({CF_TYPE_START : "PR_TYPE"});
        if(!resApiCheck(resProductTypeList)) return;
        if(resProductTypeList.data.LIST.length > 0){
            setProductTypeList(resProductTypeList.data.LIST);
        }

        // Company Product List
        const resCompanyProductList = await asyncCompanyProductList({CO_SEQ : selectCompany.CO_MAP.CO_SEQ});
        if(!resApiCheck(resCompanyProductList)) return;
        if(resCompanyProductList.data.LIST.length > 0){
            setCompanyProductList(resCompanyProductList.data.LIST);
        }else{
            setCompanyProductList(null);
        }
    }

    const handleReLoad = () => setReLoad((prevValue) => !prevValue);

    // CHANGE FUNCTION
    const handleChangeSalePrice = (e) =>{
        const PR_SEQ = Number(e.target.name);
        const CR_PRICE = cm_removeNumComma(e.target.value);

        setCompanyProductList(produce(prevValue => {
            if(!cm_isPositiveNum(CR_PRICE, true, t("alert_num_positive"))) return;
            const product = prevValue.find(x => x.PR_SEQ === PR_SEQ);
            if(product){
                product.CR_PRICE = Number(CR_PRICE);
            }
        }));
    }

    const handleChangeSalePonticPrice = (e) =>{
        const PR_SEQ = Number(e.target.name);
        const CR_PNPRICE = cm_removeNumComma(e.target.value);

        setCompanyProductList(produce(prevValue => {
            if(!cm_isPositiveNum(CR_PNPRICE, true, t("alert_num_positive"))) return;
            const product = prevValue.find(x => x.PR_SEQ === PR_SEQ);
            if(product){
                product.CR_PNPRICE = Number(CR_PNPRICE);
            }
        }));
    }

    const handleChangeSaleStatus = (PR_SEQ) => {
        setCompanyProductList(produce(prevValue => {
            const product = prevValue.find(x => x.PR_SEQ === PR_SEQ);
            if(product){
                product.CR_SALES = (product.CR_SALES === "Y" ? "N" : "Y");
            }
        }));
    }

    const handleDisableSalePrice = (PR_SEQ) => {
        setCompanyProductList(produce(prevValue => {
            const product = prevValue.find(x => x.PR_SEQ === PR_SEQ);
            if(product){
                product.disabled = !product.disabled;
            }
        }));
    }

    // SAVE FUNCTION
    async function saveValidate(){
        let text = "";

        for(let product of companyProductList){
            if(Number(product.CR_PRICE) < _PRODUCT_MINIMU_COST ){
                text =`${_PRODUCT_MINIMU_COST} ${SYMBOL}. ${t("alert_belowPrice")}`;
                break;
            }
        }

        const response = await cm_swConfirm(t("confirm_common_save"), text, "warning", t("common_yes"), t("common_no"));

        if(response){
            handleSave();
        }
    }

    const handleSave = async () => {
        const response = await asyncCompanyProductWrite({
            CO_SEQ : selectCompany.CO_MAP.CO_SEQ,
            list : companyProductList
        });
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "info", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleReLoad();
        }
    }
    
    return (
        <>
            <div className="table1">
                <table>
                    <colgroup>
                        <col style={{width: "20%"}} />
                        <col style={{width: "20%"}} />
                        <col style={{width: "20%"}} />
                        <col style={{width: "20%"}} />
                        <col style={{width: "20%"}} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common_category")}</th>
                            <th className="text-left">{t("common_pstheticType")}</th>
                            <th>{t("common_division")}</th>
                            <th>{t("common_cost")} ({SYMBOL})</th>
                            <th>{`${t("common_cost")} Pontic`} ({SYMBOL})</th>
                            <th>{t("common_status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productTypeList && companyProductList ?
                            companyProductList.map((product) => {
                                let productTypeName = productTypeList.find(x => x.CF_CODE === product.PR_TYPE).CF_TITLE || "";
                                let PR_SEQ = product.PR_SEQ;
                                
                                return(
                                    <tr key={PR_SEQ}>
                                        <td>{productTypeName}</td>
                                        <td className="text-left">{product.PR_DESC}</td>
                                        <td>{product.PR_TYPE === "P" ? t("common_print") : t("memberMg_general")}</td>
                                        <td>
                                            <div className="inq-price">
                                                <strong>
                                                    <input 
                                                        type="text"
                                                        name={PR_SEQ}
                                                        value={cm_numComma(product.CR_PRICE)}
                                                        onChange={handleChangeSalePrice} 
                                                        disabled={product.disabled}
                                                        maxLength={7}
                                                    />
                                                </strong>
                                                <button onClick={() => handleDisableSalePrice(PR_SEQ)}><i className="xi-pen"></i></button>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inq-price">
                                                <strong>
                                                    <input 
                                                        type="text"
                                                        name={PR_SEQ}
                                                        value={cm_numComma(product.CR_PNPRICE)}
                                                        onChange={handleChangeSalePonticPrice} 
                                                        disabled={product.disabled}
                                                        maxLength={7}
                                                    />
                                                </strong>
                                                <button onClick={() => handleDisableSalePrice(PR_SEQ)}><i className="xi-pen"></i></button>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inq-stat">
                                                <span>{product.CR_SALES === "Y" ? t("common_use") : <em>{t("common_notUse")}</em>}</span>
                                                <button onClick={() => handleChangeSaleStatus(PR_SEQ)}><i className="xi-renew"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                )        
                            })
                            :
                            <tr><td colSpan={5}>{t("order_searchOrder_content_1")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex gap10 justify-content-center">
                <button className="btn btn-fill-blue2 btn-round" disabled={companyProductWritePending} onClick={saveValidate}>{t("common_save")}</button>
            </div>
        </>
    )
}
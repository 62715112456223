import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import { cm_isEmpty, sortbyToothPosition } from "utils/common";

export default function OrderSections(props) {
    const store = useStore();
    const {US_MAP} = store;
    const t = useTranslate();
    
    const {OL_LIST, deleteSection, remake, remakeTeeth, handleRemakeTeeth, splitSection, handleSplitSection} = props;

    const OL_LIST_FT = US_MAP.CO_TYPE === _CO_TYPE_LAB ? [...OL_LIST.filter(x => x.PR_CODE !== "PLA-Cr")] : [...OL_LIST];

    const groupByProductCode = () => {
        const groupedData = [];

        sortbyToothPosition(OL_LIST_FT).forEach((line) => {
            const PR_CODE = line.PR_CODE;
            const PR_TYPE = line.PR_TYPE;
            const OL_SHADE = line.OL_SHADE;
    
            const existingGroupIndex = groupedData.findIndex(group => group.PR_CODE === PR_CODE);
    
            if (existingGroupIndex !== -1) {
                const existingShadeIndex = groupedData[existingGroupIndex].shades.findIndex(shade => shade.OL_SHADE === OL_SHADE);
                if (existingShadeIndex !== -1) {
                    groupedData[existingGroupIndex].shades[existingShadeIndex].teeth.push(line);
                } else {
                    groupedData[existingGroupIndex].shades.push({ OL_SHADE, teeth: [line] });
                }
            } else {
                groupedData.push({ PR_TYPE, PR_CODE, shades: [{ OL_SHADE, teeth: [line] }] });
            }
        });

        return groupedData;
    };

    return (
        <ul>
            {
                groupByProductCode().map((item) => {
                    const splitActiveClass = splitSection && splitSection.filter(x => x === item.PR_CODE).length > 0;

                    return (
                        <li key={`section_${item.PR_CODE}`} className={splitActiveClass ? "active" : null} onClick={handleSplitSection && (() => handleSplitSection(item.PR_CODE))}>
                            <div className={`tooth-list-item c${item.PR_TYPE}`}>
                                <h4>{item.PR_CODE}</h4>
                                <div className="shade-list">
                                    <ul>
                                        {item.shades.map((shade) => {
                                            const number = shade.teeth.map((x) => {
                                                let toothName = x.OL_TOOTH;

                                                if(x.OL_PONTIC === "Y") toothName = "X";
                                                if(toothName === "u"){
                                                    toothName = t("common_maxillary_tooths");
                                                }else if(toothName === "l"){
                                                    toothName = t("common_mandible_tooths");
                                                }

                                                return toothName;
                                            }).join(", ");
                                            const shades = !cm_isEmpty(shade.OL_SHADE) ? shade.OL_SHADE.split("/") : [];
                                            let shadeText = "";
                                            if(shades[0] === shades[1]){
                                                shadeText = shades[0];
                                            }else {
                                                shadeText = shades.join("/");
                                            }

                                            return (
                                                <li key={`shade_${shade.OL_SHADE}`}>
                                                    {number}{(shadeText !== "/" && !cm_isEmpty(shadeText)) ? ` (${shadeText})` : null}
                                                </li>
                                            )
                                        })}
                                    </ul>

                                </div>
                            </div>
                            {deleteSection && 
                                <button className="btn-del" style={{display:"block"}} onClick={() => deleteSection(item.PR_CODE)}>
                                    <span className="hidden">{t("myPage_del")}</span>
                                </button>
                            }
                            {remake && 
                                <ul className="mt10" style={{display:"flex"}}>
                                    {OL_LIST.filter(x => x.PR_CODE === item.PR_CODE).map((tooth) => {
                                        let currentProduct = remakeTeeth.find(x => x.product === item.PR_CODE);
                                        let currentClass = (currentProduct && currentProduct.num.includes(tooth.OL_TOOTH)) ? "tooth-list-item active" : "tooth-list-item";
                                        
                                        return (
                                            <li key={`tn_${tooth.OL_TOOTH}`} onClick={() => handleRemakeTeeth(item.PR_CODE, tooth.OL_TOOTH)} style={{flex:"1", marginTop:0}}>
                                                <div className={currentClass}>{tooth.OL_TOOTH}</div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}
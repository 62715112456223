import useTranslate from "components/language/useTranslate";
import AddCompanyModal from "components/modal/AddCompanyModal";
import { _CO_TYPE_CENTER } from "config";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_formatMobile } from "utils/common";

export default function CompanyListMng({companyType, companyListCount, companyList, onClickDetail, handleReLoad}){
    const [isOpen, setIsOpen] = useState(false);
    const t = useTranslate();
    
    const handleModalCompanyWrite = () => setIsOpen((prevValue) => !prevValue);

    return(
        <>
            {companyType !== _CO_TYPE_CENTER &&
                <div className="board-head" style={{float : "right"}}>
                    <div className="btns">
                        <button className="btn btn-fill-blue2 btn-round btn-s" onClick={handleModalCompanyWrite}>
                            <i className="xi-plus"></i>{t("common_addregi")}
                        </button>
                    </div>
                </div>
            }
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "20%"}}/>
                        <col style={{width: "40%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common_companyName")}</th>
                            <th>{t("common_RepresentativeName")}</th>
                            <th>{t("common_rePresentativePhone")}</th>
                            <th>{t("common_address")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {companyListCount > 0 ?
                            companyList.map((company) =>{
                                const {CO_SEQ, CO_NAME, CO_OWNER, CO_TEL, CO_ADDRESS} = company;

                                return(
                                    <tr key={CO_SEQ} onClick={() => onClickDetail(CO_SEQ)}>
                                        <td>{CO_NAME}</td>
                                        <td>{CO_OWNER}</td>
                                        <td>{cm_formatMobile(CO_TEL)}</td>
                                        <td>{CO_ADDRESS}</td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr><td colSpan={5}>{t("order_searchOrder_content_1")}</td></tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
            <Modal show={isOpen} onHide={handleModalCompanyWrite} centered>
                <AddCompanyModal isOpen={isOpen} handleModal={handleModalCompanyWrite} companyType={companyType} handleReLoad={handleReLoad}/>
            </Modal>
        </>
    )
}
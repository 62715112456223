import useAsync from "hooks/useAsync";
import useTranslate from "components/language/useTranslate";
import OrderSections from "components/order/OrderSections";
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";
import { orderSplitApi } from "api/orderApi";
import { useState } from "react";

export default function OrderSplitModal({isOpen, handleModal, orderMap, handleOrderList, handleOrderMap}){
    const t = useTranslate();

    const {OL_LIST, OD_MAP} = orderMap;
    const OD_BRIDGE = JSON.parse(OD_MAP.OD_BRIDGE);

    const [asyncOrderSplit, orderSplitPending] = useAsync(orderSplitApi);
    const [splitSection, setSplitSection] = useState([]);

    const orderSplit = async () => {
        if(cm_isEmpty(splitSection)){
            cm_swAlert(t("alert_order_prosthetics_choice"), "warning", t("common_check"));
            return;
        }

        const newLine = OL_LIST.filter(x => splitSection.some(item => item === x.PR_CODE));
        const orgLine = OL_LIST.filter(x => !splitSection.some(item => item === x.PR_CODE));
        
        const OD_BRIDGE_ORG = handleSplitBridge(OD_BRIDGE, orgLine);
        const OD_BRIDGE_NEW = handleSplitBridge(OD_BRIDGE, newLine);

        const resSplit = await asyncOrderSplit({
            OD_SEQ: OD_MAP.OD_SEQ, 
            OL_LIST: JSON.stringify(newLine),
            OD_BRIDGE_ORG: JSON.stringify(OD_BRIDGE_ORG),
            OD_BRIDGE_NEW: JSON.stringify(OD_BRIDGE_NEW),
        });
        if(!resApiCheck(resSplit)) return;

        handleModal();
        handleOrderList();
        handleOrderMap(resSplit.data.OD_SEQ);
    }

    function handleSplitBridge(OD_BRIDGE, orderLine){
        const bridges = OD_BRIDGE.filter(bridge => {
            const bridgeParts = bridge.split("_");
            return bridgeParts.every(num => orderLine.some(line => line.OL_TOOTH === num));
        });

        return bridges
    }

    const handleSplitSection = (product) => {
        const include = splitSection.includes(product);

        if(include){
            const currentSection  = [...splitSection.filter(item => item !== product)];
            setSplitSection(currentSection);
        }else{
            const currentSection = [...splitSection, product];
    
            setSplitSection(currentSection);
        }
    }

    return(
        <div className="modal fade" id="Modal1" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"1000px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("order_orderSplit")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>

                    <div className="modal-body">
                        <div className="mb20 tooth-wrap2 ">
                            <div className="tooth-list3">
                                <OrderSections OL_LIST={OL_LIST} splitSection={splitSection} handleSplitSection={handleSplitSection}/>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-gray" data-bs-dismiss="modal" onClick={handleModal} disabled={orderSplitPending}>{t("common_cancel")}</button>
                        <button type="button" className="btn btn-fill-blue" onClick={orderSplit} disabled={orderSplitPending}>{t("common_check")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
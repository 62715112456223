import { useState } from "react";
import useAsync from "hooks/useAsync";
import { deviceWriteApi } from "api/deviceApi";
import { cm_isEmpty, resApiCheck, cm_swAlert, cm_numComma, cm_removeNumComma, cm_isPositiveNum } from "utils/common";
import { DeviceSelectOption } from "pages/DeviceMng";
import useTranslate from "components/language/useTranslate";

export default function DeviceModal({isOpen, handleModal, selectDevice, configList, handleSearch}){
    const [addDevice, setAddDevice] = useState(selectDevice || {
        DV_SEQ : 0,
        DV_TYPE : "",
        DV_MAN : "",
        DV_MODEL : "",
        DV_NAME : "",
        DV_COST : 0
    });

    const [asyncDeviceWrite, deviceWritePending] = useAsync(deviceWriteApi);
    const t = useTranslate();

    const handleChangeAddDevice = (e) => {
        // 상위 항목 변경 하위 항목 리셋
        let {name, value} = e.target;

        let newSearch = {...addDevice};

        if(name === "DV_TYPE"){
            newSearch = {...addDevice, [name] : value};
            newSearch.DV_MAN = "";
            newSearch.DV_MODEL = "";
        }else if(name === "DV_MAN"){
            newSearch = {...addDevice, [name] : value};
            newSearch.DV_MODEL = "";
        }else if(name === "DV_COST"){
            value = Number(cm_removeNumComma(value));
            if(!cm_isPositiveNum(value, false)) return;
            
            newSearch = {...addDevice, [name] : value};
        }else{
            newSearch = {...addDevice, [name] : value};
        }

        setAddDevice(newSearch);
    };

    const handleSave = async () => {
        if(!saveValidate()) return;
        
        const response = await asyncDeviceWrite(addDevice);
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleSearch();
            handleModal();
        }
    }

    function saveValidate(){
        const {DV_TYPE, DV_MAN, DV_MODEL, DV_NAME, DV_COST} = addDevice;

        let result = true;

        if(cm_isEmpty(DV_TYPE)){
            cm_swAlert(t("alert_choice_equipType"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(DV_MAN)){
            cm_swAlert(t("alert_choice_mf"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(DV_MODEL)){
            cm_swAlert(t("alert_choice_model"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(DV_NAME)){
            cm_swAlert(t("alert_writeProdName"),  "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(DV_COST)){
            cm_swAlert(t("alert_writeCost"),  "warning", t("common_check"));
            result = false;
        }

        return result;
    }

    return(
        <>
            <div className="modal fade" id="ModalAsset" style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">{t("common_addDevice")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                        </div>
                        <div className="modal-body">
                            <h4 className="form-tit">{t("device_equipType")}</h4>
                            <div className="inp-box">
                                <DeviceSelectOption deviceConfigList={configList} type={"DV_TYPE"} searchFilter={addDevice} onChange={handleChangeAddDevice} />
                            </div>
                            <h4 className="form-tit">{t("common_mf")}</h4>
                            <div className="inp-box">
                                <DeviceSelectOption deviceConfigList={configList} type={"DV_MAN"} searchFilter={addDevice} onChange={handleChangeAddDevice} />
                            </div>
                            <h4 className="form-tit">{t("common_prodModel")}</h4>
                            <div className="inp-box">
                                <DeviceSelectOption deviceConfigList={configList} type={"DV_MODEL"} searchFilter={addDevice} onChange={handleChangeAddDevice} />
                            </div>
                            <h4 className="form-tit">{t("common_prodName")}</h4>
                            <div className="inp-box">
                                <input type="text" className="inp block" name="DV_NAME" value={addDevice.DV_NAME} onChange={handleChangeAddDevice} placeholder={t("device_prodNamePh")}/>
                            </div>
                            <h4 className="form-tit">{t("common_cost")}</h4>
                            <div className="inp-box">
                                <input type="text" className="inp block" name="DV_COST" value={cm_numComma(addDevice.DV_COST)} onChange={handleChangeAddDevice} placeholder={t("device_amountPh")}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-fill-blue2" disabled={deviceWritePending} onClick={handleSave}>{t("common_save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import { API } from 'config';
import { axio } from 'utils/common';

// USER
export async function userListApi(data){
    return await axio(API.USER_LIST, "POST", data);
}

export async function userReadApi(data){
    return await axio(API.USER_READ, "POST", data);
}

export async function userWriteApi(data){
    return await axio(API.USER_WRITE, "POST", data);
}

export async function userUpdateApi(data){
    return await axio(API.USER_UPDATE, "POST", data);
}

export async function userUpdatePwdApi(data){
    return await axio(API.USER_UPDATE_PWD, "POST", data);
}

export async function userValidatePwdApi(data){
    return await axio(API.USER_VALIDATE_PWD, "POST", data);
}

export async function userDeleteApi(data){
    return await axio(API.USER_DELETE, "POST", data);
}

export async function userOptionListApi(data){
    return await axio(API.USER_OPTION_LIST, "POST", data);
}

export async function userOptionWriteApi(data){
    return await axio(API.USER_OPTION_WRITE, "POST", data);
}

export async function userOptionDeleteApi(data){
    return await axio(API.USER_OPTION_DELETE, "POST", data);
}



import { useEffect, useState } from "react";
import useAsync from "hooks/useAsync";
import useTranslate from "components/language/useTranslate";
import AddDeviceModal from "components/modal/AddDeviceModal";
import { Modal } from "react-bootstrap";
import { planReadApi } from "api/planApi";
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";
import { companyDeviceListApi, companyPlanListApi } from "api/companyApi";
import { COMPANY_DEVICE_STATUS } from "config";

export default function CompanyDeviceMng({selectCompany}){
    const [companyDeviceList, setCompanyDeviceList] = useState([]);
    const [companyPlanList, setCompanyPlanList] = useState(null);
    const [planDeviceList, setPlanDeviceList] = useState(null);
    const [selectDevice, setSelectDevice] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const companyDeviceStatus = COMPANY_DEVICE_STATUS();
    const t = useTranslate();

    //API
    const [asyncCompanyPlanList] = useAsync(companyPlanListApi)
    const [asyncPlanRead] = useAsync(planReadApi);
    const [asyncCompanyDeviceList] = useAsync(companyDeviceListApi);

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleLoad = async () => {
        // Company Plan, PlanDevice List
        const response = await asyncCompanyPlanList({CO_SEQ : selectCompany.CO_MAP.CO_SEQ});
        if(!resApiCheck) return;
        if(response.data.LIST.length > 0){
            let arrPlanDevice = [];
            for(let companyPlan of response.data.LIST){
                let resPlanRead = await asyncPlanRead({PL_SEQ: companyPlan.PL_SEQ});
                if(!resApiCheck(resPlanRead)) return;
                
                arrPlanDevice.push({
                    PL_SEQ: companyPlan.PL_SEQ,
                    PL_NAME : companyPlan.PL_NAME,
                    CP_SDATE : companyPlan.CP_SDATE,
                    CP_EDATE : companyPlan.CP_EDATE,
                    PD_LIST: resPlanRead.data.PD_LIST
                });
            }
            setPlanDeviceList(arrPlanDevice);
            setCompanyPlanList(response.data.LIST);
        }else{
            setPlanDeviceList([]);
        }

        // Company Device List
        const resCompanyDeviceList = await asyncCompanyDeviceList({CO_SEQ : selectCompany.CO_MAP.CO_SEQ});
        if(!resApiCheck) return;
        if(resCompanyDeviceList.data.LIST.length > 0){
            setCompanyDeviceList(resCompanyDeviceList.data.LIST);
        }
    }

    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    //Company device Add || Modify
    const handleModify = (e) => {
        const {name, value} = e.target;

        if(name === "modify"){
            const find = companyDeviceList.find(x => x.CD_SEQ === Number(value));
            setSelectDevice(find);
        }else{
            if(!planDeviceExist()) return;
            setSelectDevice(null);
        }

        handleModal();
    }

    function planDeviceExist(){
        let result = true;
        let text = "";

        if(cm_isEmpty(companyPlanList)){
            result = false;
            text = t("alert_planAdd");
        }else if(cm_isEmpty(planDeviceList)){
            result = false;
            text = t("alert_planNoDevice")
        }

        if(!result){
            cm_swAlert(text, "info", t("common_check"));
        }

        return result;
    }

    return (
        <>
            <div className="board-head" style={{float : "right"}}>
                <div className="btns">
                    <button type="button" className="btn btn-fill-blue2 btn-round btn-s" name="add" onClick={handleModify}>{t("common_addDevice")}</button>
                </div>
            </div>
            <div className="table1">
                <table>
                    <colgroup>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "15%"}}/>
                        <col style={{width: "10%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("device_equipType")}</th>
                            <th>{t("common_mf")}</th>
                            <th>{t("common_model")}</th>
                            <th>S/N</th>
                            <th>{t("common_period")}</th>
                            <th>{t("common_status")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(companyDeviceList) ?
                            companyDeviceList.map((CD_MAP, index) => {
                                let period = CD_MAP.CD_SDATE + " ~ " + CD_MAP.CD_EDATE;
                                let status = "";
                                let findStatus = companyDeviceStatus.find(x => x.status === CD_MAP.CD_STATUS);
                                if(!cm_isEmpty(findStatus)){
                                    status = findStatus.text;
                                }

                                return(
                                    <tr key={"CP_" + CD_MAP.CD_SEQ + "_" +  index}>
                                        <td>{CD_MAP.DV_TYPE}</td>
                                        <td>{CD_MAP.CF_TITLE}</td>
                                        <td>{CD_MAP.DV_NAME}</td>
                                        <td>{CD_MAP.CD_SN}</td>
                                        <td>{period}</td>
                                        <td>{status}</td>
                                        <td>
                                            <button 
                                                type="button" 
                                                className="btn btn-round btn-fill-gray btn-s" 
                                                name="modify"
                                                value={CD_MAP.CD_SEQ}
                                                onClick={handleModify}
                                            >{t("common_edit")}
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={7} align='center'>{t("alert_noDquipment")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>

            <Modal show={isOpen} onHide={handleModal} centered>
                <AddDeviceModal 
                    isOpen={isOpen} 
                    handleModal={handleModal} 
                    selectDevice={selectDevice}
                    planDeviceList={planDeviceList}
                    companyDeviceList={companyDeviceList}
                    selectCompany={selectCompany}
                    handleLoad={handleLoad}
                />
            </Modal>
        </>
    )
}
import { useEffect, useState } from "react";
import { encryptUrlApi } from "api/commonApi";
import { resApiCheck } from "utils/common";
import useAsync from "hooks/useAsync";
import QRCode from "qrcode.react";

export default function QRCodes({option, children}){
    const [encUrl, setEncUrl] = useState(null);
    const [asyncEncryptUrl] = useAsync(encryptUrlApi);

    useEffect(() => {
        const handleLoad = async () => {
            const response = await asyncEncryptUrl(option);
            if(!resApiCheck(response)) return;
            setEncUrl(response.data.ENC_URL);
        }
        
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            {encUrl && <QRCode value={encUrl} />}
            {children}
        </>
    )
}
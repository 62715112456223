import useAsync from "hooks/useAsync";
import { useMyInfo, useSetMyInfo } from "contexts/MyInfoContext";
import useTranslate from 'components/language/useTranslate';
import { cm_numComma, cm_swAlert, resApiCheck } from "utils/common";
import { produce } from "immer";
import { companyProductWriteApi } from "api/companyApi";

export default function CompanyProduct(){
    const t = useTranslate();
    const myInfo = useMyInfo();
    const setMyInfo = useSetMyInfo();

    //API
    const [asyncCompanyProductWrite, companyProductPending] = useAsync(companyProductWriteApi);

    const {CO_MAP, PRODUCT_TYPE_LIST, CR_LIST} = myInfo;
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    const handleChangeSaleStatus = (PR_SEQ) => {
        setMyInfo(produce(prevValue => {
            const product = prevValue.CR_LIST.find(x => x.PR_SEQ === PR_SEQ);
            if(product){
                product.CR_SALES = (product.CR_SALES === "Y" ? "N" : "Y");
            }
        }))
    }

    const handleSave = async () => {
        const response = await asyncCompanyProductWrite({
            CO_SEQ : CO_MAP.CO_SEQ,
            list : CR_LIST
        });
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "error", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
        }
    }

    return(
        <> 
            <div className="table1 ">
                <table>
                    <colgroup>
                        <col style={{width: "20%"}} />
                        <col style={{width: "30%"}} />
                        <col style={{width: "15%"}} />
                        <col style={{width: "15%"}} />
                        <col style={{width: "20%"}} />
                    </colgroup>
                    <thead>
                        <tr>
                            {/* 소재 */}
                            <th>{t("myPage_material")}</th> 
                            {/* 보철종류 */}
                            <th className="text-left">{t("common_pstheticType")}</th>
                            {/* 구분 */}
                            <th>{t("common_division")}</th>
                            {/* 납품가 */}
                            <th>{t("myPage_deliveryCost")}</th>
                            {/* 납품가 */}
                            <th>{`${t("myPage_deliveryCost")} (Pontic)`}</th>
                            {/* 상태 */}
                            <th>{t("common_status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CR_LIST?
                            CR_LIST.filter(x => x.PR_TYPE !== "P").map((product) => {
                                let productTypeName = PRODUCT_TYPE_LIST.find(x => x.CF_CODE === product.PR_TYPE).CF_TITLE || "";
                                let PR_SEQ = product.PR_SEQ;
                                return(
                                    <tr key={PR_SEQ}>
                                        <td>{productTypeName}</td>
                                        <td className="text-left">{product.PR_DESC}</td>
                                        <td>{product.PR_TYPE === "P" ? t("common_rescan") : t("memberMg_general")}</td>
                                        <td>
                                            <div className="inq-price">
                                                <span>{cm_numComma(Number(product.CR_PRICE))}{SYMBOL}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inq-price">
                                                <span>{cm_numComma(Number(product.CR_PNPRICE))}{SYMBOL}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="inq-stat">
                                                <span>{product.CR_SALES === "Y" ? t("common_use") : <em>{t("common_notUse")}</em>}</span>
                                                <button onClick={() => handleChangeSaleStatus(PR_SEQ)}><i className="xi-renew"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan='5' align='center'>{t("common_noResult")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="text-right">
                <button className="btn btn-fill-blue" disabled={companyProductPending} onClick={handleSave} >{t("common_save")}</button>
            </div>
        </>
    )
}
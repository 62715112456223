import { axio } from 'utils/common';
import { API } from 'config';

// DEVICE
export async function deviceListApi(data){
    return await axio(API.DEVICE_LIST, "POST", data);
}

export async function deviceReadApi(data){
    return await axio(API.DEVICE_READ, "POST", data);
}

export async function deviceWriteApi(data){
    return await axio(API.DEVICE_WRITE, "POST", data);
}

export async function deviceDeleteApi(data){
    return await axio(API.DEVICE_DELETE, "POST", data);
}




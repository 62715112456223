import { holidayDeleteApi, holidayListApi, holidayWriteApi } from "api/holidayApi";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_dateAddMonth, cm_dateFormat, cm_dateMoment, cm_dateToday, cm_dateYearList, cm_isEmpty, cm_isNum, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function HolidayMng(){
    const t = useTranslate();

    const [asyncHolidayList] = useAsync(holidayListApi);
    const [asyncHolidayDelete] = useAsync(holidayDeleteApi);

    const [holidayList, setHolidayList] = useState(null);
    const [searchYear, setSearchYear] = useState(cm_dateMoment(cm_dateToday(), "YYYY"));

    const handleYear = (e) => {
        setSearchYear(e.target.value);
    }

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchYear])

    const handleLoad = async () => {
        const resHolidayList = await asyncHolidayList({HD_YEAR: searchYear});
        if(!resApiCheck(resHolidayList)) return;
        if(resHolidayList.data.LIST_COUNT > 0){
            setHolidayList(resHolidayList.data.LIST);
        }else{
            setHolidayList(null);
        }
    }

    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => {
        setIsOpen(prevValue => !prevValue);
    }

    const handleDelete = async (e) => {
        const HD_SEQ = e.target.value;

        if(!cm_isEmpty(HD_SEQ)){
            const confirm = await cm_swConfirm(t("confirm_common_delete"), "", "warning", t("common_yes"), t("common_no"));
            if(confirm){
                const response = await asyncHolidayDelete({HD_SEQ: e.target.value});
        
                if(!resApiCheck(response)){
                    cm_swAlert(t("alert_delete_failed"), "error", t("common_check"));
                }else{
                    cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
                    handleLoad();
                }
            }
        }
    }

    return(
        <div id="container">
            <div className="admin-content">
                <div className="title-head">
                    <h3>{t("holiday_mng")}</h3>
                </div>

                <div className="admin-search">
                    <div className="title">
                        <h4>{t("common_surchOpt")}</h4>
                    </div>
                    <div className="search-box">
                        <div className="item">
                            <dl>
                                <dt>{t("holiday_year")}</dt>
                                <dd>
                                    <YearSelectOption searchYear={searchYear} onChange={handleYear} />
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>

                <div className="board-head" style={{float : "right"}}>
                    <div className="btns">
                        <button className="btn btn-fill-blue2 btn-s" name="add" value="" onClick={handleModal}><i className="xi-plus"></i> {t("holiday_add")}</button>
                    </div>
                </div>

                <HolidayList holidayList={holidayList} handleDelete={handleDelete}/>
            </div>

            <Modal show={isOpen} onHide={handleModal} centered>
                <HolidayModal isOpen={isOpen} handleModal={handleModal} handleLoad={handleLoad}/>
            </Modal>
        </div>
    )
}

function YearSelectOption({searchYear, onChange}){
    const yearList = cm_dateYearList(cm_dateToday("YYYY"), cm_dateMoment(cm_dateAddMonth(cm_dateToday(), 60), "YYYY"));

    return (
        <select className="select block" name={"year"} value={searchYear} onChange={onChange}>
            {yearList?.map((item) => {
                return (
                    <option key={`year_${item}`} value={item}>{item}</option>
                )
            })}
        </select>
    )
}

function HolidayList({holidayList, handleDelete}){
    const t = useTranslate();

    return (
        <div className="table1 admin-type">
            <table>
                <thead>
                    <tr>
                        <th>{t("holiday")}</th>
                        <th>{t("holiday_name")}</th>
                        <th>{t("holiday_dttm")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {holidayList ?
                        holidayList.map((item) => {
                            return(
                                <tr key={"holiday_" + item.HD_SEQ}>
                                    <td>{item.HD_DATE}</td>
                                    <td>{item.HD_NAME}</td>
                                    <td>{cm_dateMoment(item.DTTM, 'YYYY-MM-DD HH:mm:ss')}</td>
                                    <td><button className="btn btn-fill-gray btn-s" value={item.HD_SEQ} onClick={handleDelete}><img src="img/common/ico_trash.png" alt="" /> {t("myPage_del")}</button></td>
                                </tr>
                            )
                        })
                    :
                        <tr><td colSpan={6}>{t("common_noResult")}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

function HolidayModal({isOpen, handleModal, handleLoad}){
    const t = useTranslate();

    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);

    const separator = cm_dateFormat();

    const handleChangeDate = (e) => {
        let currentDate = e.target.value;

        if(!cm_isNum(currentDate)){
            setErrorMessage(t("holiday_dt_err"));
            return;
        }

        if (separator.symbol && separator.indexes.length > 0) {
            separator.indexes.forEach((index) => {
                if (currentDate.length > index && currentDate[index] !== separator.symbol) {
                    currentDate = currentDate.slice(0, index) + separator.symbol + currentDate.slice(index);
                }
            }
        )};
    
        setDate(currentDate);
        setErrorMessage(null);
    }

    const [asyncHolidayWrite, holidayWritePending] = useAsync(holidayWriteApi);

    const writeHoliday = async () => {
        if(!saveValidate()) return;
        
        const response = await asyncHolidayWrite({HD_DATE: date, HD_NAME: name});

        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleModal();
            handleLoad();
        }
    }

    function saveValidate(){
        let result = true;

        if(cm_isEmpty(date)){
            cm_swAlert(t("alert_choice_equipType"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(name)){
            cm_swAlert(t("alert_choice_mf"),  "warning", t("common_check"));
            result = false;
        }

        return result;
    }

    return(
        <div className="modal fade" id="ModalAsset" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("holiday_add")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body">
                        <h4 className="form-tit">{t("holiday_name")}</h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="HD_NAME" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        
                        <h4 className="form-tit">{t("holiday")}</h4>
                        <div className="inp-box">
                            <input type="text" className="inp block" name="HD_DATE" value={date} onChange={(e) => handleChangeDate(e)} placeholder={t("holiday_format")} />
                            { errorMessage && <div className="error-tip">{errorMessage}</div>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue2" disabled={holidayWritePending} onClick={writeHoliday}>{t("common_save")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
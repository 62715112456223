import { planListApi, planReadApi } from "api/planApi";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_numComma, resApiCheck } from "utils/common";

export default function PlanList({handleIsWrite, setPlan, setPlanDevice}) {
    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    const [planList, setPlanList] = useState(null);
    const [planListCount, setPlanListCount] = useState(null);
    const [asyncPlanList] = useAsync(planListApi);
    const [asyncPlanRead] = useAsync(planReadApi);
    const [page, setPage] = useState(1);
    const limit= 10;
    
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[page]);
    
    const handleLoad = async () => {
        const response = await asyncPlanList({
            PAGE_ROWS : limit,
            PAGE_INDEX : page,
        });
        if(!resApiCheck(response)) return;

        if(response.data.LIST_COUNT > 0){
            setPlanList(response.data.LIST);
            setPlanListCount(response.data.LIST_COUNT);
        }
    }

    const readPlan = async (PL_SEQ) => {
        const response = await asyncPlanRead({PL_SEQ: PL_SEQ});
        if(!resApiCheck(response)) return;

        setPlan(response.data.PL_MAP);
        setPlanDevice(response.data.PD_LIST);
        handleIsWrite();
    }

    const addPlan = () => {
        setPlan({
            PL_SEQ: 0,
            PL_TYPE: "B",
            PL_NAME: "",
            PL_MONTH: "",
            PL_PRICE: "",
            PL_BASEPRICE: "",
            PL_DESC: ""
        });
        setPlanDevice([]);
        handleIsWrite();
    }

    return(
        <>
            <div className="board-head">
                <h3 className="h3">{t("plan_planList")}</h3>
                <div className="btns">
                    <button className="btn btn-fill-blue2 btn-s" onClick={addPlan}><i className="xi-plus"></i> {t("plan_planRegi")}</button>
                </div>
            </div>
            <div className="table1 hover-type">
                <table>
                    <thead>
                        <tr>
                            <th>{t("plan_planType")}</th>
                            <th className="text-left">{t("plan_planName")}</th>
                            <th>{t("plan_period")}</th>
                            <th>{t("common_monthFee")}</th>
                            <th>{t("common_basePrice")}</th>
                            <th>{t("common_note")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {planList && planList.map((plan) => {
                            const planType = plan.PL_TYPE === "R" ? t("plan_rental") : t("plan_basicFee");
                            const planMonth = plan.PL_MONTH > 0 ? plan.PL_MONTH + " " + t("common_months") : t("common_indefinitely");
                            
                            return (
                                <tr key={"plan" + plan.PL_SEQ} onClick={() => readPlan(plan.PL_SEQ)}>
                                    <td>{planType}</td>
                                    <td className="text-left">{plan.PL_NAME}</td>
                                    <td>{planMonth}</td>
                                    <td>
                                        <div className="inq-price">
                                            <strong>{cm_numComma(plan.PL_PRICE)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="inq-price">
                                            <strong>{cm_numComma(plan.PL_BASEPRICE)}{SYMBOL}</strong>
                                        </div>
                                    </td>
                                    <td>{plan.PL_DESC}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination total={planListCount} limit={limit} page={page} setPage={setPage}/>
        </>
    )
}
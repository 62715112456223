import { ORDER_ISSUE } from "config";
import { useStore } from "contexts/Store";

export default function OrderIssueFilter({issueFilter, handleIssue}){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;

    const orderIssue = ORDER_ISSUE();

    return (
        <div className="orderlist-filter-wrap">
            {orderIssue.filter(x => x.coType.indexOf(CO_TYPE) !== -1).map((item) => {
                const issue = item.key;
                const issueClass = issueFilter[item.col] === 1 ? "filter on" : "filter";

                return (
                    <div key={`issue-${issue}`} className={`filter-list issue`}>
                        <button className={issueClass} name="issue" value={item.col} onClick={handleIssue}>
                            <i className={`icon ${issue}`}></i>
                            <p className={`text ${issue}`}>{item.text}</p>
                        </button>
                    </div>
                )
            })}
        </div>
    )
}
import { useState, useEffect } from "react"
import { accountTransactionListApi } from "api/accountApi";
import useAsync from "hooks/useAsync";
import { cm_dateMoment, cm_dateMonthDate, cm_dateToday, cm_isEmpty, cm_numComma, resApiCheck } from "utils/common";
import AccountSearch from "components/manage/AccountSearch";
import useTranslate from "components/language/useTranslate";

export default function AccountAdjust({tap, handleTap}) {
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const [transactionList, setTransactionList] = useState(null);
    const t = useTranslate();

    let today = cm_dateToday();
    let fromDate = cm_dateMonthDate(today, "MF");
    let toDate = cm_dateMonthDate(today, "ML");
    const [searchInfo, setSearchInfo] = useState({
        AT_TYPE : "A",
        FT_FRDATE: fromDate,
        FT_TODATE: toDate,
        CO_TYPE : "",
        CO_NAME : "",
    })

    const [asyncTransactionList] = useAsync(accountTransactionListApi);

    useEffect(() => {
        searchTransactionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const searchTransactionList = async () => {
        const response = await asyncTransactionList(searchInfo);

        if(!resApiCheck(response)) return;

        if(response.data.LIST_COUNT > 0){
            setTransactionList(response.data.LIST);
        }else{
            setTransactionList(null);
        }
    }

    return(
        <>
            <AccountSearch 
                searchInfo={searchInfo} 
                setSearchInfo={setSearchInfo}
                searchList={searchTransactionList}
                tap={tap}
                handleTap={handleTap}
            />

            <div className="table2">
                <table>
                    <colgroup>
                        <col style={{width: "6%"}}/>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "12%"}}/>
                        <col />
                        <col style={{width: "16%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("payment_no")}</th>
                            <th>{t("common_companyName")}</th>
                            <th>{t("common_cost")}</th>
                            <th>{t("common_writer")}</th>
                            <th>{t("common_note")}</th>
                            <th>{t("payment_writeDate")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(transactionList) ? 
                            transactionList.map((transaction, idx) => {
                                return(
                                    <tr key={transaction.AT_SEQ}>
                                        <td>{idx+1}</td>
                                        <td>{transaction.CO_NAME}</td>
                                        <td>
                                            <div className="inq-price">
                                                <strong>{cm_numComma(transaction.AT_AMOUNT)}{SYMBOL}</strong>
                                            </div>
                                        </td>
                                        <td>{transaction.US_NAME}</td>
                                        <td>{transaction.AT_MEMO}</td>
                                        <td>{cm_dateMoment(transaction.DTTM, "YYYY-MM-DD HH:mm:ss")}</td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan='7' align='center'>{t("payment_noDepositithdrawal")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
import useTranslate from "components/language/useTranslate";
import { createContext, useContext, useState } from "react";

const StoreContext = createContext();

export function StoreProvider({ children }){
    const [store, setStore] = useState({});

    return(
        <StoreContext.Provider value={{store, setStore}} >
            {children}
        </StoreContext.Provider>
    );
}

export function useStore(){
    const t = useTranslate();
    const context = useContext(StoreContext);
    if(!context) throw new Error(`StoreProvider ${t("common_content_11")}`);
    return context.store;
}

export function useSetStore(){
    const t = useTranslate();
    const context = useContext(StoreContext);
    if(!context) throw new Error(`StoreProvider ${t("common_content_11")}`);
    return context.setStore;
}

import useTranslate from "components/language/useTranslate";
import { _LIMIT_MEMO_LENGTH } from "config";
import { useOrder, useSetOrder } from "contexts/OrderContext";
import { useState } from "react";

export default function OrderMemo(){
    const order = useOrder();
    const setOrder = useSetOrder();
    const t = useTranslate();

    const {OD_MEMO} = order;

    const [textLength, setTextLength] = useState(order.OD_MEMO.length);

    const onChangeMemo = (e) => {
        const value = e.target.value;
        const length = value.length;

        if(length > _LIMIT_MEMO_LENGTH) return;
        
        const currentOrder = {...order, OD_MEMO: value};

        setOrder(currentOrder);
        setTextLength(length);
    }

    return(
        <div style={{border:"1px solid #d4d4d8", borderRadius:"10px", padding: "15px 20px"}}>
            <div className="tit">
                <h4 className="h4">{t("order_otherRequest")}</h4>
            </div>
            <div className="text">
                <textarea className="textarea" placeholder={t("order_otherRequestPh")} value={OD_MEMO} onChange={onChangeMemo} />
                <div className="len">{textLength}/{_LIMIT_MEMO_LENGTH}</div>
            </div>
        </div>
    )
}
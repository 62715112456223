import useTranslate from 'components/language/useTranslate';
import { _CO_TYPE_CLINIC, _CO_TYPE_DESIGN } from "config";
import { useStore } from "contexts/Store";
import { useState } from "react";
import CompanyDevice from "./CompanyDevice";
import CompanyPlanInfo from "./CompanyPlanInfo";
import CompanyProductPrice from "./CompanyProductPrice";

export default function CompanyPlan(){
    const store = useStore();
    const {US_MAP} = store;
    const [tap, setTap] = useState("plan");
    const t = useTranslate();

    const handleTap = (e) => setTap(e.target.value);

    function tapView(){
        switch (tap){
            case "plan":
                return <CompanyPlanInfo />;
            case "rent":
                return <CompanyDevice />;
            case "price":
                return <CompanyProductPrice />;
            default :
                return <></>;
        }
    }

    return(
        <>
            <div className="tabs3">
                <button className={tap === "plan" ? "active" : null} value="plan" onClick={handleTap}>{t("common_plan")}</button>
                <button className={tap === "rent" ? "active" : null} value="rent" onClick={handleTap}>{t("common_equip")}</button>
                {(US_MAP.CO_TYPE === _CO_TYPE_CLINIC || US_MAP.CO_TYPE === _CO_TYPE_DESIGN) && 
                    <button className={tap === "price" ? "active" : null} value="price" onClick={handleTap}>{t("order_cost")}</button>
                }
            </div>
            {tapView()}
        </>
    )
}
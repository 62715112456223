import { useTransition } from "react";
import { useState } from "react";
import { cm_isEmpty, cm_swAlert } from "utils/common";

function useAsync(asyncFunction) {
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const t = useTransition();

    const wrapperFunction = async (...args) => {
        try {
            setPending(true);
            setError(null);

            const response = await asyncFunction(...args);
            if(cm_isEmpty(response)){
                console.info(asyncFunction.name, "response is empty");
            }
            
            return response;
        } catch (error) {
            setError(error);
            cm_swAlert(`API communication failure :  ${error.message}`, "error", t("common_check"));
            return;
        } finally {
            setPending(false);
        }
    };

    return [wrapperFunction, pending, error]
}

export default useAsync
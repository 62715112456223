import { boardListApi } from "api/boardApi";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cm_dateMoment, resApiCheck } from "utils/common";

export default function Board(){
    const t = useTranslate();
    const store = useStore();
    const US_MAP = store.US_MAP;

    const [tap, setTap] = useState("N");
    const handleChangeTap = (e) => {
        setTap(e.target.value);
        setPage(1)
    }

    const [boardMaster, setBoardMaster] = useState({});
    const [boardList, setBoardList] = useState([]);
    const [boardCount, setBoardCount] = useState(0);
    const [asyncBoardList] = useAsync(boardListApi);
    
    const limit = 10;
    const [page, setPage] = useState(1);

    useEffect(() => {
        handleLoad();
    },[tap, page]);

    const handleLoad = async () => {
        const request = {
            BM_TYPE: tap,
            PAGE_ROWS : limit,
            PAGE_INDEX : page,
        }

        if(US_MAP.CO_TYPE !== _CO_TYPE_CENTER && tap === "Q"){
            request.US_SEQ = US_MAP.US_SEQ;
        }

        const response = await asyncBoardList(request);

        if(!resApiCheck(response)) return;
        setBoardMaster(response.data.BM_MAP);
        setBoardCount(response.data.LIST_COUNT);

        if(response.data.LIST_COUNT > 0){
            setBoardList(response.data.LIST);
        }else{
            setBoardList([]);
        }
    }

    const navigate = useNavigate();
    const handleWritePage = () => {
        navigate("/board/boardwrite", {state: {boardMaster: boardMaster}})
    }

    const handleReadPage = (BC_SEQ) => {
        navigate("/board/boardread", {state: {BC_SEQ: BC_SEQ, boardMaster: boardMaster}});
    }

    return(
        <>
            <div className="admin-content">
                <div className="title-head">   
                    <h3>{t("common_board")}</h3>
                </div>
                <div className="flex justify-content-between align-items-center mb20">
                    <div className="tabs4">
                        <button className={tap === "N" ? "active" : null} value={"N"} onClick={handleChangeTap}>{t("board_notice")}</button>
                        <button className={tap === "F" ? "active" : null} value={"F"} onClick={handleChangeTap}>{t("board_FAQ")}</button>
                        <button className={tap === "Q" ? "active" : null} value={"Q"} onClick={handleChangeTap}>{t("board_Q&A")}</button>
                    </div>
                    <div className="text-right">
                        {US_MAP.US_LEVEL >= boardMaster.BM_WLEVEL &&
                            <button className="btn btn-line-blue2 btn-round btn-s" onClick={handleWritePage}>
                                <i className="xi-pen"></i> {t("board_write")}
                            </button>
                        }
                    </div>
                </div>

                <div className="table1 admin-type hover-type">
                    <table>
                        <colgroup>
                            <col style={{width: "15%"}}/>
                            <col/>
                            {tap === "N" &&
                                <col style={{width: "15%"}}/>
                            }
                            <col style={{width: "15%"}}/>
                            <col style={{width: "15%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>{t("board_title")}</th>
                                {tap === "N" &&
                                    <th>{t("board_viewDate")}</th>
                                }
                                <th>{t("common_writer")}</th>
                                <th>{t("board_writeDate")}</th>       
                            </tr>
                        </thead>
                        <tbody>
                            {boardList.length > 0 ?
                                boardList.map((board, idx) => {
                                    const no = boardCount - ((page-1) * limit) - idx;

                                    return (
                                        <tr key={`bc_${board.BC_SEQ}`} onClick={() => handleReadPage(board.BC_SEQ)}>
                                            <td>{no}</td>
                                            <td className="text-left" ><a href={`#${board.BC_SEQ}`}>{board.BC_TITLE}</a></td>
                                            {tap === "N" &&
                                                <td>{board.BC_SDATE} ~ {board.BC_EDATE}</td>
                                            }
                                            <td>{board.US_NAME}</td>
                                            <td>{cm_dateMoment(board.BC_REGDTTM, "YYYY.MM.DD")}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={4}>{t("common_noResult")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>

                <Pagination total={boardCount} limit={limit} page={page} setPage={setPage}/>
            </div>
        </>
    )
}
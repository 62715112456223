import useTranslate from 'components/language/useTranslate';
import { _CO_TYPE_DESIGN } from 'config';
import { _CO_TYPE_CLINIC } from 'config';
import { useStore } from 'contexts/Store';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cm_isEmpty, cm_swAlert } from 'utils/common';

export default function ProtectRoute({component: Component, roles}) {
    const {ALLOW_LEVEL, ALLOW_TYPE_LIST, PLAN} = roles;
    const [wait, setWait] = useState(false);
    const store = useStore();
    const navigate = useNavigate();
    const t = useTranslate();

    useEffect(() => {
        allowPageCheck();
    })

    function allowPageCheck(){
        if(cm_isEmpty(localStorage.getItem("refresh_token")) || cm_isEmpty(store.US_MAP)){
            cm_swAlert(t("common_content_2"), "error", t("common_check"));
            navigate("/");
        }else{
            if(!roleCheck()){
                navigate("/home");
            }
            setWait(true);
        }
    }

    function roleCheck() {
        const {US_TYPE, US_LEVEL, US_USE_BASIC, US_NEW_ORDER, US_USE_PRINT, CO_TYPE} = store.US_MAP;
        let result = true;
        let text = "";

        if(US_LEVEL < ALLOW_LEVEL || (!cm_isEmpty(ALLOW_TYPE_LIST) && ALLOW_TYPE_LIST.indexOf(US_TYPE) === -1)){
            text = t("common_content_10");
            result = false;
        }else if(US_LEVEL < 10 && !cm_isEmpty(PLAN)){
            if(CO_TYPE !== _CO_TYPE_CLINIC && CO_TYPE !== _CO_TYPE_DESIGN){
                text = t("common_content_12");
                result = false;
            }else if(US_USE_BASIC !== "Y"){
                text = t("common_content_13")
                result = false;
            }else if(US_NEW_ORDER !== "Y" && US_USE_PRINT !== "Y"){
                text = t("common_content_14")
                result = false;
            }
        }
        
        if(!result){
            cm_swAlert(text, "info", t("common_check"));
        }

        return result;
    }

    return(
        <>
            {wait &&
                Component
            }
        </>   
    )
};
import { accountRealTimeApi } from "api/accountApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CLINIC, _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_isEmpty, cm_numComma, resApiCheck } from "utils/common";

export default function PaySection({OLPC_MAP, OLPL_MAP, AB_LIST}){
    const store = useStore();
    const {CO_TYPE, CO_SEQ} = store.US_MAP;
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const t = useTranslate();
    const [account, setAccount] = useState(null);

    //API
    const [asyncAccountRealTime] = useAsync(accountRealTimeApi);

    const amountType = CO_TYPE === _CO_TYPE_LAB ? "cost" : "price";
    let thisMonthAmount = 0;
    let lastMonthAmount = 0;

    if(amountType === "cost"){
        thisMonthAmount = (OLPC_MAP.OL_QTY === 0) ? 0 : OLPC_MAP.OL_COSTPRICE;
        lastMonthAmount = (OLPL_MAP.OL_QTY === 0) ? 0 : OLPL_MAP.OL_COSTPRICE;
    }else{
        thisMonthAmount = (OLPC_MAP.OL_QTY === 0) ? 0 : OLPC_MAP.OL_SELLPRICE + (cm_isEmpty(OLPC_MAP.OA_PRICE) ? 0 : OLPC_MAP.OA_PRICE); // OrderLineAdjust 조정금액 반영
        lastMonthAmount = (OLPL_MAP.OL_QTY === 0) ? 0 : OLPL_MAP.OL_SELLPRICE + (cm_isEmpty(OLPL_MAP.OA_PRICE) ? 0 : OLPL_MAP.OA_PRICE); // OrderLineAdjust 조정금액 반영
    }

    const calcGoalPer = () => { 
        return account ? (thisMonthAmount > account.AB_BASEPRICE ? 100 : (thisMonthAmount / account.AB_BASEPRICE * 100)) : 0;
    }

    useEffect(() => {
        handleLoad();
    },[]);

    const handleLoad = async () => {
        const resAccountRealTime = await asyncAccountRealTime({
            CO_SEQ : CO_SEQ
        })
        if(!resApiCheck(resAccountRealTime)) return;
        if(!cm_isEmpty(resAccountRealTime.data.AB_MAP)){
            setAccount(resAccountRealTime.data.AB_MAP);
        }
    }

    const [lastAccountBook, setLastAccountBook] = useState(null);
    useEffect(() => {
        if(!cm_isEmpty(AB_LIST)){
            setLastAccountBook(AB_LIST[AB_LIST.length - 1]);
        }
    },[AB_LIST])

    return(
        <div className="section flex-grow-0">
            <div className="month-amount">
                {!cm_isEmpty(account) && CO_TYPE === _CO_TYPE_CLINIC &&
                    <div className="totals">
                        <h3 className="h3">
                            {t("payment_estimatedAmount")}
                        </h3>
                        <div className="price">
                            <strong>{cm_numComma(account.AB_TAMOUNT)}<small className="symbol">{SYMBOL}</small></strong>
                        </div>
                        <div className="before">
                            {t("home_lastMonth")} : {!cm_isEmpty(lastAccountBook) ? cm_numComma(lastAccountBook.AB_TAMOUNT) : t("order_searchOrder_content_1")}<small className="symbol">{SYMBOL}</small>
                        </div>
                    </div>
                }
                <div className="totals">
                    <h3 className="h3">
                        {amountType === "cost" ? t("home_monthlySalesCost") : t("home_monthlyUseCost")}
                    </h3>
                    <div className="price">
                        <strong>{cm_numComma(thisMonthAmount)}<small className="symbol">{SYMBOL}</small></strong>
                    </div>
                    <div className="before">
                    {CO_TYPE !== _CO_TYPE_CLINIC && 
                        <>
                            {t("home_lastMonth")} : <strong>{cm_numComma(lastMonthAmount)}<small className="symbol">{SYMBOL}</small></strong>
                        </>
                    }
                    </div>
                </div>
                {(CO_TYPE === _CO_TYPE_CLINIC && !cm_isEmpty(account) && account.AB_BASEPRICE > 0) ?
                    <div className="goal">
                        <h4>{t("home_planGoal")}</h4>
                        <div className="val">
                            <div>
                                <p style={{height : calcGoalPer() + "%" }}><span>{calcGoalPer().toFixed(1)}%</span></p>
                            </div>
                        </div>
                    </div> : <div></div>
                }
            </div>
        </div>
    )
}
import { axio } from 'utils/common';
import { API } from 'config';

// ACCOUNT
export async function accountListApi(data){
    return await axio(API.ACCOUNT_LIST, "POST", data);
}

export async function accountReadApi(data){
    return await axio(API.ACCOUNT_READ, "POST", data);
}

export async function accountInvoiceApi(data){
    return await axio(API.ACCOUNT_INVOICE, "POST", data);
}

export async function accountSendInvoiceApi(data){
    return await axio(API.ACCOUNT_SEND_INVOICE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function accountRealTimeApi(data){
    return await axio(API.ACCOUNT_REALTIME, "POST", data);
}

export async function accountTransactionListApi(data){
    return await axio(API.ACCOUNT_TRANSACTION_LIST, "POST", data);
}

export async function accountTransactionWriteApi(data){
    return await axio(API.ACCOUNT_TRANSACTION_WRITE, "POST", data);
}

export async function accountAdjustListApi(data){
    return await axio(API.ACCOUNT_ADJUST_LIST, "POST", data);
}

export async function accountAdjustWriteApi(data){
    return await axio(API.ACCOUNT_ADJUST_WRITE, "POST", data);
}

export async function accountRemainListApi(data){
    return await axio(API.ACCOUNT_REMAIN_LIST, "POST", data);
}

export async function accountRemainAmountApi(data){
    return await axio(API.ACCOUNT_REMAIN_AMOUNT, "POST", data);
}
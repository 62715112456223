import useTranslate from "components/language/useTranslate";

function NotFound(){
    const t = useTranslate();

    return(
        <div>
            {t("alert_notFound")}
        </div>
    )
}

export default NotFound;
import { userOptionDeleteApi, userOptionListApi } from "api/userApi";
import useTranslate from 'components/language/useTranslate';
import UserProsOptionModal from "components/modal/UserProsOptionModal";
import { PROSTHETIC_OPTIONS } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_JsonParseList, cm_findData, cm_isEmpty, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function UserProstheticOpt(){
    const [prosList, setProsList] = useState([]);
    const [selectObj, setselectObj] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [reLoad, setReLoad] = useState(false);
    const store = useStore();
    const prosthetic = PROSTHETIC_OPTIONS();
    
    const [asyncUserOptionList] = useAsync(userOptionListApi);
    const [asyncUserOptionDelete, userOptionDeletePending] = useAsync(userOptionDeleteApi);
    
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);

    const handleLoad = async () => {
        const response = await asyncUserOptionList({
            US_SEQ : store.US_MAP.US_SEQ,
            UO_TYPE : "P"
        });
        if(!resApiCheck(response)) return;
        if(response.data.LIST.length > 0){
            setProsList(cm_JsonParseList(response.data.LIST, "UO_OPTION"));
        }else{
            setProsList([]);
        }
    }

    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const handleReLoad = () => setReLoad((prevValue) => !prevValue);

    const handleAddModify = (e) => {
        const {name, value} = e.target;
        let findOpt = null;
        if(name === "modify"){
            findOpt = prosList.find(x => x.UO_SEQ === Number(value));
            setselectObj(findOpt);
        }else{
            setselectObj(null);
        }

        handleModal();
    }

    const handleDelete = async (e) => {
        const UO_SEQ = e.target.value; 

        if(!cm_isEmpty(UO_SEQ)){
            const confirm = await cm_swConfirm(t("confirm_common_delete"), "", "warning", t("common_yes"), t("common_no"));
            if(confirm){
                let response = await asyncUserOptionDelete({
                    UO_SEQ: UO_SEQ,
                });
                if(!resApiCheck(response)){
                    cm_swAlert(t("alert_delete_failed"), "error", t("common_check"));
                }else{
                    cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
                    handleReLoad();
                }
            }
        }
    }

    return(
        <>
            <div className="table1 mb30">
                <table>
                    <colgroup>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "20%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("myPage_pstheticOptName")}</th>
                            <th>{t("common_contact")}</th>
                            <th>{t("common_embrasure")}</th>
                            <th>{t("common_hook")}</th>
                            <th>{t("common_ponticType")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(prosList) ?
                            prosList.map((pros) => {
                                return(
                                    <tr key={`UO_${pros.UO_SEQ}`}>
                                        <td>{pros.UO_NAME}</td>
                                        {pros.UO_OPTION.map((option)=> {
                                            let title = cm_findData(prosthetic[option.key], "value", option.value, "title");
                                            return(
                                                <td key={option.key}>{title}</td>
                                            )
                                        })}
                                        <td>
                                            <div className="flex">
                                                <button className="btn btn-fill-gray btn-s" style={{marginRight: "10px"}} name="modify" value={pros.UO_SEQ} onClick={handleAddModify}>{t("common_edit")}</button>
                                                <button className="btn btn-fill-gray btn-s" value={pros.UO_SEQ} disabled={userOptionDeletePending} onClick={handleDelete}><img src="img/common/ico_trash.png" alt="" /> {t("myPage_del")}</button>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr><td colSpan={6}>{t("order_searchOrder_content_1")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="text-right">
                <button className="btn btn-fill-blue" name="add" value="" onClick={handleAddModify}>{t("common_add")}</button>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <UserProsOptionModal isOpen={isOpen} handleModal={handleModal} selectObj={selectObj} reLoadOption={handleReLoad} />
            </Modal>
        </>
    )
}
import { orderAdjustListApi, orderAdjustWriteApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import { cm_dateMoment, cm_isEmpty, cm_numComma, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function OrderLineDetail({orderMap}) {
    const t = useTranslate();
    
    const {OD_MAP} = orderMap;
    const [adjustList, setAdjustList] = useState([]);
    const [asyncOrderAdjustList] = useAsync(orderAdjustListApi);
    const [asyncOrderAdjustWrite] = useAsync(orderAdjustWriteApi);

    const [adjustMemo, setAdjustMemo] = useState("");
    const [adjustPrice, setAdjustPrice] = useState(0);
    const handleAdjustPrice = (e) => {setAdjustPrice(e.target.value);}
    const handleAdjustMemo = (e) => {setAdjustMemo(e.target.value);}

    useEffect(() => {
        handleAdjustList();
    },[])

    const handleAdjustList = async () => {
        const response = await asyncOrderAdjustList({OD_SEQ: OD_MAP.OD_SEQ});
        if(!resApiCheck(response)) return;

        setAdjustList(response.data.LIST);
    }

    const handleAdjustWrite = async () => {
        if(!validateAdjustWrite()) return;

        const confirm = await cm_swConfirm(t("confirm_common_save"), "", "info");
        
        if(!confirm) return;
        
        const response = await asyncOrderAdjustWrite({
            OD_SEQ : OD_MAP.OD_SEQ,
            OL_SEQ : selectLine.OL_SEQ,
            OA_PRICE : adjustPrice,
            OA_MEMO : adjustMemo,
        });
        
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleAdjustList();
        }
    }

    const validateAdjustWrite = () => {
        if(cm_isEmpty(selectLine)){
            cm_swAlert(t("alert_payment_psthetic"), "warning", t("common_check"));
            return false;
        }

        return true;
    }

    const [selectLine, setSelectLine] = useState(null);
    const handleSelectLine = (line) => {
        setSelectLine(line);
    }

    return (
        <>
            <div className="table1 hover-type">
                <table>
                    <colgroup>
                        <col style={{width: "12%"}}/>
                        <col style={{width: "8%"}}/>
                        <col style={{width: "20%"}}/>
                        <col />
                        <col style={{width: "8%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common_pstheticType")}</th>
                            <th>{t("payment_dtFormula")}</th>
                            <th>{t("common_cost")}</th>
                            <th colSpan={2}>{t("common_note")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderMap.OL_LIST.map((line, index) => {
                                return (
                                    <Fragment key={index}>
                                        <tr key={`line_${line.OL_SEQ}`} onClick={() => handleSelectLine(line)}>
                                            <td>{line.PR_CODE}</td>
                                            <td>{line.OL_TOOTH}</td>
                                            <td>{cm_numComma(line.OL_SELLPRICE)}</td>
                                            <td colSpan={2}></td>
                                        </tr>

                                        {adjustList.filter(x => x.OL_SEQ === line.OL_SEQ).map((item) => {
                                            return (
                                                <tr key={`adjust-${item.OA_SEQ}`} className="bg-red">
                                                    <td colSpan={2}>{cm_dateMoment(item.OA_REGDTTM, "YYYY-MM-DD HH:mm")}</td>
                                                    <td>{cm_numComma(item.OA_PRICE)}</td>
                                                    <td>{item.OA_MEMO}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })
                        }
                        <tr>
                            <td colSpan={2}>{selectLine && `${selectLine.OL_TOOTH}/${selectLine.PR_CODE} -`}{t("payment_adjust")}</td>
                            <td>
                                <input 
                                    type="text" 
                                    className="inp" 
                                    value={adjustPrice} 
                                    onChange={handleAdjustPrice} 
                                />
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    className="inp block"
                                    maxLength={50}
                                    value={adjustMemo}
                                    onChange={handleAdjustMemo}
                                />
                            </td>
                            <td>
                                <button type="button" className="btn btn-fill-gray btn-s ml10" onClick={() => handleAdjustWrite()}>{t("common_save")}</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}
import { useEffect, useState } from "react";
import { useStore } from "contexts/Store";
import { userOptionListApi } from "api/userApi";
import useTranslate from "components/language/useTranslate";
import { cm_isEmpty } from "utils/common";

export default function OrderImplantList({OL_LIST}){
    const t = useTranslate();
    const store = useStore();
    const implantList = OL_LIST.filter(x => x.UO_SEQ !== 0 && x.PR_TYPE === "I");

    const [userImplantList, setUserImplantList] = useState(null);

    useEffect(() => {
        handleLoad();
    },[]);

    const handleLoad = async () => {
        const {US_SEQ} = store.US_MAP;
        const response = await userOptionListApi({
            // US_SEQ : US_SEQ
        });

        const LIST = response.data.LIST;

        if(LIST.length > 0){
            let filterList = LIST.filter(x => x.UO_TYPE ==="I");
            setUserImplantList(filterList);
        }
    }

    //TODO implantOption

    return (
        <>
            {implantList.length > 0 &&
                <div className="box type2">
                    <div className="tit">
                        <h4 className="h4">{t("order_implModel")}</h4>
                    </div>
                    <div className="info">
                        <ul className="print-list">
                            {userImplantList && implantList.map((implant, idx) => {
                                let implantOptionName = "";
                                const modelOption = userImplantList.find(x => x.UO_SEQ === implant.UO_SEQ);
                                if(!cm_isEmpty(modelOption)){
                                    implantOptionName = modelOption.UO_NAME;
                                }

                                return (
                                    <li key={`implantList_${idx}`}>
                                        {implant.OL_TOOTH} : {implantOptionName}
                                    </li>
                                )
                            })}
                        </ul>  
                    </div>
                </div>
            }
        </>
    )
}
export default function Pagination({ total, limit, page, setPage }) {
    if(total === 0) return;
    
    const numPages = Math.ceil(total / limit);
    const rang = Math.floor((page-1) / 10);

    return (
        <>
            <div className="pagenate">
                <button className="first" onClick={() => setPage(1)} disabled={page === 1}></button>
                <button className="prev" onClick={() => setPage(page - 1)} disabled={page === 1}></button>
                {Array(numPages).fill().map((_, i) => {
                    const pageRang = Math.floor(i / 10);
                    const show = (rang !== pageRang) ? "none" : "";

                    return (
                        <button
                            key={i + 1}
                            className={page === i + 1 ? "active" : null}
                            onClick={() => setPage(i + 1)}
                            style={{display:show}}
                        >
                            {i + 1}
                        </button>
                    )
                })}
                <button className="next" onClick={() => setPage(page + 1)} disabled={page === numPages}></button>
                <button className="last" onClick={() => setPage(numPages)} disabled={page === numPages}></button>
            </div>
        </>
    )
}
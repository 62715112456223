import useTranslate from "components/language/useTranslate";
import OrderSections from "components/order/OrderSections";
import useDueDate from "hooks/useDueDate";
import { useEffect, useState } from "react";
import { cm_dateMoment, cm_isEmpty, cm_swAlert, resApiCheck, resResultAlert } from "utils/common";
import CalendarDropdown from "./CalendarDropdown";
import useAsync from "hooks/useAsync";
import { orderRepairApi } from "api/orderApi";

export default function RepairModal({isOpen, handleModal, orderMap, targetStatus, handleOrderMap}) {
    const {OL_LIST} = orderMap;
    const MF_OL_LIST = OL_LIST.filter(x => x.PR_TYPE !== "P");

    const [modifyTeeth, setModifyTeeth] = useState([]);
    const [comment, setComment] = useState("");
    const t = useTranslate();

    const handleComment = (e) => {
        setComment(e.target.value);
    }

    const [dueDate, setDueDate] = useState(new Date());
    const onChangeDueDate = (date) => {setDueDate(date)};

    const [holidayList, minDate, defaultDueDate] = useDueDate();
    useEffect(() => {
        const handleDueDate = () => {
            setDueDate(cm_dateMoment(defaultDueDate));
        }

        handleDueDate();
    },[defaultDueDate])

    const handleModifyTeeth = (product, toothnum) => {
        const productIdx = modifyTeeth.findIndex(item => item.product === product);

        if (productIdx !== -1) {
            // 이미 선택한 제품인 경우
            const newModifyTeeth = [...modifyTeeth];
            const toothIndex = newModifyTeeth[productIdx].num.indexOf(toothnum);
            
            if (toothIndex !== -1) {
                // 이미 선택한 toothnum이 num 배열에 있다면 제거
                newModifyTeeth[productIdx].num.splice(toothIndex, 1);
                
                if (newModifyTeeth[productIdx].num.length === 0) {
                    // num 배열이 비었다면 해당 객체 제거
                    newModifyTeeth.splice(productIdx, 1);
                }
            } else {
                // 선택한 toothnum이 num 배열에 없으면 추가
                newModifyTeeth[productIdx].num.push(toothnum);
            }

            setModifyTeeth(newModifyTeeth);
        } else {
            // 선택되지 않은 제품이면 새 항목 추가
            const newRemakeTeeth = [...modifyTeeth, { product, num: [toothnum] }];
            setModifyTeeth(newRemakeTeeth);
        }
    }

    const handleModify = () => {
        if(!validateModify()) return;

        const updateLine = OL_LIST;
        for(let tooth of modifyTeeth){
            updateLine.forEach(obj => {
                if (tooth.num.includes(obj.OL_TOOTH) && obj.PR_CODE === tooth.product) {
                    obj.OL_STATUS = targetStatus;
                }
            });
        }

        const OC_TEXT = `${comment} \n ${stringifyTeeth()} \n` + t("order_info_deadline") + `: ${dueDate}`;
        handleOrderRepair(targetStatus, updateLine, dueDate, OC_TEXT);

        handleModal();
    }

    function validateModify(){
        if(cm_isEmpty(modifyTeeth)) {
            cm_swAlert(t("order_content_9"), "warning", t("common_check"));
            return false;
        }

        if(cm_isEmpty(comment)){
            cm_swAlert(t("order_content_12"), "warning", t("common_check"));
            return false;
        }

        return true;
    }

    const stringifyTeeth = () => {
        return modifyTeeth.map(item => `${item.product} : ${item.num.join(', ')}`).join(' | ');
    };

    const [asyncOrderRepair] = useAsync(orderRepairApi);
    const handleOrderRepair = async (targetStatus, OL_LIST, dueDate, OC_TEXT) => {
        const {OD_SEQ, OD_UKEY} = orderMap.OD_MAP;

        const request = {
            OD_SEQ: OD_SEQ,
            OD_UKEY: OD_UKEY,
            OD_STATUS: targetStatus,
            OL_LIST: JSON.stringify(OL_LIST),
            OA_DUEDATE: dueDate,
            OA_MEMO: OC_TEXT,
            OC_TEXT: OC_TEXT,
        }

        const response = await asyncOrderRepair(request);

        if(resApiCheck(response)){
            handleOrderMap(OD_SEQ);

            resResultAlert(response.data.RESULT, t("order_content_6"));
        }
    }

    return (
            <div className="modal fade" id="Modal1" style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"500px", margin:"50px auto"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">{t("order_repair")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                        </div>

                        <div className="modal-body">
                            <h4 className="form-tit">{t("order_selectPsthetic")}</h4>
                            <div className="tooth-list3 type2">
                                <OrderSections OL_LIST={MF_OL_LIST} remake={true} remakeTeeth={modifyTeeth} handleRemakeTeeth={handleModifyTeeth}/>
                            </div>

                            <h4 className="form-tit mt20">{t("order_repair_prosthetic")}</h4>
                            <input type="text" className="inp block" defaultValue={stringifyTeeth()} placeholder="" disabled/>

                            <h4 className="form-tit mt20">{t("order_repair_reasons")}</h4>
                            <input type="text" className="inp block" onChange={handleComment} value={comment} placeholder={t("order_repair_input_reasons")}></input>

                            <h3 className="form-tit mt20">{t("order_info_deadline")}</h3>
                            <div className="inp-box" style={{zIndex:"1"}}>
                                <CalendarDropdown
                                    isOn={false}
                                    value={dueDate}
                                    minDate={minDate}
                                    onChange={onChangeDueDate}
                                    holidayList={holidayList}
                                    title={t("order_info_deadline")}
                                />
                            </div>
                            <div className="form-msg impact">
                                <p style={{whiteSpace:"pre-line"}}>{t("order_write_text_repair")}</p>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-fill-gray" data-bs-dismiss="modal" onClick={handleModal}>{t("common_cancel")}</button>
                            <button type="button" className="btn btn-fill-blue" onClick={handleModify}>{t("common_check")}</button>
                        </div>
                    </div>
                </div>
            </div>
    )
}
import { companyPlanReadApi } from "api/companyApi";
import useTranslate from 'components/language/useTranslate';
import { useMyInfo } from "contexts/MyInfoContext";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_dateToday, cm_isEmpty, cm_numComma, cm_swAlert, downloadFile, resApiCheck } from "utils/common";

export default function CompanyPlanInfo(){
    const myInfo = useMyInfo();
    const {CP_LIST} = myInfo;
    const [usePlanList, setUsePlanList] = useState([]);
    const [pastPlanList, setPastPlanList] = useState([]);
    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    //API
    const [asyncCompanyPlanRead] = useAsync(companyPlanReadApi);

    useEffect(() => {
        let toDay = cm_dateToday();
        if(!cm_isEmpty(CP_LIST)){
            setUsePlanList(CP_LIST.filter(x => x.CP_EDATE > toDay))
            setPastPlanList(CP_LIST.filter(x => x.CP_EDATE < toDay))
        }
    },[CP_LIST]);

    async function downLoadFiles(CP_SEQ){
        const response = await asyncCompanyPlanRead({CP_SEQ : CP_SEQ});
        if(!resApiCheck(response)) return;
        
        if(!cm_isEmpty(response.data.FL_LIST)){
            response.data.FL_LIST.map((file) => {
                return downloadFile(file.FL_URL, file.FL_ORGNAME);
            })
        }else{
            cm_swAlert(t("alert_notRegiContract"), "info", t("common_check"));
        }
    }

    return(
        <>
            <h3 className="h3">{t("myPage_usePlan")}</h3>
            {!cm_isEmpty(CP_LIST) ?
                <>
                    <div className="grid grid-plan">
                        {
                            usePlanList.map((contract) => {
                                const {PL_NAME, PL_BASEPRICE, PL_DESC, CP_SDATE, CP_EDATE, PL_PRICE} = contract;
        
                                return(
                                    <div className="plan-item" key={`CP_${contract.CP_SEQ}`}>
                                        <table>
                                            <colgroup>
                                                <col style={{width:"100px"}} />
                                                <col />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <th className="text-center">{t("common_planName")}</th>
                                                    <td>{PL_NAME}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">{t("common_period")}</th>
                                                    <td>{CP_SDATE} ~ {CP_EDATE}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">{t("common_monthFee")}</th>
                                                    <td>{cm_numComma(PL_PRICE)}{SYMBOL}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">{t("common_basePrice")}</th>
                                                    <td>{cm_numComma(PL_BASEPRICE)}{SYMBOL}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">{t("memberMg_contract")}</th>
                                                    <td><button className="btn-ico" onClick={() => downLoadFiles(contract.CP_SEQ)}>download <i className="xi-download"></i></button></td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">{t("common_note")}</th>
                                                    <td>{PL_DESC}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
                :
                <div className="row">
                    <div className="plan-empty">
                        <span>{t("myPage_plan_content_1")}</span><br/>{t("myPage_plan_content_2")}
                    </div>
                </div>
            }
            <CompanyPlanHistory CP_LIST={pastPlanList} downLoadFiles={downLoadFiles} />
        </>
    )
}

function CompanyPlanHistory({CP_LIST, downLoadFiles}){
    const [showHistory, setShowHistory] = useState(false);
    const handleShowHistory = () => setShowHistory((prvValue) => !prvValue);
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const t = useTranslate();

    return(
        <>
            <div className="mb20">
                <button className={"btn btn-fill-gray btn-lg block" + (showHistory ? " active" : "")} onClick={handleShowHistory}>{t("myPage_historyPlanShow")}<i className="xi-angle-down ml5"></i></button>
            </div>

            {showHistory &&
                <div className="table1 active" id="History">
                    <table>
                        <colgroup>
                            <col style={{width : "10%"}}/>
                            <col style={{width : "25%"}}/>
                            <col style={{width : "20%"}}/>
                            <col style={{width : "20%"}}/>
                            <col style={{width : "10%"}}/>
                            <col style={{width : "20%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{t("common_planName")}</th>
                                <th>{t("myPage_plan_period")}</th>
                                <th>{t("common_monthFee")}</th>
                                <th>{t("common_basePrice")}</th>
                                <th>{t("memberMg_contract")}</th>
                                <th>{t("common_note")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!cm_isEmpty(CP_LIST) ?
                                CP_LIST.map((myPlan) => {
                                    const {PL_NAME, PL_BASEPRICE, PL_DESC, CP_SDATE, CP_EDATE, PL_PRICE} = myPlan;

                                    return(
                                        <tr key={`mchis_${myPlan.CP_SEQ}`}>
                                            <td>{PL_NAME}</td>
                                            <td>{CP_SDATE} ~ {CP_EDATE}</td>
                                            <td>{cm_numComma(PL_PRICE)}{SYMBOL}</td>
                                            <td>{cm_numComma(PL_BASEPRICE)}{SYMBOL}</td>
                                            <td><div className="plan-item"><button className="btn-ico" onClick={() => downLoadFiles(myPlan.CP_SEQ)}><i className="xi-download"></i></button></div></td>
                                            <td>{PL_DESC}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan='6' align='center'>{t("common_noResult")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}
import { companyListApi } from "api/companyApi";
import WeekDelivery from "components/delivery/WeekDelivery";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER, _CO_TYPE_CLINIC, _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_dateAddDay, cm_dateMoment, cm_dateToday, cm_isEmpty, resApiCheck } from "utils/common";

export default function Delivery(){
    const t = useTranslate();
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    
    const [coType, setCoType] = useState(_CO_TYPE_CLINIC);
    const [searchInfo, setSearchInfo] = useState({
        US_NAME : "",
        FT_FRDUEDATE : cm_dateToday(),
        FT_TODUEDATE : cm_dateMoment(cm_dateAddDay(cm_dateToday(), 5)),
    });

    const handleChangeDate = (target) => {
        let fromDate = searchInfo.FT_FRDUEDATE;
        let toDate = searchInfo.FT_TODUEDATE;

        if(target === "NEXT"){
            fromDate = cm_dateMoment(cm_dateAddDay(fromDate, 6));
            toDate = cm_dateMoment(cm_dateAddDay(toDate, 6));
        }else{
            fromDate = cm_dateMoment(cm_dateAddDay(fromDate, -6));
            toDate = cm_dateMoment(cm_dateAddDay(toDate, -6));
        }

        setSearchInfo(produce(prevValue => {
            prevValue.FT_TODUEDATE = toDate;
            prevValue.FT_FRDUEDATE = fromDate;
        }))
    }

    return(
        <>
            <div className="inquiry-head">
                {CO_TYPE === _CO_TYPE_CENTER && 
                    <DeliverySearch setSearchInfo={setSearchInfo} coType={coType} setCoType={setCoType}/>
                }

                <div className="tabs5">
                    <button className={"active"} style={{cursor: "default"}}>{t("common_delivery")}</button>
                </div>

                <div className="btns">
                    <button className="btn btn-fill-blue btn-s m10" onClick={() => handleChangeDate("PREV")}><i className="xi-angle-left"></i>{t("delivery_backSix")}</button>
                    <button className="btn btn-fill-blue btn-s m10" onClick={() => handleChangeDate("NEXT")}>{t("delivery_afterSix")}<i className="xi-angle-right"></i></button>
                </div>
            </div>

            <WeekDelivery searchInfo={searchInfo} />
        </>
    )
}

function DeliverySearch({setSearchInfo, coType, setCoType}){
    const [coName, setCoName] = useState("")
    const [coList, setCoList] = useState(null);
    const [filterCoList, setFilterCoList] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [asyncCompanyList] = useAsync(companyListApi);

    const t = useTranslate();

    useEffect(() => {
        handleLoad();
    },[coType]);

    const handleLoad = async () => {
        const response = await asyncCompanyList({ CO_TYPE : coType })
        if(!resApiCheck(response)) return;

        const {LIST_COUNT} = response.data;
        if(LIST_COUNT > 0){
            setCoList(response.data.LIST);
        }
    }

    const handleChangeCoName = (e) => {
        setCoName(e.target.value);
    }

    const handleSearchBtn = () => {
        let filterCoList = null;

        if(!cm_isEmpty(coList)){
            filterCoList = coList.filter(x => {
                return x.CO_NAME.toLowerCase().includes(coName.toLowerCase())
            });
        }

        setFilterCoList(filterCoList);
        setIsOpen((nextIsOpen) => !nextIsOpen);
    }

    const handleCompany = (company) => {
        if(company.CO_TYPE === _CO_TYPE_CLINIC){
            setSearchInfo(produce(prevValue => {
                prevValue.CO_SEQ = company.CO_SEQ;
                prevValue.OD_LAB = null;
            }))
        }else if(company.CO_TYPE === _CO_TYPE_LAB){
            setSearchInfo(produce(prevValue => {
                prevValue.CO_SEQ = null;
                prevValue.OD_LAB = company.CO_SEQ;
            }))
        }

        setCoName((cm_isEmpty(company.CO_NAME) ? "" : company.CO_NAME));
    }

    const onKeyPress = (e) => {
        if(e.key === "Enter") handleSearchBtn(e);
    }

    return(
        <div className="flex gap10">
            <div className="inp-box inp-btn-box mb0" style={{width:"200px"}}>
                <button className="btn-search" onClick={handleSearchBtn}><span className="hidden">{t("payment_search")}</span></button>
                {isOpen && filterCoList && 
                    <SearchList setIsOpen={setIsOpen} filterCoList={filterCoList} handleCompany={handleCompany}/>
                }
                <input type="text" className="inp block" name="CO_NAME" value={coName} onChange={handleChangeCoName} onKeyDown={onKeyPress} maxLength={30}/>
            </div>

            <div className="flex gap10">
                <label>
                    <input type="radio" className="radio" name="CO_TYPE" value={_CO_TYPE_CLINIC} checked={coType === _CO_TYPE_CLINIC} onChange={() => setCoType(_CO_TYPE_CLINIC)} />
                    <div><em></em><p>{t("common_dt")}</p></div>
                </label>
                <label>
                    <input type="radio" className="radio" name="CO_TYPE" value={_CO_TYPE_LAB} checked={coType === _CO_TYPE_LAB} onChange={() => setCoType(_CO_TYPE_LAB)} />
                    <div><em></em><p>{t("common_dtl")}</p></div>
                </label>
            </div>
        </div>
    )
}

function SearchList({ setIsOpen, filterCoList, handleCompany }) {
    const t = useTranslate();

    useEffect(() => {
        const handleClickOutside = () => setIsOpen(false);
        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    },[]);

    return(
        <div className="delivery">
            <div className="gnb">
                <nav>
                    <ul>
                        {!cm_isEmpty(filterCoList) ?
                            filterCoList.map((company) => {
                                return(
                                    <li key={company.CO_SEQ} style={{marginBottom:"10px"}} value={company.CO_SEQ} >
                                        <button onClick={() => handleCompany(company)}>{company.CO_NAME}</button>
                                    </li>
                                )
                            })
                            :
                            <li>{t("common_noResult")}</li>
                        }
                    </ul>
                </nav>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react";
import { accountTransactionListApi } from "api/accountApi";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { cm_isEmpty, cm_numComma, resApiCheck } from "utils/common";
import { AT_STATUS } from "config";

export default function AdjustHistory({tap, account}){
    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const transactionStatus = AT_STATUS();

    const [transactionList, setTransactionList] = useState(null);
    const [asyncTransactionList] = useAsync(accountTransactionListApi);

    useEffect(() => {
        searchTransactionList();
    },[])

    const searchTransactionList = async () => {
        const response = await asyncTransactionList({
            CO_SEQ : account.CO_SEQ,
            AB_MONTH : account.AB_MONTH,
            AT_TYPE : "A",
        });

        if(!resApiCheck(response)) return;

        if(response.data.LIST_COUNT > 0){
            setTransactionList(response.data.LIST);
        }else{
            setTransactionList(null);
        }
    }

    return(
        <div className="table1" style={{ maxHeight: "395px", overflow:"auto"}}>
            <table id={tap}>
                <colgroup>
                    <col style={{width: "15%"}}/>
                    <col style={{width: "15%"}}/>
                    <col style={{width: "15%"}}/>
                    <col style={{width: "15%"}}/>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "15%"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>{t("payment_billMonth")}</th>
                        <th>{t("common_companyName")}</th>
                        <th>{t("payment_adjustCost")}</th>
                        <th>{t("common_writer")}</th>
                        <th>{t("common_note")}</th>
                        <th>{t("common_status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!cm_isEmpty(transactionList) ?
                        transactionList.map((item) => {
                            return(
                                <tr key={item.AT_SEQ}>
                                    <td>{item.AB_MONTH}</td>
                                    <td>{item.CO_NAME}</td>
                                    <td>{cm_numComma(item.AT_AMOUNT)}{SYMBOL}</td>
                                    <td>{item.US_NAME}</td>
                                    <td>{item.AT_MEMO}</td>
                                    <td>{transactionStatus.find(x => x.key === item.AT_STATUS).text}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan='6' align='center'>{t("order_searchOrder_content_1")}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
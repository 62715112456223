import { indexApi } from "api/commonApi"
import PrintQueue from "components/common/PrintQueue"
import BoardSection from "components/dashboard/BoardSection"
import DeliverySection from "components/dashboard/DeliverySection"
import DoListSection from "components/dashboard/DoListSection"
import EmptySection from "components/dashboard/EmptySection"
import PayChartSection from "components/dashboard/PayChartSection"
import PaySection from "components/dashboard/PaySection"
import RepairSection from "components/dashboard/RepairSection"
import SituationSection from "components/dashboard/SituationSection"
import Banner from "components/common/Banner";
import { _CO_TYPE_LAB, _CO_TYPE_DESIGN,_INTERVAL_TIME} from "config"
import { useStore } from "contexts/Store"
import useAsync from "hooks/useAsync"
import useInterval from "hooks/useInterval"
import { useEffect, useState } from "react"
import { resApiCheck } from "utils/common"

export default function UserHome(){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const [indexData, setIndexData] = useState(null);
    const [asyncIndex] = useAsync(indexApi);

    useEffect(() => {
        handleLoad();
    },[])

    useInterval(() => {
        handleLoad();
    }, _INTERVAL_TIME);

    const handleLoad = async () => {
        const response = await asyncIndex({});
        if(!resApiCheck(response)) return;

        setIndexData(response.data);
    }

    return(
        <>
            {indexData &&
                <>
                    <div className="home-wrap">
                        <div className="home-main-wrap">
                            <div className="home-main-box">
                                <div className="home-main">
                                    <div className="left">
                                        <RouteSection section={"PaySection"} component={<PaySection OLPC_MAP={indexData.OLPC_MAP} OLPL_MAP={indexData.OLPL_MAP} AB_LIST={indexData.AB_LIST}/>} />
                                        <RouteSection section={"SituationSection"} component={<SituationSection ODC_MAP={indexData.ODC_MAP} ODP_MAP={indexData.ODP_MAP}/>} />
                                        <RouteSection section={"DoListSection"} component={<DoListSection ODW_LIST={indexData.ODW_LIST}/>} />
                                        <RouteSection section={"PayChartSection"} component={<PayChartSection AB_LIST={indexData.AB_LIST}/>} />
                                        <RouteSection section={"EmptySection"} component={<EmptySection />} />
                                    </div>

                                    <div className="center">
                                        <RouteSection section={"DeliverySection"} component={<DeliverySection ODD_LIST={indexData.ODD_LIST} OLD_LIST={indexData.OLD_LIST}/>} />
                                    </div>
                                    
                                    <div className="right">
                                        <BoardSection BC_LIST={indexData.BC_LIST} BM_MAP={indexData.BM_MAP}/>
                                        {CO_TYPE === _CO_TYPE_DESIGN ? <Banner></Banner> 
                                            : <RepairSection OA_LIST={indexData.OA_LIST}/>
                                        }
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {CO_TYPE !== _CO_TYPE_LAB && <PrintQueue />}
                </>
            }
        </>
    )
}

function RouteSection({section, component: Component}){
    const store = useStore();
    const {CO_TYPE, US_TYPE} = store.US_MAP;

    const [show, setShow] = useState(false);

    useEffect(() => {
        sectionCheck();
    })

    const sections = [
        {
            section: "PaySection",
            coType: ["Q", "C", "L", "D"],
            usType: ["QA", "QC", "QF", "QS", "CO"],
        },
        {
            section: "SituationSection",
            coType: ["Q", "C", "L", "D"],
            usType: ["QA", "QC", "QD", "QF", "QS", "CO", "CE"],
        },
        {
            section: "DoListSection",
            coType: ["Q"],
            usType: ["QA", "QC", "QD", "QF", "QS"],
        },
        {
            section: "PayChartSection",
            coType: ["C", "L", "D"],
            usType: ["CO"],
        },
        {
            section: "EmptySection",
            coType: ["C", "L", "D"],
            usType: ["CE"],
        },
        {
            section: "DeliverySection",
            coType: ["Q", "C", "L", "D"],
            usType: ["QA", "QC", "QF", "QM", "QD", "QS", "CO", "CE"],
        },
        {
            section: "BoardSection",
            coType: ["Q", "C", "L", "D"],
            usType: ["QA", "QC", "QF", "QM", "QD", "QS", "CO", "CE"],
        },
        {
            section: "Banner",
            coType: ["Q", "C", "L", "D"],
            usType: ["QA", "QC", "QF", "QM", "QD", "QS", "CO", "CE"],
        },
    ];

    function sectionCheck() {
        const item = sections.find(x => x.section === section);
        
        if(!item) return;
        if(!item.coType.includes(CO_TYPE)) return;
        if(!item.usType.includes(US_TYPE)) return;
        
        setShow(true);
    }

    return (
        <>
            {show &&
                Component
            }
        </>  
    )
}
import { useReducer } from "react";

/* useInput 커스텀 훅은 Input 상태값을 관리하는 기능입니다.
단순 input 데이터를 받을 때 사용하는 기능을 커스텀 훅으로 분리했습니다.

단, initalForm에 name값들을 input받을 데이터를 명시해야 함.
복잡한 데이터 로직은 별도 useReducer 사용해주세요 */

function reducer(state, action) {
    return {
        ...state,
        [action.name]: action.value
    };
}

export default function useInput(initialForm) {
    const [state, dispatch] = useReducer(reducer, initialForm);

    const onChange = (e) => {
        dispatch(e.target);
    };

    return [state, onChange];
}


import { companyWriteApi } from "api/companyApi";
import { userUpdateApi } from "api/userApi";
import SearchAddress from "components/common/SearchAddress";
import useTranslate from "components/language/useTranslate";
import PwdCheckModal from "components/modal/PwdCheckModal";
import { _CO_TYPE_CENTER } from "config";
import { useMyInfo } from "contexts/MyInfoContext";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { cm_acceptFile, cm_formatRegNo, cm_isEmpty, cm_swAlert, resApiCheck, cm_isNumeric, cm_swConfirm, cm_isMobile } from "utils/common";

export default function UserInfo({handleLoad}){
    const t = useTranslate();
    const myInfo = useMyInfo();
    const [memberInfo, setMemberInfo] = useState({
        US_MAP : myInfo.US_MAP,
        CO_MAP : myInfo.CO_MAP,
    });

    const [isEdit, setIsEdit] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [fileList, setFileList] = useState(() => {
        if(!cm_isEmpty(myInfo.CO_DOC_FL)){
            return [myInfo.CO_DOC_FL]
        }else{
            return []
        }
    });
    const [asyncUserUpdate] = useAsync(userUpdateApi);
    const [asyncCompanyUpdate] = useAsync(companyWriteApi)

    // Pwd Check
    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    // Error Msg
    const [isPhoneError, setIsPhoneError] = useState(true);

    // memberInfo change
    const handleChangeMemberInfo = useCallback((e) => {
        let {name, value} = e.target;

        if (name === "CO_REGNO"){
            value = value.replace(/[^0-9]/g, '');
        }

        if (name === "US_PHONE" || name === "CO_TEL"){
            if (e.target.value.length > 11) return;
            if (!cm_isNumeric(value)) return;

            if (name === "US_PHONE"){
                setIsPhoneError(cm_isMobile(value));
            }
        }

        setMemberInfo(produce((prevValue) => {
            if(name.indexOf("CO_") !== -1){
                prevValue.CO_MAP[name] = value;
            }else{
                prevValue.US_MAP[name] = value;
            }
        }));
    },[])

    const handleIsEdit = () => {
        setIsEdit((prevValue) => !prevValue);
    }

    const handleAddressModal = () => {
        setAddressModal((prevValue) => !prevValue);
    };

    const handleFileChange = (files) => {
        const accepts = [".jpg", ".pdf", ".png"];
        let list = cm_acceptFile(accepts, files, t("alert_common_file"));
        setFileList(list);
    }

    // Update
    const handleUpdate = async () => {
        if(!saveValidate()) return; 

        const confirm = await cm_swConfirm(t("confirm_common_save"), "", "info", t("common_yes"), t("common_no"));
        if(!confirm) return;

        handleModal();
    }

    const updateInfo = async () => {
        let resUserUpdate = await userUpdate();
        let resCompanyUpdate = await companyUpdate();

        if(!resApiCheck(resUserUpdate) && !resApiCheck(resCompanyUpdate)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleLoad(myInfo.US_MAP);
            setIsEdit(false);
        }
    }

    const userUpdate = async () => {
        let formData = new FormData();
        formData.append("US_TYPE", memberInfo.US_MAP.US_TYPE);
        formData.append("US_SEQ", memberInfo.US_MAP.US_SEQ);
        formData.append("US_EMAIL", memberInfo.US_MAP.US_EMAIL);
        formData.append("US_NAME", memberInfo.US_MAP.US_NAME);
        formData.append("US_PHONE", memberInfo.US_MAP.US_PHONE);

        if(!cm_isEmpty(fileList)){
            formData.append("CO_DOC", fileList[0]);
        }

        return await asyncUserUpdate(formData);
    }

    const companyUpdate = async () => {
        return await asyncCompanyUpdate({
            CO_SEQ : memberInfo.CO_MAP.CO_SEQ,
            CO_REGNO : memberInfo.CO_MAP.CO_REGNO,
            CO_TEL : memberInfo.CO_MAP.CO_TEL,
            CO_ZIPCODE : memberInfo.CO_MAP.CO_ZIPCODE,
            CO_ADDRESS : memberInfo.CO_MAP.CO_ADDRESS
        })
    }

    const saveValidate = () => {
        // 사업자등록증 파일 체크안함.
        let result = true;
        let text = "";

        if(memberInfo.US_MAP.US_LEVEL === 9){
            //owner
            if(cm_isEmpty(memberInfo.US_MAP.US_NAME)){
                text = t("alert_inputName");
                result = false;
            }else if(cm_isEmpty(memberInfo.US_MAP.US_PHONE)){
                text = t("common_content_3");
                result = false;
            }else if(cm_isEmpty(memberInfo.CO_MAP.CO_REGNO)){
                text = t("common_content_4");
                result = false;
            }else if(cm_isEmpty(memberInfo.CO_MAP.CO_ADDRESS)){
                text = t("common_content_6");
                result = false;
            }
        }else if(memberInfo.US_MAP.US_LEVEL <= 2){
            //staff
            if(cm_isEmpty(memberInfo.US_MAP.US_PHONE)){
                text = t("common_content_3");
                result = false;
            }
        }

        if(!result) cm_swAlert(text, "info", t("common_check"));

        return result;
    }

    return(
        <>
            <div className="form-inner">
                {memberInfo.CO_MAP.CO_TYPE === _CO_TYPE_CENTER ?
                    <QuveStaff memberInfo={memberInfo} onChange={handleChangeMemberInfo} isEdit={isEdit} isPhoneError={isPhoneError}/>
                    :
                    <>
                        {memberInfo.US_MAP.US_LEVEL < 9 ?
                            <UserStaff memberInfo={memberInfo} onChange={handleChangeMemberInfo} isEdit={isEdit} isPhoneError={isPhoneError}/>
                            :
                            <UserOwner memberInfo={memberInfo} setMemberInfo={setMemberInfo} onChange={handleChangeMemberInfo} isEdit={isEdit} addressModal={addressModal} handleAddressModal={handleAddressModal} handleFileChange={handleFileChange} fileList={fileList} isPhoneError={isPhoneError} />
                        }
                    </>
                }
                
                {/* Edit,Save Button */}
                <button className="btn btn-fill-blue block" onClick={isEdit ? handleUpdate : handleIsEdit}>{isEdit ? t("common_save") : t("myPage_editMbInfo")}</button>
            </div>

            <Modal show={isOpen} onHide={handleModal} centered>
                <PwdCheckModal isOpen={isOpen} handleModal={handleModal} updateInfo={updateInfo} />
            </Modal>
        </>
    )
}

function QuveStaff({memberInfo, onChange, isEdit, isPhoneError}) {
    const {US_MAP, CO_MAP} = memberInfo;
    const t = useTranslate();

    return(
        <>
            {/* Company */}
            <h3 className="h3">{t("common_bizInfo")}</h3>
            <h4 className="form-tit">{t("common_companyName")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="CO_NAME" value={CO_MAP.CO_NAME} disabled/>
            </div>

            <hr/>

            {/* User */}
            <h3 className="h3">{t("myPage_myinfo")}</h3>
            <h4 className="form-tit">{t("common_email")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_EMAIL" value={US_MAP.US_EMAIL} disabled/>
            </div>
            <h4 className="form-tit">{t("common_name")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_NAME" value={US_MAP.US_NAME} onChange={onChange} disabled={!isEdit} />
            </div>
            <h4 className="form-tit">{t("common_phoneNumber")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_PHONE" value={US_MAP.US_PHONE} onChange={onChange} disabled={!isEdit} />
            </div>
            {!isPhoneError && <div className="form-msg txt-red">{t("common_invalidPhNum")}</div>}
            <h4 className="form-tit">Slack ID</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_NOTICE" value={US_MAP.US_NOTICE} onChange={onChange} disabled={!isEdit} />
            </div>
        </>
    )
}

function UserOwner({memberInfo, setMemberInfo, onChange, isEdit, isPhoneError}) {
    const {US_MAP, CO_MAP} = memberInfo;
    const t = useTranslate();

    const handleZipcode = (value) => {
        setMemberInfo(produce((prevValue) => {
            prevValue.CO_MAP.CO_ZIPCODE = value;
        }));
    }

    const handleAddress = (value) => {
        setMemberInfo(produce((prevValue) => {
            prevValue.CO_MAP.CO_ADDRESS = value;
        }));
    }

    return(
        <>  
            {/* Company */}
            <h3 className="h3">{t("common_bizInfo")}</h3>
            <h4 className="form-tit">{t("common_companyName")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="CO_NAME" value={CO_MAP.CO_NAME} disabled/>
            </div>
            <h4 className="form-tit">{t("common_licenseNum")}<span className='required'>＊</span></h4>
            <div className="inp-box inp-btn-box">
                <input type="text" className="inp block" name="CO_REGNO" value={cm_formatRegNo(CO_MAP.CO_REGNO)} onChange={onChange} disabled={!isEdit} maxLength={12} />
            </div>
            <h4 className="form-tit">{t("common_rePresentativePhone")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="CO_TEL" value={CO_MAP.CO_TEL} onChange={onChange} disabled={!isEdit} />
            </div>

            <h4 className="form-tit">{t("common_address")}<span className='required'>＊</span></h4>
            <div className="inp-box inp-btn-box">
                <input type="text" className="inp block" name="CO_ZIPCODE" placeholder={t("common_postalCode")} value={CO_MAP.CO_ZIPCODE} disabled />
                <SearchAddress handleZipcode={handleZipcode} handleAddress={handleAddress} isEdit={isEdit} />
            </div>
            <div className="inp-box">
                <input type="text" className="inp block" name="CO_ADDRESS" placeholder={t("common_address")} value={CO_MAP.CO_ADDRESS} onChange={onChange} disabled={!isEdit} />
            </div>
            
            <hr/>

            {/* User */}
            <h3 className="h3">{t("myPage_myinfo")}</h3>
            <h4 className="form-tit">{t("common_email")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_EMAIL" value={US_MAP.US_EMAIL} disabled/>
            </div>
            <h4 className="form-tit">{t("common_name")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_NAME" value={US_MAP.US_NAME} onChange={onChange} disabled={!isEdit} />
            </div>
            <h4 className="form-tit">{t("common_phone")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_PHONE" value={US_MAP.US_PHONE} onChange={onChange} disabled={!isEdit} />
            </div>
            <h4 className="form-tit">{t("common_receiveNotice")}</h4>
            <div className="inp-box">
                <div className="flex gap10">
                    <label className="flex1">
                        <input type="radio" className="radio" name="US_NOTICE" value={"Y"} checked={"Y" === US_MAP.US_NOTICE} onChange={onChange} disabled={!isEdit}/>
                        <div><em></em><p>{t("common_yes")}</p></div>
                    </label>
                    <label className="flex1">
                        <input type="radio" className="radio" name="US_NOTICE" value={"N"} checked={"N" === US_MAP.US_NOTICE} onChange={onChange} disabled={!isEdit}/>
                        <div><em></em><p>{t("common_no")}</p></div>
                    </label>
                </div>
            </div>
            {!isPhoneError && <div className="form-msg txt-red" style={{marginBottom:"10px"}}>{t("common_invalidPhNum")}</div>}
        </>
    )
}

function UserStaff({memberInfo, onChange, isEdit, isPhoneError}) {
    const {CO_MAP, US_MAP} = memberInfo;
    const t = useTranslate();

    return(
        <>
            {/* Company */}
            <h3 className="h3">{t("common_bizInfo")}</h3>
            <h4 className="form-tit">{t("common_companyName")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_EMAIL" value={CO_MAP.CO_NAME} disabled/>
            </div>

            <hr/>

            {/* User */}
            <h3 className="h3">{t("myPage_myinfo")}</h3>
            <h4 className="form-tit">{t("common_email")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_EMAIL" value={US_MAP.US_EMAIL} disabled/>
            </div>
            <h4 className="form-tit">{t("common_name")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_NAME" value={US_MAP.US_NAME} onChange={onChange} disabled={!isEdit} />
            </div>
            <h4 className="form-tit">{t("common_phone")}</h4>
            <div className="inp-box">
                <input type="text" className="inp block" name="US_PHONE" value={US_MAP.US_PHONE} onChange={onChange} disabled={!isEdit} />
            </div>
            {!isPhoneError && <div className="form-msg txt-red" style={{marginBottom:"10px"}}>{t("common_invalidPhNum")}</div>}
        </>
    )
}
import useTranslate from "components/language/useTranslate";
import PlanList from "components/manage/PlanList";
import PlanWrite from "components/manage/PlanWrite";
import { useState } from "react";

export default function PlanMng(){
    const [isWrite, setIsWrite] = useState(false);
    const handleIsWrite = () => setIsWrite((prevValue) => !prevValue);
    const t = useTranslate();

    // Plan Info
    const [plan, setPlan] = useState({
        PL_SEQ: 0,
        PL_TYPE: "B",
        PL_NAME: "",
        PL_MONTH: "",
        PL_PRICE: "",
        PL_BASEPRICE: "",
        PL_DESC: ""
    });

    const [planDevice, setPlanDevice] = useState([]);

    return(
        <>
            <div id="container">
                <div className="admin-content">
                    <div className="title-head">
                        <h3>{t("plan_planMg")}</h3>
                    </div>
                    {isWrite
                        ? <PlanWrite handleIsWrite={handleIsWrite} plan={plan} setPlan={setPlan} planDevice={planDevice} setPlanDevice={setPlanDevice}/>
                        : <PlanList handleIsWrite={handleIsWrite} setPlan={setPlan} setPlanDevice={setPlanDevice}/>
                    }
                </div>
            </div>
        </>
    )
}


import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { boardDeleteApi, boardReadApi, boardWriteApi } from "api/boardApi";
import CalendarInput from "components/common/CalendarInput";
import Editor from "components/common/Editor";
import Radio from "components/common/Radio";
import RadioGroup from "components/common/RadioGroup";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { cm_acceptFile, cm_dateAddDay, cm_dateMoment, cm_dateToday, cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";
import DropFileInputWide from "components/DropFileInputWide";
import useTranslate from "components/language/useTranslate";

export default function BoardWrite(){
    const t = useTranslate();

    const location = useLocation();
    const navigate = useNavigate();

    //TODO 코멘트 삭제, HIT, style
    const [period, setPeriod] = useState("");
    const [boardMaster, setBoardMaster] = useState(null);
    const [board, setBoard] = useState({
        BC_SEQ: 0,
        BM_TYPE: "",
        BC_TITLE: "",
        BC_CONTENTS: "",
        BC_SDATE: cm_dateToday(),
        BC_EDATE: cm_dateToday(),
    });
    const [fileList, setFileList] = useState([]);
    const [delFiles, setDelFiles] = useState([]);

    // Api
    const [asyncBoardRead] = useAsync(boardReadApi);
    const [asyncBoardWrite] = useAsync(boardWriteApi);
    const [asyncBoardDelete] = useAsync(boardDeleteApi);

    useEffect(() => {
        if(location.state.boardMaster !== undefined){
            setBoardMaster(location.state.boardMaster);
        }

        if(location.state.BC_SEQ !== undefined){
            handleLoad();
        }

    },[]);

    const handleLoad = async () => {
        const response = await asyncBoardRead({
            BC_SEQ: location.state.BC_SEQ
        });
        if(!resApiCheck(response)) return;
        setBoard(response.data.BC_MAP);
        setFileList(response.data.FL_LIST);
    }
    
    //FILE
    const handleFileChange = (files) => {
        const accepts = [".jpg", ".pdf", ".png", ".txt"];
        let list = cm_acceptFile(accepts, files, t("alert_common_file"));

        setFileList(list);
    }

    function deleteFile(FL_SEQ) {
        let delFile = {FL_SEQ: FL_SEQ};
        setDelFiles([...delFiles, delFile]);
    }

    //Board Change
    const handlePeriod = (value) => {
        let date = "";
        
        switch (value) {
            case "week":
                date = cm_dateMoment(cm_dateAddDay(cm_dateToday(), +7));
                break;
            case "month":
                date = cm_dateMoment(cm_dateAddDay(cm_dateToday(), +30));
                break;
            case "quarter":
                date = cm_dateMoment(cm_dateAddDay(cm_dateToday(), +90));
                break;
            case "year":
                date = cm_dateMoment(cm_dateAddDay(cm_dateToday(), +365));
                break;
            default:
                date = board.BC_EDATE;
                break;
        }
        
        setPeriod(value);
        setBoard(produce(prevValue => {
            prevValue.BC_SDATE = cm_dateToday();
            prevValue.BC_EDATE = date;
        }))
    }

    const handleStartDate = (date) => {
        setPeriod("entire");

        setBoard(produce((prevValue) => {
            prevValue.BC_SDATE = date;
        }));
    };

    const hanldeEndDate = (date) => {
        setPeriod("entire");

        setBoard(produce((prevValue) => {
            prevValue.BC_EDATE = date;
        }));
    };

    const handleTitle = (e) => {
        const title = e.target.value;

        setBoard(produce((prevValue) => {
            prevValue.BC_TITLE = title;
        }))
    }

    const handleContents = (contents) => {
        setBoard(produce(prevValue => {
            prevValue.BC_CONTENTS = contents;
        }))
    }

    //Save
    const handleBoardWrite = async () => {
        // TODO: validate
        if(!boardValidate()) return;

        let formData = new FormData();
        formData.append("BC_SEQ", board.BC_SEQ);
        formData.append("BM_TYPE", boardMaster.BM_TYPE);
        formData.append("BC_TITLE", board.BC_TITLE);
        formData.append("BC_CONTENTS", board.BC_CONTENTS);
        formData.append("DELFILE_LIST", JSON.stringify(delFiles));
        if(boardMaster.BM_TYPE === "N"){
            formData.append("BC_SDATE", board.BC_SDATE);
            formData.append("BC_EDATE", board.BC_EDATE);
        }

        if(!cm_isEmpty(fileList)){
            for(let file of fileList){
                if(!cm_isEmpty(file.FL_SEQ)) continue;
                formData.append("file", file);
            }
        }

        const response = await asyncBoardWrite(formData);
        if(!resApiCheck(response)) return;
        if(!cm_isEmpty(response.data.BC_SEQ)){
            cm_swAlert(t("alert_save_success"), "info", t("common_check"));
            navigate("/board/boardread", {state: {BC_SEQ: response.data.BC_SEQ, boardMaster: boardMaster}});
        }else{
            cm_swAlert(t("alert_save_failed"), "info", t("common_check"));
        }
        
    }

    function boardValidate(){
        return true;
    }

    //Delete
    const handleBoardDelete = async () => {
        const response = await asyncBoardDelete({
            BC_SEQ : board.BC_SEQ
        })
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_delete_failed"), "info", t("common_check"));
        }else{
            cm_swAlert(t("alert_delete_success"), "info", t("common_check"));
            navigate("/board");
        }
    }

    return(
        <>
            <div className="board-head">
                <h3 className="h3">{t("board_write")}</h3>
            </div>

            <div className="board-write" style={{paddingTop:"20px"}}>
                <table>
                    <colgroup>
                        <col style={{width:"150px"}} />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("common_category")}</th>
                            <td>
                                {boardMaster && boardMaster.BM_TITLE}
                            </td>
                        </tr>
                        {(boardMaster && boardMaster.BM_TYPE === "N") &&
                            <tr>
                                <th>{t("board_viewDate")}</th>
                                <td>
                                    <div className="flex gap12 align-items-center" style={{height:"45px"}}>
                                        <RadioGroup value={period} onChange={handlePeriod}>
                                            <Radio value="week" defaultChecked><div><em></em><p>{t("order_week")}</p></div></Radio>
                                            <Radio value="month"><div><em></em><p>{t("common_oneMonth")}</p></div></Radio>
                                            <Radio value="quarter"><div><em></em><p>{t("order_threeMonth")}</p></div></Radio>
                                            <Radio value="year"><div><em></em><p>{t("common_oneYear")}</p></div></Radio>
                                            <Radio value="entire"><div><em></em><p>{t("common_directSelection")}</p></div></Radio>
                                        </RadioGroup>

                                        <div style={{position:"relative", zIndex:"2"}}>
                                            <CalendarInput
                                                onChange={handleStartDate}
                                                name="BC_SDATE"
                                                value={board.BC_SDATE}
                                                option={{minDate: cm_dateToday()}}
                                                inputAddStyle={{width: "250px"}}
                                                isOn={false}
                                            />
                                        </div>
                                        <div style={{position:"relative", zIndex:"2"}}>
                                            <CalendarInput
                                                onChange={hanldeEndDate}
                                                name="BC_EDATE"
                                                value={board.BC_EDATE}
                                                option={{minDate: cm_dateToday()}}
                                                inputAddStyle={{width: "250px"}}
                                                isOn={false}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t("board_title")}</th>
                            <td>
                                <input type="text" className="inp block" defaultValue={board.BC_TITLE} onChange={handleTitle} placeholder={t("board_titlePh")}maxLength={66}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("board_content")}</th>
                            <td colSpan="2" style={{paddingRight:"30px"}}>
                                <Editor contents={board.BC_CONTENTS} handleContents={handleContents}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t("common_attachFile")}</th>
                            <td>
                                <div className="dragdrop-wrap">
                                    <div className="row">
                                        <DropFileInputWide 
                                            onChangeFile={(files) => handleFileChange(files)} 
                                            fileList={fileList}
                                            deleteFile={(FL_SEQ) => deleteFile(FL_SEQ)}
                                        >
                                            <p>Drag &amp; Drop</p>
                                            <em>{t("common_text_file_drag")}</em>
                                        </DropFileInputWide>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="text-right">
                <button className="btn btn-line-blue2 btn-round btn-s" style={{marginRight:"20px"}} value="list" onClick={()=> navigate("/board")}>{t("common_list")}</button>
                {board.BC_SEQ > 0 &&
                    <button className="btn btn-line-blue2 btn-round btn-s" style={{marginRight:"20px"}} onClick={handleBoardDelete}>{t("myPage_del")}</button>
                }
                <button className="btn btn-line-blue2 btn-round btn-s" onClick={handleBoardWrite}>{t("common_regi")}</button>
            </div>
        </>
    )
}
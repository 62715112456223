import useTranslate from 'components/language/useTranslate';
import { useState } from "react";
import UserImplantOpt from "./UserImplantOpt";
import UserProstheticOpt from "./UserProstheticOpt";

export default function UserOption(){
    const [tab, setTab] = useState("prosthetic");
    const t = useTranslate();
    
    const handleChangeTab = (value) => setTab(value);

    return(
        <>
            <div className="tabs3">
                <button className={tab === "prosthetic" ? "active" : null} value="prosthetic" onClick={(e) => handleChangeTab(e.target.value)}>{t("myPage_pstheticOpt")}</button>
                <button className={tab === "implant" ? "active" : null} value="implant" onClick={(e) => handleChangeTab(e.target.value)}>{t("myPage_oftenUseImpl")}</button>
            </div>
            <UserOptionTap tab={tab}/>
        </>
    )
}

function UserOptionTap({tab}){
    switch(tab){
        case 'prosthetic':
            return <UserProstheticOpt/>;
        case 'implant' :
            return <UserImplantOpt/>;
        default :
            return null;
    }

}
import useTranslate from "components/language/useTranslate";
import { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'; // CSS import
import { cm_dateMoment, cm_isEmpty } from "utils/common";

export default function CalendarDropdown({ name, value, minDate, onChange, holidayList=[], inputAddStyle=null }) {
    const t = useTranslate();
    const calendarRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    // Toggle
    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen((prevValue) => !prevValue);
    }

    const handleChange = (date) => {
        onChange(date);
        setIsOpen(false);
    }

    const disableDate = ({date}) => {
        if (!cm_isEmpty(holidayList)) {
            if ([0].includes(date.getDay())) return true;
            if (holidayList.map(x => x.HD_DATE).includes(cm_dateMoment(date, "YYYY-MM-DD"))) return true;
        }
        
        return false;
    }

    const setTileClassName = ({date}) => {
        return holidayList.map(x => x.HD_DATE).includes(cm_dateMoment(date, "YYYY-MM-DD")) ? 'holiday' : null;
    }

    return (
        <div className="calendar-dropdown-container" ref={calendarRef}>
            <input type="text" className="inp block calendar" style={inputAddStyle}
                name={name}
                value={value}
                readOnly
                onClick={handleToggle}
            />
            {isOpen && (
                <div className="calendar-menu" onToggle={handleToggle}>
                    <Calendar
                        calendarType="US" // 일요일부터 시작
                        locale={t("common_locale")} // 언어 설정
                        next2Label={null} // 년단위 이동버튼(다음)
                        prev2Label={null} // 년단위 이동버튼(이전)
                        minDate={minDate} // 선택할수 있는 최소 날짜
                        minDetail={"month"}  // 사용자가 볼수 있는 가장 상세한 보기
                        maxDetail={"month"}  // 사용자가 볼수 있는 최소한의 상세 보기
                        showNeighboringMonth={false}      // 이전, 이후 달의 날짜는 보이지 않도록 설정
                        formatDay={(locale, date) => cm_dateMoment(date, "DD")} // 날'일' 제외하고 숫자만 보이도록 설정
                        tileDisabled={disableDate}   //특정 날 disable
                        tileClassName={setTileClassName}
                        name={name}
                        value={value}
                        onChange={(date) => handleChange(cm_dateMoment(date, "YYYY-MM-DD"))}
                    />
                </div>
            )}
        </div>
    )
}

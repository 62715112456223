import useTranslate from "components/language/useTranslate";
import OrderLineDetail from "components/order/OrderLineDetail";
import { useState } from "react";
import { cm_isEmpty, cm_swConfirm, setButtonText } from "utils/common";

export default function OrderModifyModal({isOpen, handleModal, orderMap, setOrderMap, orderUpdateCall, handleOrderStatus}) {
    const t = useTranslate();
    const {OD_MAP} = orderMap;

    const handleOrderComplete = async () => {
        const confirm = await cm_swConfirm(t("confirm_order_complete"), "", "info");

        if(confirm){
            handleModal();
            handleOrderStatus("C");
        }
    }

    const [remakeCount, setRemakeCount] = useState(OD_MAP.OD_REMAKE);
    const handleRemakeCount = (e) => {
        setRemakeCount(e.target.value)
    }

    const updateRemakeCount = () => {
        orderUpdate(OD_MAP.OD_STATUS, null, remakeCount);
    }

    function orderUpdate(OD_STATUS, OL_LIST, OD_REMAKE){
        const {OD_SEQ, OD_UKEY} = orderMap.OD_MAP;

        let formData = new FormData();
        formData.append("OD_SEQ", OD_SEQ);
        formData.append("OD_UKEY", OD_UKEY);
        formData.append("OD_STATUS", OD_STATUS);
        formData.append("OL_LIST", JSON.stringify(OL_LIST));

        if(!cm_isEmpty(OD_REMAKE)){
            formData.append("OD_REMAKE", OD_REMAKE);
        }
        
        orderUpdateCall(OD_SEQ, OD_STATUS, formData);
        handleModal();
    }

    return (
        <>
            <div className="modal fade" id="Modal1" style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"1200px", margin:"50px auto"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">{t("order_modify")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                        </div>

                        <div className="modal-body">
                            <div className="order-modify item">
                                <dl>
                                    <dt>{t("order_orderState")}</dt>
                                    <dd>
                                        <button className="btn btn-fill-blue3" onClick={handleOrderComplete}>
                                            {setButtonText("C")}
                                            <i className="xi-angle-right"></i>
                                        </button>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{t("common_remakeCnt")}</dt>
                                    <dd>
                                        <input type="number" className="inp block" maxLength={50} defaultValue={remakeCount} onChange={handleRemakeCount} />
                                    </dd>
                                    <dd>
                                        <button type="button" className="btn btn-fill-gray btn-s" onClick={updateRemakeCount}>{t("common_save")}</button>

                                    </dd>
                                </dl>
                            </div>
                            <hr/>
                            <OrderLineDetail orderMap={orderMap} setOrderMap={setOrderMap}/>
                        </div>

                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-fill-gray" data-bs-dismiss="modal" onClick={handleModal}>{t("common_cancel")}</button>
                            <button type="button" className="btn btn-fill-blue" onClick={handleModal}>{t("common_check")}</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
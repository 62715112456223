import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";

export default function OrderTags({OD_MAP}) {
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const {OD_REMAKE, OD_REPAIR, OD_RESCAN, OD_PLASTATUS, OD_DC} = OD_MAP;
    const t = useTranslate();


    return (
        <div className="tags">
            {OD_REMAKE > 0 && <p className="tag1">#{t("common_remake")}</p>}
            {OD_REPAIR > 0 && <p className="tag5">#{t("common_repair")}</p>}
            {CO_TYPE !== _CO_TYPE_LAB &&
                <>
                    {OD_RESCAN > 0 && <p className="tag2">#{t("common_rescan")}</p>}
                    {OD_PLASTATUS !== "N" && <p className="tag3">#{t("common_print")}</p>}
                    {OD_DC === "Y" && <p className="tag4">#{t("common_designReview")}</p>}
                </>
            }
        </div>
    )
}
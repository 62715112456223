const { accountListApi } = require("api/accountApi");
const { default: useTranslate } = require("components/language/useTranslate");
const { ACCOUNT_STATUS } = require("config");
const { useStore } = require("contexts/Store");
const { default: useAsync } = require("hooks/useAsync");
const { useEffect } = require("react");
const { useState } = require("react");
const { cm_dateYearList, cm_dateToday, resApiCheck, cm_isEmpty, cm_findData, cm_numComma, cm_dateMoment, cm_dateAddMonth } = require("utils/common");
const { default: DetailPayment } = require("./DetailPayment");

export default function AccountList({payInfo}){
    const store = useStore();
    const {US_MAP} =  store;
    const accountStatus = ACCOUNT_STATUS();
    const yearList = cm_dateYearList("2023", cm_dateToday("YYYY"));
    
    const [accountList, setAccountList] = useState(null);
    const [account, setAccount] = useState(null);
    const [searchYear, setSearchYear] = useState(cm_dateToday(cm_dateToday("YYYY")));
    const [detailView, setDetailView] = useState(false);
    
    const [asyncAccountList] = useAsync(accountListApi);

    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    useEffect(() => {
        handleLoad();
    },[searchYear]);

    const handleLoad = async () => {
        let fromMonth = searchYear + "-01";
        let toMonth = searchYear + "-12";

        let response = await asyncAccountList({
            CO_SEQ : US_MAP.CO_SEQ,
            FT_FRMONTH : fromMonth,
            FT_TOMONTH : toMonth
        });
        if(!resApiCheck(response)) return;
        if(response.data.LIST_COUNT > 0){
            let list = response.data.LIST.filter(x=> x.AB_STATUS !== "M");
            if(!cm_isEmpty(list)){
                setAccountList(list);
            }
        }else{
            setAccountList(null);
        }
    }

    const handleChangeSearchYear = (e) => {
        setAccount(null);
        setDetailView((prevValue) => !prevValue);
        setSearchYear(e.target.value);
    }

    const onClickDetail = (account) => {
        setAccount(account);
        setDetailView((prevValue) => !prevValue);
    }

    return(
        <>
            <div className="inquiry-box pd20">
                <div className="mb20">
                    <select className="select" value={searchYear} onChange={handleChangeSearchYear}>
                        {yearList && 
                            yearList.map((year) => {
                                return <option key={`account_${year}`} value={year}>{year}{t("common_year")}</option>
                            })
                        }
                    </select>
                </div>
                <div className="table2 cursor">
                    <table>
                        <thead>
                            <tr>
                                <th>{t("payment_billMonth")}</th>
                                <th>{t("payment_totalCost")}</th>
                                <th>{t("payment_adjustCost")}</th>
                                <th>{t("payment_billCost")}</th>
                                <th>{t("payment_payCost")}</th>
                                <th>{t("payment_unpaid")}</th>
                                <th>{t("common_status")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accountList ? 
                                accountList.filter(x=> x.AB_STATUS !== "M").map((account) => {
                                    let statusText = cm_findData(accountStatus, "status", account.AB_STATUS, "text");
                                    return(
                                        <tr key={account.AB_MONTH} onClick={() => onClickDetail(account)}>
                                            <td>{account.AB_MONTH}</td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_TAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_AAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_IAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(account.AB_DAMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong className="txt-red">{cm_numComma(account.AB_RAMOUNT)} <small className="txt-red">{SYMBOL}</small></strong>
                                                </div>
                                            </td>
                                            <td style={account.AB_STATUS === "S" ? {color:"#4C90F6"} : null}>{statusText}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan='7' align='center'>{t("payment_noBill")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            {detailView && account &&
                <DetailPayment payInfo={payInfo} account={account} MONTH={cm_dateMoment(cm_dateAddMonth(account.AB_MONTH  + "-01", -1))} CO_TYPE={US_MAP.CO_TYPE} accountListReLoad={handleLoad} setDetailView={setDetailView}/>
            }
        </>

    )
}
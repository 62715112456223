import useTranslate from 'components/language/useTranslate';
import { _CO_TYPE_DESIGN } from 'config';
import { useMyInfo } from "contexts/MyInfoContext";
import { cm_isEmpty, cm_numComma } from 'utils/common';

export default function CompanyProductPrice(){
    const t = useTranslate();
    const myInfo = useMyInfo();
    const {CR_LIST, US_MAP} = myInfo;

    return(
        <div className="table1">
            <table>
                <thead>
                    <tr>
                        <th>{t("common_type")}</th>
                        {US_MAP.CO_TYPE === _CO_TYPE_DESIGN ?
                            <th>{`${t("payment_pstheticCost")}(Design)`}</th>
                            :
                            <>
                                <th>{t("payment_pstheticCost")}</th>
                                <th>{`${t("payment_pstheticCost")}(Pontic)`}</th>
                            </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {(!cm_isEmpty(CR_LIST)) ?
                            CR_LIST.filter(x => x.CR_SALES === "Y").map((product) => {
                                const {PR_SEQ, PR_CODE, CR_PRICE, CR_PNPRICE, CR_DSPRICE} = product;

                                return(
                                    <tr key={`PR_${PR_SEQ}`}>
                                        <td>{PR_CODE}</td>
                                        {US_MAP.CO_TYPE === _CO_TYPE_DESIGN ?
                                            <td>{cm_numComma(CR_DSPRICE)}</td>
                                            :
                                            <>
                                                <td>{cm_numComma(CR_PRICE)}</td>
                                                <td>{cm_numComma(CR_PNPRICE)}</td>
                                            </>
                                        }
                                    </tr>
                                )
                            })
                        
                        :
                        <tr><td colSpan='3' align='center'>{t("common_noResult")}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
export default function ShadeImage({shade, shadeSelect, imgSize, handleModal}){
    const [top, bottom] = shade.split("/");
    return (
        <>
            <div className={"tooth " + imgSize} onClick={handleModal}>
                <svg width="118" height="151" viewBox="0 0 118 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 2433">
                        <path id="Vector" d="M9.80702 3.05957C10.207 2.84957 10.637 2.71957 11.087 2.66957C19.217 1.89957 99.087 -5.09043 111.417 16.7296C124.417 39.7296 110.417 112.73 103.417 128.73C96.417 144.73 71.417 150.73 60.417 149.73C49.417 148.73 22.417 141.73 17.417 127.73C12.417 113.73 -1.58298 40.7296 1.41702 16.7296C1.41702 16.7296 2.41702 8.72957 4.41702 6.72957C5.93702 5.20957 8.60702 3.68957 9.80702 3.04957V3.05957Z" fill="white" stroke="#4C90F6" strokeMiterlimit="10"/>
                        <g id="Group 2428">
                            <g id="Tooth1" className={top && "on"}>
                                <path id="Vector_3" d="M110.742 17.0058C98.5603 -4.53359 19.7174 2.36652 11.692 3.12662C11.2478 3.1661 10.8233 3.29443 10.4284 3.5116C9.24388 4.14337 6.60821 5.64383 5.10776 7.14428C3.14335 9.10869 2.15621 17.0058 2.15621 17.0058C0.685372 28.7528 3.35065 52.3948 7.00307 74.941H114.187C116.911 52.0986 117.158 28.3678 110.742 17.0058Z" fill="#fff"/>
                            </g>
                            <g id="Tooth2" className={bottom && "on"}>
                                <path id="Vector_2" d="M6.99976 76C10.705 98.7683 15.445 120.399 17.9284 127.315C22.8556 141.048 49.4627 147.915 60.3027 148.896C71.1426 149.877 95.7788 143.991 102.677 128.296C106.126 120.458 111.29 98.6604 114 76H6.99976Z" fill="white"/>
                            </g>
                            <path id="Vector 9" d="M5.99976 75L114 75" stroke="#4C90F6" strokeDasharray="6 6"/>
                        </g>
                    </g>
                </svg>
                {
                    shadeSelect ?
                        <div className="selected">
                            <p onClick={() => shadeSelect("top")}>{top}</p>
                            <p onClick={() => shadeSelect("bottom")}>{bottom}</p>
                        </div>
                    :
                        <div className="selected">
                            <p>{top}</p>
                            <p>{bottom}</p>
                        </div>
                }
            </div>
        </>
    )
}
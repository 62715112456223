import { ORDER_STATUS } from "config";
import { useStore } from "contexts/Store";

export default function OrderStatusFilter({statusFilter, handleFilter, orderCnt}){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const orderStatus = ORDER_STATUS();

    return (
        <div className="orderlist-filter-wrap">
            {
                orderStatus.filter(x => x.coType.indexOf(CO_TYPE) !== -1).map((item) => {
                    const status = item.key;
                    const statusCnt = orderCnt[item.col];

                    const isOn = statusFilter.includes(`'${status}'`) ? "on" : "";
                    const isStress = (status === "R" && statusCnt > 0) ? "stress" : "";

                    return (
                        <div key={`filter-${status}`} className={`filter-list status-${status}`}>
                            <button 
                                type="button" 
                                name="status" 
                                value={`'${status}'`}
                                className={`filter ${isOn} ${isStress}`}
                                onClick={handleFilter}
                            >
                                <i className={`icon-order ${status}`}></i>
                                <p className="text">{item.text}</p>
                                <p className="num">{statusCnt}</p>
                            </button>
                        </div>
                    )
                })
            }
        </div>
    )
}
import useTranslate from "components/language/useTranslate";
import { ORDER_STATUS, PLA_STATUS, _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import { cm_dateDDay, cm_dateDiff, cm_dateMoment, cm_dateToday, cm_isEmpty, sortbyToothPosition } from "utils/common";
import OrderTags from "./OrderTags";

export default function OrderList({orderList, orderLineList, handleOrderMap}){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const t = useTranslate();
    const orderStatus = ORDER_STATUS();
    const plaStatus = PLA_STATUS();

    return (
        <>
            <div className="order-list cursor">
                <table>
                    <colgroup>
                        <col style={{width:"10%"}}/>
                        {CO_TYPE !== _CO_TYPE_LAB && <col style={{width:"3%"}}/>}
                        <col style={{width:"10%"}}/>
                        <col style={{width:"10%"}}/>
                        {CO_TYPE !== _CO_TYPE_CLINIC && CO_TYPE !== _CO_TYPE_DESIGN && <col style={{width:"10%"}}/>}
                        <col style={{width:"10%"}}/>
                        <col />
                        <col style={{width:"10%"}}/>
                        <col style={{width:"10%"}}/>
                        <col style={{width:"10%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("order_orderState")}</th>
                            {CO_TYPE !== _CO_TYPE_LAB && <th></th>}
                            <th>{"D-Day"}</th>
                            <th>{t("payment_recpDat")}</th>
                            {CO_TYPE !== _CO_TYPE_CLINIC && CO_TYPE !== _CO_TYPE_DESIGN && <th>{t("common_dt")}</th>}
                            <th>{t("common_patientName")}</th>
                            <th>{t("payment_dtFormula")}</th>
                            <th>{t("common_dtl")}</th>
                            <th>{t("order_issue")}</th>
                            <th>{t("common_remakeCnt")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {!cm_isEmpty(orderList) ? 
                        orderList.map((order) => {
                            const orderLineFilter = orderLineList.filter(x => x.OD_SEQ === order.OD_SEQ);
                            const teethList = sortbyToothPosition(orderLineFilter).map(x => (x.OL_TOOTH === "u" ? "상악" : x.OL_TOOTH === "l" ? "하악" : x.OL_TOOTH));
                            const teeth = [...new Set(teethList)].join(", ");

                            return(
                                <tr key={"orderCard_" + order.OD_SEQ} onClick={() => handleOrderMap(order.OD_SEQ)}>
                                    <td>
                                        <span className={`state-${order.OD_STATUS}`}>{orderStatus.find(x => x.key === order.OD_STATUS).text}</span>
                                    </td>
                                    {CO_TYPE !== _CO_TYPE_LAB &&
                                    <td>
                                        {order.OD_PLASTATUS !== "N" && 
                                            <i className={(`print-img ${plaStatus.find(x => x.key === order.OD_PLASTATUS).class}`)}/>
                                        }
                                    </td>
                                    }
                                    <td>
                                        <div className="date">
                                            {
                                                order.OD_STATUS === "C" ?
                                                    <>
                                                        <strong>{t("order_completeOrderDate")}</strong>
                                                        <p>{cm_dateMoment(order.OD_COMDTTM)}</p>
                                                    </>
                                                    :
                                                    <>
                                                        <strong className={cm_dateDiff(cm_dateToday(), order.OD_DUEDATE) < 4 ? "txt-red" : null}>{cm_dateDDay(order.OD_DUEDATE)}</strong>
                                                        <p>{order.OD_DUEDATE}</p>
                                                    </>
                                            }
                                        </div>
                                    </td>
                                    <td>{cm_dateMoment(order.OD_REGDTTM, "YYYY-MM-DD")}</td>
                                    {CO_TYPE !== _CO_TYPE_CLINIC && CO_TYPE !== _CO_TYPE_DESIGN && <td>{order.CO_NAME}</td>}
                                    <td>{order.OD_NAME}</td>
                                    <td>{teeth}</td>
                                    <td>{order.LAB_NAME && <>{order.LAB_NAME}</>}</td>
                                    <td className="list-tags"><OrderTags OD_MAP={order} /></td>
                                    <td>{order.OD_REMAKE}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan='10' align='center'>{t("common_noResult")}</td></tr>
                    }
                    </tbody>
                </table>
            </div>
            
        </>
    )
}
import { accountRealTimeApi } from "api/accountApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CLINIC } from "config";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useCallback, useEffect, useState } from "react";
import { cm_dateToday, cm_isEmpty, cm_numComma, resApiCheck } from "utils/common";
import DetailPayment from "./DetailPayment";

export default function RealTime({payInfo}){
    const store = useStore();
    const {US_MAP} = store;
    const [account, setAccount] = useState(null);
    
    //API
    const [asyncAccountRealTime] = useAsync(accountRealTimeApi);
    
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
    },[]);
    
    const handleLoad = async () => {
        const resAccountRealTime = await asyncAccountRealTime({
            CO_SEQ : US_MAP.CO_SEQ
        })
        if(!resApiCheck(resAccountRealTime)) return;
        if(!cm_isEmpty(resAccountRealTime.data.AB_MAP)){
            setAccount(resAccountRealTime.data.AB_MAP);
        }
    }

    const getTotalAmount = useCallback((account) => {
        let totalAmount = account.AB_TAMOUNT + account.AB_RAMOUNT_TOTAL;
        if(US_MAP.CO_TYPE === _CO_TYPE_CLINIC && !cm_isEmpty(account.AB_DISC_TOTAL)){
            totalAmount = totalAmount - account.AB_DISC_TOTAL;
        }

        return totalAmount;
    },[US_MAP.US_TYPE]);

    return(
        <>  
            <div className="inquiry-box">
                <div className="table-summary">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    {t("payment_estimatedAmount")}
                                    <div className="tip-wrap" style={{width:"10px"}}>
                                        {US_MAP.CO_TYPE === _CO_TYPE_CLINIC && 
                                            <>
                                                <i className="xi-error"></i>
                                                <div className="tip-box" style={{width: "auto", textAlign: "left"}}>
                                                    <strong>* {t("payment_content_4")}</strong>
                                                    <strong>* {t("payment_content_5")}</strong>
                                                </div>
                                            </>
                                        }
                                    </div>   
                                </th>
                                <th>{t("payment_carryForwardUnpaid")}</th>
                                <th className="highlight">{t("payment_totalCost")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {account &&
                                <tr>
                                    <td><span>{cm_numComma(account.AB_TAMOUNT)}{SYMBOL}</span></td>
                                    <td><span className="txt-red" >{cm_numComma(account.AB_RAMOUNT_TOTAL)}{SYMBOL}</span></td>
                                    <td><span>{cm_numComma(getTotalAmount(account))}{SYMBOL}</span></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {account &&
                <DetailPayment payInfo={payInfo} account={{CO_SEQ: US_MAP.CO_SEQ}} MONTH={cm_dateToday()} CO_TYPE={US_MAP.CO_TYPE}/>
            } 
        </>
    )
}
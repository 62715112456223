import useTranslate from "components/language/useTranslate";
import { _LIMIT_COMMENT_LENGTH } from "config";
import { checkFileSize, cm_acceptFile, cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

const { orderCommentWriteApi } = require("api/orderApi");
const { default: useAsync } = require("hooks/useAsync");
const { useState } = require("react");

export default function OrderCommentWirte({OD_SEQ, handleOrderMap}){
    const t = useTranslate();

    const maxSize = 400 * 1024 * 1024; // 400MB

    const [asyncCommentWriteApi] = useAsync(orderCommentWriteApi);
    const [commentText, setCommentText] = useState("");
    const [textLength, setTextLength] = useState(0);

    const [fileList, setFileList] = useState([]);
    const onChangeFileList = (files) => {
        const accepts = [".jpg", ".png"];
        const list = cm_acceptFile(accepts, files, t("alert_common_file"));

        setFileList(list);
    }

    const onChangeCommentText = (e) => {
        let length = e.target.value.length;
        if(length > _LIMIT_COMMENT_LENGTH) return;

        setCommentText(e.target.value);
        setTextLength(length);
    }

    const commentWrite = async () => {
        if(cm_isEmpty(commentText) && cm_isEmpty(fileList)) return;

        let fileKeys = [];
        let formData = new FormData();
        formData.append("OD_SEQ", OD_SEQ);
        formData.append("OC_TEXT", commentText);
        if(!cm_isEmpty(fileList)){
            for(let file of fileList){
                fileKeys = [...fileKeys, { fileName: file.name }]
                formData.append("file", file);
            }
        }
        formData.append("fileKey", JSON.stringify(fileKeys));

        const response = await asyncCommentWriteApi(formData);

        if(resApiCheck(response)){
            setCommentText("");
            setFileList([]);
            handleOrderMap(OD_SEQ);
        }
    }

    const addFiles = (e) => {
        let newFiles = [...fileList];

        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];

            if(!checkFileSize(file.size, maxSize)){
                cm_swAlert(`${maxSize / 1024 / 1024} MB. ${t("common_content_1")}`, "warning", t("common_check"));
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                file.preview_url = reader.result;
                
                newFiles.push(file);
                onChangeFileList(newFiles);
            }
        }
    }

    const fileRemove = (file) => {
        const newFiles = [...fileList];
        newFiles.splice(fileList.indexOf(file), 1);

        onChangeFileList(newFiles);
    }

    return (
        <div className="chat-input">
            <ul className="bg1">
                {fileList?.map((file, idx) => {
                    return (
                        <li key={`file_${idx}`}>
                            {!cm_isEmpty(file.preview_url) &&
                                <img
                                    width={200}
                                    style={{backgroundRepeat:"no-repeat"}}
                                    src={file.preview_url}
                                    alt=""
                                />
                            }
                            <button className='btn-del' onClick={() => fileRemove(file)}></button>
                        </li>
                    )
                })}
            </ul>
            
            <textarea className="bg5" value={commentText} onChange={onChangeCommentText}></textarea>
            <div className="len">{textLength}/{_LIMIT_COMMENT_LENGTH}</div>

            <div className="bt">
                <div className='upload-file'>
                    <label htmlFor="upload"></label>
                    <input type="file" id="upload" multiple value="" onChange={addFiles} accept={".jpg, .png"}/>
                </div>
                
                <button className="btn btn-fill-blue" onClick={() => commentWrite()}>{t("common_regi")}</button>
            </div>
        </div>
    )
}
import { createContext, useContext, useState } from "react";

const MyInfoContext = createContext();

export function MyInfoProvider({ defInfo=null, children }){
    const [myInfo, setMyInfo] = useState(defInfo);

    return(
        <MyInfoContext.Provider value={{myInfo, setMyInfo}} >
            {children}
        </MyInfoContext.Provider>
    );
}

export function useMyInfo(){
    const context = useContext(MyInfoContext);
    if(!context) throw new Error("MyInfoProvider 안에서 사용해야 합니다.");
    return context.myInfo;
}

export function useSetMyInfo(){
    const context = useContext(MyInfoContext);
    if(!context) throw new Error("MyInfoProvider 안에서 사용해야 합니다.");
    return context.setMyInfo;
}


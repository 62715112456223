import { accountTransactionListApi } from "api/accountApi";
import useTranslate from "components/language/useTranslate";
import { useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { cm_dateMoment, cm_dateToday, cm_dateYearList, cm_numComma, resApiCheck } from "utils/common";

export default function BankStatement(){
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
    const store = useStore();
    const US_MAP =  store.US_MAP;
    const yearList = cm_dateYearList("2023", cm_dateToday("YYYY"));
    const [transactionList, setTransactionList] = useState(null);
    const [searchYear, setSearchYear] = useState("2023");
    const [asyncTransactionList] = useAsync(accountTransactionListApi);
    const t = useTranslate();

    useEffect(() => {
        handleLoad();
    },[searchYear]);

    const handleLoad = async () => {
        let fromMonth = searchYear + "-01-01";
        let toMonth = searchYear + "-12-31";

        const response = await asyncTransactionList({
            AT_TYPES : "'I','O'",
            CO_SEQ : US_MAP.CO_SEQ,
            FT_FRDATE : fromMonth,
            FT_TODATE : toMonth
        });
        if(!resApiCheck(response)) return;
        if(response.data.LIST_COUNT > 0){
            setTransactionList(response.data.LIST);
        }else{
            setTransactionList(null);
        }
    }

    const handleChangeSearchYear = (e) => {
        setSearchYear(e.target.value);
    }

    return(
        <>
            <div className="inquiry-box pd20">
                <div className="mb20">
                    <select className="select" value={searchYear} onChange={handleChangeSearchYear}>
                        {yearList && 
                            yearList.map((year) => {
                                return <option key={`bankState_${year}`} value={year}>{year}{t("common_year")}</option>
                            })
                        }
                    </select>
                </div>
                <div className="table2" style={{maxHeight:"530px", overflow:"auto"}}>
                    <table>
                        <colgroup>
                            <col style={{width: "15%"}}/>
                            <col style={{width: "20%"}}/>
                            <col style={{width: "50%"}}/>
                            <col style={{width: "15%"}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{t("payment_depositWithdraw")}</th>
                                <th>{t("common_amount")}</th>
                                <th>{t("common_note")}</th>
                                <th>{t("paymen_time")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactionList ? 
                                transactionList.map((transaction) => {
                                    let atTypeName = (transaction.AT_TYPE === "A"? t("payment_adjust") : (transaction.AT_TYPE === "I" ? t("payment_deposit") : t("payment_withdraw")));
                                    let atDttm =cm_dateMoment(transaction.DTTM, "YYYY-MM-DD HH:mm:ss");
                                    return(
                                        <tr key={transaction.AT_SEQ}>
                                            <td>{atTypeName}</td>
                                            <td>
                                                <div className="inq-price">
                                                    <strong>{cm_numComma(transaction.AT_AMOUNT)}{SYMBOL}</strong>
                                                </div>
                                            </td>
                                            <td>{transaction.AT_MEMO}</td>
                                            <td>{atDttm}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan='7' align='center'>{t("payment_noDepositithdrawal")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
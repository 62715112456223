import useTranslate from "components/language/useTranslate";
import OrderSections from "components/order/OrderSections";
import { _CO_TYPE_CLINIC } from "config";
import { useStore } from "contexts/Store";
import { Link } from "react-router-dom";

export default function DeliverySection({ODD_LIST, OLD_LIST}){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const t = useTranslate();
    
    return(
        <div className="section">
            <div className="head">
                <h3 className="h3">
                    <Link to={"/delivery"}>{t("home_todayDelivery")}</Link>
                </h3>
                <div className="btn-box">
                    <button className="btn-arr"><Link to={"/delivery"}>{t("home_showDeliverySchedule")}</Link></button>
                </div>
            </div>
            <div className="body">
                <div className="order-data">
                    {ODD_LIST &&
                        ODD_LIST.filter(x => x.OD_PROSTATUS !== "N").map((order) => {
                            const { OD_SEQ, OD_NAME, CO_NAME } = order;
                            const lineList = OLD_LIST.filter(x => x.OD_SEQ === OD_SEQ && x.PR_TYPE !== "P");

                            return (
                                <div className="box" key={"order_" + OD_SEQ}>
                                    <div className="tit" style={{width:"120px", wordWrap:"break-word"}}>
                                        {CO_TYPE !== _CO_TYPE_CLINIC &&
                                            <>
                                                <span><strong>{CO_NAME}</strong></span><br/>
                                            </>
                                        }
                                        <p>{OD_NAME}</p>
                                    </div>
                                    <div className="tooth-list3 type2" style={{flex:"3"}}>
                                        <OrderSections OL_LIST={lineList}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
import { userValidatePwdApi } from "api/userApi";
import useTranslate from "components/language/useTranslate";
import { useMyInfo } from "contexts/MyInfoContext";
import useAsync from "hooks/useAsync";
import { useState } from "react";
import { cm_swAlert } from "utils/common";

export default function PwdCheckModal({isOpen, handleModal, updateInfo}){
    const t = useTranslate();

    const myInfo = useMyInfo();
    const {US_EMAIL} = myInfo.US_MAP;

    const [pwd, setPwd] = useState("");
    const handlePwd = (e) => {
        setPwd(e.target.value);
    }

    const [asyncValidatePwd] = useAsync(userValidatePwdApi);

    const pwdCheck = async () => {
        const res = await asyncValidatePwd({
            US_EMAIL: US_EMAIL,
            US_PWD: pwd
        });

        if(res.data.RESULT === "OK") {
            updateInfo();
            handleModal();
        } else{
            cm_swAlert(t("alert_noPwd"));
        }
    }

    return(
        <>
            <div className="modal fade" id="ModalFavorite"  style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h1 className="modal-title">{t("common_pwConfirm")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body max-height-600">
                    <h4 className="form-tit">{t("myPage_pwCur")}</h4>
                        <div className="inp-box">
                            <input type="password" className="inp block" name="US_PWD" value={pwd} onChange={handlePwd}/>
                        </div>  
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" onClick={pwdCheck}>{t("common_check")}</button>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
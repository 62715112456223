import { accountAdjustListApi, accountSendInvoiceApi, accountTransactionWriteApi } from "api/accountApi";
import { companyReadApi } from "api/companyApi";
import { orderListApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CENTER, _CO_TYPE_CLINIC, _CO_TYPE_LAB } from "config";
import makePdf from "hooks/makePdf";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { Fragment, useEffect, useState } from "react";
import { cm_dateCompare, cm_dateMoment, cm_dateMonthDate, cm_formatRegNo, cm_isEmpty, cm_isNum, cm_isPositiveNum, cm_numComma, cm_removeNumComma, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function LabInvoiceForm({CO_TYPE, account, setAccount, MONTH, handleReLoad}) {
    // Admin, Lab common components
    const [orderList, setOrderList] = useState([]);
    const [orderLineList, setOrderLineList] = useState([]);
    const [adjustList, setAdjustList] = useState([]);
    const [dentalList, setDentalList] = useState([]);
    const [quveCompany, setQuveCompany] = useState(null);
    const [labCompany, setLabCompany] = useState(null);
    const [edit, setEdit] = useState(true);
    
    const [asyncCompanyRead] = useAsync(companyReadApi);
    const [asyncOrderList] = useAsync(orderListApi);
    const [asyncAccountAdjustList] = useAsync(accountAdjustListApi);

    const [asyncAccountTransactionWrite, accountTransactionWritePending] = useAsync(accountTransactionWriteApi);

    const t = useTranslate();
    const SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;

    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account])

    const handleLoad = async () => {
        let data = {
            FT_FRPAYDATE : cm_dateMonthDate(MONTH, "MF"),
            FT_TOPAYDATE : cm_dateMonthDate(MONTH, "ML")
        }

        // Admin에서 orderList 조회시 key name 구분
        if(CO_TYPE === _CO_TYPE_CLINIC){
            data["CO_SEQ"] = account.CO_SEQ;
        }else if(CO_TYPE === _CO_TYPE_LAB){
            data["CO_LAB"] = account.CO_SEQ;
        }

        const response = await asyncOrderList(data);

        if(!resApiCheck(response)) return;
        if(response.data.LIST_COUNT > 0){
            setOrderList(response.data.LIST);
            setOrderLineList(response.data.OL_LIST.filter(x => x.PR_CODE !== "PLA-Cr"));
            // dentalList 중복 제거
            setDentalList(() => {
                let list = response.data.LIST.map(x => ({CO_SEQ:x.CO_SEQ, CO_NAME : x.CO_NAME}))
                .filter((arr, index, callback) => index === callback.findIndex(x => x.CO_SEQ === arr.CO_SEQ));
                return list;
            })
        }else{
            setOrderList([]);
            setOrderLineList([]);
            setDentalList([]);
        }

        data["AB_SEQ"] = account.AB_SEQ;
        const resAdjust = await asyncAccountAdjustList(data);
        if(!resApiCheck(resAdjust)) return;

        if(resAdjust.data.LIST_COUNT > 0){
            setAdjustList(resAdjust.data.AA_LIST);
        }else{
            setAdjustList([]);
        }

        //quve company info
        const resQuveCompanyRead = await asyncCompanyRead({
            CO_SEQ : 1
        });
        if(!resApiCheck(resQuveCompanyRead)) return;
        if(!cm_isEmpty(resQuveCompanyRead.data.CO_MAP)){
            setQuveCompany(resQuveCompanyRead.data.CO_MAP);
        }
        // Lab company info
        const resLabCompanyRead = await asyncCompanyRead({
            CO_SEQ : account.CO_SEQ
        });
        if(!resApiCheck(resLabCompanyRead)) return;
        if(!cm_isEmpty(resLabCompanyRead.data.CO_MAP)){
            setLabCompany(resLabCompanyRead.data.CO_MAP);
        }
    }

    const getTotalPrice = (orderLineList, adjustList, addOrderList) => {
        let totalPrice = 0;
        
        if(!cm_isEmpty(orderLineList)){
            totalPrice += orderLineList.map(x => x.OL_COSTPRICE).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
        }
        if(!cm_isEmpty(adjustList)){
            totalPrice += adjustList.map(x => x.AA_AMOUNT).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
        }
        if(!cm_isEmpty(addOrderList)){
            totalPrice += addOrderList.map(x => x.AA_AMOUNT).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
        }
        
        return totalPrice;
    }

    // Add billing history
    const [addOrderList, setAddOrderList] = useState([]);
    const [key, setKey] = useState(0);
    
    const handleAddOrder = (CO_SEQ) => {
        setKey(prevValue => prevValue + 1);

        setAddOrderList(produce(prevValue => {
            prevValue.push({
                KEY : key,
                AB_SEQ: account.AB_SEQ,
                AA_CLINIC: CO_SEQ,
                AA_NAME: "",
                AA_COMDTTM: "",
                AA_PRCODE: "",
                AA_TOOTH: "",
                AA_TOOTH_CNT: 0,
                AA_AMOUNT: 0,
                AA_MEMO: "",
            });
        }));
    }

    const handleChangeAddOrder = (e, key) => {
        let {name, value} = e.target;

        if(name === "AA_TOOTH_CNT"){
            const number = cm_removeNumComma(value);
    
            if(cm_isPositiveNum(number, true, t("alert_num_positive"))){
                setAddOrderList(produce(prevValue => {
                    prevValue[key][name] = number;
                }))
            }
        }else if(name === "AA_AMOUNT"){
            const number = cm_removeNumComma(value);
    
            if(cm_isNum(number, true, t("alert_num_number"))){
                setAddOrderList(produce(prevValue => {
                    prevValue[key][name] = number;
                }))
            }
        }else if(name === "AA_NAME" || name === "AA_PRCODE" || name === "AA_TOOTH"){
            handleReSizeHeight(e);
            setAddOrderList(produce(prevValue => {
                prevValue[key][name] = value;
            }))
        }else{
            setAddOrderList(produce(prevValue => {
                prevValue[key][name] = value;
            }))
        }
    }

    const handleReSizeHeight = (e) => {
        e.currentTarget.style.height = 'auto';
        e.currentTarget.style.height = e.target.scrollHeight+ "px";
    }

    // Lab Account Adjust
    const handleAdjust = async () => {
        if(!adjustValidate()) return;

        let adJustAmount = 0;
        if(!cm_isEmpty(addOrderList)){
            adJustAmount = addOrderList.map(x => x.AA_AMOUNT).reduce((pr,cu)=> Number(pr) + Number(cu), 0);
        }

        const confirm = await cm_swConfirm(t("confirm_pay_adjust"), `${t("payment_adjustCost")} : ${adJustAmount}`, "info", t("common_yes"), t("common_no"));
        
        if(confirm){
            const response = await asyncAccountTransactionWrite({
                CO_SEQ : account.CO_SEQ,
                AT_TYPE : "A",
                AB_SEQ : account.AB_SEQ,
                AT_AMOUNT : adJustAmount,
                AT_MEMO : t("payment_labAdjustFixComment"),
                AB_MONTH : account.AB_MONTH,
                AA_LIST: JSON.stringify(addOrderList)
            })

            if(!resApiCheck(response)){
                cm_swAlert(t("alert_settlement_reflectFailed"), "warning", t("common_check"));
            }else{
                cm_swAlert(t("alert_settlement_reflected"), "success", t("common_check"));
                handleReLoad();
                setAccount(null);
            }
        }
    }

    const adjustValidate = () => {
        return addOrderList.every(adjust => {
            if (cm_isEmpty(adjust.AA_NAME)) {
                cm_swAlert(t("order_orderSearchPh"), "warning", t("common_check"));
                return false;
            }
            if (!cm_dateCompare(adjust.AA_COMDTTM)) {
                cm_swAlert(t("alert_validate_date"), "warning", t("common_check"));
                return false;
            }
            if (cm_isEmpty(adjust.AA_PRCODE)) {
                cm_swAlert(t("alert_validate_type"), "warning", t("common_check"));
                return false;
            }
            if (cm_isEmpty(adjust.AA_TOOTH)) {
                cm_swAlert(t("alert_validate_pr"), "warning", t("common_check"));
                return false;
            }
            return true;
        });
    };
    

    // Lab Invoice send to Quve
    const [asyncSendInvoice, sendInvoicePending] = useAsync(accountSendInvoiceApi);

    const handleMakeInvoice = async (isDownload = true) => {
        await setEdit(false);
        const pdf = makePdf('transactionForm', `${account.CO_NAME}_INVOICE_${account.AB_MONTH}`, isDownload);

        setEdit(true);
        return pdf;
    }
    
    const handleSendInvoice = async () => {
        
        if(addOrderList.length > 0) {
            cm_swAlert(t("alert_settlement_adjust_check"), "warning", t("common_check"));
            return;
        }

        const pdf = await handleMakeInvoice(false);

        const confirmText = account.AB_STATUS === "M" ? t("confirm_bill_billing") : t("confirm_bill_already_billing");

        const confirm = await cm_swConfirm(confirmText, "", "info", t("common_yes"), t("common_no"));
        if(!confirm) return;

        let formData = new FormData();
        formData.append("AB_SEQ", account.AB_SEQ);
        formData.append("CO_SEQ", account.CO_SEQ);
        formData.append("file", pdf);

        const response = await asyncSendInvoice(formData)

        if(resApiCheck(response)){
            cm_swAlert(t("alert_bill_billing_success"), "success", t("common_check"));
            handleReLoad();
            setAddOrderList([]);
        }else{
            cm_swAlert(t("alert_bill_billing_failed"), "warning", t("common_check"));
        }
    }

    return(
        <>
            {!cm_isEmpty(orderList) ?
                <div className={CO_TYPE === _CO_TYPE_LAB ? "inquiry-box pd20" : null}>
                    <div className="transaction-header" >
                        <div className="item" style={{display:"flex", gap:"10px", justifyContent:"end"}}>
                            {(CO_TYPE === _CO_TYPE_LAB && account.AB_STATUS !== "C") &&
                                <>
                                    {/* <button className="btn btn-fill-gray" onClick={() => handleEdit()}>{edit? t("common_save") : t("common_edit")}</button> */}
                                    <button className="btn btn-fill-gray" disabled={addOrderList.length === 0 || accountTransactionWritePending} onClick={() => handleAdjust()}>{t("payment_adjust_do")}</button>
                                    <button className="btn btn-fill-gray" disabled={addOrderList.length > 0 || sendInvoicePending} onClick={() => handleSendInvoice()}>{t("payment_bill_billing")}</button>
                                </>
                            }
                            <button className="btn btn-fill-gray" disabled={addOrderList.length > 0} onClick={handleMakeInvoice}>{t("payment_bill_download")}</button>
                        </div>
                    </div>
                    <div className="transaction-content" id="transactionForm" >
                        <h2 className="h2" style={{textAlign: "center"}}>INVOICE</h2>
                        <div style={{textAlign:"end"}}>
                            <span style={{fontSize: "18px", paddingRight: "10px"}}>{account.AB_MONTH}</span>
                        </div>
                        
                        <div className="payment-wrap">
                            {!cm_isEmpty(quveCompany) && !cm_isEmpty(labCompany) &&
                                <>
                                    <CompanyInfo title={t("payment_recipient")} companyInfo={quveCompany} />
                                    <CompanyInfo title={t("payment_supplier")} companyInfo={labCompany} />
                                </>
                            }
                        </div>
                        {!cm_isEmpty(dentalList) ?
                            <>
                                {dentalList.map((dental) => {
                                    let dentalOrderList = orderList.filter(x => x.CO_SEQ === dental.CO_SEQ);
                                    let dentalOrderLineList = orderLineList.filter(x => x.CO_SEQ === dental.CO_SEQ);
                                    let dentalAddOrderList = addOrderList.filter(x => x.AA_CLINIC === dental.CO_SEQ);
                                    let dentalAdjustList = adjustList ? adjustList.filter(x => x.AA_CLINIC === dental.CO_SEQ) : [];

                                    return(
                                        <Fragment key={dental.CO_SEQ}>
                                            <DentalOrderDetail 
                                                edit={edit}
                                                dental={dental} 
                                                dentalOrderList={dentalOrderList} 
                                                dentalOrderLineList={dentalOrderLineList}
                                                dentalAddOrderList={dentalAddOrderList}
                                                dentalAdjustList={dentalAdjustList}
                                                handleAddOrder={handleAddOrder}
                                                handleChangeAddOrder={handleChangeAddOrder}
                                                getTotalPrice={getTotalPrice}
                                            />
                                        </Fragment>
                                    )
                                })}
                                <div style={{margin: "10px"}}>
                                    <h3 className="h3" style={{textAlign:"end", color:"#ff5353"}}>
                                        {t("common_total")} : {cm_numComma(getTotalPrice(orderLineList, adjustList, addOrderList))} {SYMBOL}
                                    </h3>
                                </div>
                            </>
                            : 
                            <div style={{textAlign:"center"}}>
                                <p>{t("common_content_15")}</p>
                            </div>
                        }
                    </div>
                </div>
                :
                <div className={CO_TYPE === _CO_TYPE_LAB ? "inquiry-box pd20 text-center" : null}>
                    <p>{t("common_content_15")}</p>
                </div>
            }
        </>
    )
}

function DentalOrderDetail({edit, dental, dentalOrderList, dentalOrderLineList, dentalAddOrderList, dentalAdjustList, handleAddOrder, handleChangeAddOrder, getTotalPrice}){
    const t = useTranslate();
    
    return(
        <div className="item">
            <div className="table3">
                <h3 className="title">{dental.CO_NAME}</h3>
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "20%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("common_patientName")}</th>
                            <th>{t("common_completeDate")}</th>
                            <th>{t("common_type")}</th>
                            <th>{t("payment_dtFormula")}</th>
                            <th>{t("payment_cnt")}</th>
                            <th>{t("payment_billCost")}</th>
                            <th>{t("common_note")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!cm_isEmpty(dentalOrderList) &&
                            dentalOrderList.map((order) => {
                                const orderLine = dentalOrderLineList.filter(x => x.OD_SEQ === order.OD_SEQ);
                                const products = [...new Set(orderLine.map(x => x.PR_CODE))];

                                return (
                                    <Fragment key={order.OD_SEQ}>
                                        {products.map((product) => {
                                            let filterProductOrderLine = orderLine.filter(x => x.PR_CODE === product);
                                            let toothString = filterProductOrderLine.map(x => x.OL_TOOTH).join(',');
                                            let toothCnt = filterProductOrderLine.length;
                                            let costPriceSum = filterProductOrderLine.map(x => x.OL_COSTPRICE).reduce((pr,cu)=> Number(pr) + Number(cu), 0);

                                            return(
                                                <tr key={`order_${order.OD_SEQ}_${product}`}>
                                                    <td>{order.OD_NAME}</td>
                                                    <td>{order.OD_DUEDATE}</td>
                                                    <td>{product}</td>
                                                    <td>{toothString}</td>
                                                    <td>{toothCnt}</td>
                                                    <td>{cm_numComma(costPriceSum)}</td>
                                                    <td></td>
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })
                        }
                        {!cm_isEmpty(dentalAdjustList) && dentalAdjustList?.map((item, idx) => {
                            return (
                                <tr className="adjust" key={dental.CO_SEQ + "_adjust_" + idx}>
                                    <td>{item.AA_NAME}</td>
                                    <td>{cm_dateMoment(item.AA_COMDTTM)}</td>
                                    <td>{item.AA_PRCODE}</td>
                                    <td>{item.AA_TOOTH}</td>
                                    <td>{cm_numComma(item.AA_TOOTH_CNT)}</td>
                                    <td>{cm_numComma(item.AA_AMOUNT)}</td>
                                    <td>{item.AA_MEMO}</td>
                                </tr>
                            )
                        })

                        }
                        {/* add order*/}
                        {!cm_isEmpty(dentalAddOrderList) &&
                            dentalAddOrderList.map((item, idx) => {
                                return (
                                    <tr className="add-textarea" key={dental.CO_SEQ + "_" + idx}>
                                        <td><textarea className="add-textarea" rows={1} name="AA_NAME" value={item.AA_NAME} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></textarea></td>
                                        <td><input className="add-textarea" name="AA_COMDTTM" value={item.AA_COMDTTM} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></input></td>
                                        <td><textarea className="add-textarea" rows={1} name="AA_PRCODE" value={item.AA_PRCODE} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></textarea></td>
                                        <td><textarea className="add-textarea" rows={1} name="AA_TOOTH" value={item.AA_TOOTH} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></textarea></td>
                                        <td><input className="add-textarea" name="AA_TOOTH_CNT" value={cm_numComma(item.AA_TOOTH_CNT)} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></input></td>
                                        <td><input className="add-textarea" name="AA_AMOUNT" value={cm_numComma(item.AA_AMOUNT)} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></input></td>
                                        <td><textarea className="add-textarea" rows={1} name="AA_MEMO" value={item.AA_MEMO} onChange={(e) => handleChangeAddOrder(e, item.KEY)}></textarea></td>
                                    </tr>
                                )
                            })
                        }

                        {edit &&
                            <tr className="add" onClick={() => handleAddOrder(dental.CO_SEQ)}><td colSpan={7}>{t("payment_bill_addHistory")}</td></tr>
                        }

                        <tr>
                            <td colSpan={5}><span><strong>{t("common_sum")}</strong></span></td>
                            <td>{cm_numComma(getTotalPrice(dentalOrderLineList, dentalAdjustList, dentalAddOrderList))}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

function CompanyInfo({title, companyInfo}){
    const t = useTranslate();
    return(
        <div>
            <table>
                <colgroup>
                    <col style={{width: "20%"}} />
                    <col style={{width: "20%"}} />
                    <col style={{width: "20%"}} />
                    <col style={{width: "20%"}} />
                    <col style={{width: "20%"}} />
                </colgroup>
                <tbody>
                    <tr>
                        <th colSpan={5}>{title}</th>
                    </tr>
                    <tr>
                        <th>{t("common_regiNum")}</th>
                        <td colSpan={3}>{cm_formatRegNo(companyInfo.CO_REGNO)}</td>
                    </tr>
                    <tr>
                        <th>{t("common_companyName")}</th>
                        <td>{companyInfo.CO_NAME}</td>
                        <th>{t("common_RepresentativeName")}</th>
                        <td>{companyInfo.CO_OWNER}</td>
                    </tr>
                    <tr>
                        <th>{t("common_address")}</th>
                        <td colSpan={3}>({companyInfo.CO_ZIPCODE}) {companyInfo.CO_ADDRESS}</td>
                    </tr>
                    <tr>
                        <th>{t("common_industryType")}</th>
                        <td colSpan={3}>{companyInfo.CO_TYPE === _CO_TYPE_CENTER ? t("payment_industry_software") : t("payment_industry_manufacture")}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

import useTranslate from "components/language/useTranslate";
import { ORDER_STATUS, _CO_TYPE_CENTER, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import { useStore } from "contexts/Store";
import { useNavigate } from "react-router-dom";
import { cm_isEmpty } from "utils/common";

export default function SituationSection({ODC_MAP, ODP_MAP}){
    const store = useStore();
    const US_MAP = store.US_MAP;

    return(
        <div className="row mb0">
            <CurrentStatus ODC_MAP={ODC_MAP} US_MAP={US_MAP} />
            {US_MAP.CO_TYPE === _CO_TYPE_CENTER ?
                <CurrentPlaStatus ODP_MAP={ODP_MAP} />
                :
                <CurrentIssue ODC_MAP={ODC_MAP} US_MAP={US_MAP} />
            }
        </div>
    )
}


function CurrentStatus({ODC_MAP, US_MAP}){
    const t = useTranslate();
    const orderStatus = ORDER_STATUS();

    const navigate = useNavigate();
    function applyStatus(value){
        navigate('/order', {state: {key: "status", status: value}});
    }

    return(
        <div className="col section">
            <div className="head">
                <h3 className="h3">{t("home_orderStatus")}</h3>
            </div>
            <div className="body">
                <div className="order-list2 status">
                    {orderStatus.filter(x => x.coType.indexOf(US_MAP.CO_TYPE) !== -1 && x.key !== "C").map((status) => {
                        const statusCnt = ODC_MAP[status.col];

                        return (
                            <dl key={"status_" + status.key} onClick={() => applyStatus(`'${status.key}'`)}>
                                <dt>{status.text}</dt>
                                <dd>{statusCnt}</dd>
                            </dl>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function CurrentIssue({ODC_MAP, US_MAP}){
    const t = useTranslate();
    const navigate = useNavigate();

    let issueList = [
        {value: "FT_PRINT", text: `#${t("common_print")}`, tag: "tag3", cnt: cm_isEmpty(ODC_MAP) ? 0 : ODC_MAP.OD_PRINT},
        {value: "FT_RESCAN", text: `#${t("common_rescan")}`, tag: "tag2", cnt: cm_isEmpty(ODC_MAP) ? 0 : ODC_MAP.OD_IS_RESCAN},
        {value: "FT_REMAKE", text: `#${t("common_remake")}`, tag: "tag1", cnt: cm_isEmpty(ODC_MAP) ? 0 : ODC_MAP.OD_IS_REMAKE},
        {value: "FT_REPAIR", text: `#${t("common_repair")}`, tag: "tag5", cnt: cm_isEmpty(ODC_MAP) ? 0 : ODC_MAP.OD_IS_REPAIR}
    ]

    if(US_MAP.CO_TYPE === _CO_TYPE_LAB){
        issueList = [...issueList.filter(x => "FT_REMAKE".indexOf(x.value) !== -1 || "FT_REPAIR".indexOf(x.value) !== -1)];
    }else if(US_MAP.CO_TYPE === _CO_TYPE_DESIGN){
        issueList = [...issueList.filter(x => "FT_RESCAN".indexOf(x.value) !== -1)];
    }

    function applyIssue(value){
        navigate('/order', {state: {key: "issue", status: value}});
    }

    return(
        <div className="col section">
            <div className="head">
                <h3 className="h3">{t("home_inIssue")}</h3>
            </div>
            <div className="body">
                <div className="order-list2">
                    {
                        issueList.map((issue) => {
                            return(
                                <dl key={issue.value} onClick={() => applyIssue(issue.value)}>
                                    <dt>
                                        <div className="tags">
                                            <p className={issue.tag}>{issue.text}</p>
                                        </div>
                                    </dt>
                                    <dd>{issue.cnt}</dd>
                                </dl>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function CurrentPlaStatus({ODP_MAP}){
    const t = useTranslate();
    const navigate = useNavigate();

    let plsStatusList = [
        {status: "M", value: "'M'", text: t("print_status_standby"), cnt: cm_isEmpty(ODP_MAP) ? 0 : ODP_MAP.OP_MEMO},
        {status: "D", value: "'D'", text: t("print_status_design"), cnt: cm_isEmpty(ODP_MAP) ? 0 : ODP_MAP.OP_DESIGN},
        {status: "K", value: "'K','W'", text: t("print_status_printing"), cnt: cm_isEmpty(ODP_MAP) ? 0 : (ODP_MAP.OP_PRINT + ODP_MAP.OP_WAIT)},
        {status: "E", value: "'E'", text: t("print_status_error"), cnt: cm_isEmpty(ODP_MAP) ? 0 : ODP_MAP.OP_ERROR},
    ]

    function applyPlaStatus(value){
        if(value !== "'E'"){
            navigate('/order', {state: {key: "plaStatus", status: value}});
        }
    }

    return(
        <div className="col section">
            <div className="head">
                <h3 className="h3">{t("home_inPla")}</h3>
            </div>
            <div className="body">
                <div className="order-list2 status">
                    {
                        plsStatusList.map((plaStatus) => {
                            return(
                                <dl key={"plaStatus_" + plaStatus.status} onClick={() => applyPlaStatus(plaStatus.value)}>
                                    <dt>{plaStatus.text}</dt>
                                    <dd>{plaStatus.cnt}</dd>
                                </dl>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
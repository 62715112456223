import useTranslate from "components/language/useTranslate";
import { useNavigate } from "react-router-dom";
import { cm_dateMoment, cm_isEmpty, cm_truncateText } from "utils/common";

function RepairSection({OA_LIST}) {
    const t = useTranslate();
    const navigate = useNavigate();

    const handleMovePage = (OD_SEQ) => {
        navigate("/order", {state :{OD_SEQ : OD_SEQ}});
    }

    return (
        <div className="section">
            <div className="head">
                <h3 className="h3">{t("home_repairRequest")}</h3>
            </div>
            <div className="body">
                <div className="table1 hover-type">
                    <table>
                        <colgroup>
                            <col style={{width: "6%"}}/>
                            <col style={{width: "24%"}}/>
                            <col style={{width: "24%"}}/>
                            <col />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>{"No."}</th>
                                <th>{t("common_clinicName")}</th>
                                <th>{t("order_info_deadline")}</th>
                                <th>{t("order_repair_reasons")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!cm_isEmpty(OA_LIST) ?
                                OA_LIST.map((item, index) => {
                                    return (
                                        <tr key={`repair_${index}`} onClick={() => handleMovePage(item.OD_SEQ)}>
                                            <td>{index+1}</td>
                                            <td>{item.CO_NAME}</td>
                                            <td>{cm_dateMoment(item.OA_DUEDATE)}</td>
                                            <td>{cm_truncateText(item.OA_MEMO, 20)}</td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={4}>{t("order_searchOrder_content_1")}</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default RepairSection
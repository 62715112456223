import { companyWriteApi, companyDeleteApi } from "api/companyApi";
import SearchAddress from "components/common/SearchAddress";
import useTranslate from "components/language/useTranslate";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useState } from "react";
import { cm_formatRegNo, cm_formatMobile, cm_isEmpty, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function CompanyInfo({selectCompany, setSelectCompany, handleReLoad}) {
    const [edit, setEdit] = useState(false);
    
    //API
    const [asyncCompanyWrite] = useAsync(companyWriteApi);
    const [asyncCompanyDelete, companyDeletePending] = useAsync(companyDeleteApi);

    const t = useTranslate();

    //Edit
    const handleChangeFlag = () => {
        if(edit){
            handleCompanyUpdate();
        }
        setEdit((prevValue) => !prevValue);
    }

    //Change
    const handleChangeSelectCompany = (e) => {
        let {name, value} = e.target;

        if(name === "CO_TEL" || name === "CO_REGNO"){
            value = value.replace(/[^0-9]/g, '');
        }

        setSelectCompany(produce((prevValue) => {
            prevValue.CO_MAP[name] = value;
        }));
    }

    const handleZipcode = (value) => {
        setSelectCompany(produce((prevValue) => {
            prevValue.CO_MAP.CO_ZIPCODE = value;
        }))
    }
    const handleAddress = (value) => {
        setSelectCompany(produce((prevValue) => {
            prevValue.CO_MAP.CO_ADDRESS = value;
        }))
    }

    //Company Update
    const handleCompanyUpdate = async () => {
        if(!UserUpdateValidate()) return; 
        
        let formData = new FormData();
        formData.append("CO_SEQ", selectCompany.CO_MAP.CO_SEQ);
        formData.append("CO_TYPE", selectCompany.CO_MAP.CO_TYPE);
        formData.append("CO_NAME", selectCompany.CO_MAP.CO_NAME);
        formData.append("CO_REGNO", selectCompany.CO_MAP.CO_REGNO);
        formData.append("CO_OWNER", selectCompany.CO_MAP.CO_OWNER);
        formData.append("CO_TEL", selectCompany.CO_MAP.CO_TEL);
        formData.append("CO_FAX", selectCompany.CO_MAP.CO_FAX);
        formData.append("CO_ZIPCODE", selectCompany.CO_MAP.CO_ZIPCODE);
        formData.append("CO_ADDRESS", selectCompany.CO_MAP.CO_ADDRESS);

        const response = await asyncCompanyWrite(formData);
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));
        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
            handleReLoad();
        }
    }

    function UserUpdateValidate() {
        let result = true;
        let text = "";

        if(cm_isEmpty(selectCompany.CO_MAP.CO_NAME)){
            text = t("common_alert_bizName");
            result = false;
        }else if(cm_isEmpty(selectCompany.CO_MAP.CO_REGNO)){
            text = t("common_content_4");
            result = false;
        }else if(cm_isEmpty(selectCompany.CO_MAP.CO_TEL)){
            text = t("common_content_5");
            result = false;
        }else if(cm_isEmpty(selectCompany.CO_MAP.CO_ADDRESS)){
            text = t("common_content_6");
            result = false;
        }

        if(!result) cm_swAlert(text, "info", t("common_check"));

        return result;
    }

    //User Delete
    const handleCompanyDelete = async () => {
        // if(!userDeleteValidate()) return;

        const confirm = await cm_swConfirm(t("confirm_common_delete"), "", "info", t("common_yes"), t("common_no"));
        if(confirm){
            const response = await asyncCompanyDelete({
                CO_SEQ : selectCompany.CO_MAP.CO_SEQ
            })
            if(!resApiCheck(response)){
                cm_swAlert(t("alert_delete_failed"), "warning", t("common_check"));
            }else{
                cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
                setSelectCompany(null);
                handleReLoad();
            }
        }
    }

    return(
        <>
            <div className="board-write no-border">
                <table>
                    <colgroup>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "40%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "40%"}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{t("common_clinicName")}</th>
                            <td><input type="text" className="inp block" name="CO_NAME" value={selectCompany.CO_MAP.CO_NAME} onChange={handleChangeSelectCompany} disabled={!edit} maxLength={33} /></td>
                            <th>{t("common_RepresentativeName")}</th>
                            <td><input type="text" className="inp block" name="CO_OWNER" value={selectCompany.CO_MAP.CO_OWNER} onChange={handleChangeSelectCompany} disabled={!edit} maxLength={33} /></td>
                        </tr>
                        <tr>
                            <th>{t("common_licenseNum")}</th>
                            <td><input type="text" className="inp block" name="CO_REGNO" value={cm_formatRegNo(selectCompany.CO_MAP.CO_REGNO)} onChange={handleChangeSelectCompany} disabled={!edit} maxLength={12} /></td>
                            <th>{t("common_rePresentativePhone")}</th>
                            <td><input type="text" className="inp block" name="CO_TEL" value={cm_formatMobile(selectCompany.CO_MAP.CO_TEL)} onChange={handleChangeSelectCompany} disabled={!edit} maxLength={13}/></td>
                        </tr>
                        <tr>
                            <th>{t("common_address")}</th>
                            <td>
                                <input type="text" className="inp block" name="CO_ADDRESS" value={selectCompany.CO_MAP.CO_ADDRESS} onChange={handleChangeSelectCompany} disabled={!edit} />
                                <SearchAddress handleZipcode={handleZipcode} handleAddress={handleAddress} isEdit={edit} btnStyle={{padding: "0 15px", marginLeft: "10px"}} />
                            </td>
                            <th>{t("myPage_faxNumber")}</th>
                            <td><input type="text" className="inp block" name="CO_FAX" value={selectCompany.CO_MAP.CO_FAX} onChange={handleChangeSelectCompany} disabled={!edit} maxLength={12} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div className="flex gap10 justify-content-center">
                <button className="btn btn-fill-blue2 btn-round" onClick={handleChangeFlag}>{edit? t("common_save") : t("common_edit")}</button>
                <button className="btn btn-fill-blue2 btn-round" onClick={handleCompanyDelete} disabled={companyDeletePending}>{t("myPage_del")}</button>
            </div>
        </>
    )
}
export default function ShadeOption({shadeCompany, shadeSelect}){
    switch(shadeCompany){
        case "Vita classic":
            return <ShadeVitaClassic shadeSelect={shadeSelect}/>
        case "Vita 3D Master":
            return <ShadeVitaMaster shadeSelect={shadeSelect}/>
        case "Ivoclar":
            return <ShadeIvoclar shadeSelect={shadeSelect}/>
        default: 
            return
    }
}

function ShadeVitaClassic({shadeSelect}){
    return (
        <ul className="tooth-list1">
            <li><label><input type="radio" name="shade" value="A1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>A1</div></label></li>
            <li><label><input type="radio" name="shade" value="A2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>A2</div></label></li>
            <li><label><input type="radio" name="shade" value="A3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>A3</div></label></li>
            <li><label><input type="radio" name="shade" value="A3.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>A3.5</div></label></li>
            <li><label><input type="radio" name="shade" value="A4" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>A4</div></label></li>
            <li><label><input type="radio" name="shade" value="B1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>B1</div></label></li>
            <li><label><input type="radio" name="shade" value="B2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>B2</div></label></li>
            <li><label><input type="radio" name="shade" value="B3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>B3</div></label></li>
            <li><label><input type="radio" name="shade" value="B3.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>B3.5</div></label></li>
            <li><label><input type="radio" name="shade" value="B4" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>B4</div></label></li>
            <li><label><input type="radio" name="shade" value="C1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>C1</div></label></li>
            <li><label><input type="radio" name="shade" value="C2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>C2</div></label></li>
            <li><label><input type="radio" name="shade" value="C3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>C3</div></label></li>
            <li><label><input type="radio" name="shade" value="C3.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>C3.5</div></label></li>
            <li><label><input type="radio" name="shade" value="C4" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>C4</div></label></li>
            <li><label><input type="radio" name="shade" value="D1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>D1</div></label></li>
            <li><label><input type="radio" name="shade" value="D2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>D2</div></label></li>
            <li><label><input type="radio" name="shade" value="D3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>D3</div></label></li>
            <li><label><input type="radio" name="shade" value="D3.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>D3.5</div></label></li>
            <li><label><input type="radio" name="shade" value="D4" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>D4</div></label></li>
        </ul>
    )
}

function ShadeVitaMaster({shadeSelect}){
    return (
        <div className="tooth-list2">
            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="1M2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>1M2</div></label></li>
                        <li><label><input type="radio" name="shade" value="1M1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>1M1</div></label></li>
                    </ul>
                </div>
                <div className="tit">1M</div>
            </div>
            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="2L2.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2L2.5</div></label></li>
                        <li><label><input type="radio" name="shade" value="2L1.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2L1.5</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="2M3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2M3</div></label></li>
                        <li><label><input type="radio" name="shade" value="2M2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2M2</div></label></li>
                        <li><label><input type="radio" name="shade" value="2M1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2M1</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="2R2.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2R2.5</div></label></li>
                        <li><label><input type="radio" name="shade" value="2R1.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2R1.5</div></label></li>
                    </ul>
                </div>
                <div className="tit">2M</div>
            </div>
            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="3L2.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3L2.5</div></label></li>
                        <li><label><input type="radio" name="shade" value="3L1.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3L1.5</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="3M3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3M3</div></label></li>
                        <li><label><input type="radio" name="shade" value="3M2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3M2</div></label></li>
                        <li><label><input type="radio" name="shade" value="3M1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3M1</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="3R2.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3R2.5</div></label></li>
                        <li><label><input type="radio" name="shade" value="3R1.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3R1.5</div></label></li>
                    </ul>
                </div>
                <div className="tit">3M</div>
            </div>
            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="4L2.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4L2.5</div></label></li>
                        <li><label><input type="radio" name="shade" value="4L1.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4L1.5</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="4M3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4M3</div></label></li>
                        <li><label><input type="radio" name="shade" value="4M2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4M2</div></label></li>
                        <li><label><input type="radio" name="shade" value="4M1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4M1</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="4R2.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4R2.5</div></label></li>
                        <li><label><input type="radio" name="shade" value="4R1.5" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4R1.5</div></label></li>
                    </ul>
                </div>
                <div className="tit">4M</div>
            </div>
            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="5M3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>5M3</div></label></li>
                        <li><label><input type="radio" name="shade" value="5M2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>5M2</div></label></li>
                        <li><label><input type="radio" name="shade" value="5M1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>5M1</div></label></li>
                    </ul>
                </div>
                <div className="tit">5M</div>
            </div>
        </div>
    )
}

function ShadeIvoclar({shadeSelect}){
    return (
        <div className="tooth-list2">
            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="01" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>01</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="1E" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>1E</div></label></li>
                        <li><label><input type="radio" name="shade" value="1D" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>1D</div></label></li>
                        <li><label><input type="radio" name="shade" value="1C" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>1C</div></label></li>
                        <li><label><input type="radio" name="shade" className="checkbox-tooth" disabled/><div></div></label></li>
                        <li><label><input type="radio" name="shade" value="1A" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>1A</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="2E" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2E</div></label></li>
                        <li><label><input type="radio" name="shade" value="2C" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2C</div></label></li>
                        <li><label><input type="radio" name="shade" value="2B" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2B</div></label></li>
                        <li><label><input type="radio" name="shade" value="2A" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>2A</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="3E" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3E</div></label></li>
                        <li><label><input type="radio" name="shade" value="3C" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3C</div></label></li>
                        <li><label><input type="radio" name="shade" className="checkbox-tooth" disabled/><div></div></label></li>
                        <li><label><input type="radio" name="shade" value="3A" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>3A</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="4E" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4E</div></label></li>
                        <li><label><input type="radio" name="shade" value="4C" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4C</div></label></li>
                        <li><label><input type="radio" name="shade" value="4B" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4B</div></label></li>
                        <li><label><input type="radio" name="shade" value="4A" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>4A</div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" className="checkbox-tooth" disabled/><div></div></label></li>
                        <li><label><input type="radio" name="shade" className="checkbox-tooth" disabled/><div></div></label></li>
                        <li><label><input type="radio" name="shade" value="5B" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>5B</div></label></li>
                        <li><label><input type="radio" name="shade" className="checkbox-tooth" disabled/><div></div></label></li>
                    </ul>
                    <ul>
                        <li><label><input type="radio" name="shade" value="6E" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>6E</div></label></li>
                        <li><label><input type="radio" name="shade" value="6C" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>6C</div></label></li>
                        <li><label><input type="radio" name="shade" value="6B" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>6B</div></label></li>
                        <li><label><input type="radio" name="shade" className="checkbox-tooth" disabled/><div></div></label></li>
                    </ul>
                </div>
            </div>

            <div className="item">
                <div className="box">
                    <ul>
                        <li><label><input type="radio" name="shade" value="BL4" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>BL4</div></label></li>
                        <li><label><input type="radio" name="shade" value="BL3" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>BL3</div></label></li>
                        <li><label><input type="radio" name="shade" value="BL2" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>BL2</div></label></li>
                        <li><label><input type="radio" name="shade" value="BL1" className="checkbox-tooth" onClick={() => shadeSelect("")}/><div>BL1</div></label></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
import useTranslate from "components/language/useTranslate";
import CompanyPayment from "components/mypage/CompanyPayment";
import { _CO_TYPE_CENTER, _CO_TYPE_CLINIC, _CO_TYPE_DESIGN, _CO_TYPE_LAB } from "config";
import { useState } from "react";
import CompanyDeviceMng from "./CompanyDeviceMng";
import CompanyInfo from "./CompanyInfo";
import CompanyPlanMng from "./CompanyPlanMng";
import CompanyProductMng from "./CompanyProductMng";
import CompanyUserListMng from "./CompanyUserListMng";

export default function CompanyDetailMng ({companyType, selectCompany, setSelectCompany, handleReLoad}) {
    const [tap, setTap] = useState("info");
    const t = useTranslate();
    
    const handleChangeTap = (e) => setTap(e.target.value);

    function tapView(){
        switch (tap){
            case "info" :
                return <CompanyInfo selectCompany={selectCompany} setSelectCompany={setSelectCompany} handleReLoad={handleReLoad}/>
            case "user" :
                return <CompanyUserListMng companyType={companyType} selectCompany={selectCompany}/>
            case "plan" :
                return <CompanyPlanMng selectCompany={selectCompany}/>;
            case "product" :
                return <CompanyProductMng selectCompany={selectCompany}/>;
            case "payment" :
                return <CompanyPayment CO_SEQ={selectCompany.CO_MAP.CO_SEQ} VIEW_TITLE={false}/>;
            case "device" :
                return <CompanyDeviceMng selectCompany={selectCompany}/>;
            default :
                return <></>;
        }
    }

    return(
        <div className="inquiry-box" style={{padding: "20px"}}>
            <div className="flex justify-content-between align-items-center mb20">
                <div className="tabs4">
                    <button className={tap === "info" ? "active" : null} value={"info"} onClick={handleChangeTap}>{t("common_bizInfo")}</button>
                    {selectCompany && <button className={tap === "user" ? "active" : null} value={"user"} onClick={handleChangeTap}>{t("memberMg_info")}</button>}
                    {companyType !== _CO_TYPE_CENTER && <button className={tap === "plan" ? "active" : null} value={"plan"} onClick={handleChangeTap}>{t("common_planInfo")}</button>}
                    {(companyType === _CO_TYPE_CLINIC || companyType === _CO_TYPE_LAB) && <button className={tap === "product" ? "active" : null} value={"product"} onClick={handleChangeTap}>{t("memberMg_prodInfo")}</button>}
                    {companyType === _CO_TYPE_CLINIC && <button className={tap === "device" ? "active" : null} value={"device"} onClick={handleChangeTap}>{t("memberMg_deviceMg")}</button>}
                    {(companyType === _CO_TYPE_CLINIC || companyType === _CO_TYPE_DESIGN) && <button className={tap === "payment" ? "active" : null} value={"payment"} onClick={handleChangeTap}>{t("memberMg_paymentInfo")}</button>}
                </div>
                <button type="button" className="btn-close" onClick={() => setSelectCompany(null)}></button>
            </div>
            {tapView()}
        </div>
    )
}
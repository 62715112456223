import useTranslate from 'components/language/useTranslate';
import { setButtonText } from 'utils/common';

export default function OrderButtonStatus({BTN_MAP, handleOrderStatus, modifyOrder, handleSplitModal, handleOrderMap, handleRemakeControlModal, handleRepairCancel}){
    const {BTN_PREV, BTN_NEXT, BTN_DELETE, BTN_SPLIT, BTN_AMEND, BTN_REPAIR, BTN_REPAIR_CANCEL} = BTN_MAP;
    
    const t = useTranslate();

    return (
        <div className="home-nav">
            <div className="flex gap10 align-items-center">
                {BTN_DELETE === "Y" &&
                    <div>
                        <button className='btn btn-fill-gray2' value="E" onClick={() => handleOrderStatus("E")}><i className="xi-trash"></i>{t("order_step_delete")}</button>
                    </div>
                }

                {BTN_SPLIT === "Y" &&
                    <div>
                        <button className='btn btn-fill-gray2' onClick={handleSplitModal}><i className="xi-cut"></i>{t("order_step_split")}</button>
                    </div>
                }

                {BTN_AMEND === "Y" &&
                    <div>
                        <button className='btn btn-fill-gray2' onClick={modifyOrder}><i className="xi-pen"></i>{t("order_step_edit")}</button>
                    </div>
                }

                {BTN_PREV && 
                    <div>
                        <button 
                            key={"statusBtn_" + BTN_PREV} 
                            className="btn btn-fill-gray2" 
                            value={BTN_PREV} 
                            onClick={() => handleOrderStatus(BTN_PREV)}
                        >
                            <i className="xi-reply"></i>
                            {setButtonText(BTN_PREV)}
                        </button>
                    </div>
                }
                {BTN_REPAIR === "Y" && 
                    <div>
                        <button
                            key={"statusBtn_Repair" + BTN_REPAIR} 
                            className="btn btn-fill-gray2" 
                            onClick={() => handleOrderStatus("P")}
                        >
                            <i className="xi-wrench"></i>
                            {t("order_step_repair")}
                        </button>
                    </div>
                }
                {BTN_REPAIR_CANCEL === "Y" && 
                    <div>
                        <button
                            key={"statusBtn_Repaircancel" + BTN_REPAIR_CANCEL} 
                            className="btn btn-fill-gray2" 
                            onClick={() => handleRepairCancel()}
                        >
                            <i className="xi-reply"></i>
                            {t("order_step_repair_cancel")}
                        </button>
                    </div>
                }
                {BTN_NEXT && 
                    <button 
                        key={"statusBtn_" + BTN_NEXT} 
                        className="btn btn-fill-blue3" 
                        value={BTN_NEXT} 
                        onClick={() => handleOrderStatus(BTN_NEXT)}
                    >
                        {setButtonText(BTN_NEXT)}
                        <i className="xi-angle-right"></i>
                    </button>
                }
            </div>
            <div>
                <div>
                    <button className="btn btn-fill-gray2" onClick={() => handleOrderMap()}>{t("common_list")}</button>
                </div>
            </div>
        </div>
    )
}
import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import useTranslate from "components/language/useTranslate";
import { _CO_TYPE_CLINIC, _CO_TYPE_DESIGN } from "config";
import { useStore } from "contexts/Store";
import { Line } from "react-chartjs-2";
import { cm_dateAddMonth, cm_dateMoment } from "utils/common";

export default function PayChartSection({AB_LIST}){
    const store = useStore();
    const {CO_TYPE} = store.US_MAP;
    const t = useTranslate();

    //chart
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,   //div 박스 사이즈에 맞춤
        borderWidth : 3,    //line 굵기
        tension: 0.5,       //line 텐션
        pointRadius: 3,
        pointBorderWidth: 1,
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "#4C90F6",
        pointHoverBorderColor: "#4C90F6",
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            x : {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: 12,
                        weight: 700
                    }
                }
            },
            y : {
                grid: {
                    display: false
                },
                ticks: {
                    display: false,
                }
            }, 
        },
        interaction : {
            mode : "index",
            intersect: false
        }
    };

    const labels = AB_LIST.map(x => (cm_dateMoment(cm_dateAddMonth(x.AB_MONTH, -1), "MM"))+t("common_month")).sort((a, b) => a.AB_MONTH < b.AB_MONTH ? -1 : 1);
    
    const datas = AB_LIST.map(x => x.AB_IAMOUNT);

    const data = {
        labels,
        datasets: [
            {
                data: datas,
                borderColor: '#4C90F6',
                backgroundColor: '#4C90F6',
            }
        ],
    };

    return(
        <div className="section">
            <div className="head">
                <h3 className="h3">
                    {(CO_TYPE === _CO_TYPE_CLINIC || CO_TYPE === _CO_TYPE_DESIGN) ? t("home_usageAmountTrend") : t("home_salesAmountTrend")}
                </h3>
            </div>
            <div className="body chart">
                <Line data={data} options={options} />
            </div>
        </div>
    )
}
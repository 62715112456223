import { useLang } from 'contexts/LangContext';
import dic_ko from './dictionary_ko.json';
import dic_en from './dictionary_en.json';
import dic_jp from './dictionary_jp.json';
import dic_ru from './dictionary_ru.json';

const dic = {
    "ko": dic_ko,
    "en": dic_en,
    "jp": dic_jp,
    "ru": dic_ru,
};

export function useTranslate() {
    const lang = useLang();
    const translate = (key) => dic[lang][key] || "";
    return translate;
}

export default useTranslate;

import { useEffect, useState } from "react";
import useAsync from "./useAsync";
import { holidayListApi } from "api/holidayApi";
import { cm_dateAddDay, cm_dateMoment, resApiCheck } from "utils/common";

export default function useDueDate() {
    const [asyncHolidayList] = useAsync(holidayListApi);
    const [holidayList, setHolidayList] = useState([]);

    const [dueDate, setDueDate] = useState(new Date());
    const [minDate, setMinDate] = useState(new Date());

    useEffect(() => {
        const handleHolidayList = async () => {
            const resHolidayList = await asyncHolidayList({});
            if(!resApiCheck(resHolidayList)) return;
            if(resHolidayList.data.LIST_COUNT > 0){
                setHolidayList(resHolidayList.data.LIST);
            }
    
            const [minDate, prevDate] = handleDueDate(resHolidayList.data.LIST);
    
            setDueDate(prevDate);
            setMinDate(minDate);
        }

        handleHolidayList();
    },[])

    const handleDueDate = (holidays) => {
        let prevDate = new Date();
        let minDate = new Date();
        
        let cnt = 0;
        while(true){
            if([0, 6].includes(prevDate.getDay()) || holidays.map(x => x.HD_DATE).includes(cm_dateMoment(prevDate, "YYYY-MM-DD"))) {
                prevDate = cm_dateAddDay(prevDate, 1);
            }else{
                cnt++;

                if(cnt === 4){
                    minDate = prevDate;
                }else if(cnt === 5){
                    break;
                }

                prevDate = cm_dateAddDay(prevDate, 1);
            }
        }

        return [minDate, prevDate]
    }

    return [holidayList, minDate, dueDate];
}
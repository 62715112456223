import DropFileInput from "components/DropFileInput";
import useTranslate from "components/language/useTranslate";
import OrderSections from "components/order/OrderSections";
import useAsync from "hooks/useAsync";
import useDueDate from "hooks/useDueDate";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_acceptFile, cm_dateMoment, cm_isEmpty, cm_swAlert } from "utils/common";
import CalendarDropdown from "./CalendarDropdown";

export default function RemakeModal({isOpen, handleModal, orderMap, setOrderMap, orderUpdate, targetStatus}) {
    const {OL_LIST} = orderMap;
    const [remakeTeeth, setRemakeTeeth] = useState([]);
    const [comment, setComment] = useState("");
    const [fileList, setFileList] = useState([]);
    const t = useTranslate();

    const handleComment = (e) => {
        setComment(e.target.value);
    }

    const onFileChange = (files) => {
        const accepts = [".obj", ".ply", ".stl", ".dxd", ".png", ".jpg"];
        let list = cm_acceptFile(accepts, files, t("alert_common_file"));

        setFileList(list);
    }

    const [dueDate, setDueDate] = useState(new Date());
    const onChangeDueDate = (date) => {setDueDate(date)};

    const [holidayList, minDate, defaultDueDate] = useDueDate();
    useEffect(() => {
        const handleDueDate = () => {
            setDueDate(cm_dateMoment(defaultDueDate));
        }

        handleDueDate();
    },[defaultDueDate])

    const handleRemakeTeeth = (product, toothnum) => {
        const productIdx = remakeTeeth.findIndex(item => item.product === product);

        if (productIdx !== -1) {
            // 이미 선택한 제품인 경우
            const newRemakeTeeth = [...remakeTeeth];
            const toothIndex = newRemakeTeeth[productIdx].num.indexOf(toothnum);
            
            if (toothIndex !== -1) {
                // 이미 선택한 toothnum이 num 배열에 있다면 제거
                newRemakeTeeth[productIdx].num.splice(toothIndex, 1);
                
                if (newRemakeTeeth[productIdx].num.length === 0) {
                    // num 배열이 비었다면 해당 객체 제거
                    newRemakeTeeth.splice(productIdx, 1);
                }
            } else {
                // 선택한 toothnum이 num 배열에 없으면 추가
                newRemakeTeeth[productIdx].num.push(toothnum);
            }

            setRemakeTeeth(newRemakeTeeth);
        } else {
            // 선택되지 않은 제품이면 새 항목 추가
            const newRemakeTeeth = [...remakeTeeth, { product, num: [toothnum] }];
            setRemakeTeeth(newRemakeTeeth);
        }
    }

    const handleRemake = () => {
        if(!validateRemake()) return;

        let remakeLine = [];
        for(let tooth of remakeTeeth){
            remakeLine = [...remakeLine, ...OL_LIST.filter(x => x.PR_CODE === tooth.product && tooth.num.includes(x.OL_TOOTH))];
        }

        const updatedLine = remakeLine.map(obj => {
            obj.OL_STATUS = "A";
            return obj;
        });
        
        setOrderMap(produce(prevValue => {
            prevValue.OL_LIST = updatedLine;
        }));

        const OC_TEXT = `${comment} \n ${stringifyRemakeTeeth()}`;
        orderUpdate(targetStatus, fileList, null, OL_LIST, dueDate, OC_TEXT);

        handleModal();
    }

    function validateRemake(){
        if(cm_isEmpty(remakeTeeth)) {
            cm_swAlert(t("order_content_9"), "warning", t("common_check"));
            return false;
        }

        if(cm_isEmpty(comment)){
            cm_swAlert(t("order_content_11"), "warning", t("common_check"));
            return false;
        }

        if(cm_isEmpty(fileList)){
            cm_swAlert(t("alert_order_scan_file"), "warning", t("common_check"));
            return false;
        }

        return true;
    }

    const stringifyRemakeTeeth = () => {
        return remakeTeeth.map(item => `${item.product} : ${item.num.join(', ')}`).join(' | ');
    };

    return (
            <div className="modal fade" id="Modal1" style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth:"500px", margin:"50px auto"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">{t("order_remake")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                        </div>

                        <div className="modal-body">
                            <h4 className="form-tit">{t("order_selectPsthetic")}</h4>
                            <div className="tooth-list3 type2">
                                <OrderSections OL_LIST={OL_LIST} remake={true} remakeTeeth={remakeTeeth} handleRemakeTeeth={handleRemakeTeeth}/>
                            </div>

                            <h4 className="form-tit mt20">{t("order_remake_prosthetic")}</h4>
                            <input type="text" className="inp block" defaultValue={stringifyRemakeTeeth()} placeholder="" disabled/>

                            <h4 className="form-tit mt20">{t("order_remake_reasons")}</h4>
                            <input type="text" className="inp block" onChange={handleComment} value={comment} placeholder={t("order_content_8")}></input>

                            <h3 className="form-tit mt20">{t("order_info_deadline")}</h3>
                            <div className="inp-box" style={{zIndex:"1"}}>
                                <CalendarDropdown
                                    isOn={false}
                                    value={dueDate}
                                    minDate={minDate}
                                    onChange={onChangeDueDate}
                                    holidayList={holidayList}
                                    title={t("order_info_deadline")}
                                />
                            </div>
                            <div className="form-msg">* {t("order_write_text_deadline")}</div>

                            <h4 className="form-tit mt20">{t("order_scanFile")}</h4>
                            <DropFileInput
                                type={"SCAN"}
                                onChangeFile={(files) => onFileChange(files)}
                                fileList={fileList}
                                setFileList={setFileList}
                                accept={".obj, .ply, .stl, .dxd, .png, .jpg"}
                            />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-fill-gray" data-bs-dismiss="modal" onClick={handleModal}>{t("common_cancel")}</button>
                            <button type="button" className="btn btn-fill-blue" onClick={handleRemake}>{t("common_check")}</button>
                        </div>
                    </div>
                </div>
            </div>
    )
}
import { orderTempFileApi } from "api/orderApi";
import useTranslate from "components/language/useTranslate";
import OrderCheck from "components/order/OrderCheck";
import OrderInfo from "components/order/OrderInfo";
import OrderOption from "components/order/OrderOption";
import OrderProduct from "components/order/OrderProduct";
import { ORDER_WRITE_TAB } from "config";
import { useOrder, useSetOrder } from 'contexts/OrderContext';
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cm_isEmpty, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function OrderWrite() {
    const order = useOrder();
    const setOrder = useSetOrder();
    const [tab, setTab] = useState("info");
    const location = useLocation();
    const orderWriteTab = ORDER_WRITE_TAB();
    const t = useTranslate();
    
    //API
    const [asyncOrderTempFile] = useAsync(orderTempFileApi);

    useEffect(()=> {
        if(location.state){
            const {OD_MAP, FL_LIST, OL_LIST} = location.state.orderMap;

            const OD_FILE_SCAN = FL_LIST.filter(item => item.FL_REFKEY === 'SCAN');

            const currentOrder = {
                CO_SEQ : OD_MAP.CO_SEQ,
                OD_SEQ : OD_MAP.OD_SEQ,
                OD_STATUS : "V",
                OD_DUEDATE: OD_MAP.OD_DUEDATE,
                OD_NAME: OD_MAP.OD_NAME,
                OD_FILE_SCAN: OD_FILE_SCAN,
                OL_LIST: OL_LIST,
                OD_BRIDGE: JSON.parse(OD_MAP.OD_BRIDGE),
                OD_CONTACT: OD_MAP.OD_CONTACT,
                OD_EMBRASURE: OD_MAP.OD_EMBRASURE,
                OD_HOOK: OD_MAP.OD_HOOK,
                OD_PONTIC: OD_MAP.OD_PONTIC,
                OD_MEMO: OD_MAP.OD_MEMO,
                OD_DC : OD_MAP.OD_DC,
                OD_DESIGNER : OD_MAP.OD_DESIGNER,
                OD_CHECK : {
                    info : false,
                    product : false,
                    option : false,
                    check : false,
                },
                TEMP_KEY : "",
                OD_UKEY : OD_MAP.OD_UKEY,
            }
            setOrder(currentOrder);
        }

        //TEMP_KEY CHECK
        if(sessionStorage.getItem("TEMP_KEY")){
            setOrder(produce(prevValue => {
                prevValue.TEMP_KEY = sessionStorage.getItem("TEMP_KEY")
            }))

            handleTempFileLoad();
        }

    },[])

    const handleTempFileLoad = async () => {
        let response = await asyncOrderTempFile({
            TEMP_KEY : sessionStorage.getItem("TEMP_KEY")
        })
        if(!resApiCheck(response)) return;

        if(response.data.FL_LIST.length > 0){
            setOrder(produce(prevValue => {
                prevValue.OD_FILE_SCAN = response.data.FL_LIST;
            }))
        }

        sessionStorage.removeItem("TEMP_KEY");
    }

    const changeTab = (tab) => {
        let tabFlag = false;

        switch(tab){
            case "info":
                tabFlag = true;
                break;
            case "product":
                tabFlag = checkOrderInfo();
                break;
            case "option":
                tabFlag = checkOrderProduct();
                break;
            case "check":
                tabFlag = checkOrderOptions();
                break;
            default:
                break;
        }

        if(tabFlag){
            setTab(tab);
        }
    }

    function checkOrderInfo() {
        let result = true;

        if(cm_isEmpty(order.OD_NAME)) {
            cm_swAlert(t("alert_order_name"), "warning", t("common_check"));
            return false;
        }

        if(cm_isEmpty(order.OD_FILE_SCAN)) {
            cm_swAlert(t("alert_order_scan_file"), "warning", t("common_check"));
            return false;
        }

        orderComplete("info", result);

        return result;
    }

    function checkOrderProduct() {
        let result = true;

        if(!checkOrderInfo()){
            return false;
        }

        if(cm_isEmpty(order.OL_LIST)) {
            cm_swAlert(t("alert_formulaPsthetic"), "warning", t("common_check"));
            return false;
        }

        orderComplete("product", result);

        return result;
    }

    function checkOrderOptions() {
        let result = true;

        if(!checkOrderProduct()){
            return false;
        }

        orderComplete("option", result);

        return result;
    }

    function orderComplete(tab, result){
        setOrder(produce(prevValue => {
            prevValue.OD_CHECK[tab] = result;
        }));
    }

    return(
        <>
            <div className="tabs2">
                {orderWriteTab.map((data) => {
                    let thisClass = null;

                    if(order.OD_CHECK[data.key]){
                        thisClass = "end";

                        if(tab === data.key) {
                            thisClass += " active";
                        }

                    }else{
                        if(tab === data.key) {
                            thisClass = "active";
                        }
                    }

                    return(
                        <button key={data.key}
                            className={thisClass}
                            value={data.key}
                            onClick={(e) => changeTab(e.target.value)}
                        >
                            {data.title}
                        </button>
                    )
                })}
            </div>

            <OrderTab tab={tab} changeTab={changeTab}/>
        </>
    )
}

function OrderTab({tab, changeTab}){
    const [delFiles, setDelFiles] = useState([]);
    const t = useTranslate();
    const navigate = useNavigate();

    const cancelOrder = async () => {
        const confrim = await cm_swConfirm(t("order_step_cancle"), t("alert_orderCancel"), "warning", t("common_yes"), t("common_no"));
        if(confrim) navigate("../");
    }

    switch(tab){
        case 'info':
            return <OrderInfo changeTab={changeTab} cancelOrder={cancelOrder} delFiles={delFiles} setDelFiles={setDelFiles} />
        case 'product':
            return <OrderProduct changeTab={changeTab} cancelOrder={cancelOrder}/>
        case 'option':
            return <OrderOption changeTab={changeTab} cancelOrder={cancelOrder}/>
        case 'check':
            return <OrderCheck changeTab={changeTab} cancelOrder={cancelOrder} delFiles={delFiles} />
        default:
            return null;
    }
}
import { configListApi } from "api/configApi";
import { userListApi } from "api/userApi";
import Pagination from "components/common/Pagination";
import useTranslate from "components/language/useTranslate";
import UserDetail from "components/manage/UserDetail";
import UserList from "components/manage/UserList";
import { useSetStore, useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import { useEffect, useState } from "react";
import { cm_isEmpty, resApiCheck } from "utils/common";

export default function Staff () {
    const store = useStore();
    const setStore = useSetStore();
    const [userList, setUserList] = useState(null);
    const [userListCount, setUserListCount] = useState(0);
    const [selectUser, setSelectUser] = useState(null);

    const limit = 10;
    const [pageIndex, setPageIndex] = useState({
        CO_SEQ : store.US_MAP.CO_SEQ,
        PAGE_ROWS : limit,
        PAGE_INDEX : 1,
    });
    const handlePage = (page) => {
        setPageIndex(produce(prevValue => {
            prevValue.PAGE_INDEX = page;
        }));
    }
    
    //API
    const [asyncUserList] = useAsync(userListApi);
    const [asyncConfigList] = useAsync(configListApi);

    const t = useTranslate();
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pageIndex.PAGE_INDEX]);

    const handleLoad = async () => {
        const resUserList = await asyncUserList(pageIndex);
        if(!resApiCheck) return;
        if(resUserList.data.LIST_COUNT > 0){
            setUserList(resUserList.data.LIST);
            setUserListCount(resUserList.data.LIST_COUNT);
        }else{
            setUserList([]);
            setUserListCount(0);
        }

        // Config US_TYPE list
        if(cm_isEmpty(store.US_TYPE_LIST)){
            const response = await asyncConfigList({CF_TYPE : "US_TYPE"});
            if(!resApiCheck(response)) return;
            if(response.data.LIST.length > 0){
                setStore(produce((prevValue) => {
                    prevValue.US_TYPE_LIST = response.data.LIST;
                }));
            }
        }
    }

    function readUserInfo(US_SEQ){
        if(!cm_isEmpty(US_SEQ)){
            let findUser = userList.find(x => x.US_SEQ === US_SEQ);
            if(!cm_isEmpty(findUser)){
                setSelectUser({US_MAP : findUser});
            }
        }else{
            setSelectUser(null);
        }
    }

    return(
        <div className="admin-content">
            <div className="title-head">
                <h3>{t("memberMg_userMg")}</h3>
            </div>

            {!selectUser ? 
                <>
                    <UserList CO_TYPE={store.US_MAP.CO_TYPE} CO_SEQ={store.US_MAP.CO_SEQ}  userList={userList} onClickDetail={readUserInfo} reLoad={handleLoad}/>
                    <Pagination total={userListCount} limit={limit} page={pageIndex.PAGE_INDEX} setPage={handlePage}/>
                </>
                :
                <div className="inquiry-box" style={{padding: "20px"}}>
                    <div className="flex justify-content-between align-items-center mb20">
                        <div className="tabs4">
                        </div>
                        <button type="button" className="btn-close" onClick={() => setSelectUser(null)}></button>
                    </div>
                    <UserDetail CO_TYPE={store.US_MAP.CO_TYPE} selectUser={selectUser} setSelectUser={setSelectUser} reLoad={handleLoad}/>
                </div>
            }
        </div>
    )
}
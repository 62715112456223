import { useState, useEffect } from "react";
import UserImplantModelModal from "components/modal/UserImplantModelModal";
import { useStore } from "contexts/Store";
import { Modal } from "react-bootstrap";
import useTranslate from "components/language/useTranslate";
import { userOptionListApi } from "api/userApi";

export default function ImplantOption({fixture, handleFixture}) {
    const store = useStore();
    const [userModelList, setUserModelList] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const t = useTranslate();

    useEffect(() => {
        handleLoad();

        let startPoint = 0;
        let endPoint = 0;
        const slide = document.querySelector(".implant-menu");

        // PC 드래그 이벤트
        const handleClickStart = (e) => {
            e.preventDefault();
            startPoint = e.pageX;
        }
        
        const handleClickEnd = (e) => {
            e.preventDefault();
            endPoint = e.pageX;
            let options = document.querySelector("#options");
            if(startPoint < endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft - 600, top: 0, behavior:"smooth"});
            }else if (startPoint > endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft + 600, top: 0, behavior:"smooth"});
            }
        }

        slide.addEventListener("mousedown", handleClickStart, false);
        slide.addEventListener("mouseup", handleClickEnd, false);
        
        // 모바일 스와이프 이벤트
        const handleTouchStart = (e) => {
            e.preventDefault();
            startPoint = e.touches[0].pageX;
        }

        const handleTouchEnd = (e) => {
            e.preventDefault();
            endPoint = e.changedTouches[0].pageX;
            let options = document.querySelector("#options");
            if(startPoint < endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft - 600, top: 0, behavior:"smooth"});
            }else if (startPoint > endPoint){
                let curScrollLeft = options.scrollLeft;
                options.scrollTo({left: curScrollLeft + 600, top: 0, behavior:"smooth"});
            }else{
                handleFixture(Number(e.target.value));
            }
        }
        
        slide.addEventListener("touchstart", handleTouchStart, false);
        slide.addEventListener("touchend", handleTouchEnd, false);

        //PC click 이벤트
        const left = document.querySelector(".button-left");
        const right = document.querySelector(".button-right");

        const handleClickLeft = () => {
            let options = document.querySelector("#options");
            let curScrollLeft = options.scrollLeft;
            options.scrollTo({left: curScrollLeft - 600, top: 0, behavior:"smooth"});
        }
    
        const handleClickRight = () => {
            let options = document.querySelector("#options");
            let curScrollLeft = options.scrollLeft;
            options.scrollTo({left: curScrollLeft + 600, top: 0, behavior:"smooth"});
        }

        left.addEventListener("click", handleClickLeft);
        right.addEventListener("click", handleClickRight);

        return() => {
            slide.removeEventListener("mouseDown", handleClickStart);
            slide.removeEventListener("mouseup", handleClickEnd);
            slide.removeEventListener("touchstart", handleTouchStart);
            slide.removeEventListener("touchend", handleTouchEnd);
            left.removeEventListener("click", handleClickLeft);
            right.removeEventListener("click", handleClickRight);
        };

    },[isOpen]);

    const handleLoad = async () => {
        const response = await userOptionListApi({
            US_SEQ : store.US_MAP.US_SEQ,
            UO_TYPE : "I"
        });

        if(response.data.LIST.length > 0){
            setUserModelList(response.data.LIST);
        }
    }

    const handleOpenRigiImplant = () => setIsOpen((nextIsOpen) => !nextIsOpen);

    return(
        <>
            <div style={{display: "flex", alignItems: "center"}}>
                <div className="button">
                    <button className="button-left"></button>
                </div>
                <div className="implant-menu">
                    <ul id="options" style={{flexWrap:"nowrap", overflow:"hidden"}}>
                        {userModelList && 
                            userModelList.map((option) => {
                                return (
                                    <li style={{flex: "0 0 auto"}} key={"implant_" + option.UO_SEQ} className={fixture === option.UO_SEQ ? "active" : null}>
                                        <button 
                                            key={"optionBtn_" + option.UO_SEQ}
                                            className= {"btn-dep1"}
                                            value={option.UO_SEQ}
                                            onClick={() => handleFixture(option.UO_SEQ)}
                                        >
                                            {option.UO_NAME}
                                        </button>
                                    </li>
                                )
                        })}
                    </ul>
                </div>
                <div className="button">
                    <button className="button-right"></button>
                </div>
            </div>
            <button className="btn btn-fill-gray m10" onClick={handleOpenRigiImplant}>{t("order_oftenModelRegi")}</button>
            <Modal show={isOpen} onHide={handleOpenRigiImplant} centered>
                <UserImplantModelModal isOpen={isOpen} handleModal={handleOpenRigiImplant} reLoad={handleLoad} />
            </Modal>
        </>
    )
}
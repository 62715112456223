import { Outlet } from 'react-router-dom';
import SideBar from './components/include/SideBar';
import Header from './components/include/Header';
import { MyInfoProvider } from 'contexts/MyInfoContext';

export default function Frame() {
    return (
        <MyInfoProvider>
            <div id="wrap-user">
                <Header />
                <SideBar />
                <div id="container">
                    <Outlet />
                </div>
            </div>
        </MyInfoProvider>
    );
}
import useTranslate from "components/language/useTranslate";
import { cm_isEmpty } from "utils/common";

export default function PayDeviceInfo({ tap, companyDeviceList, DV_CON_LIST}){
    const t = useTranslate();

    function findTitle(CF_CODE){
        let findConfig = DV_CON_LIST.find(x => x.CF_CODE === CF_CODE);
        return findConfig.CF_TITLE;
    }

    return(
        <div className="table1" style={{ maxHeight: "395px", overflow:"auto"}}>
            <table id={tap}>
                <colgroup>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "25%"}}/>
                    <col style={{width: "25%"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>{t("device_equipType")}</th>
                        <th>{t("common_mf")}</th>
                        <th>{t("common_mfModel")}</th>
                        <th>{t("common_prodName")}</th>
                    </tr>
                </thead>
                <tbody>
                    {!cm_isEmpty(companyDeviceList) ?
                        companyDeviceList.map((device) => {
                            return(
                                <tr key={device.CD_SEQ}>
                                    <td>{findTitle(device.DV_TYPE)}</td>
                                    <td>{findTitle(device.DV_MAN)}</td>
                                    <td>{findTitle(device.DV_MODEL)}</td>
                                    <td>{device.DV_NAME}</td>
                                </tr>
                            )
                        })
                        :
                        <tr><td colSpan='4' align='center'>{t("payment_noEquip")}</td></tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
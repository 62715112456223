import { useEffect, useState } from "react";
import useAsync from "hooks/useAsync";
import useTranslate from 'components/language/useTranslate';
import UserImplantModelModal from "components/modal/UserImplantModelModal";
import { Modal } from "react-bootstrap";
import { cm_isEmpty, cm_swAlert, cm_JsonParseList, resApiCheck, cm_swConfirm } from "utils/common";
import { userOptionDeleteApi, userOptionListApi } from "api/userApi";
import { useStore } from "contexts/Store";

export default function UserImplantOpt(){
    const [myImplantList, setMyImplantList] = useState([]);
    const [selectObj, setselectObj] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [reLoad, setReLoad] = useState(false);
    const store = useStore();
    
    const [asyncUserOptionList] = useAsync(userOptionListApi);
    const [asyncUserOptionDelete, userOptionDeletePending] = useAsync(userOptionDeleteApi);
    
    const t = useTranslate();
    
    useEffect(() => {
        handleLoad();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reLoad]);

    const handleLoad = async () => {
        const response = await asyncUserOptionList({
            US_SEQ : store.US_MAP.US_SEQ,
            UO_TYPE : "I"
        });
        if(!resApiCheck(response)) return;
        if(response.data.LIST.length > 0){
            setMyImplantList(cm_JsonParseList(response.data.LIST, "UO_OPTION"));
        }else{
            setMyImplantList([]);
        }
    }
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    const handleReLoad = () => setReLoad((prevValue) => !prevValue);

    const handleAddModify = (e) => {
        const {name, value} = e.target;
        let findOpt = null;
        if(name === "modify"){
            findOpt = myImplantList.find(x => x.UO_SEQ === Number(value));
            setselectObj(findOpt);
        }else{
            setselectObj(null);
        }

        handleModal();
    }

    const handleDelete = async (e) => {
        const UO_SEQ = e.target.value;

        if(!cm_isEmpty(UO_SEQ)){
            const confirm = await cm_swConfirm(t("confirm_common_delete"), "", "warning", t("common_yes"), t("common_no"));
            if(confirm){
                let response = await asyncUserOptionDelete({
                    UO_SEQ: UO_SEQ,
                });
                if(!resApiCheck(response)){
                    cm_swAlert(t("alert_delete_failed"), "error", t("common_check"));
                }else{
                    cm_swAlert(t("alert_delete_success"), "success", t("common_check"));
                    handleReLoad();
                }
            }
        }

    }

    return(
        <>
            <div className="table1 mb30">
                <table>
                    <colgroup>
                        <col style={{width: "30%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "20%"}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t("myPage_displayName")}</th>
                            <th>{t("common_mf")}</th>
                            <th>{t("common_prodName")}</th>
                            <th>{t("myPage_screwType")}</th>
                            <th>{t("myPage_size")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {!cm_isEmpty(myImplantList) ? 
                            myImplantList.map((implant) => {
                                return(
                                    <tr key={`UO_${implant.UO_SEQ}`}>
                                        <td>{implant.UO_NAME}</td>
                                        <td>{implant.UO_OPTION.IMP_MAN}</td>
                                        <td>{implant.UO_OPTION.IMP_TYPE}</td>
                                        <td>{implant.UO_OPTION.IMP_SCREW}</td>
                                        <td>{implant.UO_OPTION.IMP_SIZE}</td>
                                        <td>
                                            <button className="btn btn-fill-gray btn-s" style={{marginRight: "10px"}} name="modify" value={implant.UO_SEQ} onClick={handleAddModify}>{t("common_edit")}</button>
                                            <button className="btn btn-fill-gray btn-s" value={implant.UO_SEQ} disabled={userOptionDeletePending} onClick={handleDelete}><img src="img/common/ico_trash.png" alt="" /> {t("myPage_del")}</button>
                                        </td>
                                    </tr>            
                                )
                            })
                            :
                            <tr><td colSpan={6}>{t("order_searchOrder_content_1")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="text-right">
                <button className="btn btn-fill-blue" name="add" value="" onClick={handleAddModify} >{t("common_add")}</button>
            </div>
            <Modal show={isOpen} onHide={handleModal} centered>
                <UserImplantModelModal isOpen={isOpen} handleModal={handleModal} selectObj={selectObj} reLoad={handleReLoad} />
            </Modal>
        </>
    )
}
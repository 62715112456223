import { axio } from 'utils/common';
import { API } from 'config';

// BOARD
export async function boardListApi(data){
    return await axio(API.BOARD_LIST, "POST", data);
}

export async function boardReadApi(data){
    return await axio(API.BOARD_READ, "POST", data);
}

export async function boardWriteApi(data){
    return await axio(API.BOARD_WRITE, "POST", data, {"Content-Type": "multipart/form-data"});
}

export async function boardDeleteApi(data){
    return await axio(API.BOARD_DELETE, "POST", data);
}

export async function boardCommentListApi(data){
    return await axio(API.BOARD_COMMENT_LIST, "POST", data);
}

export async function boardCommentWriteApi(data){
    return await axio(API.BOARD_COMMENT_WRITE, "POST", data);
}

export async function boardCommentDeleteApi(data){
    return await axio(API.BOARD_COMMENT_DELETE, "POST", data);
}





import { companyPaymentUpdateApi } from 'api/companyApi';
import Radio from 'components/common/Radio';
import RadioGroup from 'components/common/RadioGroup';
import useTranslate from 'components/language/useTranslate';
import useAsync from 'hooks/useAsync';
import { produce } from 'immer';
import { useState } from 'react';
import { cm_formatCardNo, cm_foramtCardMasked, cm_isEmail, cm_isEmpty, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function MyPaymentItem({myPay, setMyPay, quveInfo, VIEW_TITLE}){
    const t = useTranslate();
    const [companyPayment, setCompanyPayment] = useState(myPay)
    const [edit, setEdit] = useState(false);
    let quveBank = "";

    if(!cm_isEmpty(quveInfo.CF_VAL3)){
        quveBank = quveInfo.CF_VAL3;
    }

    //API
    const [asyncCompanyPaymentUpdate] = useAsync(companyPaymentUpdateApi);

    //edit
    const handleChangeFlag = () => {
        if(edit){
            handleUpdatePaymentInfo();
        }
        setEdit((prevValue) => !prevValue);
    }

    const handleChangePaymentInfo = (e) => {
        let {name, value} = e.target;
        setCompanyPayment(produce((prevValue) => {
            prevValue[name] = value;
        }))
    }

    const handleUpdatePaymentInfo = async () => {
        if(!saveValidate()) return;

        const response = await asyncCompanyPaymentUpdate({
            CO_SEQ : companyPayment.CO_SEQ,
            CA_EMAIL : companyPayment.CA_EMAIL,
            CA_TAXEMAIL : companyPayment.CA_TAXEMAIL,
            CA_FAX :companyPayment.CA_FAX
        });
        if(!resApiCheck(response)){
            cm_swAlert(t("alert_save_failed"), "warning", t("common_check"));

        }else{
            cm_swAlert(t("alert_save_success"), "success", t("common_check"));
        }
    }

    function saveValidate(){
        const {CA_EMAIL, CA_TAXEMAIL}  = companyPayment;

        let result = true;

        if(cm_isEmpty(CA_EMAIL)){
            cm_swAlert(t("alert_pay_content_1"), "warning", t("common_check"));
            result = false;
        }else if(cm_isEmpty(CA_TAXEMAIL)){
            cm_swAlert(t("alert_pay_content_12"), "warning", t("common_check"));
            result = false;
        }else if(!cm_isEmail(CA_EMAIL, true, `${t("myPage_billMail")} ${t("common_alert_wrongFormEmail")}`)){
            result = false;
        }else if(!cm_isEmail(CA_TAXEMAIL, true, `${t("myPage_taxMail")} ${t("common_alert_wrongFormEmail")}`)){
            result = false;
        }

        return result;
    }

    const handleChangePayment = async () => {
        const confirm = await cm_swConfirm(t("confirm_pay_info_edit") + "<br>" + t("alert_payEdit_content_2"), "", "info", t("common_yes"), t("common_no"));
        if(confirm){
            setMyPay(null);
        }
    }

    return(
        <div className="form-inner">
            {VIEW_TITLE && <h3 className="h3" >{t("myPage_payWay")}</h3>}
            <h4 className="form-tit">{t("myPage_payMethod")}</h4>
            <div className="flex gap12 align-items-center mb20">
                <RadioGroup value={myPay.CA_METHOD} disabled={true}>
                    <Radio name={"CA_METHOD"} value={"B"}>{t("myPage_directDepo")}</Radio>
                    {/* <Radio name={"CA_METHOD"} value={"C"}>{t("myPage_creditCheck")}</Radio> */}
                </RadioGroup>
                {/* {edit && 
                    <button className="btn btn-fill-blue" onClick={handleChangePayment}>{t("myPage_editPay")}</button>
                } */}
            </div>

            <h4 className="form-tit">{myPay.CA_METHOD === "C" ? t("myPage_rigiCredit") : t("myPage_depositAccount")}</h4>
            <div className="form-div">
                {myPay.CA_METHOD === "C" ?
                    <input type="text" className="inp block" value={cm_formatCardNo(cm_foramtCardMasked(myPay.CA_NO))} disabled></input>
                    :
                    <input type="text" className="inp block" value={quveBank} disabled></input>
                    }
            </div>

            <h4 className="form-tit">{t("myPage_billMail")}</h4>
            <div className="form-div">
                <input type="text" 
                    className="inp block" 
                    name="CA_EMAIL" 
                    value={companyPayment.CA_EMAIL}
                    onChange={handleChangePaymentInfo}
                    placeholder={t("myPage_billMail")}
                    disabled={!edit}
                />
            </div>
            <h4 className="form-tit">{t("myPage_taxMail")}</h4>
            <div className="form-div">
                <input type="text" 
                    className="inp block" 
                    name="CA_TAXEMAIL" 
                    value={companyPayment.CA_TAXEMAIL}
                    onChange={handleChangePaymentInfo}
                    placeholder={t("myPage_billMail")}
                    disabled={!edit}
                />
            </div>
            <h4 className="form-tit">{t("myPage_faxNumber")}</h4>
            <div className="form-div">
                <input type="text" 
                    className="inp block" 
                    name="CA_FAX" 
                    value={companyPayment.CA_FAX}
                    onChange={handleChangePaymentInfo}
                    disabled={!edit}
                />
            </div>
            <div className="btn-common-box">
                <button className="btn btn-fill-blue" onClick={handleChangeFlag}>{edit? t("common_save") : t("common_edit")}</button>
            </div>
        </div>
    )
}

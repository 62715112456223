import DaumPostCode from 'react-daum-postcode';

const DaumPost = ({handleZipcode, handleAddress, setModalIsOpen}) => {
    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';
        
        const {addressType, bname, buildingName} = data
        if (addressType === 'R') {
            if (bname !== '') {
                extraAddress += bname;
            }
            if (buildingName !== '') {
                extraAddress += `${extraAddress !== '' && ', '}${buildingName}`;
            }
            fullAddress += `${extraAddress !== '' ? ` ${extraAddress}` : ''}`;
        }

        handleZipcode(data.zonecode);
        handleAddress(fullAddress);

        setModalIsOpen(false);
    }

    return (
        <div>
            <DaumPostCode onComplete={handleComplete} className="post-code" style={{height:470}} />
        </div>
    );
}
export default DaumPost;
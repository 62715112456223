import { onSilentRefresh, tokenUserApi } from "api/commonApi";
import useTranslate from "components/language/useTranslate";
import { useSetStore, useStore } from "contexts/Store";
import useAsync from "hooks/useAsync";
import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from 'react-router-dom';
import { cm_isEmpty, cm_swAlert, resApiCheck } from "utils/common";

export default function PrivateRoute() {
    const store = useStore();
    const setStore = useSetStore();
    const navigate = useNavigate();
    const t = useTranslate();

    const [asyncOnSilentRefresh] = useAsync(onSilentRefresh);
    const [asyncTokenUser] = useAsync(tokenUserApi);

    // token, US_MAP
    const [wait, setWait] = useState(false);
    useEffect(() => {
        loginCheck();
    },[]);

    function loginCheck(){
        // 정상 로그인시 store.US_MAP, localStorage COUNTRY 보유
        if(!cm_isEmpty(store.US_MAP) && localStorage.getItem("refresh_token")){
            setWait(true);
        }else if(cm_isEmpty(store.US_MAP) && localStorage.getItem("refresh_token")){
            // 로그인 후 새창으로 들어올 경우
            handleLoad();
        }else{
            localStorage.removeItem("refresh_token");
            cm_swAlert(t("common_content_2"), "info", t("common_check"));

            navigate("/")
        }
    }

    const handleLoad = async () => {
        // axio header token setting
        const response = await asyncOnSilentRefresh({});
        if(!resApiCheck(response)) return;

        // store US_MAP setting
        const resTokenUser = await asyncTokenUser({});
        if(!resApiCheck(resTokenUser)) return;

        if(!cm_isEmpty(resTokenUser.data)){
            let US_MAP = resTokenUser.data;
            setStore(produce((prevValue) => {
                prevValue.US_MAP = US_MAP;
            }))
            setWait(true);
        }else{
            // 로그인 페이지로 이동 localStorage 삭제(초기화)
            localStorage.removeItem("refresh_token");
            cm_swAlert(t("common_content_2"), "info", t("common_check"));
            navigate("/")
        }
    }

    return(
        <>
            {wait &&
                <Outlet />
            }
        </>
    ) 
};
import axios from 'axios';
import { API } from 'config';
import { axio, cm_isEmpty } from 'utils/common';

// COMMON
export async function emailAuthApi(data){
    return await axio(API.EMAIL_AUTH, "POST", data);
}

export async function signUpApi(data){
    return await axio(API.SIGN_UP, "POST", data);
}

export async function signInApi(data){
    const response = await axios({
        url: API.SIGN_IN,
        method : "POST",
        data: data,
    }).then(signInSuccess)
    .catch((err) => {
        console.error(err);
        window.location.href = window.location.origin;
    });

    return response;
}

export const signInSuccess = (response) => {
    let access_token = null;
    let refresh_token = null;

    // onSilentRefresh로 받는 값은 토큰이 들어있는 TK_MAP없음, 바로 사용.
    if(cm_isEmpty(response.data.TK_MAP)){
        access_token = response.data.access_token;
        refresh_token = response.data.refresh_token;
    }else{
        access_token = response.data.TK_MAP.access_token;
        refresh_token = response.data.TK_MAP.refresh_token;
    }

    //setting
    localStorage.setItem("refresh_token", refresh_token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

    // 23시간마다 Refresh
    setTimeout(onSilentRefresh, 82800000);

    return response;
}

export async function onSilentRefresh(){
    const data = {
        refresh_token: localStorage.getItem("refresh_token"),
        grant_type:"refresh_token"
    };

    const response = await axios({
        url: API.TOKEN,
        method: "POST",
        headers: {
			"Content-Type": "application/json",
		},
        data: JSON.stringify(data),
        timeout: Number(process.env.REACT_APP_AXIO_TIMEOUT),
    })
    .then(signInSuccess)
    .catch((err) =>{
        console.error(err);
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = window.location.origin;
    })

    return response;
}

export async function findPwdApi(data){
    return await axio(API.FIND_PWD, "POST", data);
}

export async function indexApi(data){
    return await axio(API.INDEX, "POST", data);
}

export async function tokenUserApi(data){
    return await axio(API.TOKEN_USER, "POST", data);
}

export async function deleteFileApi(data){
    return await axio(API.DELETE_FILE, "POST", data);
}

export async function encryptUrlApi(data){
    return await axio(API.ENCRYPT_URL, "POST", data);
}
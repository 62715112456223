import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { produce } from "immer";
import $ from "jquery"
import { cm_swAlert, checkFullCase, cm_isEmpty, cm_swConfirm, resApiCheck, cm_isNum } from "utils/common";

import { configListApi } from "api/configApi";
import { companyProductListApi } from "api/companyApi";

import { useOrder, useSetOrder } from "contexts/OrderContext";

import OrderTeethBox from "./OrderTeethBox";
import useTranslate from "components/language/useTranslate";
import ImplantOption from "./ImplantOption";
import OrderSections from "./OrderSections";
import ShadeImage from "./ShadeImage";
import ShadeOption from "./ShadeOption";
import { _CO_TYPE_DESIGN } from "config";
import { useStore } from "contexts/Store";

export default function OrderProduct(props){
    const t = useTranslate();

    const store = useStore();
    const {CO_TYPE, CO_SEQ} = store.US_MAP;

    const order = useOrder();
    const setOrder = useSetOrder();

    const [prTypeList, setPrTypeList] = useState(null);
    const [productList, setProductList] = useState(null);

    const [productType, setProductType] = useState("");
    const [productCode, setProductCode] = useState("");

    const [isOpen, setIsOpen] = useState(false);
    const handleModal = () => setIsOpen((prevValue) => !prevValue);

    useEffect(()=> {
        handleLoad();
    },[]);

    // Shade Option
    const [useShade, setUseShade] = useState(false);
    const [shadeCompany, setShadeCompany] = useState("Vita classic");
    const [shade, setShade] = useState("/");

    // Implant option
    const [useFixture, setUseFixture] = useState(false);
    const [fixture, setFixture] = useState(0);
    const handleFixture = (UO_SEQ) => {
        setFixture(UO_SEQ)
    }

    // Temprary option
    const [useTemprary, setUseTemprary] = useState(false);
    const [temprary, setTemprary] = useState(false);

    // Pmma option
    const [usePmma, setUsePmma] = useState(false);
    const [pmma, setPmma] = useState(false);
    
    // Pontic option
    const [usePontic, setUsePontic] = useState(false);
    const [pontic, setPontic] = useState(false);

    // API products 데이터
    const handleLoad = async () => {
        const resPrTypeList = await configListApi({CF_TYPE_START : "PR_TYPE"});
        if(!resPrTypeList) return;
        const PR_TYPE_LIST = resPrTypeList.data.LIST;

        const response = await companyProductListApi({CO_SEQ : order.CO_SEQ ? order.CO_SEQ : CO_SEQ});
        if(!resApiCheck(response)) return;
        const PR_LIST = response.data.LIST.filter(x => x.CR_SALES === "Y");

        setProductList(PR_LIST.sort((a, b) => a.PR_DPSEQ < b.PR_DPSEQ ? -1 : 1));
        setPrTypeList(PR_TYPE_LIST.filter(x => PR_LIST.some(item => item.PR_TYPE === x.CF_CODE)).sort((a, b) => a.CF_VAL1 < b.CF_VAL1 ? -1 : 1));
    }

    async function onClickReset() {
        const confirm = await cm_swConfirm(t("confirm_order_product_reset"), "", "info", t("common_yes"), t("common_no"));

        if(confirm){
            initTooth();
        }
    }

    const initTooth = () => {
        setOrder(produce(prevValue => {
            prevValue.OL_LIST = [];
            prevValue.OD_BRIDGE = [];
        }));

        setTemprary(false);
        setPmma(false);
        setPontic(false);

    }

    function onClickTooth(toothNum){
        if(cm_isEmpty(productCode)){
            cm_swAlert(t("alert_order_prosthetics_choice"), "warning", t("common_check"));
            return;
        }

        const [top, bottom] = shade.split("/");
        if(useShade && (cm_isEmpty(top) || cm_isEmpty(bottom))) {
            handleModal();
            return;
        }

        if(useFixture && fixture === 0){
            cm_swAlert(t("alert_prodImplModelSelect"), "warning", t("common_check"));
            return;
        }

        setTeethObj(toothNum, productCode);
    }

    const setTeethObj = (toothNum, PR_CODE) => {
        const toothIdx = getToothIdx(toothNum);
        const toothType = getToothType(toothNum);

        let currentTeeth = [...order.OL_LIST];
        let currentGroup = [...order.OD_BRIDGE];
        let prevSameTooth = currentTeeth.find(x => x.OL_TOOTH === toothNum && x.PR_CODE === PR_CODE);

        if(checkFullCase(PR_CODE)){
            currentTeeth = handleTeeth(currentTeeth, toothType, PR_CODE);
            currentGroup = [];
        } else {
            if(useTemprary && temprary){
                currentTeeth = handleTooth(currentTeeth, toothNum, "PLA-Cr");
            }

            if(usePmma && pmma){
                currentTeeth = handleTooth(currentTeeth, toothNum, "PMMA-Cr");
            }

            currentTeeth = handleTooth(currentTeeth, toothNum, PR_CODE);

            const PR_GROUP = productGroup(PR_CODE);
            if(PR_GROUP > 0){
                const frontTooth = currentTeeth.find(x => getToothType(x.OL_TOOTH) === toothType && getToothIdx(x.OL_TOOTH) === (toothIdx - 1) && productGroup(x.PR_CODE) === PR_GROUP);
                const backTooth = currentTeeth.find(x => getToothType(x.OL_TOOTH) === toothType && getToothIdx(x.OL_TOOTH) === (toothIdx + 1) && productGroup(x.PR_CODE) === PR_GROUP);
    
                if(frontTooth){
                    const targetBridge = frontTooth.OL_TOOTH + "_" + toothNum;
                    if (!isBridgeRefreshed(targetBridge)) {
                        currentGroup = handleBridge(currentGroup, targetBridge, null, prevSameTooth);
                    }
                }
    
                if(backTooth){
                    const targetBridge = toothNum + "_" + backTooth.OL_TOOTH;
                    if (!isBridgeRefreshed(targetBridge)) {
                        currentGroup = handleBridge(currentGroup, targetBridge, null, prevSameTooth);
                    }
                }
            }
        }

        setOrder(produce(prevValue => {
            prevValue.OL_LIST = currentTeeth;
            prevValue.OD_BRIDGE = currentGroup;
        }))
    }

    const getToothIdx = (toothNum) => {
        return $("#" + toothNum).index();
    }

    const getToothType = (toothNum) => {
        if(!cm_isNum(toothNum)) return;

        return $("#" + toothNum).parent("g").attr("id").split("-")[1];
    }

    function productGroup(PR_CODE){
        return productList.find(x => x.PR_CODE === PR_CODE).PR_GROUP;
    }

    const handleTeeth = (currentTeeth, toothType, PR_CODE) => {
        let newTeeth = currentTeeth.filter(x => x.OL_TOOTH === "u" || x.OL_TOOTH === "l");
        let full = toothType === "maxilla" ? "u" : "l";

        if(currentTeeth.find(x => x.OL_TOOTH === full)){
            newTeeth = newTeeth.filter(x => x.OL_TOOTH !== full);
        }else{
            newTeeth.push(toothObj(full, PR_CODE));
        }

        return newTeeth;
    }

    const handleTooth = (currentTeeth, tooth, PR_CODE) => {
        let newTeeth = [];

        const sameTooth = currentTeeth.find(x => x.OL_TOOTH === tooth && x.PR_CODE === PR_CODE);

        if(sameTooth){
            if(!pontic){
                newTeeth = currentTeeth.filter(x => !(x.OL_TOOTH === tooth && x.PR_CODE === PR_CODE));
            }else{
                if(sameTooth.OL_PONTIC === "Y"){
                    newTeeth = currentTeeth.filter(x => !(x.OL_TOOTH === tooth && x.PR_CODE === PR_CODE));
                }else{
                    newTeeth = [...currentTeeth.filter(x => x.OL_TOOTH !== tooth), toothObj(tooth, PR_CODE)];
                }
            }
        }else{
            newTeeth = [...currentTeeth, toothObj(tooth, PR_CODE)];
        }

        newTeeth = newTeeth.sort((a, b) => a.idx < b.idx ? -1 : 1);
        
        return newTeeth;
    }

    const toothObj = (toothnum, PR_CODE) => {
        const product = productList.find(x => x.PR_CODE === PR_CODE);
        
        let sellPrice = pontic ? product.CR_PNPRICE : product.CR_PRICE;
        if(CO_TYPE === _CO_TYPE_DESIGN){
            sellPrice = pontic ? product.CR_DSPNPRICE : product.CR_DSPRICE;
        }
        
        return {
            OL_TOOTH : toothnum,
            OL_STATUS : "M",
            PR_SEQ : product.PR_SEQ,
            PR_TYPE : product.PR_TYPE,
            PR_CODE : product.PR_CODE,
            OL_SELLPRICE : sellPrice,
            OL_PONTIC : (pontic ? "Y" : "N"),
            OL_SHADE : (product.PR_SHADE === "Y" ? shade : "/"),
            UO_SEQ : (pontic ? 0 : fixture),
        }
    }

    const onClickBridge = (e) => {
        let btnGroup = $(e.target).attr("group");
        let currentGroup = handleBridge([...order.OD_BRIDGE], btnGroup, "bridge");
        setOrder(produce(prevValue => {
            prevValue.OD_BRIDGE = currentGroup;
        }));
    }

    function handleBridge(bridges, target, handle = null, prevSameTooth = null) {
        const [front, back] = target.split('_').map(OL_TOOTH => OL_TOOTH);
        const bothBridge = bridges.find(x => x.split("_").includes(front) && x.split("_").includes(back));
        const otherBridges = bridges.filter(x => !x.split("_").includes(front) && !x.split("_").includes(back));

        if(bothBridge) { // target 1,2 모두 포함한경우 - Split
            const newBridges = splitBridge(bothBridge, target);
            
            if(pontic && handle !== "bridge" && !cm_isEmpty(prevSameTooth) && prevSameTooth.OL_PONTIC === "N"){
                return [...bridges];
            }

            return [...newBridges, ...otherBridges];
        } else { // 그외 - Merge
            const frontBridge = bridges.find(x => x.split("_").includes(front));
            const backBridge = bridges.find(x => x.split("_").includes(back));

            // 두 배열이 모두 존재할 경우 Merge
            if (frontBridge && backBridge) {
                const newBridge = frontBridge + "_" + backBridge;
                return [newBridge, ...bridges.filter(x => x !== frontBridge && x !== backBridge)];
            }

            // 두 배열 모두 없을경우 Add
            if (!frontBridge && !backBridge) {
                return [...bridges.filter(x => x !== frontBridge), target];
            }

            // 두 배열 중 하나만 존재할 경우 Add
            if (frontBridge) {
                return [...bridges.filter(x => x !== frontBridge), (frontBridge + target.replace(front, ""))];
            }

            if (backBridge) {
                return [...bridges.filter(x => x !== backBridge), (target.replace(back, "") + backBridge)];
            }

            return [...bridges];
        }
    }

    function splitBridge(bothBridge, target){
        const [front, back] = target.split("_");

        let newBridge = bothBridge.split(target);
        newBridge[0] = newBridge[0] + front;
        newBridge[1] = back + newBridge[1];

        return newBridge.filter(x => x.indexOf("_") !== -1);
    }

    function isBridgeRefreshed(targetBridge) {
        const button = document.querySelector(`button[group="${targetBridge}"]`);
        return button && button.classList.contains('btn-refresh');
    }

    const deleteSection = (PR_CODE) => {
        const deleteTeeth = order.OL_LIST.filter(x => x.PR_CODE === PR_CODE);
        const currentTeeth = order.OL_LIST.filter(x => x.PR_CODE !== PR_CODE);
        const currentBridge = order.OD_BRIDGE.filter(bridge => {
            return (!deleteTeeth.some(obj => bridge.includes(obj.OL_TOOTH)) || currentTeeth.some(obj => bridge.includes(obj.OL_TOOTH)));
        })

        setOrder(produce(prevValue => {
            prevValue.OL_LIST = currentTeeth;
            prevValue.OD_BRIDGE = currentBridge;
        }))
    }

    function handleProductType(type){
        setProductType(type);
        
        const productItems = productList.filter(x => x.PR_TYPE === type);
        handleProductCode(productItems[0].PR_CODE);
    }

    function handleProductCode(PR_CODE){
        setProductCode(PR_CODE);
        setUseOptions(PR_CODE);

        initOptions();
    }

    function setUseOptions(PR_CODE){
        const product = productList.find(x => x.PR_CODE === PR_CODE);
        if(!product) return;

        setUseFixture(product.PR_TYPE === "I" ? true : false);
        setUseShade(product.PR_SHADE === "Y" ? true : false);
        setUseTemprary(product.PR_TEMP === "Y" ? true : false);
        setUsePmma(product.PR_PMMA === "Y" ? true : false);
        setUsePontic(product.PR_PONTIC === "Y" ? true : false);
    }

    function initOptions(){
        setFixture(0);
        setTemprary(false);
        setPmma(false);
        setPontic(false);
    }

    return (
        <>
            <div className="order-writeFrame">
                <div className="side">
                    <div className="sideDiv left">
                        <button className="arrow arrow-left" onClick={() => props.changeTab("info")}></button>
                    </div>
                </div>
                <div className="middle">
                    <div className="inner1100">
                        <div className="choice-wrap">
                            <div className="left">
                                <h3 className="h3">{t("order_selectPsthetic")} 
                                    <div className="tip-wrap">
                                        <i className="xi-error"></i>
                                        <div className="tip-box">
                                            <strong>{t("order_content_3")}</strong>
                                            <small>{t("order_content_4")}</small>
                                        </div>
                                    </div>
                                </h3>
                            </div>

                            <div className="menu-dep2">
                                <ul>
                                    {prTypeList?.map((type, idx) => {
                                        return(
                                            <li key={type.CF_CODE} className={productType === type.CF_CODE ? "active" : null}>
                                                <button 
                                                    key={type.CF_CODE}
                                                    className= {`btn-dep1 c${idx}`}
                                                    value={type.CF_CODE}
                                                    onClick={(e) => handleProductType(e.target.value)}
                                                >
                                                    {type.CF_TITLE}
                                                </button>
                                                <ul>
                                                    {productList.filter(x => x.PR_TYPE === type.CF_CODE)?.map((product) => {
                                                        return(
                                                            <li key={product.PR_CODE} className={product.PR_CODE === productCode ? "active" : null}>
                                                                <button 
                                                                    className="btn-dep2"
                                                                    value={product.PR_CODE}
                                                                    onClick={() => handleProductCode(product.PR_CODE)}
                                                                >
                                                                    {product.PR_NAME}
                                                                </button>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            {useShade && 
                                <div className="tooth-box3">
                                    <ShadeImage shade={shade} imgSize={"small"} handleModal={handleModal}/>
                                    <div className="txt">{shadeCompany}</div>
                                </div>
                            }
                        </div>
                        
                        {useFixture && 
                            <>
                                <hr/>

                                <div className="flex gap20 mb20">
                                    {/* <h3 className="h3 mb5" style={{alignContent:"center"}}>{t("order_implModelSelect")}</h3> */}
                                    <h3 className="h3 mb5" style={{width: "120px", lineBreak: "anywhere"}}>{t("order_implModelSelect")}</h3>
                                    <ImplantOption fixture={fixture} handleFixture={handleFixture} />
                                </div>
                            </>
                        }

                        <hr/>

                        <div className="flex mb20 justify-content-between">
                            <h3 className="h3">{t("order_selectFormula")}</h3>
                            <div className="flex align-items-center gap10 ">
                                {useTemprary && 
                                    <label>
                                        <input type="checkbox" className="switch2" onChange={() => setTemprary((prevValue) => !prevValue)} checked={temprary}/>
                                        <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p><span>{t("order_temporaryTeeth")}</span></div>
                                    </label>
                                }
                                {usePmma && 
                                    <label>
                                        <input type="checkbox" className="switch2" onChange={() => setPmma((prevValue) => !prevValue)} checked={pmma}/>
                                        <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p><span>{"PMMA"}</span></div>
                                    </label>
                                }
                                {usePontic &&
                                    <label>
                                        <input type="checkbox" className="switch2" onChange={() => setPontic((prevValue) => !prevValue)} checked={pontic}/>
                                        <div><p><em><i className="xi-check"></i><i className="xi-close"></i></em></p><span>{t("order_pontic")}</span></div>
                                    </label>
                                }

                                <button className="btn btn-icon btn-m" onClick={() => onClickReset()}>{t("order_reset")}</button>
                            </div>
                        </div>
                        <OrderTeethBox OL_LIST={order.OL_LIST} OD_BRIDGE={order.OD_BRIDGE} productList={productList} onClickTooth={onClickTooth} onClickBridge={onClickBridge}/>

                        <hr/>

                        <div className="tooth-wrap2">
                            <div className="tooth-list3">
                                <OrderSections OL_LIST={order.OL_LIST} deleteSection={deleteSection}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="side">
                    <div className="sideDiv right">
                        <button className="arrow arrow-right" onClick={() => props.changeTab("option")}></button>
                    </div>
                </div>
            </div>
            

            <Modal show={isOpen} onHide={handleModal} centered>
                <SelectShadeModal isOpen={isOpen} handleModal={handleModal} shadeCompany={shadeCompany} setShadeCompany={setShadeCompany} shade={shade} setShade={setShade} />
            </Modal>
        </>
    )
}

function SelectShadeModal({isOpen, handleModal, shadeCompany, setShadeCompany, shade, setShade}) {
    const t = useTranslate();
    const [currShade, setCurrShade] = useState(shade);

    const shadeSelect = (keyname) => {
        const [top, bottom] = currShade.split("/");

        document.getElementsByName('shade').forEach((item) => {
            if(item.checked){
                if(cm_isEmpty(top) && cm_isEmpty(bottom)){
                    setCurrShade(item.value + "/" + item.value)
                }else{
                    if(keyname === "top"){
                        setCurrShade(item.value + "/" + bottom);
                    }else if(keyname === "bottom"){
                        setCurrShade(top + "/" + item.value);
                    }
                }
            }
        })
    }

    const handleShadeConfirm = () => {
        setShade(currShade);
        handleModal();
    }
    
    function onClickCompany(company){
        setShadeCompany(company);
        setCurrShade("/");
    }

    return (
        <div className="modal fade" style={isOpen ? {display : "block"} : null}>
            <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "1040px"}}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">{t("order_shade")}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                    </div>
                    <div className="modal-body" id="orderRequest">
                        <div className="shade-wrap">
                            <div className="btns">
                                <button className={shadeCompany === "Vita classic" ? "active" : null} onClick={() => onClickCompany("Vita classic")}>Vita classic</button>
                                <button className={shadeCompany === "Vita 3D Master" ? "active" : null} onClick={() => onClickCompany("Vita 3D Master")}>Vita 3D Master</button>
                                <button className={shadeCompany === "Ivoclar" ? "active" : null} onClick={() => onClickCompany("Ivoclar")}>Ivoclar</button>
                            </div>
                            <div className="choices">
                                <ShadeOption shadeCompany={shadeCompany} shadeSelect={shadeSelect}/>
                            </div>

                            <div className="tooth-divide">
                                <ShadeImage shade={currShade} shadeSelect={shadeSelect}/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-fill-blue" onClick={handleShadeConfirm}>{t("common_check")}</button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
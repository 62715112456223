import { accountInvoiceApi, accountSendInvoiceApi } from "api/accountApi";
import useTranslate from "components/language/useTranslate";
import makePdf from "hooks/makePdf";
import useAsync from "hooks/useAsync";
import { useEffect, useState } from "react";
import { _FMT_DT, cm_dateMoment, cm_isEmpty, cm_numComma, cm_swAlert, cm_swConfirm, resApiCheck } from "utils/common";

export default function InvoicePreviewModal({isOpen, handleModal, handleReLoad, account}){
    const t = useTranslate();
    const init = {
        AD_AMOUNT_MAP : {},
        AD_OD_DATA : {},
        AD_QUVE_MAP : {},
        AD_RECI_MAP : {},
        AD_SUPP_MAP : {}
    }

    const [invoice, setInvoice] = useState(init);
    const [asyncAccountInvoice] = useAsync(accountInvoiceApi);

    useEffect(() => {
        handleLoad();
    },[]);

    const handleLoad = async () => {
        const response = await asyncAccountInvoice({
            CO_SEQ : account.CO_SEQ,
            AB_MONTH : account.AB_MONTH,
        })
        if(!resApiCheck(response)) return;
        if(!cm_isEmpty(response.data.AD_MAP)){
            setInvoice(response.data.AD_MAP);
        }
    }

    const handleDownInvoice = (isDownload = true) => {
        return makePdf('invoice', `${account.CO_NAME}_INVOICE_${account.AB_MONTH}`, isDownload);
    }

    const [asyncSendInvoice, sendInvoicePending] = useAsync(accountSendInvoiceApi);

    const handleSendInvoice = async () => {
        const pdf = await handleDownInvoice(false);

        const confirm = await cm_swConfirm(t("confirm_bill_billing"), "", "info", t("common_yes"), t("common_no"));
        if(!confirm) return;

        let formData = new FormData();
        formData.append("AB_SEQ", account.AB_SEQ);
        formData.append("CO_SEQ", account.CO_SEQ);
        formData.append("file", pdf);

        const response = await asyncSendInvoice(formData)

        if(response.data.RESULT === "OK"){
            cm_swAlert(t("alert_bill_billing_success"), "success", t("common_check"));
            handleModal();
            handleReLoad();
        } else if (response.data.RESULT === "NO_EMAIL"){
            cm_swAlert(t("alert_bill_confirm_noEmail"), "warning", t("common_check"));
            handleReLoad();
        } else {
            cm_swAlert(t("alert_bill_billing_failed"), "warning", t("common_check"));
        }
    };

    return(

            <div className="modal fade" style={isOpen ? {display : "block"} : null}>
                <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "1100px"}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">{t("payment_bill")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleModal}></button>
                        </div>
                        <div className="modal-body invoice-preview" id="invoice" style={{marginTop: 30, marginBottom: 30}}>
                            <table className="invoice-table">
                                <colgroup>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col style={{width: "7%"}}/>
                                    <col />
                                </colgroup>
                                <thead>
                                    <tr><th colSpan={14} className="title">INVOICE</th></tr>
                                    <tr><th colSpan={14} className="month">{account.AB_MONTH}</th></tr>
                                </thead>
                                <tbody className="body">
                                    <tr>
                                        <th colSpan={7}>{t("invoice_reci")}</th>
                                        <th colSpan={7}>{t("invoice_supp")}</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>{t("common_regiNum")}</th>
                                        <td colSpan={5}>{invoice.AD_RECI_MAP.CO_REGNO}</td>
                                        <th colSpan={2}>{t("common_regiNum")}</th>
                                        <td colSpan={5}>{invoice.AD_SUPP_MAP.CO_REGNO}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>{t("common_companyName")}</th>
                                        <td colSpan={2}>{invoice.AD_RECI_MAP.CO_NAME}</td>
                                        <th colSpan={2}>{t("common_RepresentativeName")}</th>
                                        <td>{invoice.AD_RECI_MAP.CO_OWNER}</td>
                                        <th colSpan={2}>{t("common_companyName")}</th>
                                        <td colSpan={2}>{invoice.AD_SUPP_MAP.CO_NAME}</td>
                                        <th colSpan={2}>{t("common_RepresentativeName")}</th>
                                        <td>{invoice.AD_SUPP_MAP.CO_OWNER}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>{t("common_address")}</th>
                                        <td colSpan={5}>{invoice.AD_RECI_MAP.CO_ADDRESS}</td>
                                        <th colSpan={2}>{t("common_address")}</th>
                                        <td colSpan={5}>{invoice.AD_SUPP_MAP.CO_ADDRESS}</td>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>{t("common_industryType")}</th>
                                        <td colSpan={5}>{t("common_industryClinic")}</td>
                                        <th colSpan={2}>{t("common_industryType")}</th>
                                        <td colSpan={5}>{t("payment_industry_software")}</td>
                                    </tr>

                                    <tr className="row"></tr>
                                    
                                    <tr>
                                        <th>No.</th>
                                        <th colSpan={2}>{t("invoice_order_date")}</th>
                                        <th colSpan={2}>{t("invoice_complete_date")}</th>
                                        <th colSpan={2}>{t("invoice_patient_name")}</th>
                                        <th colSpan={2}>{t("common_type")}</th>
                                        <th colSpan={2}>{t("payment_dtFormula")}</th>
                                        <th>{t("payment_cnt")}</th>
                                        <th colSpan={2}>{t("common_total")}</th>
                                    </tr>
                                    {invoice.AD_OD_DATA.AD_OD_LIST?.map((list, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{idx +1}</td>
                                                <td colSpan={2}>{list.OD_REGDTTM}</td>
                                                <td colSpan={2}>{list.OD_DUEDATE}</td>
                                                <td colSpan={2}>{list.OD_NAME}</td>
                                                <td colSpan={2}>{list.OL_PR_CODE}</td>
                                                <td colSpan={2}>{list.OL_TOOTHS}</td>
                                                <td>{list.OL_TOOTHS_CNT}</td>
                                                <td colSpan={2}>{cm_numComma(list.OL_SELLPRICE_SUM)}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <th colSpan={11}>{t("invoice_total")}</th>
                                        <td colSpan={1}> {invoice.AD_OD_DATA.TOTAL_TOOTHCNT}</td>
                                        <td colSpan={2}>{cm_numComma(invoice.AD_OD_DATA.TOTAL_SELLPRICE)}</td>
                                    </tr>

                                    <tr className="row"></tr>

                                    <tr>
                                        <th>No.</th>
                                        <th colSpan={2}>{t("invoice_content_1")}</th>
                                        <th colSpan={9}>{t("invoice_content_2")}</th>
                                        <th colSpan={3}>{t("invoice_content_3")}</th>
                                    </tr>

                                    {(invoice.AD_OD_DATA?.AD_AT_LIST?.length ?? 0) > 0 ?
                                        invoice.AD_OD_DATA.AD_AT_LIST.map((item, idx) => (
                                            <tr key={`${item.AT_SEQ}_${idx}`}>
                                                <td>{idx + 1}</td>
                                                <td colSpan={2}>{cm_dateMoment(item.DTTM, _FMT_DT)}</td>
                                                <td colSpan={9}>{item.AT_MEMO}</td>
                                                <td colSpan={3}>{cm_numComma(item.AT_AMOUNT)}</td>
                                            </tr>
                                        )) :
                                        (
                                            <tr>
                                                <td colSpan={15}>{t("common_noResult")}</td>
                                            </tr>
                                        )
                                    }

                                    <tr className="row "></tr>

                                    <tr>
                                        <th>No.</th>
                                        <th colSpan={4}>{t("invoice_content_4")}</th>
                                        <th colSpan={4}>{t("common_cost")}</th>
                                        <th colSpan={5}>{t("board_content")}</th>
                                    </tr>
                                    
                                    <tr>
                                        <td>1</td>
                                        <td colSpan={4}>{t("invoice_content_5")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_RT_PRICE)}</td>
                                        <td colSpan={5}>{t("invoice_content_5")}(Prime Scan)</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td colSpan={4}>{t("invoice_content_6")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_PL_PRICE)}</td>
                                        <td colSpan={5}>{t("invoice_content_7")}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td colSpan={4}>{t("common_basePrice")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_PL_BASEPRICE)}</td>
                                        <td colSpan={5}>{t("invoice_content_8")}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td colSpan={4}>{t("invoice_content_9")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_SV_PRICE)}</td>
                                        <td colSpan={5}>{t("invoice_content_10")}</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td colSpan={4}>{t("invoice_content_3")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_AAMOUNT)}</td>
                                        <td colSpan={5}>-</td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td colSpan={4}>{t("invoice_content_11")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_TAMOUNT)}</td>
                                        <td colSpan={5}>{t("invoice_content_11")}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>6</td>
                                        <td colSpan={4}>{t("invoice_content_11")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_NAMOUNT)}</td>
                                        <td colSpan={5}>{t("invoice_content_12")}</td>
                                    </tr> 
                                    <tr>
                                        <td>7</td>
                                        <td colSpan={4}>{t("invoice_content_13")}</td>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_VAMOUNT)}</td>
                                        <td colSpan={5}>{t("invoice_content_13")} 10%</td>
                                    </tr> */}
                                    <tr>
                                        <th colSpan={5}>{t("payment_billCost")}</th>
                                        <td colSpan={4}>{cm_numComma(invoice.AD_AMOUNT_MAP.AD_TAMOUNT)}</td>
                                        <td colSpan={5}>-</td>
                                    </tr>
                                </tbody>
                            </table>
                            <footer>
                                <p>{invoice.AD_QUVE_MAP.CF_TITLE}</p>
                                <p>{invoice.AD_QUVE_MAP.CF_DESC3} : {invoice.AD_QUVE_MAP.CF_VAL3} </p>
                                <p>{invoice.AD_QUVE_MAP.CF_DESC1} : {invoice.AD_QUVE_MAP.CF_VAL1}</p>
                                <p>{invoice.AD_QUVE_MAP.CF_DESC2} : {invoice.AD_QUVE_MAP.CF_VAL2}</p>
                            </footer>
                        </div>
                        
                        <div className="flex gap10 justify-content-center">
                            <button className="btn btn-fill-blue2 btn-round" onClick={() => handleDownInvoice()}><i className="xi-download"></i>{t("invoice_content_14")}</button>
                            {account.AB_STATUS !== "C" &&
                                <button className="btn btn-fill-blue2 btn-round" onClick={() => handleSendInvoice()} disabled={sendInvoicePending}>
                                    <i className="xi-upload"></i>{account.AB_STATUS === "I" ? t("payment_bill_reBilling") : t("payment_bill_billing")}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
}
import useTranslate from 'components/language/useTranslate';
import { useMyInfo } from "contexts/MyInfoContext";

export default function CompanyDevice(){
    const t = useTranslate();
    const myInfo = useMyInfo();
    const {CD_LIST, DV_CON_LIST} = myInfo;

    function findTitle(CF_CODE){
        let findConfig = DV_CON_LIST.find(x => x.CF_CODE === CF_CODE);
        return findConfig.CF_TITLE;
    }

    return(
        <>
            <div className="table1">
                <table>
                    <thead>
                        <tr>
                            <th>{t("device_equipType")}</th>
                            <th>{t("common_mf")}</th>
                            <th>{t("common_prodName")}</th>
                            <th>{t("common_period")}</th>
                            <th>{t("myPage_serialNum")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(CD_LIST && DV_CON_LIST) ?
                                CD_LIST.map((device) => {
                                    const {DV_TYPE, DV_MAN, DV_NAME, CD_SN, CD_EDATE, CD_SDATE} = device;
                                    let period = CD_SDATE + " ~ " + CD_EDATE;
                                    return(
                                        <tr key={`CD_${device.CD_SEQ}`}>
                                            <td>{findTitle(DV_TYPE)}</td>
                                            <td>{findTitle(DV_MAN)}</td>
                                            <td>{DV_NAME}</td>
                                            <td>{period}</td>
                                            <td>{CD_SN}</td>
                                        </tr>
                                    )
                                })
                            
                            :
                            <tr><td colSpan='5' align='center'>{t("common_noResult")}</td></tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}